import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash';
import { CancelFormBody } from '../../../../components/molecules/CancelFormBody/CancelFormBody'
import { CardContainer } from '../../../../components/molecules/CardContainer/CardContainer'
import Modal from '../../../../components/organisms/Modal/Modal'
import { StepperComponent } from '../../../../components/Stepper/Stepper'
import { ProfileForm } from '../../../../components/templates/BaseLayout/Profile/ProfileForm'
import { ClientDocuments } from './ClientDocuments'
// import './ClientRegister.scss';
import '../../../../styles/stylesSteppers.scss'
import { ClientType } from './ClientType'
import { LegalRepresentative } from './LegalRepresentative'
import { PrincipalDataClient } from './PrincipalDataClient'
export const ClientRegister = (props) => {
    const { mode } = useParams;
    
    const history = useHistory()
    const [stepper, setStepper] = useState(1);
    const [formData, setFormData] = useState(
        _.get(props, 'location.state.clientType') === 1 ? {
            "isfisica":true,
            "ismoral":false,
        }:{
            "isfisica":false,
            "ismoral":true,
        });

    const [openModal, setOpenModal] = useState(false)
    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    
    const clientType = _.get(props,'location.state.clientType', 1) ;

    
    const steps = clientType == 1 ? [
        {
            label: 'Tipo de cliente',
            step:0,
            component: <ClientType stepper={stepper} setStepper={setStepper} mode={mode} />
        },
        {
            label: 'Datos principales',
            step:1,
            component: <PrincipalDataClient toggleModal={toggleModal} stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData} type={"clientefisico"} mode={mode} />
            // component: <ClientDocuments stepper={stepper} setStepper={setStepper}/>

        },
        {
            label: 'Documentación',
            step:2,
            component: <ClientDocuments mode={mode} toggleModal={toggleModal} stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData}/>
            // component: <PrincipalDataClient stepper={stepper} setStepper={setStepper}/>
        },
    ] :
    [
        {
            label: 'Tipo de cliente',
            step:0,
            component: <ClientType stepper={stepper} setStepper={setStepper} mode={mode}/>
        },
        {
            label: 'Datos principales',
            step:1,
            component: <PrincipalDataClient toggleModal={toggleModal} stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData} type={"clienteMoral"} mode={mode}/>
            // component: <ClientDocuments stepper={stepper} setStepper={setStepper}/>

        },
        {
            label: 'Datos del representante legal',
            step:2,
            component: <LegalRepresentative toggleModal={toggleModal} stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData} mode={mode}/>
            // component: <ClientDocuments stepper={stepper} setStepper={setStepper}/>

        },
        {
            label: 'Documentación',
            step:3,
            component: <ClientDocuments toggleModal={toggleModal} stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData} mode={mode}/>
             //component: <PrincipalDataClient stepper={stepper} setStepper={setStepper}/>
        },
    ]

   
    const [render, setRender]= useState(null)
    useEffect(() => {
       if(steps){
           setRender(steps[stepper].component)
       }
    }, [stepper])
    
    const renders = steps.map((ele)=>{
        return(
            ele.component
        )
    })
    const cancel = ()=>{
        history.push('/dashboard/gestion-clientes')
    }
    
    return (
        <>
        {
            openModal &&
            <Modal
                isOpen={toggleModal}
                toggle={toggleModal}
            >
                <CardContainer btnLeftAction={toggleModal} btnRightAction={cancel} body={<CancelFormBody/>}/>
            </Modal>
        }
        <div className='main-containerAuto'>
            <div className='stepper-container'>
            <StepperComponent step={stepper} steps={steps} setStepper={setStepper} client={true}/>
            </div>
            <div className='card-container-stepper'>
                {
                render
                }
            </div>
        </div>
        </>
    )
}
