import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCountriesAction, getAllStatesAction } from '../../redux/actions/addressAction';
import {
    modality,
    delivery,
    containerTypeGround,
    sino,
    service
} from './optionsQuotation';

const LandQuotationForm = ({ principalDataLandForm, setPrincipalDataLandForm }) => {

    const dispatch = useDispatch();
    const client = useSelector(state => state.client.getOneClients);
    const tradename = _.get(client, 'data[0].tradename', '')
    const changeData = (ele, value) => {
        const data = {
            ...principalDataLandForm,
            [value]: ele,
        }
        setPrincipalDataLandForm(data)
    }
    useEffect(() => {
        dispatch(getAllCountriesAction());
        if (_.get(principalDataLandForm, "id")) {
            dispatch(getAllStatesAction(
                {
                    where: {
                        countryId: principalDataLandForm.originCountryId
                    }, target: "Origen",
                }
            ));
            dispatch(getAllStatesAction(
                {
                    where: {
                        countryId: principalDataLandForm.destinyCountryId
                    }, target: "Destino",
                }
            ));
        }
    }, [])
    const countries = useSelector(state => state.address.countries.data);
    const statesOrigen = useSelector(state => state.address.statesOrigen.data);
    const statesDestino = useSelector(state => state.address.statesDestino.data);
    const [disabled, setDisabled] = useState(true);

    return (
        <div className='quotation-form-general-container'>
            <div className='value-container-principal-data'>
                <p className='data-section-title'>Datos principales</p>
                <div className='data-section-container'>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Folio</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="text"
                                placeholder="Agregar"
                                value={principalDataLandForm.invoice}
                                onChange={(e) => changeData(e.target.value, "invoice")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Nombre</label>
                        </div>
                        <div className='value-container'>
                            <input  /* control={control} name={'bussinesName'} {...register('bussinesName')} */
                                className="input-generic"
                                type="text"
                                placeholder="Agregar"
                                value={principalDataLandForm.name}
                                onChange={(e) => changeData(e.target.value, "name")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Empresa*</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="text"
                                placeholder="Agregar"
                                value={tradename && tradename}
                                onChange={(e) => changeData(e.target.value, "bussines")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Plataforma*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataLandForm.platform}
                                onChange={(e) => changeData(e.target.value, "platform")} >
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    containerTypeGround &&
                                    containerTypeGround.map((ele, i) => {
                                        return <option key={i} value={ele.value}>{ele.value}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Servicio*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataLandForm.service}
                                onChange={(e) => changeData(e.target.value, "service")} >
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    service &&
                                    service.map((ele, i) => {
                                        return <option key={i} value={ele.value}>{ele.value}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='value-container-origin-data'>
                <p className='data-section-title'>Datos de origen</p>
                <div className='data-section-container'>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>País*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataLandForm.originCountryId} onChange={(e) => {
                                changeData(parseInt(e.target.value), "originCountryId");
                                dispatch(getAllStatesAction(
                                    {
                                        where:
                                        {
                                            countryId: parseInt(e.target.value)
                                        },
                                        target: "Origen",
                                    }
                                ));
                            }}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    _.get(countries, 'data') &&
                                    countries.data.map(ele => {
                                        return <option value={ele.id}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Estado*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataLandForm.originStateId} onChange={(e) => changeData(parseInt(e.target.value), "originStateId")}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    _.get(statesOrigen, 'data') &&
                                    statesOrigen.data.map(ele => {
                                        return <option value={ele.id}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='value-container-destiny-data'>
                <p className='data-section-title'>Datos de destino</p>
                <div className='data-section-container'>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>País*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataLandForm.destinyCountryId} onChange={(e) => {
                                changeData(parseInt(e.target.value), "destinyCountryId");
                                dispatch(getAllStatesAction(
                                    {
                                        where:
                                        {
                                            countryId: parseInt(e.target.value)
                                        },
                                        target: "Destino",
                                    }
                                ));
                            }}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    _.get(countries, 'data') &&
                                    countries.data.map(ele => {
                                        return <option value={ele.id}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Estado*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataLandForm.destinyStateId} onChange={(e) => changeData(parseInt(e.target.value), "destinyStateId")}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    _.get(statesDestino, 'data') &&
                                    statesDestino.data.map(ele => {
                                        return <option value={ele.id}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='value-container-other'>
                <p className='data-section-title'>Cargos</p>
                <div className='data-section-container'>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Flete Terrestre</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="text"
                                placeholder="Agregar"
                                value={principalDataLandForm.landFreight}
                                onChange={(e) => changeData(e.target.value, "landFreight")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Sobrepeso</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="number"
                                placeholder="Agregar"
                                value={principalDataLandForm.overweight}
                                onChange={(e) => changeData(e.target.value, "overweight")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Maniobras</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="text"
                                placeholder="Agregar"
                                value={principalDataLandForm.maneuvers}
                                onChange={(e) => changeData(e.target.value, "maneuvers")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Drop off</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="text"
                                placeholder="Agregar"
                                value={principalDataLandForm.dropOff}
                                onChange={(e) => changeData(e.target.value, "dropOff")}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='value-container-total-amount'>
                <div className='input-container-total'>
                    <div className='label-container'>
                        <p className='container-label'>Total $</p>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic"
                            type="number"
                            placeholder="$"
                            value={principalDataLandForm.total}
                            onChange={(e) => changeData(e.target.value, "total")}
                        />
                    </div>
                </div>
                <div className='input-container-total'>
                    <div className='label-container'>
                        <p className='container-label'>Vigencia</p>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic date"
                            type="date"
                            min={'2023-05-12'}
                            max='2050-12-31'
                            value={principalDataLandForm.validity}
                            onChange={(e) => changeData(e.target.value, "validity")}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandQuotationForm;
