/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import '../../styles/stylesSteppers.scss';
import _ from 'lodash'
import { Button } from '../../components/atoms';
import './Records.scss';
import './RecordRegister.scss';
import { RECORDS, RECORDS_DOCUMENTATION } from '../../routes/Paths';
import RecordRegisterOne from './RecordRegister/RecordRegisterOne';

import { Title } from 'chart.js';

const RecordRegister = () => {

    const {id} = useParams();
    const location = useLocation();
    const history = useHistory();
    let btnRight = 'ACEPTAR';
    let btnLeft = 'VER DOCUMENTOS';
    let recordId = id && id;



    const defaultValues = {
        // name : '',
    };


    const docomentsView = () => {
        history.push({
            pathname: RECORDS_DOCUMENTATION.replace(':id', location.payload.row.id),
            payload:location.payload,
        });
    }


    const handleNext = () => {
        history.push(RECORDS)
    }

    return (
        <div className='records-documentation-container'>
            <form className='main-container-generic-type records'>
                <div className='header-form-generic-type'>
                    <h2>{location && location.payload&& location.payload.row && location.payload.row.client && location.payload.row.client.tradename}</h2>
                    <h4>ID: {recordId}</h4>
                </div>
                <div className='body-form-generic-type-register'>
                    {
                        <RecordRegisterOne />
                    }
                </div>
                <div className='footer-form-generic-type record-register' style={{justifyContent: 'space-between'}}>
                    <Button type='button' onClick={() => docomentsView()} className={'button-generate quotation'} style={{width: '40%'}}>{btnLeft}</Button>
                    <Button type='button' onClick={() => handleNext()} className={'button-next quotation'} style={{width: '40%'}}>{btnRight}</Button>
                </div>
            </form>
        </div>
    )
}

RecordRegister.propTypes = {
    toggleModal: PropTypes.func,
}

export default RecordRegister;
