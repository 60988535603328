/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import profile from "../../../../assets/images/perfil.png";
import edit from "../../../../assets/images/navieras/editIcon.png";
import { Button } from "../../../atoms";
import "./ProfileFormStyles.scss";
import {
  getOneAdministratorRecordAction,
  upsertAdministratorAction,
} from "../../../../redux/actions/adminAction";
import { updatePasswordRequestAction } from "../../../../redux/actions/userActions";
import { addNotification } from "../../../../redux/actions/notificationAction";
import { ADMIN } from "../../../../routes/Paths";
import MultipleSelectChip from "../../../organisms/CheckMark/MultipleSelectChip";
import { getAllPermissionsAction } from "../../../../redux/actions/permissionAction";
import MultipleSelect from "../../../organisms/CheckMark/MultipleSelect";

export const ProfileForm = (props) => {
  let { userId } = useParams();
  // const data = useSelector(state => state.admin.adminRecord.data);

  const dispatch = useDispatch();
  const history = useHistory();
  const mode = props.type;

  const user = useSelector((state) => state.user.user);
  // traer permisos

  let currentUser;
  if (user.administratorId != null) currentUser = "administrator";
  else currentUser = "operative";

  //ADD IMG

  const [imageSrc, setImageSrc] = useState(profile);
  const [fileUrl, setFileUrl] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);
  const [permisos, setPermisos] = useState([]);

  const [arrayAdd, setArrayAdd] = useState();
  const [arrayDelete, setArrayDelete] = useState();

  const api = process.env.SERVER_API_DATA_URL;
  //ESTE ES EL QUE SE VA A RECIBIR POR PARAMS
  let mainData;
  if (props.type == "profile") mainData = _.get(props.data, "data", null);
  else if (props.type == "see") mainData = props.data && props.data;
  // const dataUser = user.administratorId != null ? mainData.administrator : mainData.operative;
  const emailDefault = _.get(mainData, "email", ""); /* mainData.email; */
  const firstNameDefault = _.get(
    mainData,
    "person.firstName",
    ""
  ); /*  mainData.person.firstName; */
  const firstLastNameDefault = _.get(mainData, "person.firstLastName", "");
  const positionDefault = _.get(mainData, `${currentUser}.job`, "");
  const startsHourAtDefault = _.get(
    mainData,
    `${currentUser}.workHourStartAt`,
    ""
  );
  const endsHourAtDefault = _.get(mainData, `${currentUser}.workHourEndAt`, "");
  const fechaIngresoDefault = _.get(
    mainData,
    `${currentUser}.dateOfAdmission`,
    null
  );
  const userRolDefault =
    _.get(mainData, "administrator.isSuperAdmin") === true ? 1 : 2;
  const userRolNameDefault = userRolDefault
    ? userRolDefault === 1
      ? "Super admin"
      : "Admin"
    : "";
  const imgDefault = _.get(mainData, "media.media.url", null);
  /* data.media.media.url ? data.media.media.url : */ ("");
  const permission = _.get(mainData, "userPermission");

  const fechaIngreso = fechaIngresoDefault
    ? moment(fechaIngresoDefault).format("yyyy-MM-DD")
    : moment(new Date()).format("yyyy-MM-DD");

  const defaultValues = {
    name: firstNameDefault,
    lastName: firstLastNameDefault,
    position: positionDefault,
    email: emailDefault,
  };
  const methods = useForm({ defaultValues: defaultValues });
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = methods;

  //función para que jale la info
  const watchAllFields = watch();

  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      console.log(value, name, type)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  const id = user.id ? user.id : "";
  const [disabled, setDisabled] = useState(id ? true : false);

  useEffect(() => {
    dispatch(getAllPermissionsAction());

    if (props.type == "create") setDisabled(false);
  }, []);

  const permissions = useSelector((state) => state.permission.permissions.data);

  const [userRolSelected, setUserRolSelected] = useState(
    _.get(mainData, "administrator.isSuperAdmin") !== true ? 2 : 1
  );


  useEffect(() => {
    if (mainData) {
      let sessiones = _.get(mainData, `${currentUser}`, "");
      setHoraInicio(startsHourAtDefault);
      setHoraFin(endsHourAtDefault);
      let semana = [];
      if (sessiones.workInMonday === true) {
        semana.push({
          dia: "L",
          id: 1,
          selected: true,
        });
      } else {
        semana.push({
          dia: "L",
          id: 1,
          selected: false,
        });
      }
      if (sessiones.workInTuesday === true) {
        semana.push({
          dia: "M",
          id: 2,
          selected: true,
        });
      } else {
        semana.push({
          dia: "M",
          id: 2,
          selected: false,
        });
      }
      if (sessiones.workInWednesday === true) {
        semana.push({
          dia: "M",
          id: 3,
          selected: true,
        });
      } else {
        semana.push({
          dia: "M",
          id: 3,
          selected: false,
        });
      }
      if (sessiones.workInThursday === true) {
        semana.push({
          dia: "J",
          id: 4,
          selected: true,
        });
      } else {
        semana.push({
          dia: "J",
          id: 4,
          selected: false,
        });
      }
      if (sessiones.workInFriday === true) {
        semana.push({
          dia: "V",
          id: 5,
          selected: true,
        });
      } else {
        semana.push({
          dia: "V",
          id: 5,
          selected: false,
        });
      }
      if (sessiones.workInSaturday === true) {
        semana.push({
          dia: "S",
          id: 6,
          selected: true,
        });
      } else {
        semana.push({
          dia: "S",
          id: 6,
          selected: false,
        });
      }
      if (sessiones.workInSunday === true) {
        semana.push({
          dia: "D",
          id: 7,
          selected: true,
        });
      } else {
        semana.push({
          dia: "D",
          id: 7,
          selected: false,
        });
      }
      setSemana1(semana);
    }
  }, []);

  const roles = [
    {
      id: 1,
      name: "Super admin",
    },
    {
      id: 2,
      name: "Admin",
    },
  ];
  const [horaInicio, setHoraInicio] = useState("08:00");
  const [horaFin, setHoraFin] = useState("17:00");
  const handleAddHour = (e) => {
    setHoraInicio(e.target.value);
  };
  const handleAddHourFinal = (e) => {
    setHoraFin(e.target.value);
  };
  const [semana1, setSemana1] = useState([
    {
      dia: "L",
      id: 1,
      selected: false,
    },
    {
      dia: "M",
      id: 2,
      selected: false,
    },
    {
      dia: "M",
      id: 3,
      selected: false,
    },
    {
      dia: "J",
      id: 4,
      selected: false,
    },
    {
      dia: "V",
      id: 5,
      selected: false,
    },
    {
      dia: "S",
      id: 6,
      selected: false,
    },
    {
      dia: "D",
      id: 7,
      selected: false,
    },
  ]);
  const handleAdd = (item) => {
    let semanaCopy = JSON.parse(JSON.stringify(semana1));
    semanaCopy.map((el) => {
      if (item.id === el.id) {
        if (el.selected === true) {
          el.selected = false;
        } else {
          el.selected = true;
        }
      }
    });
    setSemana1(semanaCopy);
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        return resolve({
          src: reader.result,
          title: file.name,
        });
      };
      reader.onerror = (error) => reject(error);
    });
  };
  const unpdateInfoFile = (event) => {
    const imageFile = event.target.files;
    const urlImages = [];
    const arrImgBase64 = [];
    Object.entries(imageFile).map(async (ele) => {
      urlImages.push(URL.createObjectURL(ele[1]));

      try {
        const base64 = await toBase64(ele[1]).then((img) => {
          return img;
        });

        if (base64) {
          arrImgBase64.push(base64);
        }
      } catch (err) {
        console.log(err);
        return err;
      }
    });

    setFileUrl(urlImages);

    setImgBase64(arrImgBase64);
    // setPicturesSelected(imageFile.name)
  };

  const profileImg = fileUrl[fileUrl.length - 1]
    ? fileUrl[fileUrl.length - 1]
    : imgDefault
    ? `${api}/${imgDefault}`
    : imageSrc;

  const setImgProfile = {
    backgroundImage: `url(${profileImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  //END ADD IMG

  const onSubmit = async ({ name, lastName, position, email }) => {
    let data = {};

    let newPermissions = [];

    permisos.forEach((element) => {
      let idPermission;
      switch (element) {
        case "Administrador":
          newPermissions.push(1);
          break;
        case "Agentes Aduanales":
          newPermissions.push(2);
          break;
        case "Clientes":
          newPermissions.push(3);
          break;
        case "Cotizaciones":
          newPermissions.push(4);
          break;
        case "Expedientes":
          newPermissions.push(5);
          break;
        case "Operativos":
          newPermissions.push(6);
          break;
        case "Proveedores":
          newPermissions.push(7);
          break;
        case "Puertos":
          newPermissions.push(8);
          break;
        default:
          break;
      }
    });

    if (props.type === "see") {
      data = {
        user: userId
          ? {
              id: mainData.id,
              email: email,
            }
          : {
              email: email,
              password: "1234",
            },
        admin: {
          id: userId ? _.get(mainData, "administrator.id") : 0,
          isSuperAdmin: userRolSelected === 1 ? true : false,
          job: position,
          workHourStartAt: horaInicio,
          workHourEndAt: horaFin,
          dateOfAdmission: fechaIngreso,
          workInMonday: semana1[0].selected,
          workInTuesday: semana1[1].selected,
          workInWednesday: semana1[2].selected,
          workInThursday: semana1[3].selected,
          workInFriday: semana1[4].selected,
          workInSaturday: semana1[5].selected,
          workInSunday: semana1[6].selected,
          media:
            imgBase64.length == 0
              ? null
              : {
                  base64: {
                    src: imgBase64[0].src,
                    title: imgBase64[0].title.replace(/[{()}]/g, ""),
                  },
                },
        },
        person: {
          firstName: name,
          firstLastName: lastName,
          primaryPhone: null,
        },
        userPermission: {
          permissionDelete: arrayDelete,
          permissionUpdate: arrayAdd,
        },
      };
    } else if (props.type === "create" || props.type === "profile") {
      data = {
        user:
          props.type === "create"
            ? {
                email: email,
              }
            : {
                id: mainData.id,
                email: mainData.email,
              },
        admin: {
          isSuperAdmin: userRolSelected == 1 ? true : false,
          job: position,
          workHourStartAt: horaInicio,
          workHourEndAt: horaFin,
          dateOfAdmission: fechaIngreso,
          workInMonday: semana1[0].selected,
          workInTuesday: semana1[1].selected,
          workInWednesday: semana1[2].selected,
          workInThursday: semana1[3].selected,
          workInFriday: semana1[4].selected,
          workInSaturday: semana1[5].selected,
          workInSunday: semana1[6].selected,
          media:
            imgBase64.length < 1
              ? null
              : {
                  base64: {
                    src: imgBase64[0].src,
                    title: imgBase64[0].title.replace(/[{()}]/g, ""),
                  },
                },
        },
        person: {
          firstName: name,
          firstLastName: lastName,
          primaryPhone: null,
        },
        userPermission: {
          newPermissions,
        },
      };
    }

    console.log("res",res)
    console.log("userId",userId)

    const res = await dispatch(upsertAdministratorAction(data));
    history.push(ADMIN);
    console.log("res",res)
    console.log("userId",userId)

    if (res && !userId) {
      const res2 = await dispatch(updatePasswordRequestAction({ email }));
      if (res2 ) {
        dispatch(
          addNotification({
            toastType: "success",
            toastTitle:
              "Se han envido instrucciones para concluir la acción a su email",
          })
        );
        history.push(ADMIN);
      }
    } else if (props.isSuccessUpsertAdmin && userId) history.push(ADMIN);
  };

  
  return (
    <div className="main-container">
      <form className="form-admin-container" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-admin-profile-left form-admin-profile-cards">
          <div className="picture-container">
            <div className="preContainer">
              <div className="img-profile" style={setImgProfile}></div>
            </div>
          </div>
          <div className="text-container">
            <h2>{`${firstNameDefault} ${firstLastNameDefault}`}</h2>
            <p>
              {user.administratorId != null ? "Administrador" : "Operativo"}
            </p>
            <div
              className={
                disabled ? "container-input disable" : "container-input"
              }
            >
              <input
                type="file"
                id="carga"
                accept="image/*"
                className="file"
                name="image"
                onChange={unpdateInfoFile}
              />
              <label
                className="LeftInfoComponent-button"
                htmlFor={disabled ? "" : "carga"}
              >
                SUBIR FOTO
              </label>
            </div>
          </div>
          <div className="bottom-text">
            {/* <p>Fecha de ingreso {fecha}</p> */}
            {props.type !== "create" && (
              <p>ID : {mainData && mainData.administratorId}</p>
            )}
            <p>Fecha de ingreso: {fechaIngreso}</p>
          </div>
        </div>
        <div className="form-admin-profile-right form-admin-profile-cards">
          <div className="header-form">
            <h2>Datos principales</h2>
            {props.type !== "create" && (
              <div
                className="edit-profil-button"
                onClick={() => setDisabled(!disabled)}
              >
                {" "}
                <img src={edit} />
              </div>
            )}
          </div>
          <div className="body-form">
            <div className="input-container">
              <div className="label-container">
                <label className="container-label">Nombre(s)</label>
              </div>
              <div className="value-container">
                <input
                  disabled={disabled}
                  control={control}
                  name={"name"}
                  {...register("name")}
                  className="input-generic"
                  type="text"
                  placeholder="Nombre(s)"
                />
              </div>
            </div>
            <div className="input-container">
              <div className="label-container">
                <label className="container-label">Apellido(s)</label>
              </div>
              <div className="value-container">
                <input
                  disabled={disabled}
                  control={control}
                  name={"lastName"}
                  {...register("lastName")}
                  className="input-generic"
                  type="text"
                  placeholder="Apellido(s)"
                />
              </div>
            </div>
            <div className="input-container">
              <div className="label-container">
                <label className="container-label">Puesto</label>
              </div>
              <div className="value-container">
                <input
                  disabled={disabled}
                  control={control}
                  name={"position"}
                  {...register("position")}
                  className="input-generic"
                  type="text"
                  placeholder="Puesto"
                />
              </div>
            </div>
            <div className="input-container">
              <div className="label-container">
                <label className="container-label">Correo electronico</label>
              </div>
              <div className="value-container">
                <input
                  disabled={props.type == "create" ? false : true}
                  control={control}
                  name={"email"}
                  {...register("email")}
                  className="input-generic"
                  type="text"
                  placeholder="Correo electronico"
                />
              </div>
            </div>
            <div className="input-container">
              <div className="label-container">
                <label className="container-label">Horario</label>
              </div>
              <div
                className="value-container"
                style={{ justifyContent: "space-between" }}
              >
                {/* <input disabled={disabled} control={control} name={'name'} {...register('name')} className="input-generic" type="text" placeholder=""/> */}
                <input
                  type="time"
                  disabled={disabled}
                  control={control}
                  value={horaInicio}
                  onChange={(e) => handleAddHour(e)}
                  className="input-time"
                />
                <input
                  type="time"
                  disabled={disabled}
                  control={control}
                  value={horaFin}
                  onChange={(e) => handleAddHourFinal(e)}
                  className="input-time"
                />
              </div>
            </div>
            <div className="input-container">
              <div className="label-container"></div>
              <div className="value-container">
                <div className="container-days">
                  {semana1 &&
                    semana1.map((item, index) => (
                      <button
                        disabled={disabled}
                        key={index}
                        type="button"
                        onClick={() => handleAdd(item)}
                        className={`btnDay ${
                          item.selected === true ? "active" : ""
                        }`}
                        name={item.id}
                      >
                        {item.dia}
                      </button>
                    ))}
                </div>
              </div>
            </div>
            <div className="input-container">
              <div className="label-container">
                <label className="container-label">Fecha de ingreso</label>
              </div>
              <div className="value-container">
                <input
                  type="date"
                  disabled={true}
                  value={fechaIngreso}
                  className="input-time"
                />
              </div>
            </div>
            {userRolDefault !== null && (
              <>
                <div className="input-container">
                  <div className="label-container">
                    <label className="container-label">Tipo de usuario</label>
                  </div>
                  <div className="value-container">
                    <select
                      className="input-select"
                      disabled={true}
                      control={control}
                      value={userRolSelected}
                    //   onChange={(e) => {
                    //     setUserRolSelected(parseInt(e.target.value));
                    //   }}
                    >
                      <option
                        className="option-selected"
                        value={userRolDefault}
                        selected
                        hidden
                      >
                        {" "}
                        {userRolDefault !== ""
                          ? userRolNameDefault
                          : "Seleccionar especialidad"}
                      </option>
                      {roles &&
                        roles.map((ele, i) => {
                          return (
                            <option key={i} value={ele.id}>
                              {" "}
                              {ele.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                {/* aqui trabaja alan */}
                <div className="input-container">
                  <div className="label-container">
                    <label className="container-label">Permisos</label>
                  </div>
                  <div className="value-container">
                    {props.type === "create" &&  userRolSelected !== 1 &&
                      <MultipleSelectChip
                        disable={disabled}
                        data={permissions}
                        setPermisos={setPermisos}
                      />
                  
                     
                    }
                    {props.type === "create" && userRolSelected == 1 &&
                         <MultipleSelectChip
                         disable={true}
                         data={permissions}
                         setPermisos={setPermisos}
                       />
                    }
                    {props.type === "see" && (
                      <MultipleSelect
                        disable={mode}
                        data={permission}
                        setArrayDelete={setArrayDelete}
                        setArrayAdd={setArrayAdd}
                      />
                    )}
                    {props.type === "profile" && (
                      <MultipleSelect
                        disable={mode}
                        data={permission}
                        setArrayDelete={setArrayDelete}
                        setArrayAdd={setArrayAdd}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {(props.type === "create" || !disabled) && (
            <div className="footer-form">
              <h2 onClick={!userId ? () => reset() : () => history.goBack()}>
                {!userId ? "Limpiar campos" : "Cancelar"}
              </h2>
              <Button type="submit" style={{ marginTop: "0" }}>
                {"GUARDAR"}
              </Button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

ProfileForm.propTypes = {
  isSuccessUpsertAdmin: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    isSuccessUpsertAdmin: _.get(state, "admin.upsertAdmin.isSuccess", {}),
    isSuccessUpdatePassword: _.get(
      state,
      "user.updatePasswordRequest.isSuccess",
      {}
    ),
    user: _.get(state, "user.user", {}),
  };
};

const mapDispatchToProps = (dispatch) => ({});

connect(mapDispatchToProps, mapStateToProps)(ProfileForm);
