import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/atoms/Button';
import plusIcon from '../../../assets/images/navieras/plus-icon.png';
import './AssingForms.scss';
import { PROVIDER_CREATE } from '../../../routes/Paths';
import { useSelector } from 'react-redux';

export const AssignProvider = ({provider, setProvider, providers}) => {

    const history = useHistory();
    const adminId = useSelector(state => state.user.user.operativeId)

    // const providers = [
    //     {
    //     id : 1,
    //     name :'Proveedor 1'
    //     },
    //     {
    //     id : 2,
    //     name :'Proveedor 2'
    //     },
    // ]

  return (
    <div className='assign-form'>
        <div className='assign-form-button-container'>
        {
            !adminId &&
            <Button type={'button'} onClick={() => history.push(PROVIDER_CREATE)} srcicon={plusIcon}>CREAR</Button>
        }
        </div>
        <div className='input-container-data-form'>
            <div className='label-container'>
                <label className='container-label'>Seleccionar proveedor</label>
            </div>
            <div className='value-container' >
            <select className='input-select' value={provider} onChange={(e) => {
                setProvider(parseInt(e.target.value));
            }}>
                <option className='option-selected' value={null} selected hidden>Seleccionar proveedor</option>
                {
                    providers &&
                    providers.map((ele, i) => {
                        return <option key={i} value={ele.id}> {ele.name}</option>
                    })
                }
            </select >
            </div>
        </div>
    </div>
  )
}
