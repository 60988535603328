export const modality = [
    {
        name:'SD-CY',
        id: 1,
    }, 
    {
        name:'SD-SD',
        id: 2,
    }, 
    {
        name: 'CY-CY',
        id: 3,
    }, 
    {
        name:'CY-SD',
        id: 4,
    }
]

export const delivery = [
    {
        name: 'All Truck Consolidado',
        id: 1,
    },
    {
        name: 'All Truck Dedicado',
        id: 2,
    },
    {
        name: 'All Truck Consolidado Ext',
        id: 3,
    },
    {
        name: 'All Truck Dedicado Ext',
        id: 4,
    },
]
export const deliveryFCL = [
    {
        name: 'Rail + Truck',
        id: 1,
    },
    {
        name: 'Rail + Truck Ext',
        id: 2,
    },
    {
        name: 'Rail Ramp',
        id: 3,
    },
    {
        name: 'All Truck Sencillo',
        id: 4,
    },
    {
        name: 'All Truck Full',
        id: 5,
    },
    {
        name: 'All Truck Sencillo Ext',
        id: 6,
    },
    {
        name: 'All Truck Full Ext',
        id: 7,
    },
    {
        name: 'No aplica',
        id: 8,
    },
]

export const containerType = [
    {
        id: 1,
        value: "20'"
    },
    {
        id: 2,
        value: "40'"
    },
    {
        id: 3,
        value: "40HC"
    },
    {
        id: 4,
        value: "40NOR"
    },
    {
        id: 5,
        value: "Otro"
    }
]
export const containerTypeGround = [
    {
        id: 1,
        value: "Caja de 48"
    },
    {
        id: 2,
        value: "Caja de 53"
    },
    {
        id: 3,
        value: "Torton"
    },
    {
        id: 4,
        value: "Otro"
    },
]


export const service = [
    {
        id: 1,
        value: 'Road trip',
    },
    {
        id: 2,
        value: 'One way',
    },
    {
        id: 3,
        value: 'Nacional',
    },
]
export const sino = [
    {
        id: 1,
        value: true,
        name: 'Si'
    },
    {
        id: 2,
        value: false,
        name: 'No'
    },
]

export const intercom = [
    {
        id: 1,
        value: 'EXW'
    },
    {
        id: 2,
        value: 'FCA'
    },
    {
        id: 3,
        value: 'FOB'
    },
    {
        id: 4,
        value: 'CFR/CIF'
    },
    {
        id: 5,
        value: 'DAP'
    },
    {
        id: 6,
        value: 'DDP'
    },
    {
        id: 7,
        value: 'Otro'
    },
]

export const aditionalServices= [
    {
        id: 1,
        value: 'Previo en origen'
    },
    {
        id: 2,
        value: 'Otros'
    },
]

