import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { Box, MenuItem, createSvgIcon } from '@material-ui/core';
import plus from '../../../../assets/images/navieras/plus-icon.png'
import '../../../../styles/tableViewStyles.scss';
import { Button as ButtonD } from '@material-ui/core';


import {
    Button,
    DataTable,
    ActionMenu,
    GlobalFilter,
    Link,
} from '../../../../components/atoms';
import {
    getAllAdministratorsAction,
    deleteAdministratorAction,
} from '../../../../redux/actions/adminAction';
import Modal from '../../../../components/organisms/Modal/Modal';
import { CardContainer } from '../../../../components/molecules/CardContainer/CardContainer';
import { AssignCustomsAgent } from './AssignCustomsAgent';
import { DeleteBody } from '../../../../components/molecules/CancelFormBody/DeleteBody';
import { deleteClientAction, getAllClientsAction } from '../../../../redux/actions/clientAction';
import { upsertCostumerCustomAgentAction } from '../../../../redux/actions/CostumerCustomAgent';
import { addNotification } from '../../../../redux/actions/notificationAction';
import { getAllCustomAgentsAction, getAllAvailableCustomAgentsAction } from '../../../../redux/actions/customsAgentAction';
import view from '../../../../assets/images/navieras/view.png';
import trash from '../../../../assets/images/navieras/trash-can.png';
import agency from '../../../../assets/images/navieras/agency.png';
import edit from '../../../../assets/images/navieras/edit-icon.png';
import file from '../../../../assets/images/navieras/icon-file.png';
import { RECORDS } from '../../../../routes/Paths';
import { DataGrid, GridToolbarContainer, gridSortedRowIdsSelector, useGridApiContext } from '@mui/x-data-grid';

const ClientDashboard = (props) => {
    const history = useHistory();
    const [searchObj, setSearchObj] = useState({});
    const [searchCondition, setSearchCondition] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [itemSelected, setItemSelected] = useState(true);
    const [itemDeleted, setItemDeleted] = useState(true);

    const getClient = useSelector(state => state.client.clients.data)
    const [clients, setClients] = useState([]);


    const toggleModal = (type, id) => {
        if (openModal === true) {
            setItemSelected(id)
            setOpenModal(false);
        } {
            setItemSelected([type, id]);
            setOpenModal(true);
        }
    }


    useEffect(() => {
        props.getAllClientsAction(searchCondition);
        // props.getAllAdministratorsAction(searchCondition);
    }, [itemDeleted, searchCondition]);


    useEffect(() => {
        if (!_.isEmpty(searchObj)) {
            props.getAllClientsAction(
                {
                    where: searchObj
                }
            );
        }
    }, [searchObj]);

    useEffect(() => {
    }, [searchObj])

    // useEffect(() => {
    // }, [props.clients])

    useEffect(() => {
        setClients([])
        getClient && getClient.length > 0 && getClient.map((el) => {
            let sectionCopy = JSON.parse(JSON.stringify(el));
            let sectionCopyPerson = JSON.parse(JSON.stringify(el));
            sectionCopy.id = sectionCopy.id;
            sectionCopyPerson.persono = sectionCopy.expedient;
            setClients((d) => [...d, sectionCopy]);
        })
    }, [getClient])



    const onClickActionMenu = (actionSelected, payload) => {
        switch (actionSelected) {
            case 'showRecord':
                history.push({
                    pathname: `/dashboard/gestion-clientes/${payload.id}/ver`,
                    state: payload
                });
                break;
            case 'editRecord':
                history.push({
                    pathname: `/dashboard/gestion-clientes/${payload.id}/editar`,
                    state: payload
                });
                break;
            case 'quotation':
                history.push({
                    pathname: `/dashboard/cotizacion/${payload.id}`
                });
                break;
            case 'deleteRecord':
                props.deleteAdministratorAction({ id: payload.id });
                break;
            default:
                break;
        }
    }
    const deleteUser = async () => {
        const res = await props.deleteClientAction({ "id": itemSelected[1] })

        if (res) {
            setOpenModal(false)
            setItemDeleted(itemSelected)
            setItemSelected(null)
        }
    }

    const columnsGrid = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            type: 'number',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.id || 'null'}`
        },
        {
            field: 'tradename',
            headerName: 'Nombre comercial',
            headerAlign: 'center',
            width: 260,
            type: 'text',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.tradename || 'Sin informacion'}`
        },
        {
            field: 'businessname',
            headerName: 'Razon social',
            width: 280,
            headerAlign: 'center',
            aling: 'center',
            type: 'text',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.businessname || 'N/A'}`


        },
        {
            field: 'rfc',
            headerName: 'RFC',
            type: 'text',
            width: 180,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            aling: 'center',
            valueGetter: (params) =>
                `${params.row.rfc || 'Sin informacion'}`
        },
        {
            field: 'person.primaryEmail',
            headerName: 'Email',
            type: 'text',
            width: 270,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            aling: 'center',
            valueGetter: (params) =>
                `${params.row.person.primaryEmail || 'Sin informacion'}`
        },
        {
            field: 'ismoral',
            headerName: 'Tipo de cliente',
            type: 'text',
            width: 160,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            aling: 'center',
            valueGetter: (params) =>
                `${params.row.isfisica == true ? "Persona Fisica" : "Persona Moral" || 'Sin informacion'}`
        },
        {
            field: 'action',
            headerName: 'Acciones',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            width: 110,
            headerAlign: 'center',
            aling: 'center',
            renderCell: (params) => {
                return (
                    <ActionMenu id={params.row.id} >
                        <MenuItem onClick={() => onClickActionMenu('showRecord', params.row)}><img src={view} /> Ver registro</MenuItem>
                        <MenuItem onClick={() => onClickActionMenu('editRecord', params.row)}><img src={edit} /> Editar registro</MenuItem>
                        <MenuItem onClick={() => onClickActionMenu('quotation', params.row)}><img src={view} /> Cotizaciones</MenuItem>
                        <MenuItem onClick={() => history.push(RECORDS)}><img src={file} /> Ver expedinetes</MenuItem>
                        <MenuItem onClick={() => toggleModal(2, params.row.id)}><img src={trash} /> Eliminar</MenuItem>
                        <MenuItem onClick={() => preAssingCustomAgent(params.row.id)}><img src={agency} /> Asignar agente aduanal</MenuItem>
                    </ActionMenu>
                );
            },
        }
    ];


    const dispatch = useDispatch();

    const preAssingCustomAgent = async (id) => {
        const res = await props.getAllAvailableCustomAgentsAction({ "clientId": id });
        if (res) {
            toggleModal(1, id);
        } else {
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de agentes aduanales, favor de intentarlo de nuevo',
            }));
        }
    }

    const getNewData = (searchCondition) => {
        // TODO: Restrucutrar vs Filtros*
        setSearchCondition(searchCondition);
    };

    const [customAgentAssigned, setCustomAgentAssigned] = useState(null);

    const assignAgent = async () => {
        if (customAgentAssigned) {
            const data = {
                idClient: itemSelected[1],
                idAgend: customAgentAssigned,
            }
            let res = await props.upsertCostumerCustomAgentAction(data);
            if (res) {
                setOpenModal(false);
            }
        } else {
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Debe seleccionar algún agente aduanal',
            }));
        }
    }


    const ExportIcon = createSvgIcon(
        <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
        "SaveAlt"
    );

    const getUnfilteredRows = ({ apiRef }) => gridSortedRowIdsSelector(apiRef);

    const descargarExpedient = () => {
        const apiRef = useGridApiContext();
        const handleExport = (options) => apiRef.current.exportDataAsCsv(options);
        const buttonBaseProps = {
            color: "primary",
            size: "small",
            startIcon: <ExportIcon />,
        };

        return (
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    // marginBottom: "1rem",
                    background: "#000;",

                }}
            >



                <GridToolbarContainer
                    csvOptions={{
                        utf8WithBom: true,
                        fileName: "Custom Agent",
                    }}
                >

                    <ButtonD className='button-exp2'
                        {...buttonBaseProps}
                        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}>
                        {/* <img icon={} className="img" style={{ marginRight: "1rem" }} /> */}
                        Descargar Excel
                    </ButtonD>
                </GridToolbarContainer>
            </div>
        );
    };

    const localizedTextsMap = {

        //Fileters operator text
        columnMenuUnsort: "Desclasificar",
        columnMenuSortAsc: "Ordenar por ASC",
        columnMenuSortDesc: "Ordenar por DESC",
        columnMenuFilter: "Filtrar",
        columnMenuHideColumn: "Esconder",
        columnMenuShowColumns: "Mostrar colunas",
        noRowsLabel: "No existen registros",
        noResultsOverlayLabel: 'No se han encontrado resultados',
        errorOverlayDefaultLabel: 'Ocurrió un error.',

        //Filter operator
        filterPanelColumns: 'Columnas',
        filterPanelOperators: 'Operador',
        filterPanelInputLabel: 'Valor',
        filterPanelInputPlaceholder: 'Valor de filtro',

        // Filter operators text
        filterOperatorContains: 'Contiene',
        filterOperatorEquals: 'Igual',
        filterOperatorStartsWith: 'Comienza con',
        filterOperatorEndsWith: 'Termina con',
        filterOperatorIs: 'Es',
        filterOperatorNot: 'No es',
        filterOperatorAfter: 'Es despues de',
        filterOperatorOnOrAfter: 'Está encendido o después',
        filterOperatorBefore: 'Es antes',
        filterOperatorOnOrBefore: 'Está en o antes',
        filterOperatorIsEmpty: 'Está vacío',
        filterOperatorIsNotEmpty: 'No está vacío',
        filterOperatorIsAnyOf: 'Es cualquiera de',


        // Columns panel text
        columnsPanelTextFieldLabel: 'Buscar columna',
        columnsPanelTextFieldPlaceholder: 'Título de la columna',
        columnsPanelDragIconLabel: 'Reordenar columna',
        columnsPanelShowAllButton: 'Mostrar todo',
        columnsPanelHideAllButton: 'Ocultar todo',
    };



    return (
        <>
            {
                openModal && itemSelected[0] === 1 &&
                <Modal
                    isOpen={toggleModal}
                    toggle={toggleModal}
                >
                    <CardContainer btnLeftAction={toggleModal} btnRightText={'ASIGNAR'} btnRightAction={() => assignAgent()} title={'Agentes Aduanales'} body={<AssignCustomsAgent customsAgents={!_.isEmpty(props.customAgentsAvailable) && _.get(props, 'customAgentsAvailable.data.rows', [])} setCustomAgentAssigned={setCustomAgentAssigned} customAgentAssigned={customAgentAssigned} />} />
                </Modal>
            }
            {
                openModal && itemSelected[0] === 2 &&
                <Modal
                    isOpen={toggleModal}
                    toggle={toggleModal}
                >
                    <CardContainer btnLeftAction={toggleModal} btnRightAction={() => deleteUser()} body={<DeleteBody user={'cliente'} />} />
                </Modal>
            }
            <section className='ClientRecordAccountSection' >
                <div className='ClientRecordAccountSection__container'>
                    <div className='ClientRecordAccountSection__headerContainer'>
                        <div className='HomeTableSection__containerFilters'>
                            <GlobalFilter
                                filter={searchObj}
                                setFilter={setSearchObj}
                                showTextSearch
                                showDateRangeSearch={false}
                                // classNameDate={'containerFilters__date'}
                                // classNameSearch={'containerFilters__search'}
                                placeholderSearch={'Buscar por ID o Nombre...'}
                            />
                        </div>
                        <div className='HomeTableSection__containerButtons'>
                            <Link
                                className={'linkClient'}
                                to={`/dashboard/gestion-clientes/tipo-usuario`}>
                                <Button className={'containerButtons__clientButton'} srcicon={plus}>CREAR</Button>
                            </Link>
                        </div>
                    </div>
                    <div className='ClientRecordAccountSection__tableContainer'>

                        <Box sx={{
                            height: 390,
                            width: '100%',
                            '& .super-app-theme--header': {
                                backgroundColor: '#707070',
                                color: '#ffff',
                                fontFamily: 'Montserrat',
                                fontSize: '.9vw',
                            },
                        }}>
                            <DataGrid
                                sx={{
                                    '@media print': {
                                        '.MuiDataGrid-main': { color: '#000' },
                                    }
                                }}
                                localeText={localizedTextsMap}
                                rows={clients}
                                columns={columnsGrid}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                components={{ Toolbar: descargarExpedient}}
                                checkboxSelection={false}
                                disableSelectionOnClick
                                experimentalFeatures={{ newEditingApi: true }}

                            />
                        </Box>
                        {/* <DataTable
                        columns={columns}
                        // data={{...props.companies}}
                        data={{
                            data: _.get(props, 'clients.data', []),
                            totalRecords: _.get(props, 'clients.totalRecords', 0),
                        }}
                        getData={getNewData}
                        searchObj={searchObj}
                    /> */}
                    </div>
                </div>
            </section>
        </>
    );
}

ClientDashboard.propTypes = {
    history: PropTypes.any,
    dispatch: PropTypes.any,
    getAllAdministratorsAction: PropTypes.any,
    admins: PropTypes.any,
    deleteAdministratorAction: PropTypes.any,
    isSuccessDeleteAdmin: PropTypes.any,
    upsertCostumerCustomAgentAction: PropTypes.any,
    getAllCustomAgentsAction: PropTypes.any,
    getAllAvailableCustomAgentsAction: PropTypes.any,
};

function mapStateToProps(state) {
    return {
        clients: _.get(state, 'client.clients', {}),
        customAgentsAvailable: _.get(state, 'customAgent.customAgentsAvailableByClient', []),
        customsAgents: _.get(state, 'customAgent.customAgents', {}),
        isSuccessDeleteAdmin: _.get(state, 'admin.deleteAdmin.isSuccess', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    deleteClientAction: params => dispatch(deleteClientAction(params)),
    getAllClientsAction: params => dispatch(getAllClientsAction(params)),
    getAllAdministratorsAction: params => dispatch(getAllAdministratorsAction(params)),
    deleteAdministratorAction: params => dispatch(deleteAdministratorAction(params)),
    upsertCostumerCustomAgentAction: params => dispatch(upsertCostumerCustomAgentAction(params)),
    getAllCustomAgentsAction: params => dispatch(getAllCustomAgentsAction(params)),
    getAllAvailableCustomAgentsAction: params => dispatch(getAllAvailableCustomAgentsAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientDashboard);

