import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './OptionsSelector.scss';
import arrow from '../../assets/images/navieras/icon-arrow.png';
import userImg from '../../assets/images/navieras/admin.png';
import Link from '../atoms/Link1';

import { getOneAdministratorRecordAction } from '../../redux/actions/adminAction';
import { getAllOperativesAction } from '../../redux/actions/operativeAction';

const OptionsSelector = ({
    options,
    // = [
        //     {
            //         name: 'Mi perfil',
    //         path: PROFILE,
    //     },
    //     {
    //         name: 'Log out',
    //         path: '/iniciar-sesion',
    //         onClick: () => {
        //             history.push('/iniciar-sesion');
        //             props.userLogout();
        //         },
        //     },
        // ],
    admin = true,
    src = arrow,
    role = 'Administrator',
}) => {
    
    const [ optionsDeployed, setOptionsDeployed ] = useState(false);
    const [ rotate, setRotate ] = useState('');
    
    const onClickDeployOptions = () => {
        setOptionsDeployed(!optionsDeployed);
        // setRotate(rotate == '' ? 'rotate' : '');
    };
    
    const user = useSelector(state => state.user.user);
    

    // useEffect(() => {
    //     catchUser().then((res) => {
    //         console.warn(res);
    //         debugger
    //         setData(res.data);
    //     })
    // }, []);

    return (
        <div className='OptionsSelectorContainer'>
            {
                admin &&
                <div className='userIconContainer'>          
                <img
                    className='adminAccount__img'
                    src={userImg}
                />
                </div> 
            }
            <div className='profileLogoutButtonContainer'>
                <div className={admin ? 'buttonSelectorContainer' : 'buttonMenuContainer'}
                    onClick={() => onClickDeployOptions()}
                >
                    {
                        admin &&
                        <>
                            <p>{role}</p>
                            <img className={`arrow ${rotate}`} src={src} />
                        </>
                    }
                </div>
                {
                    optionsDeployed &&
                    <div className='deployedOptionsContainer'>
                        {   
                            options.map(option => 
                                <div
                                    key={option.name}
                                    className='optionStyleContainer'
                                    onClick={option.onClick ? () => option.onClick() : ''}
                                >
                                        {option.name}
                                </div>
                            )
                        }
                    </div>
                }

            </div>
        </div>
    )
};

OptionsSelector.propTypes = {
    options: PropTypes.array,
    src: PropTypes.any,
    admin: PropTypes.bool,
    menu: PropTypes.bool,
    role: PropTypes.string,
}

export default OptionsSelector;
