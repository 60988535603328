import {
    TOGGLE_SIDEBAR_SUCCESS
} from "../actionTypes";

export const toggleSidebarSuccess = () => ({
    type: TOGGLE_SIDEBAR_SUCCESS,
    payload: {}
});

//

export const toggleSidebar = () => {
    return function (dispatch) {
        return dispatch(toggleSidebarSuccess());
    };
}