import {
   TOGGLE_SIDEBAR_SUCCESS 
} from '../actionTypes';

import initialState from './../initialState';

export default function userInterfaceReducer (state = initialState.userInterface, action) {
    switch (action.type) {
        case TOGGLE_SIDEBAR_SUCCESS:
            return {
                ...state,
                isSidebarOpened: !state.isSidebarOpened,
            }
        default:
            return state;
    }
}