/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../components/atoms';
import './Quotation.scss';
import '../../styles/stylesSteppers.scss';
import '../../components/molecules/DetailsViewLayout/DetailsView.scss';
import arrow from '../../assets/images/navieras/arrowButton.png'
import { useForm } from 'react-hook-form';
import AerialQuotationForm from './AerialQuotationForm';
import LandQuotationForm from './LandQuotationForm';
import MaritimeFCLQuotationForm from './MaritimeFCLQuotationForm';
import dropdown from '../../assets/images/navieras/icon-dropdown.png';
import MaritimeLCLQuotationForm from './MaritimeLCLQuotationForm';
import { useEffect } from 'react';
import { getOneClientAction } from '../../redux/actions/clientAction';
import { useDispatch, useSelector } from 'react-redux';

export const PrincipalDataQuotation = ({
    stepper,
    setStepper,
    toggleModal,
    modality,
    formData,
    setFormData,
    clientPorts,
    countriesSelect
}) => {
    const methods = useForm({ defaultValues: defaultValues });
    const defaultValues = {
        // name : '',
    };
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;
    const [clientType, setClientType] = useState(null);
    const dispatch = useDispatch()


    let modalitySelected;

    if (modality == 1) modalitySelected = 'Aérea';
    else if (modality == 2) modalitySelected = 'Terrestre';
    else if (modality == 3) modalitySelected = 'Marítimo FCL';
    else if (modality == 4) modalitySelected = 'Marítimo LCL';

    const date = '17/09/22';
    const [principalDataAerialForm, setPrincipalDataAerialForm] = useState(
        {
            originCountryId: '',
            originStateId: null,
            destinyCountryId: '',
            destinyStateId: null,
            invoice: '',
            name: '',
            bussines: '',
            modality: '',
            delivery: '',
            cbms: '',
            weight: '',
            factor: '',
            validity: '',
            total: '',
            originLocalCharges: '',
            originLandFreight: '',
            originSeaFreight: '',
            destinyLocalChargesBl: '',
            destinyLocalChargesCbm: '',
            destinyLandFreight: '',
            destinySeaFreight: '',
            coordination: '',
            agency: '',
            officeMx: '',
            insurance: ''
        }
    );
    const [principalDataLandForm, setPrincipalDataLandForm] = useState(
        {

            invoice: "",
            name: "",
            bussines: "",
            platform: "",
            service: "",
            validity: "",
            total: "",
            originCountryId: "",
            originStateId: null,
            destinyCountryId: "",
            destinyStateId: null,
            landFreight: "",
            overweight: "",
            maneuvers: "",
            dropOff: ""
        }
    );
    const [principalDataMaritimeForm, setPrincipalDataMaritimeForm] = useState(
        {
            invoice: "",
            name: "",
            bussines: "",
            intercom: "",
            modality: "",
            pickup: "",
            delivery: "",
            cbms: "",
            weight: 0,
            factor: "",
            originCountryId: "",
            originStateId: null,
            originPortId: "",
            originCharges: "",
            destinyCountryId: "",
            destinyStateId: null,
            destinyPortId: "",
            destinyCharges: "",
            destinySeaFreight: "",
            destinyLandFreight: "",
            destinyLocalChargesBl: "",
            destinyLocalChargesCbm: "",
            coordination: "",
            officeMx: null,
            agency: "",
            insurance: "",
            validity: "",
            total: "",
            lcl: "",
            fcl: "",
            other: "",
            containerType: "",
            containersNumber: "",
            delayDays: "",
            storageDays: "",
            destinyLocalChargesContainer: "",
            servicest3: "",
            aditionalService: "",
            officeMxTlp: null,
        }
    );
    const form = {
        1: principalDataAerialForm,
        2: principalDataLandForm,
        3: principalDataMaritimeForm,
        4: principalDataMaritimeForm,
    }

    const [arrayContainers, setArrayContainers] = useState([])
    const [selectValues, setSelectValues] = useState();


    const changeStage = () => {
        // setStepper(stepper+1)
        let dataForm = form[formData.modalityTypeId]
        const datos = {
            ...formData,
            modalityTypeId: formData.modalityTypeId !== 4 ? formData.modalityTypeId : 3,

            quotationData: {
                ...dataForm,
                // containersNumber: arrayContainers,

            }
        }
        setFormData(datos);
        setStepper(stepper + 1)
    }
    const changeStageBack = () => {
        setStepper(stepper - 1)
    }
    const [quotationData, setQuotationData] = useState();




    useEffect(() => {
        if (formData.clientId) {
            dispatch(getOneClientAction({ "id": formData.clientId }))
        }
    }, [])


    return (
        <form className='main-container-generic-type'>
            <div className='header-form-generic-type'>
                <h2>Datos de cotización</h2>
            </div>
            <div className='title-modality-container'>
                <div className='header-data-container'>
                    <div className='label-container'>
                        <label className='modality-title'>Modalidad:</label>
                    </div>
                    <div className='value-container'>
                        <p className='container-label'>{modalitySelected}</p>
                    </div>
                </div>
                <div className='header-data-container'>
                    <div className='label-container'>
                        <label className='modality-title'>Fecha:</label>
                    </div>
                    <div className='value-container'>
                        <p className='container-label'>{date}</p>
                    </div>
                </div>
            </div>
            <div className='body-form-generic-type-data' style={{ display: 'flex', height: '45vh' }}>
                <div className='body-data'>
                    {
                        modality == 1 &&
                        <AerialQuotationForm principalDataAerialForm={principalDataAerialForm} setPrincipalDataAerialForm={setPrincipalDataAerialForm} clientId={formData.clientId} />
                    }
                    {
                        modality == 2 &&
                        <LandQuotationForm principalDataLandForm={principalDataLandForm} setPrincipalDataLandForm={setPrincipalDataLandForm} />
                    }
                    {
                        modality == 3 &&
                        <MaritimeFCLQuotationForm countriesSelect={countriesSelect} clientPorts={clientPorts} principalDataMaritimeForm={principalDataMaritimeForm} setPrincipalDataMaritimeForm={setPrincipalDataMaritimeForm} arrayContainers={arrayContainers}
                            setArrayContainers={setArrayContainers} selectValues={selectValues} setSelectValues={setSelectValues}
                        />
                    }
                    {
                        modality == 4 &&
                        <MaritimeLCLQuotationForm countriesSelect={countriesSelect} clientPorts={clientPorts} principalDataMaritimeForm={principalDataMaritimeForm} setPrincipalDataMaritimeForm={setPrincipalDataMaritimeForm} />
                    }
                </div>
            </div>
            <div className='dropdown-container'>
                <img src={dropdown} />
            </div>
            <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                <Button type='button' onClick={toggleModal ? toggleModal : changeStageBack} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                <Button type='button' onClick={changeStage} className={'button-next'} srcicon={arrow} style={{ width: '40%' }}>SIGUIENTE</Button>
            </div>
        </form>
    )
}

PrincipalDataQuotation.propTypes = {
    stepper: PropTypes.any,
    setStepper: PropTypes.func,
    toggleModal: PropTypes.any,
    modality: PropTypes.any,
}
