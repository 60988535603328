/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import RadioButton from '../RadioButtons/RadioButtons';
import './AssingForms.scss';

export const TypeOfModality = ({
    setModality,
}) => {

    const modalities = [
        {
            keyName: 'Aerea',
            id: 1
        },
        {
            keyName: 'Terrestre',
            id: 2
        },
        {
            keyName: 'Marítimo FCL',
            id: 3
        },
        {
            keyName: 'Marítimo LCL',
            id: 4
        },
    ]
  return (
    <div className='assign-form'>
        <div className='input-container-data-form'>
            <div className='label-container modality'>
                <label className='container-label'>Seleccionar modalidad</label>
            </div>
            <div className='value-container' >
                <div className='value-container-radio-buttons'>
                    <RadioButton data={modalities} setValue={setModality} />
                </div>
            </div>
        </div>
    </div>
  )
}

TypeOfModality.propTypes = {
    setValue: PropTypes.func,
}
