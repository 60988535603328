/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button } from '../../../components/atoms';
import '../../../styles/stylesSteppers.scss';
import { deliveryFCL, modality } from '../../../constants/dummyData';
import arrow from './../../../assets/images/navieras/arrowButton.png'
import { useHistory, useLocation } from 'react-router-dom';
import './RecordsData.scss';
import './DeliveryTypes.scss';
import { RECORDS } from '../../../routes/Paths';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { upsertExpedient } from '../../../redux/actions/recordAction';
import { useDispatch } from 'react-redux';
import Modal from '../../../components/organisms/Modal/Modal';
import { CardContainer } from '../../../components/molecules/CardContainer/CardContainer';
import { BaseModalBody } from '../../../components/molecules/ModalBody/BaseModalBody';
import { cancelUploadOfDocs, noDocumentsUploded } from '../../../components/molecules/ModalBody/modalBodyVaribles';

const PickupAndDeliveryData = ({ recordId = '002', setStepper, stepper, formData, setFormData }) => {

    const [status, setStatus] = useState(null);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();



    const defaultValues = {

        mode: location.payload.row.pickupDelivery.mode,
        deliveryType: location.payload.row.pickupDelivery.deliveryType,
        railArrivalDate: location.payload.row.pickupDelivery.railArrivalDate,
        railwayDeliveryDate: location.payload.row.pickupDelivery.railwayDeliveryDate,
        emptyReturnDate: location.payload.row.pickupDelivery.emptyReturnDate,
        pickUp: location.payload.row.pickupDelivery.pickUp ? location.payload.row.pickupDelivery.pickUp : '',
        fpod: location.payload.row.pickupDelivery.fpod ? location.payload.row.pickupDelivery.fpod : '',
    };


    const methods = useForm({ defaultValues: defaultValues });
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;


    const [deliveryData, setDeliveryData] = useState({
        mode: defaultValues.mode,
        deliveryType: defaultValues.deliveryType,
        railArrivalDate: defaultValues.railArrivalDate,
        railwayDeliveryDate: defaultValues.railwayDeliveryDate,
        emptyReturnDate: defaultValues.emptyReturnDate,
        pickUp: defaultValues.pickUp,
        fpod: defaultValues.fpod,
    });


    const changeStage = () => {
        history.push({
            pathname: RECORDS,
            state: { clientType: status },
        });
        // setStepper(stepper+1);
        console.warn(deliveryData);
    }


    const pickupDelivery_ = {
        mode: deliveryData.mode == null ? '' : deliveryData.mode,
        deliveryType: deliveryData.deliveryType == null ? 0 : deliveryData.deliveryType,
        railArrivalDate: deliveryData.railArrivalDate == null ? '' : deliveryData.railArrivalDate,
        railwayDeliveryDate: deliveryData.railwayDeliveryDate == null ? '' : deliveryData.railwayDeliveryDate ,
        emptyReturnDate: deliveryData.emptyReturnDate == null ? '' : deliveryData.emptyReturnDate,
        pickUp: deliveryData.pickUp,
        fpod: deliveryData.fpod,
    }

    const pickupDelivery = JSON.stringify(pickupDelivery_)

    const expedient_ = {
        arrivalPortDate: formData.expedient.arrivalPortDate == null ? '' : formData.expedient.arrivalPortDate,
        departureDate: formData.expedient.departureDate  == null ? '' : formData.expedient.departureDate,
        eta:formData.expedient.eta  == null ? '' : formData.expedient.eta ,
        etd:formData.expedient.etd  == null ? '' : formData.expedient.etd,
        folioTl:formData.expedient.folioTl == null ? '' : formData.expedient.folioTl,
        naviera:formData.expedient.naviera == null ? '' : formData.expedient.naviera,
        noContainers:formData.expedient.noContainers == null ? '' : formData.expedient.noContainers,
        numberMbl:formData.expedient.numberMbl == null ? '' : formData.expedient.numberMbl,
        quiantityContainers:formData.expedient.quiantityContainers == null ? '' : formData.expedient.quiantityContainers,
        status:formData.expedient.status == null ? '' : formData.expedient.status,
        teus:formData.expedient.teus == null ? '' : formData.expedient.teus,
    }


    const expedient = JSON.stringify(expedient_)

    const customAndFiles_  = {...formData.customAndFiles}

    const customAndFiles = JSON.stringify(customAndFiles_)


    
    const arrivalDates_ = {
        arrivalDate: formData.arrivalDates.arrivalDate == null ?  '' :formData.arrivalDates.arrivalDate,
        dateFourteenDaysAfterArrival: formData.arrivalDates.dateFourteenDaysAfterArrival === 'Invalid date' ? '' : formData.arrivalDates.dateFourteenDaysAfterArrival, 
        dateSevenDaysAfterArrival: formData.arrivalDates.dateSevenDaysAfterArrival === 'Invalid date' ? '' : formData.arrivalDates.dateSevenDaysAfterArrival ,
        dateThreeDaysAfterArrival: formData.arrivalDates.dateThreeDaysAfterArrival === 'Invalid date'? '' : formData.arrivalDates.dateThreeDaysAfterArrival,
        daysOfDelay: formData.arrivalDates.daysOfDelay == null ? 0 : formData.arrivalDates.daysOfDelay,
        lastDayOffDelay: formData.arrivalDates.lastDayOffDelay  == null || formData.arrivalDates.lastDayOffDelay === 'Invalid date'  ?  '' : formData.arrivalDates.lastDayOffDelay,
    }

    const arrivalDates = JSON.stringify(arrivalDates_)


    const paymentDetails_ = {
        creditDays: formData.paymentDetails.creditDays == null ? 0 : formData.paymentDetails.creditDays,
        paydayLimit: formData.paymentDetails.paydayLimit == null ? '' : formData.paymentDetails.paydayLimit,
        payment: formData.paymentDetails.payment == null ? 0 : formData.paymentDetails.payment,
        paymentDate: formData.paymentDetails.paymentDate == null ? '' : formData.paymentDetails.paymentDate,
        paymentNaviera: formData.paymentDetails.paymentNaviera == null ? 0 : formData.paymentDetails.paymentNaviera,
        paymentDateNaviera: formData.paymentDetails.paymentDateNaviera == null ? '' : formData.paymentDetails.paymentDateNaviera,
        paymentAgent: formData.paymentDetails.paymentAgent == null ? 0 : formData.paymentDetails.paymentAgent,
        paymentDateAgent: formData.paymentDetails.paymentDateAgent == null ? '' : formData.paymentDetails.paymentDateAgent,
        localCharges: formData.paymentDetails.localCharges == null ? 0 : formData.paymentDetails.localCharges,
        dateLocalCharges: formData.paymentDetails.dateLocalCharges == null ? '' : formData.paymentDetails.dateLocalCharges
    }

    const paymentDetails = JSON.stringify(paymentDetails_);

    const [openModal, setOpenModal] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);


    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    const toggleModal2 = () => {
        setOpenModal2(!openModal);
    }



    const cancel = () => {
        history.push(RECORDS);
    }



    useEffect(() => {
    }, [formData])



    const onSubmit = async () => {

        const formData_ = new FormData()

        formData_.append("id", location.payload.row.expedientId)
        formData_.append("expedient", expedient);
        formData_.append("customAgent", customAndFiles);
        formData_.append("arrivalDates", arrivalDates);
        formData_.append("paymentDetails", paymentDetails);
        formData_.append("pickupDelivery", pickupDelivery);
        formData_.append("delay", '{}');
        formData_.append("route", '{}');
        formData_.append("deviation", '[]');
        formData_.append("tracing", '[]');
        formData_.append("mediasDeleted", '[]');
        formData_.append("File", '[]');

        const res = await dispatch(upsertExpedient(formData_))
        if (res) {
            history.push('/dashboard/expedientes');
        }

    }


    return (
        <>
            {
                openModal &&
                <Modal
                    isOpen={openModal}
                    toggle={toggleModal}
                >
                    <CardContainer
                        btnLeftAction={toggleModal}
                        btnRightAction={cancel}
                        body={<BaseModalBody data={cancelUploadOfDocs} />}
                        btnLeftText={'REGRESAR'}
                    />
                </Modal>
            }
            {
                openModal2 &&
                <Modal
                    isOpen={openModal2}
                    toggle={toggleModal2}
                >
                    <CardContainer
                        btnLeftAction={toggleModal2}
                        btnRightAction={cancel}
                        body={<BaseModalBody data={noDocumentsUploded} />}
                        btnLeftText={'REGRESAR'}
                    />
                </Modal>
            }


            <form className='main-container-generic-type' onSubmit={handleSubmit(onSubmit)}>
                <div className='header-form-generic-type'>
                    <h2>Datos de recolección y entrega</h2>
                    <h3>ID: {location.payload.row.id}</h3>
                </div>
                <div className='body-form-generic-type-data pickup-delivery' style={{ height: '45vh' }}>
                    <div className='input-column-container delivery'>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>MODE</label>
                            </div>

                            <div className='value-container' >
                                <input
                                    placeholder="Agregar..."
                                    type='text'
                                    disabled={true}
                                    name='quiantityContainers'
                                    {...register('quiantityContainers')}
                                    control={control}
                                    value={deliveryData.mode}
                                    //  value={location && location.payload && location.payload.row && location.payload.row.expedient && location.payload.row.expedient.quiantityContainers ? location.payload.row.expedient.quiantityContainers : ''}
                                    onChange={(e) => {
                                        setDeliveryData({ ...deliveryData, mode: parseInt(e.target.value) })
                                    }}
                                    className='input-generic'
                                />
                            </div>

                            {/* <div className='value-container' >
                                <select className='input-select' value={deliveryData.mode} onChange={(e) => {
                                    setDeliveryData({ ...deliveryData, mode: parseInt(e.target.value) });
                                }}>
                                    <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                    {
                                        modality &&
                                        modality.map(ele => {
                                            return <option value={ele.id}> {ele.name}</option>
                                        })
                                    }
                                </select>
                            </div> */}
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Tipo de entrega</label>
                            </div>
                            <div className='value-container' >
                                <select className='input-select' value={deliveryData.deliveryType} onChange={(e) => {
                                    setDeliveryData({ ...deliveryData, deliveryType: parseInt(e.target.value) });
                                }}>
                                    <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                    {
                                        deliveryFCL &&
                                        deliveryFCL.map(ele => {
                                            return <option value={ele.id}> {ele.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Fecha de llegada ferrocarril</label>
                            </div>
                            <div className='value-container'>
                                <input
                                    className="input-generic date"
                                    type="date"
                                    placeholder="Agregar..."
                                    value={deliveryData.railArrivalDate}
                                    onChange={(e) => setDeliveryData({ ...deliveryData, railArrivalDate: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Fecha de entrega ferrocarril</label>
                            </div>
                            <div className='value-container'>
                                <input
                                    className="input-generic date"
                                    type="date"
                                    placeholder="Agregar..."
                                    value={deliveryData.railwayDeliveryDate}
                                    onChange={(e) => setDeliveryData({ ...deliveryData, railwayDeliveryDate: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Fecha de retorno de vacío</label>
                            </div>
                            <div className='value-container'>
                                <input
                                    className="input-generic date"
                                    type="date"
                                    placeholder="Agregar..."
                                    value={deliveryData.emptyReturnDate}
                                    onChange={(e) => setDeliveryData({ ...deliveryData, emptyReturnDate: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Pick Up</label>
                            </div>
                            <div className='value-container' >
                                <input
                                    disabled={deliveryData.mode === "CY-CY" ? true :  deliveryData.mode === "CY-SD" ? true : false}
                                    type='text'
                                    value={deliveryData.pickUp}
                                    onChange={(e) => setDeliveryData({ ...deliveryData, pickUp: e.target.value })}
                                    className='input-generic'
                                    placeholder='Nombre de la ciudad donde se recolecta'
                                />
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>FPOD</label>
                            </div>
                            <div className='value-container' >
                                <input
                                disabled={deliveryData.mode === 'CY-CY' ? true : deliveryData.mode === 'SD-CY' ? true : false }
                                    type='text'
                                    value={deliveryData.fpod}
                                    onChange={(e) => setDeliveryData({ ...deliveryData, fpod: e.target.value })}
                                    className='input-generic'
                                    placeholder='Nombre de la ciudad donde se entrega...'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-form-generic-type records'>
                    <Button type='button' onClick={toggleModal} className={'button-back'} style={{ width: '28%' }}>CANCELAR</Button>
                    <Button type='onsubmit' className={'button-generate'} style={{ width: '28%' }}>GUARDAR</Button>
                    {/* <Button type='button' className={'button-next'} style={{ width: '30%' }}>CERRAR SEGUIMIENTO</Button> */}
                </div>
            </form>
        </>
    )
}

export default PickupAndDeliveryData;
