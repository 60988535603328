import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";

import Themes from "./themes";
import es from './translations/es/translation_es';
import en from './translations/en/translation_en';
import App from './App';

export default class Root extends Component {
  render() {
    const { store, history } = this.props;
    i18next
      .use(LanguageDetector)
      .init({
        interpolation: { escapeValue: false },
        lng: process.env.DEFAULT_LANGUAGE,
        resources: {
          es: {
            translation: es,
          },
          en: {
            translation: en,
          },
        },
      })
    ;

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={Themes.default}>
            <I18nextProvider i18n={i18next}>
              <CssBaseline />
              <App />
            </I18nextProvider>
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
