import request from "../../utils/request";
import { addNotification } from './notificationAction';

import {
UPSERT_EXPEDIENT_FAILURE,
UPSERT_EXPEDIENT_BEGIN,
UPSERT_EXPEDIENT_SUCCESS,
GET_ALL_EXPEDIENT_BEGIN,
GET_ALL_EXPEDIENT_SUCCESS,
GET_ALL_EXPEDIENT_FAILURE,
GET_ALL_EXPEDIENT_DELETED_BEGIN,
GET_ALL_EXPEDIENT_DELETED_SUCCESS,
GET_ALL_EXPEDIENT_DELETED_FAILURE ,
RECOVE_EXPEDIENT_BEGIN,
RECOVE_EXPEDIENT_SUCCESS,
RECOVE_EXPEDIENT_FAILURE,
} from './../actionTypes';

// ACTIONS TO REDUCER
 
export const upsertExpedientBegin = () => ({
    type: UPSERT_EXPEDIENT_BEGIN,
});

export const upsertExpedientSuccess = (/* totalRecords, */ data) => ({
    type: UPSERT_EXPEDIENT_SUCCESS,
    // totalRecords,
    data,
});

export const upsertExpedientFailure = (err) => ({
    type: UPSERT_EXPEDIENT_FAILURE,
    err
});

//GETALL
export const getExpedientBegin = () => ({
    type: GET_ALL_EXPEDIENT_BEGIN,
});

export const getExpedientSuccess = (/* totalRecords, */ data) => ({
    type: GET_ALL_EXPEDIENT_SUCCESS,
    // totalRecords,
    data,
});

export const getExpedientFailure = (err) => ({
    type: GET_ALL_EXPEDIENT_FAILURE,
    err
});


//GET ALL DELETED
export const getExpedientDeletedBegin = () => ({
    type: GET_ALL_EXPEDIENT_DELETED_BEGIN,
});

export const getExpedientDeletedSuccess = (/* totalRecords, */ data) => ({
    type: GET_ALL_EXPEDIENT_DELETED_SUCCESS,
    // totalRecords,
    data,
});

export const getExpedientDeletedFailure = (err) => ({
    type: GET_ALL_EXPEDIENT_DELETED_FAILURE,
    err
});

//RECOVE EXPEDIENT
export const recoveExpedientBegin = () => ({
    type: RECOVE_EXPEDIENT_BEGIN,
});

export const recoveExpedientSuccess = (/* totalRecords, */ data) => ({
    type: RECOVE_EXPEDIENT_SUCCESS,
    // totalRecords,
    data,
});

export const recoveExpedientFailure = (err) => ({
    type: RECOVE_EXPEDIENT_FAILURE,
    err
});



// DISPATCH TO ACTIONSv
export function upsertExpedient(data) {
    return (dispatch, getState) => {
        dispatch(upsertExpedientBegin());
        return request({
            partialUrl: `/api/v1/record/upsert-record-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,

            headers: {
                //'Content-Type': 'multipart/form-data; boundary=—-WebKitFormBoundaryfgtsKTYLsT7PNUVD',
                //Pragma: 'no-cache',
                // 'Cache-control': 'no-store',
                // 'Access-Control-Allow-Origin': '*',
                // 'access-control-allow-origin': '*',
            },
            isFileUpload: true,
        })
            .then(request => request.json())
            .then(data => {
                dispatch(addNotification({
                    toastType: 'success',
                    toastTitle: 'Se ha ejecutado la acción con éxito',
                }));
                dispatch(upsertExpedientSuccess(data.data));
                return true;
            })
            .catch(err => {
                console.log(err);
                dispatch(addNotification({
                    toastType: 'error',
                    toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
                }));
                return dispatch(upsertExpedientFailure(err));
            });
    };
}


export function getAllExpedientAction (data) {
    return (dispatch, getState) => {
        dispatch(getExpedientBegin());
        return request({
            partialUrl: `/api/v1/record/get-all-record-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(getExpedientSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(getExpedientFailure(err));
        });
    };
}


export function deleteRecordAction(data) {
    return (dispatch, getState) => {
        return request({
            partialUrl: `/api/v1/record/delete-record-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
            .then(request => request.json())
            .then(data => {
                dispatch(addNotification({
                    toastType: 'success',
                    toastTitle: 'Se ha eliminado con éxito',
                }));
                return true;
            })
            .catch(err => {
                console.log(err);
                dispatch(addNotification({
                    toastType: 'error',
                    toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
                }));
                // return dispatch(deleteClientFailure(err));
            });
    };
}



export function getAllExpedientDeletedAction (data) {
    return (dispatch, getState) => {
        dispatch(getExpedientDeletedBegin());
        return request({
            partialUrl: `/api/v1/record/get-all-deleted-record-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(getExpedientDeletedSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(getExpedientDeletedFailure(err));
        });
    };
}



//Recove Record 
export function recoveExpedientAction(data){
    return (dispatch, getState) => {
        dispatch(recoveExpedientBegin());
        return request({
            partialUrl: `/api/v1/record/recover-deleted-record-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(recoveExpedientSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(recoveExpedientFailure(err));
        });
    };
}




