import React, { useEffect, useState } from 'react'
// import './Quotation.scss';
import '../../styles/stylesSteppers.scss';
import './QuotationDocuments.scss';
import _ from 'lodash';
import { upsertQuotationAction } from '../../redux/actions/quotationAction';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { QuotationDocuments } from './QuotationDocuments';


export const QuotationDocumentsView = (props) => {

    const quotationData =  _.get(props, 'location.payload', null);
    const documents = _.get(quotationData, 'file', []);

    const [files, setFiles] = useState(
        [
            {
                name: 'RFC',
                id: 0,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'Situación fiscal',
                id: 1,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'INE, pasaporte, cédula profesional',
                id: 2,
                file: null,
                deletedAt: false,
                watch: false
            },
        ]
    )

    useEffect(() => {
        if (documents.length > 0) {
            let newFiles = documents.map((el) => {
            return {
                    id: el.id,
                    file: {
                        url : el.file.url,
                        name: el.file.url,
                        deletedAt: el.deletedAt,
                    },
                    watch: true
                }
            })
            setFiles(newFiles)
        }
    }, [])

    const data = [
        { name: 'RFC' }, { name: 'Situacion  Fiscal' }, { name: 'INE, pasaporte, cédula profesional' }
    ]

    const history = useHistory();
    const dispatch = useDispatch();

    console.warn('sí entró al componente quotationDocsview')
    const submitData = async () => {

        // if (_.isEmpty(files)) { toggleModalNoFiles() }

        const formData_ = new FormData();

        // formData_.append("quotation", quotation);
        // formData_.append("quotationData", quotationData);
        formData_.append("mediasDeleted", '[]');

        const arrFiles = files.map(file => file.file != null ? file : false).filter(file => file != false)

        for (let i = 0; i < arrFiles.length; i++) {

            formData_.append("File", arrFiles[i].file);
        }


        const res = await dispatch(upsertQuotationAction(formData_))
        if (res) {
            history.push('/dashboard/cotizacion');
        }
    }
    return (
        <div className='main-container-docs-quotation'>
            <QuotationDocuments 
                FormClassName={'container-docs-quotation'} 
                documents={documents}
                onlyDocs
                quotationData={quotationData}
            />
        </div>
    )
}
