/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { DesviationsForm } from './DesviationsForm';
import { Button } from '../../../components/atoms';
import './RecordDeviations.scss';
import add from '../../../assets/images/navieras/agregar_red.png'
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllExpedientAction, upsertExpedient } from '../../../redux/actions/recordAction';
import { getAllPortsAction } from '../../../redux/actions/portAction';
import { getAllCountriesAction } from '../../../redux/actions/addressAction';
import _ from 'lodash';



const RecordDeviations = ({ toggleModal, location, commentaryDeviation, setCommentaryDeviation, }) => {

    const dispatch = useDispatch()

    const [stateSelected, setStateSelected] = useState('');
    const [stateSelectedId, setStateSelectedId] = useState('');
    const [countrySelected, setCountrySelected] = useState('');
    const getDeviations = useSelector(state => state.record);
    const [deviationState, setDeviationState ] = useState(null);
    const [countries, setCountries] = useState([])

    useEffect(() => {
        dispatch(getAllPortsAction());
        dispatch(getAllCountriesAction({}))
        dispatch(getAllExpedientAction({ "offset": 0, "limit": 200 }))
    }, []);

    useEffect(() => {
        if (getDeviations && getDeviations.getAllRecord && getDeviations.getAllRecord.data && getDeviations.getAllRecord.data.length > 0) {
            const data = _.map(getDeviations.getAllRecord.data, (ele) => {
                if (ele.id === location.payload.row.id) {
                    return {
                        ...ele
                    }
                } else {
                    return false;
                }
            }).filter(ele => ele !== false);
            setDeviationState([...data[0].route.deviation])
        }
    }, [getDeviations])

    const [formsDeleted, setFormsDeleted] = useState([]);
    const [last, setLast] = useState(null);

    const [delayReason, setDelayReason] = useState({
        reasonDeviations: commentaryDeviation,
    });


    useEffect(() => {
        if (deviationState && deviationState.length > 0) {
            const values = deviationState.map(el => {
                return {
                    id: el.id,
                    countryId: el.country.id,
                    country: el.country.name,
                    stateId: el.state.id,
                    state: el.state.name,
                }
            })
            setCountries([...values])
        }
    }, [deviationState])

    const handleAdd = () => {
        let arrayCopy = JSON.parse(JSON.stringify(countries));
        let lastElement = countries.pop();
        arrayCopy.push(
            {
                countryId: null,
                stateId: null,
                state: ''
            }
        )
        setCountries(arrayCopy);
    }

    useEffect(() => {
        if (countries) {
        }

    }, [countries])

    const saveChanges = async () => {
        const formData_ = new FormData()


        const route = JSON.stringify(delayReason)
        const deviationn = JSON.stringify(countries)


        formData_.append("id", location.payload.row.expedientId)
        formData_.append("expedient", '{}');
        formData_.append("customAgent", '{}');
        formData_.append("arrivalDates", '{}');
        formData_.append("paymentDetails", '{}');
        formData_.append("pickupDelivery", '{}');
        formData_.append("delay", '{}');
        formData_.append("route", route);
        formData_.append("deviation", deviationn);
        formData_.append("tracing", '[]');
        formData_.append("mediasDeleted", '[]');

        formData_.append("File", '[]');

        const res = await dispatch(upsertExpedient(formData_))
        if (res) {
            setCommentaryDeviation(delayReason.reasonDeviations)
            // setCountrySelected(countries.countryId)
            // setStateSelected(countries.stateId)
            toggleModal()
        }
    }

    return (
        <div className='deviations-modal-container'>
            <div className='deviations-title-container'>
                <h3>DESVÍOS</h3>
                <h4>Ingresar lugar de desvío:</h4>
            </div>
            <div className='deviations-select-container'>
                {
                    countries && countries.length > 0 && countries.map((ele, index) => {
                        return (
                            <DesviationsForm
                                posicion={index} ports={ele} arrayComplete={countries}
                                last={last}
                                formsBorrados={formsDeleted} setFormsBorrados={setFormsDeleted}
                                countrySelected={countrySelected} setCountrySelected={setCountrySelected}
                                stateSelected={stateSelected} setStateSelected={setStateSelected}
                                setCountries={setCountries}
                                stateSelectedId={stateSelectedId} setStateSelectedId={setStateSelectedId}

                            />
                        )
                    })
                }
            </div>
            <div className='add-button-container' style={{ justifyContent: 'flex-end' }}>
                <img className='add-card' src={add} onClick={handleAdd} />
            </div>
            <div className='deviation-reason'>
                <label>Motivo del desvío:</label>
                <input
                    className='reasons-deviations-input'
                    type='textarea'
                    placeholder='Escriba aquí sus comentarios...'
                    value={delayReason.reasonDeviations}
                    onChange={(e) => setDelayReason({ reasonDeviations: e.target.value })}
                />
            </div>
            <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                <Button type='button' onClick={toggleModal} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                <Button type='button' onClick={() => saveChanges()} className={'button-next'} style={{ width: '40%' }}>GUARDAR</Button>
            </div>
        </div>
    )
}

export default RecordDeviations;
