import errorIcon from '../../../assets/images/navieras/icon-error.png';

export const continueWithoutUploadFilesProvider = {
    title: '¿CONTINUAR?',
    body: 'No se ha cargado ninguna documentación al proveedor, ¿quiere continuar y cargar después la documentación?'
}

export const deleteDocument = {
    title: 'ELIMINAR DOCUMENTO',
    body: 'Esta acción es definitiva. Se eliminará por completo el documento.'
}

export const cancelUploadOfDocs = {
    title: 'CANCELAR CARGA DE DOCUMENTOS',
    body: 'Esta acción es definitiva. Se borrarán todos los documentos hasta ahora cargados.'
}

export const noDocumentsUploded = {
    title: 'NO SE HAN CARGADO DOCUMENTOS',
    body: '¿Desea guardar sin haber cargado ningún documento?',
}

export const cancelTracing = {
    title: 'CANCELAR SEGUIMIENTO',
    body: 'Esta acción es definitiva. Se borrarán todos los documentos hasta ahora agregados.'
}

export const closeRecordError = {
    title: 'ERROR AL CERRAR EXPEDIENTE',
    body: 'No ha completado expediente con la información solicitada.',
    src: errorIcon,
}
// export const noDocumentCharged = {
//     title: 'NO SE HAN CARGADO DOCUMENTOS',
//     body: '¿Desea guardar sin haber cargado ningún documento?',
// }
