import {
    CUSTOMSAGENT,
    HOME,
    ADMIN,
    CUSTOMERMANAGEMENT,
    QUOTATION,
    PROVIDERS,
    OPERATIVES,
    RECORDS,
    PORTS,
} from '../../../../routes/Paths';

import home from '../../../../assets/images/navieras/home-a.png';
import operativos from '../../../../assets/images/navieras/Icon-operativos.png';
import admins from '../../../../assets/images/navieras/Icon-administradores.png';
import proveedores from '../../../../assets/images/navieras/Icon-proveedores.png';
import cotizacion from '../../../../assets/images/navieras/Icon-cotizaciones.png';
import expediente from '../../../../assets/images/navieras/Icon-expedientes.png';
import aduanales from '../../../../assets/images/navieras/Icon-aduanales.png';
import clientes from '../../../../assets/images/navieras/Icon-clientes.png';
import puertos from '../../../../assets/images/navieras/puertos.png';


export const adminSidebarOptions = [
   
    {
        img: admins,
        optionName: 'Administrador',
        link: ADMIN,
    },
    {
        img: aduanales,
        optionName: 'Agentes Aduanales',
        link: CUSTOMSAGENT,
    },
    {
        img: clientes,
        optionName: 'Clientes',
        link: CUSTOMERMANAGEMENT,
    },
    {
        img: cotizacion,
        optionName: 'Cotizaciones',
        link: QUOTATION,
    },
    {
        img: expediente,
        optionName: 'Expedientes',
        link: RECORDS,
    },
    {
        img: home,
        optionName: 'Inicio',
        link: HOME,
    },
    {
        img: operativos,
        optionName: 'Operativos',
        link: OPERATIVES,
    },
    {
        img: proveedores,
        optionName: 'Proveedores',
        link: PROVIDERS,
    },
    {
        img: puertos,
        optionName: 'Puertos',
        link: PORTS,
    },
  
 
   
];


export const operativeSidebarOptions = [
    {
        img: home,
        optionName: 'Inicio',
        link: HOME,
    },
    {
        img: cotizacion,
        optionName: 'Gestión cotizaciones',
        link: QUOTATION,
    },
    {
        img: expediente,
        optionName: 'Gestión expedientes',
        link: RECORDS,
    },
    {
        img: clientes,
        optionName: 'Gestión clientes',
        link: CUSTOMERMANAGEMENT,
    }
];