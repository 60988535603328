import React from 'react';
import PropTypes from "prop-types";
import {connect, useSelector } from 'react-redux';
import _ from 'lodash';
import {
    withRouter,
} from 'react-router-dom';
import './MainContainer.scss';

const MainContainer = (props) => {
    const toggle = useSelector(state => state.userInterface.isSidebarOpened)
    const mainClass = toggle ? [ 'mainTemplate container-fluid ', 'navMin'] : [ 'mainTemplate mainTemplateClose container-fluid ', 'navMin'];

    if (!props.isSidebarOpened) {
        mainClass.pop();
    }

    return (
        <main className={mainClass.join(' ')}>
            {props.children}
        </main>
    );              
}

MainContainer.propTypes = {
    children: PropTypes.any,
    classNameMain: PropTypes.any,
    isSidebarOpened: PropTypes.any,
}

const mapStateToProps = (state) => { 
    return { 
        isSidebarOpened: _.get(state.userInterface, 'isSidebarOpened', false),
    }; 
};

const mapDispatchToProps = dispatch => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContainer));
