/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { Box, MenuItem, createSvgIcon } from '@material-ui/core';
import plus from '../../assets/images/navieras/plus-icon.png'
import '../../styles/tableViewStyles.scss';
import { Button as ButtonD } from '@material-ui/core';
import {
    Button,
    DataTable,
    ActionMenu,
    GlobalFilter,
    Link,
} from '../../components/atoms';
import {
    getAllQuotationsAction,
    deleteQuotationAction,
    getAllDeleteQuotationsAction,
} from '../../redux/actions/quotationAction';
import Modal from '../../components/organisms/Modal/Modal';
import { CardContainer } from '../../components/molecules/CardContainer/CardContainer';
import { DeleteBody } from '../../components/molecules/CancelFormBody/DeleteBody';
import { DataGrid, GridToolbarContainer, gridSortedRowIdsSelector, useGridApiContext } from '@mui/x-data-grid';



const QuotationDashboard = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { clientId } = useParams();
    const [searchObj, setSearchObj] = useState({});
    const [searchCondition, setSearchCondition] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [itemSelected, setItemSelected] = useState(true);
    const admin = useSelector(state => state.user.user.administratorId)
    const operative = useSelector(state => state.user.user.operativeId)
    const getQuotation = useSelector(state => state.quotation.quotations.data)
    const [quotation, setQuotation] = useState([]);


    const toggleModal = ({ type, id }) => {
        if (openModal === true) {
            setItemSelected(null)
        } {
            setItemSelected({ type, id })
        }
        setOpenModal(!openModal);
    }


    //     limit
    // : 
    // 5
    // offset
    // : 
    // 0
    // sort
    // : 
    // {id: 'id', desc: true}
    // where
    // : 
    // {textSearch: 'a'}
    useEffect(() => {
        // ? {"where":{"clientId": clientId}} :z;
        let search;
        if (clientId) {
            search = {
                ...searchCondition,
                "where": {
                    ...searchCondition.where,
                    "clientId": clientId
                }
            }
        } else {
            search = { ...searchCondition }
        }
        dispatch(getAllQuotationsAction(search));
    }, [props.isSuccessDeleteQuotation, searchCondition]);



    useEffect(() => {
        if (!_.isEmpty(searchObj)) {
            props.getAllQuotationsAction(
                {
                    where: searchObj
                }
            );
        }
    }, [searchObj]);


    useEffect(() => {
        setQuotation([])
        getQuotation && getQuotation.length > 0 && getQuotation.map((el) => {
            let sectionCopy = JSON.parse(JSON.stringify(el));
            sectionCopy.id = sectionCopy.id;
            // sectionCopy.expedient.mov = sectionCopy.expedient.mov;
            setQuotation((d) => [...d, sectionCopy]);
        })
    }, [getQuotation])


    const onClickActionMenu = (actionSelected, payload) => {
        switch (actionSelected) {
            case 'showRecord':
                history.push({
                    pathname: `/dashboard/cotizacion/${payload.id}/ver`,
                    state: payload
                });
                break;
            case 'deleteRecord':
                props.deleteQuotationAction({ id: payload.id, deletedBy: admin ? admin : operative });
                break;
            default:
                break;
        }
    }


    const deleteQuote = () => {
        props.deleteQuotationAction({ id: itemSelected.id, deletedBy: admin ? admin : operative });
        setOpenModal(!openModal);
    }

    // const modality = (quotation) => {
    //     let modality = '';
    //     if (quotation.modalityGroundId !== null) modality = 'Terrestre'
    //     else if (quotation.modalityAirId !== null) modality = 'Aéreo'
    //     else if (quotation.modalityMarineId !== null) {
    //         if (quotation.quotationData.lcl == true) modality = 'Marítimo LCL'
    //         else if (quotation.quotationData.fcl == true) modality = 'Marítimo FCL'
    //     }
    //     return modality;
    // }


    const getNewData = (searchCondition) => {
        // TODO: Restrucutrar vs Filtros*
        setSearchCondition(searchCondition);
    };


    const columnsGrid = [
        {
            field: 'id',
            headerName: 'ID',
            width: 60,
            headerAlign: 'center',
            type: 'number',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.id || 'null'}`
        },
        {
            field: 'cliente',
            headerName: 'Cliente',
            headerAlign: 'center',
            width: 310,
            type: 'text',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.client.tradename || 'Sin informacion'}`
        },
        {
            field: 'proveedor.name',
            headerName: 'Proveedor',
            width: 410,
            headerAlign: 'center',
            aling: 'center',
            type: 'text',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.provider.name || 'Sin Informacion'}`
        },
        {
            field: 'modalidad.type',
            headerName: 'Tipo de modalidad',
            type: 'text',
            width: 200,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            aling: 'center',
            valueGetter: (params) =>
                `${params.row.modalityGround != null ? 'Terrestre' : params.row.modalityAirId != null ? 'Aerea' : params.row.modalityMarine != null ? 'Maritimo' : 'Sin Informacion'}  ${params.row.modalityMarine != null ? params.row.modalityMarine.lcl == true ? 'LCL' : params.row.modalityMarine.fcl == true ? 'FCL' : '' : ''}`
        },
        {
            field: 'servicio.type',
            headerName: 'Tipo de servicio',
            type: 'text',
            width: 200,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            aling: 'center',
            valueGetter: (params) =>
                `${params.row.serviceImport ? 'Importacion' : params.row.serviceExport ? 'Exportacion' : params.row.serviceNacional ? 'Nacional' : 'Sin Informacion'}`
        },
        {
            field: 'action',
            headerName: 'Acciones',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            width: 130,
            headerAlign: 'center',
            aling: 'center',
            renderCell: (params) => {
                return (
                    <ActionMenu id={params.row.id} >
                        <MenuItem onClick={() => onClickActionMenu('showRecord', params.row)}>Ver cotización</MenuItem>
                        <MenuItem onClick={() => toggleModal({ type: 'eliminar', id: params.row.id })}>Eliminar cotización</MenuItem>
                    </ActionMenu>
                );
            },
        }
    ];



    const ExportIcon = createSvgIcon(
        <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
        "SaveAlt"
    );

    const getUnfilteredRows = ({ apiRef }) => gridSortedRowIdsSelector(apiRef);

    const descargarExpedient = () => {
        const apiRef = useGridApiContext();
        const handleExport = (options) => apiRef.current.exportDataAsCsv(options);
        const buttonBaseProps = {
            color: "primary",
            size: "small",
            startIcon: <ExportIcon />,
        };

        return (
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    // marginBottom: "1rem",
                    background: "#000;",

                }}
            >



                <GridToolbarContainer
                    csvOptions={{
                        utf8WithBom: true,
                        fileName: "Custom Agent",
                    }}
                >

                    <ButtonD className='button-exp2'
                        {...buttonBaseProps}
                        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}>
                        {/* <img icon={} className="img" style={{ marginRight: "1rem" }} /> */}
                        Descargar Excel
                    </ButtonD>
                </GridToolbarContainer>
            </div>
        );
    };


    const localizedTextsMap = {

        //Fileters operator text
        columnMenuUnsort: "Desclasificar",
        columnMenuSortAsc: "Ordenar por ASC",
        columnMenuSortDesc: "Ordenar por DESC",
        columnMenuFilter: "Filtrar",
        columnMenuHideColumn: "Esconder",
        columnMenuShowColumns: "Mostrar colunas",
        noRowsLabel: "No existen registros",
        noResultsOverlayLabel: 'No se han encontrado resultados',
        errorOverlayDefaultLabel: 'Ocurrió un error.',

        //Filter operator
        filterPanelColumns: 'Columnas',
        filterPanelOperators: 'Operador',
        filterPanelInputLabel: 'Valor',
        filterPanelInputPlaceholder: 'Valor de filtro',

        // Filter operators text
        filterOperatorContains: 'Contiene',
        filterOperatorEquals: 'Igual',
        filterOperatorStartsWith: 'Comienza con',
        filterOperatorEndsWith: 'Termina con',
        filterOperatorIs: 'Es',
        filterOperatorNot: 'No es',
        filterOperatorAfter: 'Es despues de',
        filterOperatorOnOrAfter: 'Está encendido o después',
        filterOperatorBefore: 'Es antes',
        filterOperatorOnOrBefore: 'Está en o antes',
        filterOperatorIsEmpty: 'Está vacío',
        filterOperatorIsNotEmpty: 'No está vacío',
        filterOperatorIsAnyOf: 'Es cualquiera de',


        // Columns panel text
        columnsPanelTextFieldLabel: 'Buscar columna',
        columnsPanelTextFieldPlaceholder: 'Título de la columna',
        columnsPanelDragIconLabel: 'Reordenar columna',
        columnsPanelShowAllButton: 'Mostrar todo',
        columnsPanelHideAllButton: 'Ocultar todo',
    };

    const options = {
        autoHeight: true,
        hideFooter: true,
    };


    return (
        <>
            {
                openModal && itemSelected.type === 'eliminar' &&
                <Modal
                    isOpen={toggleModal}
                    toggle={toggleModal}
                >
                    <CardContainer btnLeftAction={toggleModal} btnRightAction={() => deleteQuote()} body={<DeleteBody user={'cotización'} />} />
                </Modal>
            }
            <section className='ClientRecordAccountSection' >
                <div className='ClientRecordAccountSection__container'>
                    <div className='ClientRecordAccountSection__headerContainer'>
                        <div className='HomeTableSection__containerFilters'>
                            <GlobalFilter
                                filter={searchObj}
                                setFilter={setSearchObj}
                                showTextSearch
                                showDateRangeSearch={false}
                                // classNameDate={'containerFilters__date'}
                                // classNameSearch={'containerFilters__search'}
                                placeholderSearch={'Buscar ID...'}
                            />
                        </div>
                        <div className='HomeTableSection__containerButtons'>
                            <Link
                                className={'linkClient'}
                                to={'/dashboard/cotizaciones/crear'}>
                                <Button
                                    className={'containerButtons__clientButton'}
                                    srcicon={plus}
                                    onClick={() => history.push('/dashboard/cotizaciones/crear')}
                                >
                                    CREAR
                                </Button>
                            </Link>

                            <Link
                                className={'linkClient'}
                                to={'/dashboard/cotizacion-delete'}>
                                <Button
                                    className={'containerButtons__clientButton'}
                                    // srcicon={deleteIcon}
                                    onClick={() => history.push('/dashboard/cotizacion-delete')}
                                >
                                    PAPELERA
                                </Button>
                            </Link>
                        </div>

                    </div>
                    <div className='ClientRecordAccountSection__tableContainer'>


                        <Box sx={{
                            height: 390,
                            width: '100%',
                            '& .super-app-theme--header': {
                                backgroundColor: '#707070',
                                color: '#ffff',
                                fontFamily: 'Montserrat',
                                fontSize: '.9vw',
                            },
                        }}>

                            {/* <Scrollbar style={{ height: "100%", width: '100%' }}> */}

                            <div style={{ width: '100%', height: '100%', overflowX: 'auto' }}>
                                <DataGrid
                                    sx={{
                                        '@media print': {
                                            '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)' },
                                        }
                                    }}
                                    localeText={localizedTextsMap}
                                    rows={quotation}
                                    columns={columnsGrid}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    components={{ Toolbar: descargarExpedient }}
                                    checkboxSelection={false}
                                    disableSelectionOnClick
                                    experimentalFeatures={{ newEditingApi: true }}
                                // scrollbarSize={15}

                                />

                            </div>
                        </Box>
                        {/* <DataTable
                            columns={columns}
                            data={{
                                data: _.get(props.quotations, 'data', []),
                                totalRecords: _.get(props.quotations, 'totalRecords', 0),
                            }}
                            getData={getNewData}
                            searchObj={searchObj}
                        /> */}
                    </div>
                </div >
            </section >
        </>
    );
}

QuotationDashboard.propTypes = {
    history: PropTypes.any,
    dispatch: PropTypes.any,
    getAllQuotationsAction: PropTypes.any,
    quotations: PropTypes.any,
    deleteQuotationAction: PropTypes.any,
    isSuccessDeleteQuotation: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
};

function mapStateToProps(state) {
    return {
        quotations: _.get(state, 'quotation.quotations', {}),
        isSuccessDeleteQuotation: _.get(state, 'quotation.deleteQuotation.isSuccess', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    getAllQuotationsAction: params => dispatch(getAllQuotationsAction({"offset": 0,
        "limit": 100000})),
    deleteQuotationAction: params => dispatch(deleteQuotationAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuotationDashboard);
