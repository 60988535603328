import React, { useEffect, useState } from 'react'
import { Button } from '../../../../../components/atoms';
// import './ClientRegister.scss';
import { useHistory } from 'react-router-dom';
import '../../../../../styles/stylesSteppers.scss'
import arrow from '../../../../../assets/images/navieras/arrowButton.png'
import { SeeUploadedFiles } from '../../../../../components/organisms/UploadFile/seeUploadedFiles';
import { UploadFile } from '../../../../../components/organisms/UploadFile/UploadFile';
import { useDispatch, useSelector } from 'react-redux';
import { upsertClientAction } from '../../../../../redux/actions/clientAction';

export const SeeClientDocuments = ({ stepper, setStepper, toggleModal, formData, setFormData, mode }) => {

    const history = useHistory();
    const typeAction = useSelector(state => state.router.location.hash);
    const agent = JSON.stringify(formData && formData.agent ? formData.agent : []);
    const person = JSON.stringify(formData.person);
    const mediasDeleted = JSON.stringify(formData.mediasDeleted)
   

    const dispatch = useDispatch()

    const {
        id,
        businessname,
        country,
        creatorId,
        direction,
        isfisica,
        ismoral,
        paymentmethods,
        postcode,
        rfc,
        state,
        tradename,
        zone,
        file,
    } = formData;

    const client = {
        businessname,
        country,
        creatorId,
        direction,
        isfisica,
        ismoral,
        paymentmethods,
        postcode,
        rfc,
        state,
        tradename,
        zone,
        file
    }

    const clientD = JSON.stringify(client);

    const [files, setFiles] = useState(
        [
            {
                name: 'RFC',
                id: 0,
                file: null,
                deletedAt: false,
                watch: true
            },
            {
                name: 'Situación fiscal',
                id: 1,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'INE, pasaporte, cédula profesional',
                id: 2,
                file: null,
                deletedAt: false,
                watch: false
            },
        ]
    )


    const changeStage = () => {
        setStepper(stepper + 1)
    }

    const changeStageBack = () => {
        setStepper(stepper - 1)
    }





    const submitData = async () => {

        if (mode !== "ver") {

            if (_.isEmpty(files)) { toggleModalNoFiles() }
            const formData_ = new FormData();


            formData_.append("id", formData.id)


            formData_.append("client", clientD);
            formData_.append("person", person);
            formData_.append("agent", agent);
            formData_.append("mediasDeleted", mediasDeleted)
            // formData_.append("File", file);


            const arrFilesSend = file.map(file => file.file != null ? file : false).filter(file => file != false)

            for (let i = 0; i < arrFilesSend.length; i++) {
                formData_.append("File", arrFilesSend[i].file);
            }

            const res = await dispatch(upsertClientAction(formData_));

            if (res) {
                history.push('/dashboard/gestion-clientes');
            }
        }

        history.push('/dashboard/gestion-clientes');
    }


    return (

        <div className='main-container-generic-type'>
            <form>
                <div className='header-form-generic-type'>
                    <h2>Cotizaciones</h2>
                </div>
                <div className='body-form-generic-type-docs'>
                    {
                        files.map((ele) => {
                            return (
                                <UploadFile files={ele} noDelete={true} />
                            )
                        })
                    }
                </div>
                <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                    <Button onClick={toggleModal ? toggleModal : changeStageBack} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                    <Button type="button" onClick={submitData} className={'button-next'} srcicon={arrow} style={{ width: '40%' }}>SIGUIENTE</Button>
                </div>
            </form>

        </div>

    )
}
