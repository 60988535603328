import { userLogout } from '../redux/actions/userActions';

export default async function ({
    partialUrl,
    method,
    body,
    headers=null,
    state, 
    dispatch=null,
    isFileUpload=false,
}) {
    // Aca hay que validar que el token no este vencido
    return fetch(`https://74.208.189.189${partialUrl}`, 
    // return fetch(`http://localhost:1337${partialUrl}`, 

    {
        method,
        headers: headers ? headers : state.headers,
        
        // headers:new Headers({ ...(headers ? headers : state.headers) }) ,
      
        /*  headers: new Headers({ 
            'Content-Type': 'application/json',
            //'Access-Controll-Allow-Origin': '*',
        }),
        */

        body: method !== "GET" ? 
            (
                isFileUpload ? body : JSON.stringify({
                ...state.body,
                ...body,
                })
            ) : null,
    }).then(response => {
        if (!dispatch) {
            return response;
        }
        if (!response.ok) {
            if(response.status === 401) {
                dispatch(userLogout());
            }
            throw Error (response.statusText); // testiar
        }
        return response;
    });
}
