/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import profile from '../../../../assets/images/perfil.png';
import edit from '../../../../assets/images/navieras/editIcon.png';
import { Button } from '../../../atoms';
import './ProfileFormStyles.scss';
import { getOneOperativeAction, upsertOperativeAction } from '../../../../redux/actions/operativeAction';
import { upsertAdministratorAction } from '../../../../redux/actions/adminAction';
import { updatePasswordRequestAction } from '../../../../redux/actions/userActions';
import { addNotification } from '../../../../redux/actions/notificationAction';


export const ProfileFormOperative = (props) => {

    // useEffect(() => {
    //     // const catchUser = async () => {
    //         // let res =/*  await */ dispatch(getOneOperativeAction({ id: user.operativeId }));
    //         // // return res;
    //     // }
    //     dispatch(getOneOperativeAction({ id: user.operativeId }))
    //     // catchUser().then((res) => {
    //     //     console.warn(res);
    //     //     setData(res);
    //     // })
    // }, []);

    // const [ data, setData ] = useState(props.data);

    let { userId } = useParams();

    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(state => state.user.user);

    // const data = useSelector(state => state.operative.operativeRecord.data.data);

    //ADD IMG

    const [imageSrc, setImageSrc] = useState(profile);
    const [fileUrl, setFileUrl] = useState([]);
    const [imgBase64, setImgBase64] = useState([]);

    const api = process.env.SERVER_API_DATA_URL;
    //ESTE ES EL QUE SE VA A RECIBIR POR PARAMS
    const mainData = _.get(props.data, 'data', null);
    console.warn('operative dataaa',mainData);
    const firstNameDefault = _.get(mainData, 'person.firstName', '')
    const firstLastNameDefault = _.get(mainData, 'person.firstLastName', '');
    const positionDefault = _.get(mainData, `job`, '');
    const startsHourAtDefault = _.get(mainData, `workHourStartAt`, '');
    const endsHourAtDefault = _.get(mainData, `workHourEndAt`, '');
    const dateOfAdmissionDefault = _.get(mainData, `dateOfAdmission`, null);
    const imgDefault =  _.get(mainData, 'media.media.url', null);
    const dateOfAdmissionDefaultTLP = _.get(mainData, 'dateOfAdmissionTLP', '');
    console.warn(_.get(mainData,'media', 'noxiste'));

    const dateFormat = (date) => {
         date ? moment(date).format('yyyy-MM-DD') : moment(new Date()).format('yyyy-MM-DD');
    }
    
    const fechaIngreso = dateOfAdmissionDefault ? moment(dateOfAdmissionDefault).format('yyyy-MM-DD') : moment(new Date()).format('yyyy-MM-DD');

    const fechaIngresoTLP = dateOfAdmissionDefaultTLP ? moment(dateOfAdmissionDefaultTLP).format('yyyy-MM-DD') : moment(new Date()).format('yyyy-MM-DD');
    let defaultValues = {
        name : firstNameDefault,
        lastName: firstLastNameDefault,
        position: positionDefault,
    };
     
    const methods = useForm({ defaultValues: defaultValues });
    const { register, handleSubmit, control, reset, watch, formState: { errors }, } = methods;
    const watchAllFields = watch();
    useEffect(() => {
        const subscription = watch((value, { name, type }) => console.log(value, name, type));
        return () => subscription.unsubscribe();
      }, [watch]);

    // useEffect (() => {
    //     defaultValues = {
    //         name: props.data && props.data.person.firstName,
    //         lastName: props.data && props.data.person.firstLastName,
    //         position: props.data && props.data.operative.job,
    //     }
    //     reset(defaultValues)
    // }, [props.data, reset])

    const id = user.id ? user.id : '';
    const [disabled, setDisabled]= useState(id ? true : false);

    // useEffect(() => {
    //       reset(props.data);
    //   }, [props.data]);


    useEffect(() => {
            if (mainData) {
                setHoraInicio(startsHourAtDefault);
                setHoraFin(endsHourAtDefault);
                let semana=[];
                if (mainData.workInMonday === true) {
                    semana.push({
                        dia:'L',
                        id:1,
                        selected:true
                    })
                }else{
                    semana.push({
                        dia:'L',
                        id:1,
                        selected:false
                    }
                )}
                if (mainData.workInTuesday === true) {
                    semana.push({
                        dia:'M',
                        id:2,
                        selected:true
                    })
                }else{
                    semana.push({
                        dia:'M',
                        id:2,
                        selected:false
                    }
                )}
                if (mainData.workInWednesday === true) {
                    semana.push({
                        dia:'M',
                        id:3,
                        selected:true
                    })
                }else{
                    semana.push({
                        dia:'M',
                        id:3,
                        selected:false
                    }
                )}
                if (mainData.workInThursday === true) {
                    semana.push({
                        dia:'J',
                        id:4,
                        selected:true
                    })
                }else{
                    semana.push({
                        dia:'J',
                        id:4,
                        selected:false
                    }
                )}
                if (mainData.workInFriday === true) {
                    semana.push({
                        dia:'V',
                        id:5,
                        selected:true
                    })
                }else{
                    semana.push({
                        dia:'V',
                        id:5,
                        selected:false
                    }
                )}
                if (mainData.workInSaturday === true) {
                    semana.push({
                        dia:'S',
                        id:6,
                        selected:true
                    })
                }else{
                    semana.push({
                        dia:'S',
                        id:6,
                        selected:false
                    }
                )}
                if (mainData.workInSunday === true) {
                    semana.push({
                        dia:'D',
                        id:7,
                        selected:true
                    })
                }else{
                    semana.push({
                        dia:'D',
                        id:7,
                        selected:false
                    }
                )}
                setSemana1(semana)
            }
        }, [])

    const [ horaInicio, setHoraInicio ] = useState('08:00');
    const [ horaFin, setHoraFin ] = useState('17:00');
    const handleAddHour = (e) => {
        setHoraInicio(e.target.value)
    }
    const handleAddHourFinal = (e) => {
        setHoraFin(e.target.value)
    }
    const [semana1,setSemana1] = useState([
        {
            dia:'L',
            id:1,
            selected:false
        },
        {
            dia:'M',
            id:2,
            selected:false
        },
        {
            dia:'M',
            id:3,
            selected:false
        },
        {
            dia:'J',
            id:4,
            selected:false
        },
        {
            dia:'V',
            id:5,
            selected:false
        },
        {
            dia:'S',
            id:6,
            selected:false
        },
        {
            dia:'D',
            id:7,
            selected:false
        },
    ])
    const handleAdd = (item) => {
        let semanaCopy = JSON.parse(JSON.stringify(semana1));
        semanaCopy.map( (el) => {
            if(item.id === el.id){
                if(el.selected === true){
                    el.selected = false
                }
                else{
                    el.selected = true
                }
            }
        })
        setSemana1(semanaCopy);
    }

    const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                return resolve({
                    src: reader.result,
                    title: file.name
                });
            }
            reader.onerror = error => reject(error);
        });
    };
    const unpdateInfoFile = (event) => {
        const imageFile = event.target.files;
        const urlImages = [];
        const arrImgBase64 = [];
        Object.entries(imageFile).map(async ele => {

            urlImages.push(URL.createObjectURL(ele[1]));

            try {
                const base64 = await toBase64(ele[1])
                    .then(img => {
                        return img;
                    })

                if (base64) {
                    arrImgBase64.push(base64);
                }
            } catch (err) {
                console.log(err)
                return err
            }

        }
        );
        setFileUrl(
            urlImages,
        )
        setImgBase64(
            arrImgBase64,
        )
        // setPicturesSelected(imageFile.name)
    };


    const profileImg = fileUrl[fileUrl.length - 1] ? fileUrl[fileUrl.length - 1] : (imgDefault ? `${api}/${imgDefault}` : imageSrc);

    const setImgProfile = {
        backgroundImage:  `url(${profileImg})`,
        backgroundSize:     "cover",
        backgroundRepeat:   "no-repeat",
    }
    //END ADD IMG

    const onSubmit = async ({name, lastName, position, email}) => {
        const data = {
            id: mainData.id,
            user: userId ? {
                // id: data.id,
                email: email,
            }:
            {
                email: email,
                password: '1234'
            },

            operative: {
                // id : userId ? _.get(data, 'administrator.id'): 0,
                job: position,
                workHourStartAt: horaInicio,
                workHourEndAt: horaFin,
                dateOfAdmission: fechaIngreso,
                workInMonday: semana1[0].selected,
                workInTuesday: semana1[1].selected,
                workInWednesday: semana1[2].selected,
                workInThursday:semana1[3].selected,
                workInFriday:semana1[4].selected,
                workInSaturday:semana1[5].selected,
                workInSunday:semana1[6].selected,
                media: imgBase64.length < 1 ? null : {
                    base64: {
                        src: imgBase64[0].src,
                        title: imgBase64[0].title.replace(/[{()}]/g, ''),
                    }
                },
            },
            person: {
                firstName: name,
                firstLastName: lastName,
            }
        }

        const res = await dispatch(upsertOperativeAction(data));
        if(res){
            history.push('inicio');
        }
        // if (props.isSuccessUpsertAdmin && !userId) {
        //     const res2 = await dispatch(updatePasswordRequestAction({email}));
        //     if (props.isSuccessUpdatePassword) {
        //         dispatch(addNotification({
        //             toastType: 'success',
        //             toastTitle: 'Se han envido instrucciones para concluir la acción a su email',
        //         }));
        //         history.push(OPERATIVES);
        //     }
        // }
        // else if (props.isSuccessUpsertAdmin && userId) history.push(ADMIN);
    }


    return (
        <div className='main-container'>
            <form className='form-admin-container' onSubmit={handleSubmit(onSubmit)}>
                <div className='form-admin-profile-left form-admin-profile-cards'>
                    <div className='picture-container'>
                        <div className="preContainer">
                            <div className='img-profile'style={setImgProfile}>
                            </div>
                        </div>
                    </div>
                    <div className='text-container'>
                        <h2>{`${firstNameDefault} ${firstLastNameDefault}`}</h2>
                        <p>{'Operativo'}</p>
                        <div className={disabled ? 'container-input disable' : 'container-input'}>
                            <input type='file' id='carga' accept='image/*' className='file' name='image' onChange={unpdateInfoFile} />
                            <label className="LeftInfoComponent-button" htmlFor={disabled ? '' : 'carga'}>
                                SUBIR FOTO
                            </label>
                        </div>
                    </div>
                    <div className='bottom-text'>
                        {
                            props.type !=='create' &&
                            <p>ID : {mainData.id}</p>
                        }
                        <p>Fecha de ingreso: {fechaIngreso}</p>
                        <p>Fecha de ingreso al sistema: {fechaIngresoTLP}</p>
                    </div>
                </div>
                <div className='form-admin-profile-right form-admin-profile-cards'>
                    <div className='header-form'>
                        <h2>Datos principales</h2>
                        {
                            props.type !=='create' &&
                            <div className='edit-profil-button' onClick={()=> setDisabled(!disabled)}> <img src={edit}/></div>
                        }
                    </div>
                    <div className='body-form'>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Nombre(s)</label>
                            </div>
                            <div className='value-container'>
                                <input disabled={disabled} control={control} name={'name'} {...register('name')} className="input-generic" type="text" placeholder="Nombre(s)"/>
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Apellido(s)</label>
                            </div>
                            <div className='value-container'>
                                <input disabled={disabled} control={control} name={'lastName'} {...register('lastName')} className="input-generic" type="text" placeholder="Apellido(s)"/>
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Puesto</label>
                            </div>
                            <div className='value-container'>
                                <input disabled={disabled} control={control} name={'position'} {...register('position')} className="input-generic" type="text" placeholder="Puesto"/>
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Horario</label>
                            </div>
                            <div className='value-container' style={{justifyContent: 'space-between'}}>
                                {/* <input disabled={disabled} control={control} name={'name'} {...register('name')} className="input-generic" type="text" placeholder=""/> */}
                                <input type='time' disabled={disabled} control={control} value={horaInicio} onChange={(e) => handleAddHour(e)} className='input-time'/>
                                <input type='time' disabled={disabled} control={control} value={horaFin} onChange={(e) => handleAddHourFinal(e)} className='input-time'/>
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                            </div>
                            <div className='value-container'>
                                <div className='container-days'>
                                    {
                                        semana1 &&
                                        semana1.map( (item,index) => (
                                            <button type='button' onClick={() => handleAdd(item)} className={`btnDay ${item.selected === true ? 'active' : ''}`}  name={item.id}>{item.dia}</button>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='footer-form'>
                        <h2 onClick={() => history.goBack()}>
                            REGRESAR
                        </h2>
                        <Button type='submit' style={{marginTop:'0'}}>GUARDAR</Button>
                    </div>
                </div>
            </form>
        </div>
    );
}

ProfileFormOperative.propTypes = {
    isSuccessUpsertAdmin: PropTypes.any,
}

const mapStateToProps = (state) => {
    return {
        isSuccessUpdatePassword: _.get(state, 'user.updatePasswordRequest.isSuccess', {}),
        user: _.get(state, 'user.user', {}),
        operativeRecord: _.get(state, 'operative.operativeRecord.data', {}),
    }
}

const mapDispatchToProps = dispatch => ({
});

connect(mapDispatchToProps, mapStateToProps)(ProfileFormOperative);