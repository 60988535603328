import request from "../../utils/request";
import { addNotification } from './notificationAction';
import {
    OPERATIVE_MODULE_GET_ALL_OPERATIVES_BEGIN,
    OPERATIVE_MODULE_GET_ALL_OPERATIVES_SUCCESS,
    OPERATIVE_MODULE_GET_ALL_OPERATIVES_FAILURE,
    OPERATIVE_MODULE_GET_ONE_OPERATIVE_BEGIN,
    OPERATIVE_MODULE_GET_ONE_OPERATIVE_SUCCESS,
    OPERATIVE_MODULE_GET_ONE_OPERATIVE_FAILURE,
    OPERATIVE_MODULE_UPSERT_OPERATIVE_BEGIN,
    OPERATIVE_MODULE_UPSERT_OPERATIVE_SUCCESS,
    OPERATIVE_MODULE_UPSERT_OPERATIVE_FAILURE,
    OPERATIVE_MODULE_DELETE_OPERATIVE_BEGIN,
    OPERATIVE_MODULE_DELETE_OPERATIVE_SUCCESS,
    OPERATIVE_MODULE_DELETE_OPERATIVE_FAILURE,
} from './../actionTypes';

// ACTIONS TO REDUCER
 
export const getAllOperativesBegin = () => ({
    type: OPERATIVE_MODULE_GET_ALL_OPERATIVES_BEGIN,
});

export const getAllOperativesSuccess = (/* totalRecords, */ data) => ({
    type: OPERATIVE_MODULE_GET_ALL_OPERATIVES_SUCCESS,
    // totalRecords,
    data,
});

export const getAllOperativesFailure = (err) => ({
    type: OPERATIVE_MODULE_GET_ALL_OPERATIVES_FAILURE,
    err
});

export const getOneOperativeBegin = () => ({
    type: OPERATIVE_MODULE_GET_ONE_OPERATIVE_BEGIN,
});

export const getOneOperativeSuccess = (data) => ({
    type: OPERATIVE_MODULE_GET_ONE_OPERATIVE_SUCCESS,
    data,
});

export const getOneOperativeFailure = (err) => ({
    type: OPERATIVE_MODULE_GET_ONE_OPERATIVE_FAILURE,
    err
});

export const upsertOperativeBegin = () => ({
    type: OPERATIVE_MODULE_UPSERT_OPERATIVE_BEGIN,
});

export const upsertOperativeSuccess = ({totalRecords, data}) => ({
    type: OPERATIVE_MODULE_UPSERT_OPERATIVE_SUCCESS,
    data,
    totalRecords,
});

export const upsertOperativeFailure = (err) => ({
    type: OPERATIVE_MODULE_UPSERT_OPERATIVE_FAILURE,
    err
});

export const deleteOperativeBegin = () => ({
    type: OPERATIVE_MODULE_DELETE_OPERATIVE_BEGIN,
});

export const deleteOperativeSuccess = () => ({
    type: OPERATIVE_MODULE_DELETE_OPERATIVE_SUCCESS,
});

export const deleteOperativeFailure = (err) => ({
    type: OPERATIVE_MODULE_DELETE_OPERATIVE_FAILURE,
    err,
});


// DISPATCH TO ACTIONSv

export function getAllOperativesAction (data) {
    return (dispatch, getState) => {
        dispatch(getAllOperativesBegin());
        return request({
            partialUrl: `/api/v1/operative/get-all-operative-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(getAllOperativesSuccess(data.data));
            return data.data;
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllOperativesFailure(err));
        });
    };
}

export function getOneOperativeAction (data) {
    return (dispatch, getState) => {
        dispatch(getOneOperativeBegin());
        return request({
            partialUrl: `/api/v1/operative/get-one-operative-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(getOneOperativeSuccess(data.data));
            return data.data.data;
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(getOneOperativeFailure(err));
        });
    };
}

export function upsertOperativeAction (data) {
    return (dispatch, getState) => {
        dispatch(upsertOperativeBegin());
        return request({
            partialUrl: `/api/v1/operative/upsert-operative-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(upsertOperativeSuccess(data.data));
            return true
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al actualizar la información',
            }));
            return dispatch(upsertOperativeFailure(err));
        });
    };
}

export function deleteOperativeAction(data){
    return (dispatch, getState) => {
        dispatch(deleteOperativeBegin());
        return request({
            partialUrl: `/api/v1/operative/delete-operative-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(deleteOperativeSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(deleteOperativeFailure(err));
        });
    };
}
