import React, { useState } from 'react'
import { Button } from '../../../../components/atoms';
// import './ClientRegister.scss';
import '../../../../styles/stylesSteppers.scss'
import arrow from '../../../../assets/images/navieras/arrowButton.png'
import { useHistory } from 'react-router-dom';
import { CLIENT_CREATE } from '../../../../routes/Paths';

export const ClientType = ({stepper, setStepper,mode}) => {
    const [clientType, setClientType]= useState(null);
    const history = useHistory()
    const clientTypes = [
        {
        id : 1,
        name :'Persona fisica'
        },
        {
        id : 2,
        name :'Persona moral'
        },
    ]
    const changeStage = ()=>{
        history.push(
            {
                pathname: `${CLIENT_CREATE.replace(':mode', 'crear')}`,
                state: {clientType},
            }
        )
    }
  return (
    <div className='main-container'>
        <div className='stepper-container'>

        </div>
        <div className='card-container-generic-type'>
            <div className='main-container-generic-type'>
                <div className='header-form-generic-type'>
                    <h2>Tipo de cliente</h2>
                </div>
                <div className='body-form-generic-type'>
                    <div className='input-container-data'>
                                    <div className='label-container'>
                                        <label className='container-label'>Tipo de usuario</label>
                                    </div>
                                    <div className='value-container' >
                                    <select required className='input-select' value={clientType} onChange={(e) => {
                                        setClientType(parseInt(e.target.value));
                                    }}>
                                        <option required className='option-selected' value={null} selected hidden>Seleccionar tipo de cliente</option>
                                        {
                                            clientTypes &&
                                            clientTypes.map(ele => {
                                                return <option value={ele.id}> {ele.name}</option>
                                            })
                                        }
                                    </select>
                                    </div>
                                </div>
                    </div>
                <div className='footer-form-generic-type'>
                    <Button onClick={changeStage} className={'button-next'} srcicon={arrow} style={{width: '40%'}}>SIGUIENTE</Button>
                </div>
            </div>
        </div>
    </div>
  )
}
