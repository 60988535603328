/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';
import './VerticalStepper.scss';
import moment from 'moment';

const VerticalStepper = ({ array, onClick }) => {

    return (
        <div className='status-data-container'>
            {
                !_.isEmpty(array) && array !== null &&
                array.map((ele, i) => 
                <>
                    <div className='checkpoint-container'
                        onClick={() => onClick(i)}
                    >
                        <div className='checkpoint'></div>
                        <p className='place'>{`${ele && ele.state}, ${ele && ele.country && ele.country}`}</p>
                    </div>
                    <div className='line-between-checkpoints-container'>
                        <div className={i != (array.length - 1) ? 'line-between-checkpoints' : 'line-between-checkpoints none'}></div>
                        <div className='status-container'>
                            <p className='date'>{ele && ele.date && moment( ele.date).utc().format('DD/MM/YYYY')}</p>
                            <p className='space'>{' - '}</p>
                            <p className='status'>{ele && ele.status}</p>
                        </div>
                    </div>
                </>
                )
            }
        </div>
    )
}

export default VerticalStepper;
