/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './../../../components/templates/FullScreenLayout/FullScreenLayout.scss';
import Link from '../../../components/atoms/Link1';
import InputPassword from '../../../components/atoms/InputPassword';
import { userLogin } from '../../../redux/actions/userActions';
import { getOneCompanyRecordAction } from '../../../redux/actions/companyAction';
import user from '../../../assets/images/icon_user.png';
import password from '../../../assets/images/password.png';
import { ButtonComponent } from '../../../components/atoms/buttonComponent';
import { Button } from '../../../components/atoms';



const FormLogIn = (props) => {

    const [openModal, setOpenModal] = useState(false)
    const [userValue, setUserValue] = useState(null)
    const [contraValue,serContraValue] = useState(null)
    const history = useHistory();
    const { register, handleSubmit } = useForm();
   
   



    // useEffect(() => {
    //     if (props.isAuthenticated) {
    //         if (props.typeUser !== "administrator") {

    //             const validation = async () => {

    //                 const res = await dispatch(getOneCompanyRecordAction({ id: userId }));
    //                 setUserValue(res);
    //                 if (userValue !== null) {
    //                     toggleModal();
    //                 }
    //             }
    //             validation();
    //         } else {
    //             history.push('/dashboard');
    //         }
    //     }
    // }, [props.isAuthenticated]);
    // const toggleModal = () => {
    //     setOpenModal(!openModal);
    // }

    const onSubmitLoginForm = async (formData) => {
        const result = await props.userLogin(formData);
        
        result &&
        history.push('/dashboard');
    }

    return (
        <>
            <form
                name='login'
                onSubmit={handleSubmit(onSubmitLoginForm)}
                className='form-logIn'>


                <p className='txt-login'>INICIAR SESIÓN</p>

                <div className={'log-in'}>

                    <InputPassword
                        className='email-input'
                        type={'email'}
                        inputName={'usuario'}
                        {...register('email')}
                        placeholder={'Usuario'}
                        icon={user}
                    />
                    <InputPassword
                        name={'contraseña'}
                        {...register('password')}
                        placeholder={'Contraseña'}
                        type={'password'}
                        icon={password}
                    />
                    <Button type='submit'>INICIAR SESIÓN</Button>

                    <Link className={'link'} to={props.to}>RECUPERAR CONTRASEÑA</Link>

                </div>
            </form>
        </>
    );
}

FormLogIn.propTypes = {
    to: PropTypes.any,
    isAuthenticated: PropTypes.bool,
    userLogin: PropTypes.func,
}

function mapStateToProps(state) {
    return {
        isAuthenticated: _.get(state.user, 'isAuthenticated', false),
        typeUser: _.get(state.user, 'user.roles[0]', false),
    };
}

const mapDispatchToProps = dispatch => ({
    userLogin: params => dispatch(userLogin(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormLogIn);
