import React, { useState, useEffect } from 'react';
import { Button } from '../../../../../components/atoms';
import _ from 'lodash';
// import './ClientRegister.scss';
import '../../../../../styles/stylesSteppers.scss';
import arrow from '../../../../../assets/images/navieras/arrowButton.png';
import { useForm } from 'react-hook-form';
import Modal from '../../../../../components/organisms/Modal/Modal';
import { AssignCustomsAgent } from '../AssignCustomsAgent';
import { CardContainer } from '../../../../../components/molecules/CardContainer/CardContainer';
import seeButton from '../../../../../assets/images/navieras/seeButton.png';
import add from '../../../../../assets/images/navieras/plus-icon.png';
import { upsertCostumerCustomAgentAction } from '../../../../../redux/actions/CostumerCustomAgent';
import { addNotification } from '../../../../../redux/actions/notificationAction';
// import { getCustomAgentBelongsClient } from '../../../../../redux/actions/clientAction';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAssociation, getAllCustomAgentBelongsClient, getAllAvailableCustomAgentsAction } from '../../../../../redux/actions/customsAgentAction';
import { useHistory, useParams } from 'react-router-dom';
import { upsertClientAction } from '../../../../../redux/actions/clientAction';



export const CustomsAgents = ({ stepper, setStepper, toggleModal, formData, setFormData }) => {

    const [openAssignAgentModal, setOpenAssignAgentModal] = useState(false);
    // const [itemSelected, setItemSelected] = useState(true);
    const [customAgentAssigned, setCustomAgentAssigned] = useState(null);

    const { mode } = useParams();
    const [isDisabled, setIsDisabled] = useState(mode === 'ver' ? true : false);

    const methods = useForm({ defaultValues: defaultValues });
    const dispatch = useDispatch();
    const offset = 0;
    const limit = 2;
    const customAgents = useSelector(state => state.customAgent.getCustomAgentBelongsClient);
    const associationDelete = useSelector(state => state.customAgent.deleteAssociation);
    const customAgentsAvailable = useSelector(state => state.customAgent.customAgentsAvailableByClient);
    const history = useHistory()

    const person = JSON.stringify(formData.person);
    const clientId = formData.id;
    const agent = JSON.stringify(formData && formData.agent ? formData.agent : []);
    const mediasDeleted = JSON.stringify(formData.mediasDeleted)
    const files = JSON.stringify(formData.file)



    const {
        id,
        businessname,
        country,
        creatorId,
        direction,
        isfisica,
        ismoral,
        paymentmethods,
        postcode,
        rfc,
        state,
        tradename,
        zone,
        file,
    } = formData;

    const client = {
        businessname,
        country,
        creatorId,
        direction,
        isfisica,
        ismoral,
        paymentmethods,
        postcode,
        rfc,
        state,
        tradename,
        zone,
        file
    }

    const clientD = JSON.stringify(client);


    const toggleAssignModal = () => {
        setOpenAssignAgentModal(!openAssignAgentModal);
    }

    const preAssingCustomAgent = async () => {
        const res = await dispatch(getAllAvailableCustomAgentsAction({ "clientId": formData.id }));
        if (res) {
            toggleAssignModal(1, formData.id);
        } else {
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de agentes aduanales, favor de intentarlo de nuevo',
            }));
        }
    }

    const defaultValues = {
        // name : '',
    };
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;
    const changeStage = () => {
        setStepper(stepper + 1)
    }
    const changeStageBack = () => {
        setStepper(stepper - 1)
    }
    // const datos = [
    //     {
    //         id: 1,
    //         name: 'Grupo Bolt'
    //     },
    //     {
    //         id: 2,
    //         name: 'Importadira GCR'
    //     },
    //     {
    //         id: 3,
    //         name: 'Mexbuy'
    //     },
    // ]

    useEffect(() => {
        if (formData) {
            dispatch(getAllCustomAgentBelongsClient({ clientId, offset, limit }))
        } if (associationDelete && associationDelete.isSuccess) {
            dispatch(getAllCustomAgentBelongsClient({ clientId, offset, limit }))
        }
    }, [associationDelete])


    const deleteAsign = (id) => {
        dispatch(deleteAssociation({ id }))
    }
    const seeCustomer = (id, payload, file, associationClientAgent, media) => {

        history.push(
            {
                pathname: `/dashboard/agentes-aduanales/${id}/ver`,
                state: { ...payload, "file": file, "associationPorts": associationClientAgent, "media": media }
            });

    }

    const assignAgent = async () => {
        if (customAgentAssigned) {
            const data = {
                idClient: clientId,
                idAgend: customAgentAssigned,
            }
            let res = await dispatch(upsertCostumerCustomAgentAction(data));
            if (res) {
                setOpenAssignAgentModal(false);
                dispatch(getAllCustomAgentBelongsClient({ clientId, offset, limit }))
            }
        } else {
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Debe seleccionar algún agente aduanal',
            }));
        }
    }

    const submitData = async () => {

        if (mode !== "ver") {

            if (_.isEmpty(files)) { toggleModalNoFiles() }
            const formData_ = new FormData();


            formData_.append("id", formData.id)


            formData_.append("client", clientD);
            formData_.append("person", person);
            formData_.append("agent", agent);
            formData_.append("mediasDeleted", mediasDeleted)
            // formData_.append("File", file);


            const arrFilesSend = file.map(file => file.file != null ? file : false).filter(file => file != false)

            if (arrFilesSend.length > 0) {
                for (let i = 0; i < arrFilesSend.length; i++) {
                    if (arrFilesSend[i].newAdd) {
                        formData_.append("File", arrFilesSend[i].file);
                    }
                }
            } else {
                formData_.append("File", '[]');
            }

            if (!formData_.has("File")) {
                formData_.append("File", '[]');
            }

            const res = await dispatch(upsertClientAction(formData_));

            if (res) {
                history.push('/dashboard/gestion-clientes');
            }
        }

        history.push('/dashboard/gestion-clientes');
    }

  

    return (
        <>
            {
                openAssignAgentModal /* && itemSelected[0] === 1 */ &&
                <Modal
                    isOpen={toggleAssignModal}
                    toggle={toggleAssignModal}
                >
                    <CardContainer btnLeftAction={toggleAssignModal} btnRightAction={() => assignAgent()} title={'Agente Aduanal'} body={<AssignCustomsAgent customsAgents={!_.isEmpty(customAgentsAvailable) && _.get(customAgentsAvailable, 'data.rows', [])} setCustomAgentAssigned={setCustomAgentAssigned} customAgentAssigned={customAgentAssigned} />} />
                </Modal>
            }



            <form className='main-container-generic-type' >

                <div className='header-form-generic-type'>
                    <h2>Agentes aduanales asignados</h2>
                </div>
                <div className='body-form-generic-type-data'>
                    <div className='button-head-container'>
                        <Button type="button" disabled={isDisabled} style={{ margin: 0 }} srcicon={add} onClick={() => preAssingCustomAgent()}>ASIGNAR</Button>
                    </div>
                    <div>
                        {
                            customAgents && customAgents.data && customAgents.data.associationClientAgent &&
                            customAgents.data.associationClientAgent.map((ele, index) => {
                                return (
                                    <div className='customer-agent-container'>
                                        <div className='customer-agent-row'>
                                            <h2>{ele && ele.customagendclient ? ele.customagendclient.agentname : '---'}</h2>
                                            <img onClick={() => seeCustomer(ele.idAgend, ele.customagendclient, ele.file, ele.ports, ele.media)} src={seeButton} alt="" />
                                            <button type="button" disabled={isDisabled} onClick={() => deleteAsign(ele.id)} style={{ margin: '0' }}>QUITAR ASIGNACION</button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                    <Button type='button' onClick={toggleModal ? toggleModal : changeStageBack} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                    <Button type='button' onClick={submitData} className={'button-next'} srcicon={arrow} style={{ width: '40%' }}>GUARDAR</Button>
                </div>
            </form>
        </>
    )
}
