import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Link from '../../../atoms/Link1';
import './SidebarOptionItem.scss'

const SidebarOptionItem = ({
    icon = 'sidebarItemIconContainer',
    optionName,
    text = 'link',
    link,
    img,
    sidebarState,
    onclick,
}) => {
    
    const history = useHistory();
    const isSidebarOpened = () => {
        if (sidebarState) {
            onclick();
        } 
    }

    return (
        <div
            className={sidebarState == false ? 'optionItemContainer' : 'optionItemContainer open' }
            key={optionName}
            onClick={() => {history.push(link), isSidebarOpened()}}
        >
            <div className={icon}>
                <img className='iconImg' src={img}/>
            </div>
            <div className={sidebarState == true ? 'openOptionItem' : 'closeOptionItem'}>
                {
                    sidebarState == true &&
                    <Link
                        to={link}
                        className={text}
                        id={optionName}
                    >
                        {optionName}
                    </Link>
                }
            </div>
        </div>
    )
}

SidebarOptionItem.propTypes = {
    span: PropTypes.any,
    icon: PropTypes.any,
    img: PropTypes.any,
    optionName: PropTypes.any,
    text: PropTypes.any,
    link: PropTypes.string,
    activeIconClassName: PropTypes.any,
    sidebarState: PropTypes.any,
    onclick: PropTypes.func,
}

export default SidebarOptionItem;
