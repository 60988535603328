/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import profile from '../../assets/images/perfil.png';
import edit from '../../assets/images/navieras/editIcon.png';
import { Button } from '../../components/atoms';
import '../../components/templates/BaseLayout/Profile/ProfileFormStyles.scss';
import { upsertOperativeAction } from '../../redux/actions/operativeAction';
import { OPERATIVES } from '../../routes/Paths';
import historyApiFallback from 'connect-history-api-fallback';

export const OperativeDetailsView = (props) => {

    const history = useHistory();
    let { userId } = useParams();
    const dispatch = useDispatch();
    const api = process.env.SERVER_API_DATA_URL;

    const { mode } = useParams();

    const currentData = _.get(props, 'location.state', null);

    //ADD IMG
    const [imageSrc, setImageSrc] = useState(profile);
    const [fileUrl, setFileUrl] = useState([]);
    const [imgBase64, setImgBase64] = useState(null);
    const [nameBase64, setNameBase64] = useState('');

    //ESTE ES EL QUE SE VA A RECIBIR POR PARAMS
    const upsertSuccess = useSelector(state => state.operative.upsertOperative.isSuccess);

    const admissionDate = currentData && currentData.operative && currentData.operative.dateOfAdmission ? moment(currentData.operative.dateOfAdmission).format('yyyy-MM-DD') : moment(new Date()).format('yyyy-MM-DD');
    const admissionDateTLP = currentData && currentData.operative && currentData.operative.dateOfAdmissionTLP ? moment(currentData.operative.dateOfAdmissionTLP).format('yyyy-MM-DD') : moment(new Date()).format('yyyy-MM-DD');
    // const defaultValues = {
    //     name : firstNameDefault,
    //     lastName: firstLastNameDefault,
    //     position: positionDefault,
    //     email: emailDefault,
    // };

    const id = userId;
    const imgDefault = _.get(currentData, 'media.media.url', null);
    const [disabled, setDisabled] = useState(id ? true : false);

    useEffect(() => {
        if (mode === 'ver') setDisabled(true);
        else if (mode === 'editar') setDisabled(false);
    }, [])

    const [firstName, setFirstName] = useState(!_.isNil(currentData && currentData.person && currentData.person.firstName) ? currentData && currentData.person && currentData.person.firstName : '');
    const [firstLastName, setFirstLastName] = useState(!_.isNil(currentData && currentData.person && currentData.person.firstLastName) ? currentData && currentData.person && currentData.person.firstLastName : '');
    const [email, setEmail] = useState(!_.isNil(currentData.email) ? currentData && currentData.email : '');
    const [job, setJob] = useState(!_.isNil(currentData && currentData.operative && currentData.operative.job) ? currentData && currentData.operative && currentData.operative.job : '');
    const [horaInicio, setHoraInicio] = useState(!_.isNil(currentData && currentData.operative && currentData.operative.workHourStartAt) ? currentData && currentData.operative && currentData.operative.workHourStartAt : '');
    const [horaFin, setHoraFin] = useState(!_.isNil(currentData && currentData.operative && currentData.operative.workHourEndAt) ? currentData && currentData.operative && currentData.operative.workHourEndAt : '');
    const [semana1, setSemana1] = useState([
        {
            dia: 'L',
            id: 1,
            selected: currentData && currentData.operative && currentData.operative.workInMonday
        },
        {
            dia: 'M',
            id: 2,
            selected: currentData && currentData.operative && currentData.operative.workInTuesday
        },
        {
            dia: 'M',
            id: 3,
            selected: currentData && currentData.operative && currentData.operative.workInWednesday
        },
        {
            dia: 'J',
            id: 4,
            selected: currentData && currentData.operative && currentData.operative.workInThursday
        },
        {
            dia: 'V',
            id: 5,
            selected: currentData && currentData.operative && currentData.operative.workInFriday
        },
        {
            dia: 'S',
            id: 6,
            selected: currentData && currentData.operative && currentData.operative.workInSaturday
        },
        {
            dia: 'D',
            id: 7,
            selected: currentData && currentData.operative && currentData.operative.workInSunday
        },
    ])
    const handleAddHour = (e) => {
        setHoraInicio(e.target.value)
    }
    const handleAddHourFinal = (e) => {
        setHoraFin(e.target.value)
    }

    const handleAdd = (item) => {
        let semanaCopy = JSON.parse(JSON.stringify(semana1));
        semanaCopy.map((el) => {
            if (item.id === el.id) {
                if (el.selected === true) {
                    el.selected = false
                }
                else {
                    el.selected = true
                }
            }
        })
        setSemana1(semanaCopy);
    }

    const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                return resolve({
                    src: reader.result,
                    title: file.name
                });
            }
            reader.onerror = error => reject(error);
        });
    };

    const updateInfoFile = (event) => {
        const imageFile = event.target.files;
        const urlImages = [];
        const arrImgBase64 = [];
        Object.entries(imageFile).map(async ele => {
            urlImages.push(URL.createObjectURL(ele[1]));
            try {
                const base64 = await toBase64(ele[1])
                    .then(img => {
                        return img;
                    })
                if (base64) {
                    arrImgBase64.push(base64);
                }
            } catch (err) {
                console.log(err)
                return err
            }
        });
        setFileUrl(
            urlImages,
        )
        setImgBase64(
            arrImgBase64,
        )
        // setPicturesSelected(imageFile.name)
    };


    const profileImg = fileUrl[fileUrl.length - 1] ? fileUrl[fileUrl.length - 1] : (imgDefault ? `${api}/${imgDefault}` : imageSrc);
    const setImgProfile = {
        backgroundImage: `url(${profileImg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    }
    //END ADD IMG

    const updateData = () => {

        let newPermissions = [];

        dispatch(upsertOperativeAction({
            id: currentData.operative.id,
            user: {
                email: email,
            },
            
            operative: {
                media: !imgBase64 ? null : {
                    base64: {
                        src: imgBase64[0].src,
                        title: imgBase64[0].title.replace(/[{()}]/g, ''),
                    }
                },
                job: job,
                workHourStartAt: horaInicio,
                workHourEndAt: horaFin,
                workInMonday: semana1[0].selected,
                workInTuesday: semana1[1].selected,
                workInWednesday: semana1[2].selected,
                workInThursday: semana1[3].selected,
                workInFriday: semana1[4].selected,
                workInSaturday: semana1[5].selected,
                workInSunday: semana1[6].selected,
            },
            person: {
                firstName: firstName,
                firstLastName: firstLastName
            },
            // userPermission: {
            //   },
        }));
        if (upsertSuccess) history.push(OPERATIVES);
    }

    const cancelButton = () => {
        history.goBack();
    }


    return (
        <div className='main-container main-container-operative'>
            <form className='form-admin-container' /* onSubmit={handleSubmit(onSubmit)} */>
                <div className='form-admin-profile-left form-admin-profile-cards'>
                    <div className='picture-container'>
                        <div className="preContainer">
                            <div className='img-profile' style={setImgProfile}>
                            </div>
                        </div>
                    </div>
                    <div className='text-container'>
                        <h2>{firstName + ' ' + firstLastName}</h2>
                        <p>{job}</p>
                        <div className={disabled ? 'container-input disable' : 'container-input'}>
                            <input disabled={disabled} type='file' id='carga' accept='image/*' className='file' name='image' onChange={updateInfoFile} />
                            <label className="LeftInfoComponent-button" htmlFor={disabled ? '' : 'carga'}>
                                SUBIR FOTO
                            </label>
                        </div>
                    </div>
                    <div className='bottom-text'>
                        {
                            props.type !== 'create' &&
                            <p>ID : {currentData.operativeId}</p>
                        }
                        <p>Fecha de ingreso: {admissionDate}</p>
                        <p>Fecha de ingreso a TLP: {admissionDateTLP}</p>
                    </div>
                </div>
                <div className='form-admin-profile-right form-admin-profile-cards'>
                    <div className='header-form'>
                        <h2>Datos principales</h2>
                    </div>
                    <div className='body-form'>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Nombre(s)</label>
                            </div>
                            <div className='value-container'>
                                <input
                                    disabled={disabled}
                                    name={'name'}
                                    value={firstName}
                                    className="input-generic"
                                    type="text"
                                    placeholder="Nombre(s)"
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Apellido(s)</label>
                            </div>
                            <div className='value-container'>
                                <input
                                    disabled={disabled}
                                    name={'lastName'}
                                    value={firstLastName}
                                    className="input-generic"
                                    type="text"
                                    placeholder="Apellido(s)"
                                    onChange={(e) => setFirstLastName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Correo electrónico</label>
                            </div>
                            <div className='value-container'>
                                <input
                                    disabled={mode === "crear" ? false : true}
                                    name={'email'}
                                    className="input-generic"
                                    type="text"
                                    placeholder="Correo electrónico"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Puesto</label>
                            </div>
                            <div className='value-container'>
                                <input
                                    disabled={disabled}
                                    name={'position'}
                                    value={job}
                                    className="input-generic"
                                    type="text" placeholder="Puesto"
                                    onChange={(e) => setJob(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Horario</label>
                            </div>
                            <div className='value-container' style={{ justifyContent: 'space-between' }}>
                                {/* <input disabled={disabled} control={control} name={'name'} {...register('name')} className="input-generic" type="text" placeholder=""/> */}
                                <input type='time' disabled={disabled} value={horaInicio} onChange={(e) => handleAddHour(e)} className='input-time' />
                                <input type='time' disabled={disabled} value={horaFin} onChange={(e) => handleAddHourFinal(e)} className='input-time' />
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                            </div>
                            <div className='value-container'>
                                <div className='container-days'>
                                    {
                                        semana1 &&
                                        semana1.map((item, i) => (
                                            <button disabled={disabled} key={i} type='button' onClick={() => handleAdd(item)} className={`btnDay ${item.selected === true ? 'active' : ''}`} name={item.id}>{item.dia}</button>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        <div className='footer-form operative-buttons-container'>
                            <Button type='button' onClick={cancelButton} className={'button-back'} style={{ marginTop: '0' }}>CANCELAR</Button>
                            <Button type='button' onClick={() => { updateData(), history.push(OPERATIVES) }} style={{ marginTop: '0' }}>{mode == 'ver' ? 'ACEPTAR' : 'GUARDAR'}</Button>
                        </div>
                    }
                </div>
            </form>
        </div>
    );
}
