// import _ from 'lodash';
import {
    CLIENT_MODULE_GET_ALL_CLIENTS_BEGIN,
    CLIENT_MODULE_GET_ALL_CLIENTS_SUCCESS,
    CLIENT_MODULE_GET_ALL_CLIENTS_FAILURE,
    CLIENT_MODULE_UPSERT_CLIENT_BEGIN,
    CLIENT_MODULE_UPSERT_CLIENT_SUCCESS,
    CLIENT_MODULE_UPSERT_CLIENT_FAILURE,
    GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_BEGIN,
    GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_SUCCESS,
    GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_FAILURE,
    GET_ONE_CLIENT_BEGIN,
    GET_ONE_CLIENT_FAILURE,
    GET_ONE_CLIENT_SUCCESS,
 } from '../actionTypes';

import initialState from '../initialState';

export default function clientReducer(state=initialState.client, action){
    switch (action.type) {
        case CLIENT_MODULE_GET_ALL_CLIENTS_BEGIN:
            return {
                ...state,
                clients: {
                    ...state.clients,
                    loadingGetAllClients: true,
                    errorGetAllClients: null,
                    totalRecords: 0,
                    data: [],
                }
            };
        case CLIENT_MODULE_GET_ALL_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: {
                    data: action.data.data,
                    totalRecords: action.data.totalRecords,
                }
            };
        case CLIENT_MODULE_GET_ALL_CLIENTS_FAILURE:
            return {
                ...state,
                clients: {
                    ...state.clients,
                    loadingGetAllClients: false,
                    errorGetAllClients: action.err,
                    totalRecords: 0,
                    data: [],
                }
            };
        case CLIENT_MODULE_UPSERT_CLIENT_BEGIN:
            return {
                ...state,
                upsertClient: {
                    ...state.upsertClient,
                    loading: true,
                    isSuccess: false,
                    err: null,
                    data: {},
                }
            };
        case CLIENT_MODULE_UPSERT_CLIENT_SUCCESS:
            return {
                ...state,
                upsertClient: {
                    ...state.upsertClient,
                    loading: false,
                    isSuccess: true,
                    err: null,
                    data: action.client,
                }
            };
        case CLIENT_MODULE_UPSERT_CLIENT_FAILURE:
            return {
                ...state,
                upsertClient: {
                    ...state.upsertClient,
                    loading: false,
                    isSuccess: false,
                    err: action.err,
                    data: {},
                }
            };
        case GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_BEGIN: 
            return {
                ...state,
                clientsAvailableByCustomerAgent: {
                    // ...state.clientsAvailableByCustomerAgent,
                    loadingGetAllCustomerAgents: true,
                    errorGetAllCustomerAgents: null,
                    totalRecords: 0,
                    data: []

                }
            };
        case GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_SUCCESS: 
            return {
                ...state,
                clientsAvailableByCustomerAgent: {
                    // ...state.clientsAvailableByCustomerAgent,
                    loadingGetAllCustomerAgents: false,
                    errorGetAllCustomerAgents: null,
                    totalRecords: 0,
                    data: action.data.data,

                }
            };
        case GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_FAILURE: 
            return {
                ...state,
                clientsAvailableByCustomerAgent: {
                    // ...state.clientsAvailableByCustomerAgent,
                    loadingGetAllCustomerAgents: false,
                    errorGetAllCustomerAgents: action.err,
                    totalRecords: 0,
                    data: []
                }
            };

            case GET_ONE_CLIENT_BEGIN: 
            return {
                ...state,
                getOneClients: {
                    // ...state.clientsAvailableByCustomerAgent,
                    loadingGetAllCustomerAgents: true,
                    errorGetAllCustomerAgents: null,
                    totalRecords: 0,
                    data: []

                }
            };
        case GET_ONE_CLIENT_SUCCESS: 
            return {
                ...state,
                getOneClients: {
                    // ...state.getOneClients,
                    loadingGetAllCustomerAgents: false,
                    errorGetAllCustomerAgents: null,
                    totalRecords: 0,
                    data: action.data.data,

                }
            };
        case GET_ONE_CLIENT_FAILURE: 
            return {
                ...state,
                getOneClients: {
                    // ...state.clientsAvailableByCustomerAgent,
                    loadingGetAllCustomerAgents: false,
                    errorGetAllCustomerAgents: action.err,
                    totalRecords: 0,
                    data: []
                }
            };
        default:
            return state;
    }
}
