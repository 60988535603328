import request from "../../utils/request";
import { addNotification } from './notificationAction';
import {
    QUOTATION_MODULE_GET_ALL_QUOTATIONS_BEGIN,
    QUOTATION_MODULE_GET_ALL_QUOTATIONS_SUCCESS,
    QUOTATION_MODULE_GET_ALL_QUOTATIONS_FAILURE,
    QUOTATION_MODULE_UPSERT_QUOTATION_BEGIN,
    QUOTATION_MODULE_UPSERT_QUOTATION_SUCCESS,
    QUOTATION_MODULE_UPSERT_QUOTATION_FAILURE,
    QUOTATION_MODULE_DELETE_QUOTATION_BEGIN,
    QUOTATION_MODULE_DELETE_QUOTATION_SUCCESS,
    QUOTATION_MODULE_DELETE_QUOTATION_FAILURE,
    QUOTATION_GET_ALL_QUOTATIONS_DELETED_BEGIN,
    QUOTATION_GET_ALL_QUOTATIONS_DELETED_SUCCESS,
    QUOTATION_GET_ALL_QUOTATIONS_DELETED_FAILURE,
    QUOTATIONS_RECOVE_BEGIN,
    QUOTATIONS_RECOVE_SUCCESS,
    QUOTATIONS_RECOVE_FAILURE,

} from './../actionTypes';

// ACTIONS TO REDUCER

export const getAllQuotationsBegin = () => ({
    type: QUOTATION_MODULE_GET_ALL_QUOTATIONS_BEGIN,
});

export const getAllQuotationsSuccess = (data) => ({
    type: QUOTATION_MODULE_GET_ALL_QUOTATIONS_SUCCESS,
    data,
});

export const getAllQuotationsFailure = (err) => ({
    type: QUOTATION_MODULE_GET_ALL_QUOTATIONS_FAILURE,
    err
});

export const upsertQuotationBegin = () => ({
    type: QUOTATION_MODULE_UPSERT_QUOTATION_BEGIN,
});

export const upsertQuotationSuccess = ({totalRecords, data}) => ({
    type: QUOTATION_MODULE_UPSERT_QUOTATION_SUCCESS,
    totalRecords,
    data,
});

export const upsertQuotationFailure = (err) => ({
    type: QUOTATION_MODULE_UPSERT_QUOTATION_FAILURE,
    err
});

export const deleteQuotationBegin = () => ({
    type: QUOTATION_MODULE_DELETE_QUOTATION_BEGIN,
});

export const deleteQuotationSuccess = () => ({
    type: QUOTATION_MODULE_DELETE_QUOTATION_SUCCESS,
});

export const deleteQuotationFailure = (err) => ({
    type: QUOTATION_MODULE_DELETE_QUOTATION_FAILURE,
    err,
});

//get all deleted
export const getAllDeletedQuotationsBegin = () => ({
    type: QUOTATION_GET_ALL_QUOTATIONS_DELETED_BEGIN,
});

export const getAllDeletedQuotationsSuccess = (data) => ({
    type: QUOTATION_GET_ALL_QUOTATIONS_DELETED_SUCCESS,
    data,
});

export const getAllDeletedQuotationsFailure = (err) => ({
    type: QUOTATION_GET_ALL_QUOTATIONS_DELETED_FAILURE,
    err
});

//Recover quotation 
export const recoveQuotationsBegin = () => ({
    type: QUOTATIONS_RECOVE_BEGIN,
});

export const recoveQuotationSuccess = (data) => ({
    type: QUOTATIONS_RECOVE_SUCCESS,
    data,
});

export const recoveQuotationsFailure = (err) => ({
    type: QUOTATIONS_RECOVE_FAILURE,
    err
});


// DISPATCH TO ACTIONSv

export function getAllQuotationsAction (data) {
    return (dispatch, getState) => {
        dispatch(getAllQuotationsBegin());
        return request({
            partialUrl: `/api/v1/quotation/get-all-quotes-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(getAllQuotationsSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllQuotationsFailure(err));
        });
    };
}

export function upsertQuotationAction (data) {
    return (dispatch, getState) => {
        dispatch(upsertQuotationBegin());
        return request({
            partialUrl: `/api/v1/quotation/upsert-quotation-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,

            headers: {
                //'Content-Type': 'multipart/form-data; boundary=—-WebKitFormBoundaryfgtsKTYLsT7PNUVD',
                //Pragma: 'no-cache',
                // 'Cache-control': 'no-store',
                // 'Access-Control-Allow-Origin': '*',
                // 'access-control-allow-origin': '*',
            },
            isFileUpload: true,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(upsertQuotationSuccess(data.data));
            return true;
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(upsertQuotationFailure(err));
        });
    };
}

export function deleteQuotationAction(data){
    return (dispatch, getState) => {
        dispatch(deleteQuotationBegin());
        return request({
            partialUrl: `/api/v1/quotation/delete-quotation-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(deleteQuotationSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(deleteQuotationFailure(err));
        });
    };
}

//get all deleted 

export function getAllDeleteQuotationsAction (data) {
    return (dispatch, getState) => {
        dispatch(getAllDeletedQuotationsBegin());
        return request({
            partialUrl: `/api/v1/quotation/get-all-deleted-quotes-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(getAllDeletedQuotationsSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllDeletedQuotationsFailure(err));
        });
    };
}


//Recove Quotations 
export function recoveQuotationAction(data){
    return (dispatch, getState) => {
        dispatch(recoveQuotationsBegin());
        return request({
            partialUrl: `/api/v1/quotation/recover-deleted-quote-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(recoveQuotationSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(recoveQuotationsFailure(err));
        });
    };
}