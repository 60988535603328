/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/atoms';
// import './CustomAgent.scss';
// import './ClientRegister.scss';
import '../../../styles/stylesSteppers.scss'
import arrow from '../../../assets/images/navieras/arrowButton.png'
import docIcon from '../../../assets/images/navieras/docIcon.png'
import { PORTS } from '../../../routes/Paths';
import plus from '../../../assets/images/navieras/plus-icon.png';
import add from '../../../assets/images/navieras/agregar_red.png'
import { useForm } from 'react-hook-form';
import _ from 'lodash'
import { UploadFile } from '../../../components/organisms/UploadFile/UploadFile';
import { PortsForm } from '../../../components/organisms/PortsForm/PortsForm';
import { useSelector } from 'react-redux';


export const CustomAgentPorts = ({ stepper, setStepper, toggleModal, formData, setFormData, edit, setSelectedModal }) => {

    const portsDafault = _.get(formData, 'associationPorts', null)
    const { mode } = useParams()
    const [isDisabled, setIsDisabled] = useState(mode === 'ver' ? true : false);
    const [watchDocumentMedia, setWatchDocumentMedia] = useState(false);
    const [portsBorrados, setPortsBorrados] = useState([]);
    const [last, setLast] = useState(null);
    const [ports, setPorts] = useState(
        [
            {
                id: 0,
                port: null,
                executiveName: null,
            },
        ]
    )
    const typeAction = useSelector(state => state.router.location.hash)
    const methods = useForm({ defaultValues: defaultValues });
    const { register, handleSubmit, watch } = useForm();

    const defaultValues = {
        //  name : '',
    };

    const data = [
        { name: 'RFC' }, { name: 'Situacion  Fiscal' }, { name: 'INE, pasaporte, cédula profesional' }
    ]


    const changeStage = () => {
        setStepper(stepper + 1)
    }

    const changeStageBack = () => {
        setStepper(stepper - 1)
    }

    // ESTO ES PARA RECUPERAR LOS PUERTOS
    useEffect(() => {
        let ports = _.get(formData, 'associationPorts', null)
        if (ports) {
            let portsSet = [];

            ports.forEach(i => {
                let port = {
                    id: i.portId,
                    port: i.ports.port
                }
                portsSet.push(port)
            });

            setPorts(portsSet);

        }
    }, [formData])


    useEffect(() => {
        let final = [...ports];
        setLast(final.pop())
    }, [ports])


    const handleAdd = () => {
        let arrayCopy = JSON.parse(JSON.stringify(ports));
        let lastElement = ports.pop();
        arrayCopy.push(
            {
                id: null,
                newAdd: true,
            }
        )
        setPorts(arrayCopy);
    }
    const onSubmit = async () => {

        let port;
        let portsSet = [];
        let deletePorts = [];


        if (edit) {

            ports.forEach((i) => {


                if (i.hasOwnProperty("newAdd")) {
                    port = {
                        id: i.id,
                        port: i.port,
                        executiveName: i.executiveName,
                        job: i.job,
                        phone: i.phone,
                        email: i.email,
                    }
                    portsSet.push(port)
                }
                else {
                    port = {
                        id: i.id,
                        port: i.port,
                        executiveName: i.executiveName,
                        job: i.job,
                        phone: i.phone,
                        email: i.email,
                    }
                    portsSet.push(port)
                }

            })
            if (formData && formData.associationPorts.length > 0) {
                formData && formData.associationPorts.forEach((i) => {

                    portsBorrados.forEach((e) => {
                        if(e.id == i.ports.id){
                            deletePorts.push(JSON.stringify(i.id))
                            port = {
                                id: i.id,
                                port: i.ports.port,
                                // executiveName: i.executiveName,
                            }
                            portsSet.push(port)
                        }
                    })
                })

            } else {

                portsBorrados.forEach((i) => {
                    deletePorts.push(JSON.stringify(i.id))
                    port = {
                        id: i.id,
                        port: i.port,
                        executiveName: i.executiveName,
                    }
                    portsSet.push(port)
                })
            }
        } else {
            ports.forEach((i) => {
                // port = {
                //     id: 0,
                //     port: i.port,
                //     executiveName: i.executiveName,
                //     job: i.job,
                //     phone: i.phone,
                //     email: i.email,
                // }
                portsSet.push(i.id)
            })
        }

        let newPortSet = [];
        portsSet.map((i) => {
            if (i.port && i.id) {
                newPortSet.push(i)
            }
        })

        const data = {
            ...formData,
            ports: portsSet[0] == 0 ? [] : portsSet,
            deletedAssociationPorts: deletePorts
        }
        setFormData(data);
        changeStage()
    }


    return (
        <form className='main-container-generic-type' onSubmit={handleSubmit(onSubmit)}>
            <div className='header-form-generic-type'>
                <h2>Datos de puerto</h2>
            </div>
            <div className='body-form-generic'>
                {/* <div className='create-port-container'>
                    <Button
                        className={'createPortButton'}
                        srcicon={plus}
                        onClick={() => history(PORTS)}
                    >
                        CREAR
                    </Button>
                </div> */}
                <div className='port-data-container'>
                    {
                        ports.map((ele, index) => {
                            return (
                                <PortsForm index={index} disabled={false} ports={ele} last={last} setPorts={setPorts} arrayComplete={ports} portsBorrados={portsBorrados} setPortsBorrados={setPortsBorrados} />
                            )
                        })
                    }
                </div>
                {
                    !isDisabled &&
                    <div className='add-button-container' style={{ justifyContent: 'flex-end' }}>
                        <img className='add-card' src={add} onClick={handleAdd} />
                    </div>
                }
            </div>
            <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                <Button type='button' onClick={() => mode == 'ver' || mode == 'editar' ? changeStageBack() : (toggleModal(), setSelectedModal('cancel'))} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                <Button type='submit' className={'button-next'} srcicon={arrow} style={{ width: '40%' }}>SIGUIENTE</Button>
                {/* <Button type='button' onClick={changeStage} className={'button-next'} srcicon={arrow} style={{width: '40%'}}>SIGUIENTE</Button> */}
            </div>
        </form>
    )
}
