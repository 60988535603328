import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import notificationReducer from './notificationReducer';
import userInterfaceReducer from './userInterfaceReducer';
import userReducer from './usersReducer';
import companyReducer from './companyReducer';
import adminReducer from './adminReducer';
import customAgentReducer from './customAgentReducer';
import clientReducer from './clientReducer';
import costumerCustomAgentReducer from './CostumerCustomAgentReducer';
import providerReducer from './providerReducer';
import quotationReducer from './quotationReducer';
import operativeReducer from './operativeReducer';
import addressReducer from './addressReducer';
import portReducer from './portReducer';
import recordReducer from './recordReducer';
import permissionsReducer from './permissionReducer';



const rootReducer = history => combineReducers({
  router: connectRouter(history),
  notification: notificationReducer,
  userInterface: userInterfaceReducer,
  user: userReducer,
  company: companyReducer,
  admin: adminReducer,
  customAgent:customAgentReducer,
  client: clientReducer,
  costumerCustomAgent: costumerCustomAgentReducer,
  provider: providerReducer,
  quotation: quotationReducer,
  operative: operativeReducer,
  address: addressReducer,
  port: portReducer,
  record:recordReducer,
  permission: permissionsReducer,

});

export default rootReducer;
