import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { Box, MenuItem, createSvgIcon } from '@material-ui/core';
import { Button as ButtonD } from '@material-ui/core';
import plus from '../../../assets/images/navieras/plus-icon.png'
import '../../../styles/tableViewStyles.scss';
import {
    Button,
    DataTable,
    ActionMenu,
    GlobalFilter,
    Link,
} from '../../../components/atoms';
import Modal from '../../../components/organisms/Modal/Modal';
import { CardContainer } from '../../../components/molecules/CardContainer/CardContainer';
import { DeleteBody } from '../../../components/molecules/CancelFormBody/DeleteBody';
import { AssignClient } from '../../../components/molecules/AssingForms/AssignClient';
import { addNotification } from '../../../redux/actions/notificationAction';
import { getAllCustomAgentsAction, deleteCustomAgentAction } from '../../../redux/actions/customsAgentAction';
import { getAllClientsAction, getAllAvailableClientsAction } from '../../../redux/actions/clientAction';
import { upsertCostumerCustomAgentAction } from '../../../redux/actions/CostumerCustomAgent';
import { DataGrid, GridToolbarContainer, gridSortedRowIdsSelector, useGridApiContext } from '@mui/x-data-grid';




const CustomAgent = (props) => {
    const history = useHistory();
    const [searchObj, setSearchObj] = useState({});
    const [searchCondition, setSearchCondition] = useState({});
    const [openModal, setOpenModal] = useState(false)
    const [itemSelected, setItemSelected] = useState(true)
    const [itemDeleted, setItemDeleted] = useState(true)
    const getCustom = useSelector(state => state.customAgent.customAgents.data)
    const [custom, setCustom] = useState([]);
    const dispatch = useDispatch();
    const [clientAssigned, setClientAssigned] = useState(null);




    const toggleModal = (num, id) => {
        if (openModal === true) {
            setItemSelected(id)
            setOpenModal(false);
        } else {
            setItemSelected([num, id])
            setOpenModal(true);
        }
    }


    useEffect(() => {
        props.getAllCustomAgentsAction(searchCondition);
        // props.getAllAdministratorsAction(searchCondition);
    }, [itemDeleted, searchCondition]);



    useEffect(() => {
        if (!_.isEmpty(searchObj)) {
            props.getAllCustomAgentsAction(
                {
                    where: searchObj
                }
            );
        }
    }, [searchObj]);


    useEffect(() => {
        setCustom([])
        getCustom && getCustom.length > 0 && getCustom.map((el) => {
            let sectionCopy = JSON.parse(JSON.stringify(el));
            sectionCopy.id = sectionCopy.id;
            // sectionCopy.expedient.mov = sectionCopy.expedient.mov;
            setCustom((d) => [...d, sectionCopy]);
        })
    }, [getCustom])



    const onClickActionMenu = (actionSelected, payload) => {
        switch (actionSelected) {
            case 'showRecord':
                history.push(
                    {
                        pathname: `/dashboard/agentes-aduanales/${payload.id}/ver`,
                        state: payload
                    });
                break;
            case 'editRecord':
                history.push(
                    {
                        pathname: `/dashboard/agentes-aduanales/${payload.id}/editar`,
                        state: payload
                    });
                break;
            default:
                break;
        }
    }
    const deleteUser = async () => {
        const res = await props.deleteCustomAgentAction({ "id": itemSelected[1] })
        if (res) {
            setOpenModal(false)
            setItemDeleted(itemSelected)
            setItemSelected(null)
        }
    }



    const preAssingClient = async (id) => {
        const res = await props.getAllAvailableClientsAction({ customAgendId: id });
        if (res) {
            toggleModal(1, id);
        } else {
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de clientes, favor de intentarlo de nuevo',
            }));
        }
    }



    const getNewData = (searchCondition) => {
        // TODO: Restrucutrar vs Filtros*
        setSearchCondition(searchCondition);
    };


    const assignClient = async () => {
        if (clientAssigned) {
            const data = {
                idAgend: itemSelected[1],
                idClient: clientAssigned,
            }
            let res = await props.upsertCostumerCustomAgentAction(data);
            if (res) {
                setOpenModal(false);
            }
        } else {
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Debe seleccionar algún cliente',
            }));
        }
    }


    // const localizedText = {
    //     // Texto de las opciones de filtrado
    //     filterPanelHeaderText: 'Desclasificar',
    //     filterPanelHeaderText: 'Ordenar por ASC',
    //     filterPanelHeaderText: 'Ordenar por DESC',
    //     filterPanelHeaderText: 'Filtrar',
    //     filterPanelHeaderText: 'Esconder',
    //     filterPanelHeaderText: 'Mostrar Columnas',


    //     // Otros textos personalizados
    //     // ...
    //   };


    const columnsGrid = [
        {
            field: 'id',
            headerName: 'ID',
            width: 60,
            headerAlign: 'center',
            type: 'number',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.id || 'null'}`
        },
        {
            field: 'agentname',
            headerName: 'Agente Aduanal',
            headerAlign: 'center',
            width: 310,
            type: 'text',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.agentname || 'Sin informacion'}`
        },
        {
            field: 'tradename',
            headerName: 'Agencia aduanal',
            width: 200,
            headerAlign: 'center',
            aling: 'center',
            type: 'text',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.tradename || 'Sin informacion'}`
        },
        {
            field: 'associationPorts',
            headerName: 'Puertos',
            width: 500,
            headerAlign: 'center',
            aling: 'center',
            type: 'text',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.associationPorts.map((i, index) => {
                    return `${(index + 1) + '.-'}${i.ports.port} `
                }) || 'Sin informacion'}`
        },
        {
            field: 'patent',
            headerName: 'No.patente',
            type: 'number',
            width: 120,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            aling: 'center',
            valueGetter: (params) =>
                `${params.row.patent || 'Sin informacion'}`
        },
        {
            field: 'action',
            headerName: 'Acciones',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            width: 120,
            headerAlign: 'center',
            aling: 'center',
            renderCell: (params) => {
                return (
                    <ActionMenu id={params.row.id} >
                        <MenuItem onClick={() => onClickActionMenu('showRecord', params.row)}>Ver registro</MenuItem>
                        <MenuItem onClick={() => onClickActionMenu('editRecord', params.row)}>Editar registro</MenuItem>
                        <MenuItem onClick={() => toggleModal(2, params.row.id)}>Eliminar</MenuItem>
                        <MenuItem onClick={() => preAssingClient(params.row.id)}>Asignar cliente</MenuItem>
                    </ActionMenu>
                );
            },
        }
    ];


    const ExportIcon = createSvgIcon(
        <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
        "SaveAlt"
    );

    const getUnfilteredRows = ({ apiRef }) => gridSortedRowIdsSelector(apiRef);

    const descargarExpedient = () => {
        const apiRef = useGridApiContext();
        const handleExport = (options) => apiRef.current.exportDataAsCsv(options);
        const buttonBaseProps = {
            color: "primary",
            size: "small",
            startIcon: <ExportIcon />,
        };

        return (
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    // marginBottom: "1rem",
                    background: "#000;",


                }}
            >



                <GridToolbarContainer
                    csvOptions={{
                        utf8WithBom: true,
                        fileName: "Custom Agent",
                    }}
                >

                    <ButtonD className='button-exp2'
                        {...buttonBaseProps}
                        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}>
                        {/* <img icon={} className="img" style={{ marginRight: "1rem" }} /> */}
                        Descargar Excel
                    </ButtonD>
                </GridToolbarContainer>
            </div>
        );
    };


    const localizedTextsMap = {

        //Fileters operator text
        columnMenuUnsort: "Desclasificar",
        columnMenuSortAsc: "Ordenar por ASC",
        columnMenuSortDesc: "Ordenar por DESC",
        columnMenuFilter: "Filtrar",
        columnMenuHideColumn: "Esconder",
        columnMenuShowColumns: "Mostrar colunas",
        noRowsLabel: "No existen registros",
        noResultsOverlayLabel: 'No se han encontrado resultados',
        errorOverlayDefaultLabel: 'Ocurrió un error.',

        //Filter operator
        filterPanelColumns: 'Columnas',
        filterPanelOperators: 'Operador',
        filterPanelInputLabel: 'Valor',
        filterPanelInputPlaceholder: 'Valor de filtro',

        // Filter operators text
        filterOperatorContains: 'Contiene',
        filterOperatorEquals: 'Igual',
        filterOperatorStartsWith: 'Comienza con',
        filterOperatorEndsWith: 'Termina con',
        filterOperatorIs: 'Es',
        filterOperatorNot: 'No es',
        filterOperatorAfter: 'Es despues de',
        filterOperatorOnOrAfter: 'Está encendido o después',
        filterOperatorBefore: 'Es antes',
        filterOperatorOnOrBefore: 'Está en o antes',
        filterOperatorIsEmpty: 'Está vacío',
        filterOperatorIsNotEmpty: 'No está vacío',
        filterOperatorIsAnyOf: 'Es cualquiera de',


        // Columns panel text
        columnsPanelTextFieldLabel: 'Buscar columna',
        columnsPanelTextFieldPlaceholder: 'Título de la columna',
        columnsPanelDragIconLabel: 'Reordenar columna',
        columnsPanelShowAllButton: 'Mostrar todo',
        columnsPanelHideAllButton: 'Ocultar todo',

    };

    const handleScroll = (GridScrollParams) => {
        const { left, top } = GridScrollParams;
        const gridContainer = document.querySelector('MuiDataGrid-virtualScrollerRenderZone css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone');
        if (gridContainer) {
            gridContainer.scrollLeft = left;
        }
    };


    return (
        <>
            {
                openModal && itemSelected[0] === 1 &&
                <Modal
                    isOpen={toggleModal}
                    toggle={toggleModal}
                >
                    <CardContainer btnLeftAction={toggleModal} btnRightText={'ASIGNAR'} btnRightAction={() => assignClient()} title={'Clientes'} body={<AssignClient clients={!_.isNil(props.clients) && _.get(props, 'clients.data.rows', [])} setClientAssigned={setClientAssigned} clientAssigned={clientAssigned} />} />
                </Modal>
            }
            {
                openModal && itemSelected[0] === 2 &&
                <Modal
                    isOpen={toggleModal}
                    toggle={toggleModal}
                >
                    <CardContainer btnLeftAction={toggleModal} btnRightAction={() => deleteUser()} body={<DeleteBody user={'agente aduanal'} />} />
                </Modal>
            }
            <section className='ClientRecordAccountSection' >
                <div className='ClientRecordAccountSection__container'>
                    <div className='ClientRecordAccountSection__headerContainer'>
                        <div className='HomeTableSection__containerFilters'>
                            <GlobalFilter
                                filter={searchObj}
                                setFilter={setSearchObj}
                                showTextSearch
                                showDateRangeSearch={false}
                                placeholderSearch={'Buscar por ID o Nombre...'}
                            />
                        </div>
                        <div className='HomeTableSection__containerButtons'>
                            <Link
                                className={'linkClient'}
                                to={`/dashboard/agentes-aduanales/crear`}>
                                <Button className={'containerButtons__clientButton'} srcicon={plus}>CREAR</Button>
                            </Link>
                        </div>
                    </div>


                    <div className='ClientRecordAccountSection__tableContainer'>
                        <Box sx={{
                            height: 390,
                            width: '100%',
                            '& .super-app-theme--header': {
                                backgroundColor: '#707070',
                                color: '#ffff',
                                fontFamily: 'Montserrat',
                                fontSize: '.9vw',
                            },
                        }}>

                                <DataGrid
                                    sx={{
                                        '@media print': {
                                            '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)' },
                                        }
                                    }
                                    }
                                    localeText={localizedTextsMap}
                                    rows={custom}
                                    columns={columnsGrid}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    components={{ Toolbar: descargarExpedient }}
                                    checkboxSelection={false}
                                    disableSelectionOnClick
                                    experimentalFeatures={{ newEditingApi: true }}
                                />
                        </Box>
                        {/* <DataTable
                        columns={columns}
                        // data={{...props.companies}}
                        data={{
                            data: _.get(props, 'customsAgents.data', {}),
                            totalRecords: _.get(props, 'customsAgents.totalRecords', 0),
                        }}
                        getData={getNewData}
                        searchObj={searchObj}
                    /> */}
                    </div>
                </div >
            </section >
        </>
    );
}

CustomAgent.propTypes = {
    history: PropTypes.any,
    dispatch: PropTypes.any,
    getAllAdministratorsAction: PropTypes.any,
    admins: PropTypes.any,
    deleteAdministratorAction: PropTypes.any,
    isSuccessDeleteAdmin: PropTypes.any,
    getAllAvailableClientsAction: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        clients: _.get(state, 'client.clientsAvailableByCustomerAgent', []),
        customsAgents: _.get(state, 'customAgent.customAgents', {}),
        // admins: _.get(state, 'admin.admins', {}),
        // isSuccessDeleteAdmin: _.get(state, 'admin.deleteAdmin.isSuccess', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    upsertCostumerCustomAgentAction: params => dispatch(upsertCostumerCustomAgentAction(params)),
    getAllClientsAction: params => dispatch(getAllClientsAction(params)),
    deleteCustomAgentAction: params => dispatch(deleteCustomAgentAction(params)),
    getAllCustomAgentsAction: params => dispatch(getAllCustomAgentsAction(params)),
    getAllAvailableClientsAction: params => dispatch(getAllAvailableClientsAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomAgent);
