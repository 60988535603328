/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../../components/organisms/PortsForm/PortsForm.scss'
import deleteIcon from '../../../assets/images/navieras/deleteTrash2.png';
import { getAllPortsAction } from '../../../redux/actions/portAction';
import _ from 'lodash';
import { getAllStatesAction } from '../../../redux/actions/addressAction';

export const DesviationsForm = ({ posicion, setCountries, ports, arrayComplete, setFormsBorrados, formsBorrados, countrySelected, setCountrySelected, stateSelected, setStateSelected, deviation, setDeviation, stateSelectedId, setStateSelectedId }) => {

    const dispatch = useDispatch();
    const countries = useSelector(state => state.address.countries.data);
    const states = useSelector(state => state.address.states.data);
    const [selectedData, setSelectedData] = useState(null)

    const [countryId, setCountryId] = useState()
    const [stateId, setStateId] = useState()

    const handleDelete = () => {
        let arrayCopy = JSON.parse(JSON.stringify(arrayComplete));
        let borrado = [];
        let arreglo = [];
        arrayCopy.forEach((i) => (i.id !== ports.id ? arreglo.push(i) : borrado.push({
            id: i.id,
            deletedAt: new Date(),
        })));
        setCountries(arreglo);
        if (formsBorrados.length > 0) {
            setFormsBorrados([...formsBorrados, ...borrado]);
        } else {
            setFormsBorrados([...borrado]);
        }
    }

    useEffect(() => {
        if (selectedData) {
            let aux = JSON.parse(JSON.stringify(arrayComplete))
            aux[posicion] = selectedData
            setCountries([...aux])
        }
    }, [selectedData])

    useEffect(() => {
        if (arrayComplete) {
        }
    }, [arrayComplete])

    return (
        <>
            <div className='record-form-container'>
                {
                    arrayComplete.length !== 1 &&
                    <div className='delete-container-button'>
                        <img src={deleteIcon} onClick={handleDelete} alt="" />
                    </div>
                }
                <div className='inputs-container'>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>País</label>
                        </div>
                        <div className='value-container-select' >
                            <select required className='input-select' value={arrayComplete[posicion].countryId} onChange={(e) => {
                                const valor = JSON.parse(e.target.value)
                                setCountrySelected(valor.id)
                                setSelectedData({ ...selectedData, countryId: valor.id, country: valor.name })
                                setCountryId(parseInt(valor.id))
                                dispatch(getAllStatesAction(
                                    {
                                        where: {
                                            countryId: parseInt(valor.id)
                                        }
                                    }
                                ));

                            }}>
                                <option className='option-selected' value={null} selected hidden>{arrayComplete && arrayComplete[posicion] && arrayComplete[posicion].countryId != null ? arrayComplete[posicion].country : "Seleccionar"}</option>
                                {
                                    _.get(countries, 'data') &&
                                    countries.data.map(ele => {
                                        return <option value={JSON.stringify(ele)}> {ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Estado</label>
                        </div>
                        <div className='value-container-select' >
                            <select required className='input-select' value={arrayComplete[posicion].stateId} onChange={(e) => {
                                const valor = JSON.parse(e.target.value)
                                setSelectedData({ ...selectedData, stateId: valor.id, state: valor.name })
                                setStateId(valor.id)
                                setStateSelected(valor.name);
                                setStateSelectedId(valor.id)
                            }}>
                                <option className='option-selected' value={null} selected hidden>{arrayComplete && arrayComplete[posicion]  && arrayComplete[posicion].stateId && arrayComplete[posicion].stateId != null ?  arrayComplete[posicion].state   : "Seleccionar"}</option>
                                {
                                    _.get(states, 'data') &&
                                    states.data.map(ele => {
                                        return <option value={JSON.stringify(ele)}>{ele.name} </option>
                                    })
                                }
                            </select>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

