/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button } from './../../../components/atoms';
import '../../../styles/stylesSteppers.scss';
import arrow from './../../../assets/images/navieras/arrowButton.png'
import { useHistory, useLocation } from 'react-router-dom';
import './RecordsData.scss';
import { useForm } from 'react-hook-form';
import { upsertExpedient } from '../../../redux/actions/recordAction';
import { useDispatch } from 'react-redux';
import { RECORDS } from '../../../routes/Paths';
import { BaseModalBody } from '../../../components/molecules/ModalBody/BaseModalBody';
import { cancelUploadOfDocs, noDocumentsUploded } from '../../../components/molecules/ModalBody/modalBodyVaribles';
import { CardContainer } from '../../../components/molecules/CardContainer/CardContainer';
import Modal from '../../../components/organisms/Modal/Modal';


const ArrivalDates = ({ recordId = '002', setStepper, stepper, formData, setFormData }) => {

    const location = useLocation();
    const dispatch = useDispatch()

    const arrivalDateDefault = _.get(location, 'payload.row.arrivalDates.arrivalDate', '')
    const dateFourteenDaysAfterArrivalDefault = _.get(location, 'payload.row.arrivalDates.dateFourteenDaysAfterArrival', '')
    const dateSevenDaysAfterArrivalDefault = _.get(location, 'payload.row.arrivalDates.dateSevenDaysAfterArrival', '')
    const dateThreeDaysAfterArrivalDefault = _.get(location, 'payload.row.arrivalDates.dateThreeDaysAfterArrival', '')
    const daysOfDelayDefault = _.get(location, 'payload.row.arrivalDates.daysOfDelay', '')
    const lastDayOffDelayDefault = _.get(location, 'payload.row.arrivalDates.lastDayOffDelay', '')

    const defaultValues = {
        arrivalDate: arrivalDate ? arrivalDate : arrivalDateDefault,
        dateFourteenDaysAfterArrival: fourteenDaysBefore ? fourteenDaysBefore : dateFourteenDaysAfterArrivalDefault,
        dateSevenDaysAfterArrival: sevenDaysBefore ? sevenDaysBefore : dateSevenDaysAfterArrivalDefault,
        dateThreeDaysAfterArrival: threeDaysBefore ? threeDaysBefore : dateThreeDaysAfterArrivalDefault,
        daysOfDelay: delayDays ? delayDays : daysOfDelayDefault,
        lastDayOffDelay: freeDelay ? freeDelay : lastDayOffDelayDefault,

    };

    const [status, setStatus] = useState(null);
    const history = useHistory();
    const [freeDelay, setFreeDelay] = useState(defaultValues.lastDayOffDelay);
    const [delayDays, setDelayDays] = useState(defaultValues.daysOfDelay);
    const [arrivalDate, setArrivalDate] = useState(defaultValues.arrivalDate);
    const [fourteenDaysBefore, setFourteenDaysBefore] = useState(defaultValues.dateFourteenDaysAfterArrival);
    const [sevenDaysBefore, setSevenDaysBefore] = useState(defaultValues.dateSevenDaysAfterArrival);
    const [threeDaysBefore, setThreeDaysBefore] = useState(defaultValues.dateThreeDaysAfterArrival);


    const methods = useForm({ defaultValues: defaultValues });
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;

    const [data, setData] = useState({
        arrivalDate: arrivalDateDefault,
        dateFourteenDaysAfterArrival: dateFourteenDaysAfterArrivalDefault,
        dateSevenDaysAfterArrival: dateSevenDaysAfterArrivalDefault,
        dateThreeDaysAfterArrival: dateThreeDaysAfterArrivalDefault,
        daysOfDelay: daysOfDelayDefault,
        lastDayOffDelay: lastDayOffDelayDefault,
    });


    const [openModal, setOpenModal] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);


    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    const toggleModal2 = () => {
        setOpenModal2(!openModal);
    }



    const cancel = () => {
        history.push(RECORDS);
    }

    const calculateDates = (date, subDays, setter, mode = 'sub') => {
        if (mode === 'sub') {
            const newDate = moment(date, 'YYYY-MM-DD').subtract(subDays, 'days');
            setter(moment(newDate._d).format('YYYY-MM-DD'));
        }
        else if (mode === 'add') {
            const newDate = moment(date, 'YYYY-MM-DD').add(subDays, 'days');
            setter(moment(newDate._d).format('YYYY-MM-DD'));
        }
    }

    useEffect(() => {
        calculateDates(arrivalDate, 14, setFourteenDaysBefore);
        calculateDates(arrivalDate, 7, setSevenDaysBefore);
        calculateDates(arrivalDate, 3, setThreeDaysBefore);
        if (delayDays != null) {
            let delaysOrigin =(parseInt(delayDays) + 1);
            calculateDates(arrivalDate, delaysOrigin, (setFreeDelay), 'add');
        }
    }, [arrivalDate, delayDays])


    const changeStage = () => {
        history.push({
            state: { clientType: status },
        });
        setStepper(stepper + 1);
    }

    const onSubmit = async ({ }) => {
        const dataNew = {
            ...formData,
            arrivalDates: {
                arrivalDate: arrivalDate,
                dateFourteenDaysAfterArrival: fourteenDaysBefore,
                dateSevenDaysAfterArrival: sevenDaysBefore,
                dateThreeDaysAfterArrival: threeDaysBefore,
                daysOfDelay: delayDays,
                lastDayOffDelay: freeDelay,
            }
        }
        setFormData(dataNew)
        changeStage()
    }

  

    const expedient_ = {
        arrivalPortDate: formData.expedient.arrivalPortDate == null ? '' : formData.expedient.arrivalPortDate,
        departureDate: formData.expedient.departureDate  == null ? '' : formData.expedient.departureDate,
        eta:formData.expedient.eta  == null ? '' : formData.expedient.eta ,
        etd:formData.expedient.etd  == null ? '' : formData.expedient.etd,
        folioTl:formData.expedient.folioTl == null ? '' : formData.expedient.folioTl,
        naviera:formData.expedient.naviera == null ? '' : formData.expedient.naviera,
        noContainers:formData.expedient.noContainers == null ? '' : formData.expedient.noContainers,
        numberMbl:formData.expedient.numberMbl == null ? '' : formData.expedient.numberMbl,
        quiantityContainers:formData.expedient.quiantityContainers == null ? '' : formData.expedient.quiantityContainers,
        status:formData.expedient.status == null ? '' : formData.expedient.status,
        teus:formData.expedient.teus == null ? '' : formData.expedient.teus,
    }


    const expedient = JSON.stringify(expedient_)

    const customAndFiles_  = {...formData.customAndFiles}

    const customAndFiles = JSON.stringify(customAndFiles_)


    const arrivalDates_ = {
        arrivalDate: arrivalDate == null ?  '' : arrivalDate,
        dateFourteenDaysAfterArrival: fourteenDaysBefore === 'Invalid date' ? '' : fourteenDaysBefore, 
        dateSevenDaysAfterArrival: sevenDaysBefore === 'Invalid date' ? '' : sevenDaysBefore ,
        dateThreeDaysAfterArrival: threeDaysBefore === 'Invalid date'? '' : threeDaysBefore,
        daysOfDelay:  delayDays == null ? 0 : delayDays,
        lastDayOffDelay: freeDelay  == null || freeDelay === 'Invalid date'  ?  '' : freeDelay,
    }

    const arrivalDates = JSON.stringify(arrivalDates_)



    const generateArrivalDates = async () => {
        const formData_ = new FormData()


        formData_.append("id", location.payload.row.expedientId)
        formData_.append("expedient", expedient);
        formData_.append("customAgent", customAndFiles);
        formData_.append("arrivalDates", arrivalDates);
        formData_.append("paymentDetails", '{}');
        formData_.append("pickupDelivery", '{}');
        formData_.append("delay", '{}');
        formData_.append("route", '{}');
        formData_.append("deviation", '[]');
        formData_.append("tracing", '[]');
        formData_.append("mediasDeleted", '[]');
        formData_.append("File", '[]');


        const res = await dispatch(upsertExpedient(formData_))
        if (res) {
            history.push('/dashboard/expedientes');
        }
    }

    useEffect(() => {
    }, [formData])




    return (
        <>

            {
                openModal &&
                <Modal
                    isOpen={openModal}
                    toggle={toggleModal}
                >
                    <CardContainer
                        btnLeftAction={toggleModal}
                        btnRightAction={cancel}
                        body={<BaseModalBody data={cancelUploadOfDocs} />}
                        btnLeftText={'REGRESAR'}
                    />
                </Modal>
            }
            {
                openModal2 &&
                <Modal
                    isOpen={openModal2}
                    toggle={toggleModal2}
                >
                    <CardContainer
                        btnLeftAction={toggleModal2}
                        btnRightAction={cancel}
                        body={<BaseModalBody data={noDocumentsUploded} />}
                        btnLeftText={'REGRESAR'}
                    />
                </Modal>
            }
            <form className='main-container-generic-type' onSubmit={handleSubmit(onSubmit)}>
                <div className='header-form-generic-type'>
                    <h2>Fechas de arribo</h2>
                    <h3>ID: {location.payload.row.id}</h3>
                </div>
                <div className='body-form-generic-type-data record-data' style={{ height: '45vh' }}>
                    <div className='input-container-data records-arrival-dates'>
                        <div className='input-column-container records'>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Fecha de arribo</label>
                                </div>
                                <div className='value-container' >
                                    <input
                                        value={arrivalDate}
                                        onChange={(e) =>
                                            setArrivalDate(moment(e.target.value).format('YYYY-MM-DD'))}
                                        type='date'
                                        // name='arrivalDate'
                                        // {...register('arrivalDate')}
                                        // control={control}
                                        className='input-generic date' />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Fecha 7 días antes de arribo</label>
                                </div>
                                <div className='value-container' >
                                    <input value={sevenDaysBefore}
                                        disabled
                                        type='date'
                                        // name='dateFourteenDaysAfterArrival'
                                        // {...register('dateFourteenDaysAfterArrival')}
                                        // control={control}
                                        className='input-generic date' />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Días de demora</label>
                                </div>
                                <div className='value-container arrival'>
                                    <input
                                        name={'name'}
                                        value={delayDays}
                                        onChange={(e) => setDelayDays(e.target.value)}
                                        className="input-generic"
                                        type="number"
                                        min={0}
                                        placeholder="Agregar..." />
                                    <p>*En caso de que el embarque tenga retraso, agregar los días de demora en este campo.</p>
                                </div>
                            </div>
                        </div>
                        <div className='input-column-container records'>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Fecha 14 días antes de arribo</label>
                                </div>
                                <div className='value-container' >
                                    <input value={fourteenDaysBefore} disabled type='date' className='input-generic date' />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Fecha 3 días antes de arribo</label>
                                </div>
                                <div className='value-container' >
                                    <input value={threeDaysBefore} disabled type='date' className='input-generic date' />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Último día libre demora</label>
                                </div>
                                <div className='value-container arrival' >
                                    <input type='date' value={freeDelay} className='input-time records' disabled />
                                    <p>*Esta fecha se llenará en automático en caso de haber días de demora, sumará campo de fecha de arribo + campo de días de demora.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-form-generic-type records'>
                    <Button type='button' onClick={toggleModal} className={'button-back'} style={{ width: '28%' }}>CANCELAR</Button>
                    <Button type='button' onClick={() => generateArrivalDates()} className={'button-generate'} style={{ width: '28%' }}>GUARDAR</Button>
                    <Button type='submit' className={'button-next'} srcicon={arrow} style={{ width: '30%' }}>SIGUIENTE</Button>
                </div>
            </form>
        </>
    )
}

export default ArrivalDates;
