import React from 'react';
import SimpleFooter from '../../../organisms/Footer1/SimpleTextFooter';
import './Footer.scss';

const Footer = () => {
    return (
        <footer>
            <SimpleFooter className='footer' text={'© 2022, APP DEVELOP'} />
        </footer>
    );              
}

export default Footer;
