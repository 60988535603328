/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from '../../atoms';
import './DetailsView.scss';
import dropdown from '../../../assets/images/navieras/icon-dropdown.png';
import moment from 'moment';
import { upsertQuotationAction } from '../../../redux/actions/quotationAction';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { QUOTATION_DOCUMENTS } from '../../../routes/Paths';
import { upsertExpedient } from '../../../redux/actions/recordAction';
import { addNotification } from '../../../redux/actions/notificationAction';
import { getOneClientAction } from '../../../redux/actions/clientAction';


const DetailsViewLayout = ({ children, data, formData }) => {

    const date = _.get(data, 'createdAt', '');
    const idQuotation = _.get(data, 'id', '');
    const clientName = _.get(data, 'client.tradename', '');
    const supplier = _.get(data, 'provider.name', '');
    const modality = _.get(data, 'modalityType.name', '');
    const serviceI = _.get(data, 'serviceImport', '');
    const serviceE = _.get(data,'serviceExport', '')
    const serviceN = _.get(data, 'serviceNacional', '')

    const serviceDisplay = serviceI === true ?  "Importacion" : serviceE === true ?  "Exportacion" : serviceN === true && 'Nacional'

    const admin = useSelector(state => state.user.user.administratorId)
    const operative = useSelector(state => state.user.user.operativeId)

    const modalityDisplay = (modality) => {
        if (modality === "Marine") {
            let valor = _.get(data, 'modalityMarine.fcl');
            let displayValue = valor === true ? "Marina FCL" : "Marina LCL"
            return displayValue
        } else if (modality === "Air") {
            return "Aérea"
        } else if (modality === "Ground") {
            return "Terrestre"
        }
    }
    const history = useHistory();
    const dispatch = useDispatch();


    //Quotation
    const {
        modalityTypeId,
        modalityType,
        clientId,
        providerId,
        serviceImport,
        serviceExport,
        expedientId,
        generateExpedient,
    } = data;

    const quotation_ = {
        modalityTypeId,
        clientId,
        providerId,
        serviceImport,
        serviceExport,
    }

    const quotationUpdate_ = {
        modalityTypeId,
        clientId,
        providerId,
        serviceImport,
        serviceExport,
        expedientId: data.id,
        generateExpedient: true,
    }


    const quotationUpdate = JSON.stringify(quotationUpdate_);
    const quotation = JSON.stringify(quotation_);


    


    useEffect(() => {
        if(data.id)
        dispatch(getOneClientAction({"id":data.clientId}))
    }, [])



    // QuotationData
    const {
        modality: modality_,
        destinyLocalChargesCbm,
        destinySeaFreight,
        destinyStateId,
        factor,
        insurance,
        invoice,
        name,
        officeMx,
        originCountryId,
        originLandFreight,
        originLocalCharges,
        originSeaFreight,
        originStateId,
        total,
        validity,
        weight,
        destinyCountryId,
        bussines,
        delivery,
        cbms,
        destinyLocalChargesBl,
        destinyLandFreight,
        coordination,
        platform,
        service: service_,
        landFreight,
        overweight,
        maneuvers,
        dropOff,
        intercom,
        pickup,
        originPortId,
        originCharges,
        destinyPortId,
        destinyCharges,
        agency,
        lcl,
        fcl,
        containerType,
        containersNumber,
        delayDays,
        storageDays,
        destinyLocalChargesContainer,
        servicest3,
        aditionalService,
        other,
        officeMxTlp, } = formData

    const quotationD = {
        destinyLocalChargesCbm,
        destinySeaFreight,
        destinyStateId,
        factor,
        insurance,
        invoice,
        name,
        officeMx,
        originCountryId,
        originLandFreight,
        originLocalCharges,
        originSeaFreight,
        originStateId,
        total,
        validity,
        weight,
        destinyCountryId,
        bussines,
        modality_,
        delivery,
        cbms,
        destinyLocalChargesBl,
        destinyLandFreight,
        coordination,
        platform,
        service_,
        landFreight,
        overweight,
        maneuvers,
        dropOff,
        intercom,
        pickup,
        originPortId,
        originCharges,
        destinyPortId,
        destinyCharges,
        agency,
        lcl,
        fcl,
        containerType,
        containersNumber,
        delayDays,
        storageDays,
        destinyLocalChargesContainer,
        servicest3,
        aditionalService,
        other,
        officeMxTlp,
    }

    const quotationData = JSON.stringify(quotationD);


    //Expedient

    let containerNumber =  parseInt(formData.containersNumber)


    const expedient_ = {
        quotationId: data.id,
        clientId,
        mov: serviceDisplay,
        team: fcl == 1 ? "Tipo contenedor" : lcl == 1 ? "LCL" : modalityType.name === "Ground" ? "FTL" : modalityType.name === "Air" ? "Aereo" : '',
        quiantityContainers: fcl == 1 ? containersNumber : '',
        // teus: formData.containerType === "40'" || formData.containerType === "40NOR"  ||  formData.containerType === "40HC" ? 
        // containerNumber * 2 :
        // formData.containerType === "20'" ?
        // formData.containersNumber :
        // formData.containerType === "Otro" ?
        // '' :
        //  formData.containerType == undefined ?
        // '' : 
        // formData.containerType === '' && ''
    }

    
    const expedient = JSON.stringify(expedient_);

    const delivetObejct = {
        mode: formData.modality ? formData.modality : "N/A"
    }

    const deliverySend = JSON.stringify(delivetObejct)


    // const submitData = async ()=>{
    //     const res = await dispatch(upsertQuotationAction(formData)) 
    //     if(res){
    //         history.push('/dashboard/cotizacion');
    //     }
    // }

    const closeExpedientError = async() =>{
        dispatch(addNotification({
            toastType: 'error',
            toastTitle: 'La cotización no puede ser modificada',
        }));
    }


    const submit = async () => {
        const formData_ = new FormData();

        formData_.append("id", data.id);

        formData_.append("quotation", quotation);
        formData_.append("quotationData", quotationData);
        formData_.append("mediasDeleted", '[]')

        formData_.append("File", '[]');

        const res = await dispatch(upsertQuotationAction(formData_))
        if (res) {
            history.push('/dashboard/cotizacion');
        }
    }
    

    //update expedient quotation
    const submitQuotation = async () => {
        const formData_ = new FormData();

        formData_.append("id", data.id);
        formData_.append("quotation", quotationUpdate);
        formData_.append("quotationData", quotationData);
        formData_.append("mediasDeleted", '[]')

        formData_.append("File", '[]');

        const res = await dispatch(upsertQuotationAction(formData_))
    }

    const generateExpedientM = async () => {
        const formData_ = new FormData()
        // const expedient = '{"quotationId": 1,"clientId":1, "mov":"Importacion", "folioTl": "saasa" , "numberMbl":"sssS","noContainers":"sssss", "naviera":"sdasdas" , "quiantityContainers":"sas" , "team": "eweww" , "teus":"asaas", "etd":"2022-10-10T04:11:02.554Z" , "eta":"2022-10-10T04:11:02.554Z" , "departureDate": "2022-10-10T04:11:02.554Z" , "arrivalPortDate":"2022-10-10T04:11:02.554Z"}'
        // const customAgent = '{"hbl": true ,  "mbl": true ,  "eir": true ,  "telex": true , "release": true, "insurancePolice": true, "courtDelays": true , "otherFile": true, "customAgentId":1 , "pol":2, "pod":4}'
        // const arrivalDates = '{"arrivalDate": "2022-10-10T04:11:02.554Z", "dateFourteenDaysAfterArrival": "2022-10-10T04:11:02.554Z", "dateSevenDaysAfterArrival": "2022-10-10T04:11:02.554Z", "dateThreeDaysAfterArrival":"2022-10-10T04:11:02.554Z" , "daysOfDelay":1,"lastDayOffDelay": "2022-10-10T04:11:02.554Z"}'
        // const paymentDetails = '{"creditDays": 123,"paydayLimit":"2022-10-10T04:11:02.554Z", "payment": 123, "paymentDate":"2022-10-10T04:11:02.554Z", "paymentNaviera": 1234,"paymentDateNaviera":"2022-10-10T04:11:02.554Z", "paymentAgent": 1234, "paymentDateAgent":"2022-10-10T04:11:02.554Z" , "localCharges": 1234,  "dateLocalCharges":"2022-10-10T04:11:02.554Z"}'
        // const pickupDelivery = '{"mode":233232, "deliveryType":12, "railArrivalDate":"2022-10-10T04:11:02.554Z", "railwayDeliveryDate":"2022-10-10T04:11:02.554Z","emptyReturnDate":"2022-10-10T04:11:02.554Z", "pickUp":"aaaaa", "fpod": "aaaaaaa"}'

        formData_.append("closeFile", false);

        if(admin != null){
            formData_.append("adminId", admin);
        }else if(operative ){
            formData_.append("operativeId", operative);

        }
        formData_.append("expedient", expedient);
        formData_.append("customAgent", '{}');
        formData_.append("arrivalDates", '{}');
        formData_.append("paymentDetails", '{}');
        formData_.append("pickupDelivery", deliverySend);
        formData_.append("delay", '{}');
        formData_.append("route", '{}');
        formData_.append("deviation", '[]');
        formData_.append("tracing", '[]');
        formData_.append("mediasDeleted", '[]');
        formData_.append("File", '[]');

        submitQuotation()

        const res = await dispatch(upsertExpedient(formData_))
        if (res) {
            history.push('/dashboard/expedientes');
        }
    }
    return (
        <div className='view-details-general-container'>
            <div className='general-data-container'>
                <div className='client-data-container'>
                    <div className='header-data-container'>
                        <p className='title-text'>{moment(date).format('l')}</p>
                        <div className='id-quotation'>
                            <p className='title-text'>ID Cotización:</p>
                            <p className='id'>{idQuotation}</p>
                        </div>
                    </div>
                    <div className='one-data-container'>
                        <h4 className='data-title'>Cliente</h4>
                        <p className='client-info'>{clientName}</p>
                    </div>
                    <div className='one-data-container'>
                        <h4 className='data-title'>Proveedor</h4>
                        <p className='client-info'>{supplier}</p>
                    </div>
                    <div className='one-data-container'>
                        <h4 className='data-title'>Modalidad</h4>
                        <p className='client-info'>{modalityDisplay(modality)}</p>
                    </div>
                    <div className='one-data-container'>
                        <h4 className='data-title'>Servicio</h4>
                        <p className='client-info'>{serviceDisplay}</p>
                    </div>
                </div>
                <div className='buttons-container'>
                    <Button className={'button-next quotation'} onClick={data.generateExpedient == true ? () => closeExpedientError()  :  () => submit() }>GUARDAR</Button>
                    <Button className={'button-generate quotation'} onClick={() => history.push({
                        pathname: QUOTATION_DOCUMENTS.replace(':id',  data.id),
                        payload: data,
                    })}>VER DOCUMENTOS</Button>
                    <Button onClick={() => generateExpedientM()} className={'button-next quotation'}>GENERAR COMO EXPEDIENTE</Button>
                </div>
            </div>
            <div className='quotation-container'>
                <h3>Datos de cotización</h3>
                <div className='quotation-data'>
                    {children}
                </div>
                <div className='dropdown-container'>
                    <img src={dropdown} />
                </div>
            </div>
        </div>
    );
}

DetailsViewLayout.propTypes = {
    children: PropTypes.any,
    date: PropTypes.any,
    idQuotation: PropTypes.any,
    clientName: PropTypes.any,
    supplier: PropTypes.any,
    modality: PropTypes.any,
    service: PropTypes.any,
}

export default DetailsViewLayout;
