import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { CancelFormBody } from '../../../components/molecules/CancelFormBody/CancelFormBody';
import { CardContainer } from '../../../components/molecules/CardContainer/CardContainer';
import Modal from '../../../components/organisms/Modal/Modal';
import { StepperComponent } from '../../../components/Stepper/Stepper';
import { AssignCustomsAgent } from '../../AdminDashboard/HomeModule/CustomerManagement/assignCustomsAgent';
import { ClientDocuments } from '../../AdminDashboard/HomeModule/CustomerManagement/ClientDocuments';
// import './CustomAgent.scss';
// import './ClientRegister.scss';
import '../../../styles/stylesSteppers.scss'
import { CustomAgentDocuments } from './CustomAgentDocuments';
import { CustomAgentPorts } from './CustomAgentPorts';
import { PrincipalDataCustomAgent } from './PrincipalDataCustomAgent';
import { CUSTOMS_AGENT } from '../../../routes/Paths';

export const CustomAgenteRegister = (props) => {
    const history = useHistory()
    const [stepper, setStepper] = useState(0);

    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({});
    const toggleModal = () => {
        setOpenModal(!openModal);
    }
    
    const steps = [
        {
            label: 'Datos principales',
            step:0,
            component: <PrincipalDataCustomAgent toggleModal={toggleModal} stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData}/>
            // component: <ClientDocuments stepper={stepper} setStepper={setStepper}/>

        },
        {
            label: 'Datos de puerto',
            step:1,
            component: <CustomAgentPorts toggleModal={toggleModal} stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData}/>
            // component: <PrincipalDataClient stepper={stepper} setStepper={setStepper}/>
        },
        {
            label: 'Documentos',
            step:2,
            component: <CustomAgentDocuments toggleModal={toggleModal} stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData} mode={"create"}/>
            // component: <PrincipalDataClient stepper={stepper} setStepper={setStepper}/>
        },
    ] 

    const [render, setRender]= useState(null)


    useEffect(() => {
        setRender(steps[stepper].component)
    }, [stepper])
    
    const renders = steps.map((ele)=>{
        return(
            ele.component
        )
    })
    
    const cancel = () => {
        history.push(CUSTOMS_AGENT)
    }
    return (
        <>
        {
            openModal &&
            <Modal
                isOpen={toggleModal}
                toggle={toggleModal}
            >
                <CardContainer btnLeftAction={toggleModal} btnRightAction={cancel} body={<CancelFormBody/>}/>
            </Modal>
        }
        <div className='main-container'>
            <div className='stepper-container'>
            <StepperComponent step={stepper} steps={steps} setStepper={setStepper}/>
            </div>
            <div className='card-container-stepper'>
                {
                render
                }
            </div>
        </div>
        </>
    )
}
