/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button } from './../../../components/atoms';
import '../../../styles/stylesSteppers.scss';
import arrow from './../../../assets/images/navieras/arrowButton.png'
import { useHistory, useLocation } from 'react-router-dom';
import './RecordsData.scss';
import { useEffect } from 'react';
import { upsertExpedient } from '../../../redux/actions/recordAction';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Modal from '../../../components/organisms/Modal/Modal';
import { BaseModalBody } from '../../../components/molecules/ModalBody/BaseModalBody';
import { cancelUploadOfDocs, noDocumentsUploded } from '../../../components/molecules/ModalBody/modalBodyVaribles';
import { CardContainer } from '../../../components/molecules/CardContainer/CardContainer';


const PaymentData = ({ recordId = '002', setStepper, stepper, formData, setFormData }) => {
    const [status, setStatus] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation()

    const creditDaysLocation = _.get(location, 'payload.row.paymentDetails.creditDays', '')
    const payDayLimitLocation = _.get(location, 'payload.row.paymentDetails.paydayLimit', '')
    const paymentLocation = _.get(location, 'payload.row.paymentDetails.payment', '')
    const paymentDateLocation = _.get(location, 'payload.row.paymentDetails.paymentDate', '')
    const paymentNavieraLocation = _.get(location, 'payload.row.paymentDetails.paymentNaviera', '')
    const paymentDateNavieraLocation = _.get(location, 'payload.row.paymentDetails.paymentDateNaviera', '')
    const paymentAgentLocation = _.get(location, 'payload.row.paymentDetails.paymentAgent', '')
    const paymentDateAgentLocation = _.get(location, 'payload.row.paymentDetails.paymentDateAgent', '')
    const localChargesLocation = _.get(location, 'payload.row.paymentDetails.localCharges', '')
    const dateLocalChargesLocation = _.get(location, 'payload.row.paymentDetails.dateLocalCharges', '')



    const [creditDays, setCreditDays] = useState(creditDaysLocation);
    const [payLimitDate, setPayLimitDate] = useState(payDayLimitLocation)
    const [pays, setPays] = useState({
        pay: paymentLocation,
        navierasPay: paymentNavieraLocation,
        agentPay: paymentAgentLocation,
        localCharges: localChargesLocation,
    });

    const changeStage = () => {
        setStepper(stepper + 1);
    }


    const [openModal, setOpenModal] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);


    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    const toggleModal2 = () => {
        setOpenModal2(!openModal);
    }

    const cancel = () => {
        history.push(RECORDS);
    }


    const [payDates, setPayDates] = useState({
        payDate: paymentDateLocation,
        navierasPayDate: paymentDateNavieraLocation,
        agentPayDate: paymentDateAgentLocation,
        localChargesDate: dateLocalChargesLocation,
    });


    const defaultValues = {
        creditDays: creditDays,
        paydayLimit: payLimitDate,
        payment: pays.pay,
        paymentDate: payDates.payDate,
        paymentNaviera: pays.navierasPay,
        paymentDateNaviera: payDates.navierasPayDate,
        paymentAgent: pays.agentPay,
        paymentDateAgent: payDates.agentPayDate,
        localCharges: pays.localCharges,
        dateLocalCharges: payDates.localChargesDate

    };



    const methods = useForm({ defaultValues: defaultValues });
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;





    useEffect(() => {
    }, [])

    
    const expedient_ = {
        arrivalPortDate: formData.expedient.arrivalPortDate == null ? '' : formData.expedient.arrivalPortDate,
        departureDate: formData.expedient.departureDate  == null ? '' : formData.expedient.departureDate,
        eta:formData.expedient.eta  == null ? '' : formData.expedient.eta ,
        etd:formData.expedient.etd  == null ? '' : formData.expedient.etd,
        folioTl:formData.expedient.folioTl == null ? '' : formData.expedient.folioTl,
        naviera:formData.expedient.naviera == null ? '' : formData.expedient.naviera,
        noContainers:formData.expedient.noContainers == null ? '' : formData.expedient.noContainers,
        numberMbl:formData.expedient.numberMbl == null ? '' : formData.expedient.numberMbl,
        quiantityContainers:formData.expedient.quiantityContainers == null ? '' : formData.expedient.quiantityContainers,
        status:formData.expedient.status == null ? '' : formData.expedient.status,
        teus:formData.expedient.teus == null ? '' : formData.expedient.teus,
    }


    const expedient = JSON.stringify(expedient_)

    const customAndFiles_  = {...formData.customAndFiles}

    const customAndFiles = JSON.stringify(customAndFiles_)


    
    const arrivalDates_ = {
        arrivalDate: formData.arrivalDates.arrivalDate == null ?  '' :formData.arrivalDates.arrivalDate,
        dateFourteenDaysAfterArrival: formData.arrivalDates.dateFourteenDaysAfterArrival === 'Invalid date' ? '' : formData.arrivalDates.dateFourteenDaysAfterArrival, 
        dateSevenDaysAfterArrival: formData.arrivalDates.dateSevenDaysAfterArrival === 'Invalid date' ? '' : formData.arrivalDates.dateSevenDaysAfterArrival ,
        dateThreeDaysAfterArrival: formData.arrivalDates.dateThreeDaysAfterArrival === 'Invalid date'? '' : formData.arrivalDates.dateThreeDaysAfterArrival,
        daysOfDelay: formData.arrivalDates.daysOfDelay == null ? 0 : formData.arrivalDates.daysOfDelay,
        lastDayOffDelay: formData.arrivalDates.lastDayOffDelay  == null || formData.arrivalDates.lastDayOffDelay === 'Invalid date'  ?  '' : formData.arrivalDates.lastDayOffDelay,
    }

    const arrivalDates = JSON.stringify(arrivalDates_)


    const paymentDetails_ = {
        creditDays: creditDays == null ? 0 : creditDays,
        paydayLimit: payLimitDate == null ? '' : payLimitDate,
        payment: pays.pay == null ? 0 : pays.pay,
        paymentDate: payDates.payDate == null ? '' : payDates.payDate,
        paymentNaviera: pays.navierasPay == null ? 0 : pays.navierasPay,
        paymentDateNaviera: payDates.navierasPayDate == null ? '' : payDates.navierasPayDate,
         paymentAgent: pays.agentPay == null ? 0 : pays.agentPay,
        paymentDateAgent: payDates.agentPayDate == null ? '' : payDates.agentPayDate,
        localCharges: pays.localCharges == null ? 0 : pays.localCharges ,
        dateLocalCharges: payDates.localChargesDate == null ? '' : payDates.localChargesDate
    }

    const paymentDetails = JSON.stringify(paymentDetails_);




    const generatePaymentData = async () => {

        const formData_ = new FormData()

        formData_.append("id", location.payload.row.expedientId)
        formData_.append("expedient", expedient);
        formData_.append("customAgent", customAndFiles);
        formData_.append("arrivalDates", arrivalDates);
        formData_.append("paymentDetails", paymentDetails);
        formData_.append("pickupDelivery", '{}');
        formData_.append("delay", '{}');
        formData_.append("route", '{}');
        formData_.append("deviation", '[]');
        formData_.append("tracing", '[]');
        formData_.append("mediasDeleted", '[]');
        formData_.append("File", '[]');


        const res = await dispatch(upsertExpedient(formData_))
        if (res) {
            history.push('/dashboard/expedientes');
        }
    }


    const onSubmit = async ({ }) => {
        const dataNew = {
            ...formData,
            paymentDetails: {
                creditDays: creditDays,
                paydayLimit: payLimitDate,
                payment: pays.pay,
                paymentDate: payDates.payDate,
                paymentNaviera: pays.navierasPay,
                paymentDateNaviera: payDates.navierasPayDate,
                paymentAgent: pays.agentPay,
                paymentDateAgent: payDates.agentPayDate,
                localCharges: pays.localCharges,
                dateLocalCharges: payDates.localChargesDate
            }
        }
        setFormData(dataNew)
        changeStage()
    }




    return (
        <>
            {
                openModal &&
                <Modal
                    isOpen={openModal}
                    toggle={toggleModal}
                >
                    <CardContainer
                        btnLeftAction={toggleModal}
                        btnRightAction={cancel}
                        body={<BaseModalBody data={cancelUploadOfDocs} />}
                        btnLeftText={'REGRESAR'}
                    />
                </Modal>
            }
            {
                openModal2 &&
                <Modal
                    isOpen={openModal2}
                    toggle={toggleModal2}
                >
                    <CardContainer
                        btnLeftAction={toggleModal2}
                        btnRightAction={cancel}
                        body={<BaseModalBody data={noDocumentsUploded} />}
                        btnLeftText={'REGRESAR'}
                    />
                </Modal>
            }
            <form className='main-container-generic-type' onSubmit={handleSubmit(onSubmit)}>
                <div className='header-form-generic-type'>
                    <h2>Datos de pago</h2>
                    <h3>ID: {location.payload.row.id}</h3>
                </div>
                <div className='body-form-generic-type-data record-data' style={{ height: '45vh' }}>
                    <div className='input-row-container records-payment-data'>
                        <div className='credit-left-container'>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Días de crédito</label>
                                </div>
                                <div className='value-container arrival'>
                                    <input value={creditDays} type='number' onChange={(e) => setCreditDays(e.target.value)} className='input-generic date' placeholder='Agregar...' />

                                    {/* <p>*Esta información es un dato que se encontra en los datos principales del cliente.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Fecha límite de pago</label>
                            </div>
                            <div className='value-container'>
                                <input value={payLimitDate} onChange={(e) => setPayLimitDate(e.target.value)} type='date' className='input-generic date' />
                            </div>
                        </div>
                    </div>
                    <div className='input-row-container'>
                        <div className='title-container'><h3>Monto de pagos</h3></div>
                        <div className='title-container'><h3>Fechas de pagos</h3></div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Pago</label>
                            </div>
                            <div className='value-container' >
                                <input value={pays.pay} onChange={(e) => setPays({ ...pays, pay: e.target.value })} className="input-generic" type="number" min={0} placeholder="Agregar..." />
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Fecha de pago</label>
                            </div>
                            <div className='value-container' >
                                <input value={payDates.payDate} onChange={(e) => setPayDates({ ...payDates, payDate: e.target.value })} type='date' className='input-generic date' />
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Pago de naviera</label>
                            </div>
                            <div className='value-container'>
                                <input value={pays.navierasPay} onChange={(e) => setPays({ ...pays, navierasPay: e.target.value })} className="input-generic" type="number" min={0} placeholder="Agregar..." />
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Fecha de pago de naviera</label>
                            </div>
                            <div className='value-container'>
                                <input value={payDates.navierasPayDate} onChange={(e) => setPayDates({ ...payDates, navierasPayDate: e.target.value })} type='date' className='input-generic date' />
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Pago al agente</label>
                            </div>
                            <div className='value-container' >
                                <input value={pays.agentPay} onChange={(e) => setPays({ ...pays, agentPay: e.target.value })} className="input-generic" type="number" min={0} placeholder="Agregar..." />
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Fecha de pago al agente</label>
                            </div>
                            <div className='value-container' >
                                <input value={payDates.agentPayDate} onChange={(e) => setPayDates({ ...payDates, agentPayDate: e.target.value })} type='date' className='input-generic date' />
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Cargos locales</label>
                            </div>
                            <div className='value-container' >
                                <input value={pays.localCharges} onChange={(e) => setPays({ ...pays, localCharges: e.target.value })} className="input-generic" type="number" min={0} placeholder="Agregar..." />
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='label-container'>
                                <label className='container-label'>Fecha de cargos locales</label>
                            </div>
                            <div className='value-container' >
                                <input value={payDates.localChargesDate} onChange={(e) => setPayDates({ ...payDates, localChargesDate: e.target.value })} type='date' className='input-generic date' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-form-generic-type records'>
                    <Button type='button' onClick={toggleModal} className={'button-back'} style={{ width: '28%' }}>CANCELAR</Button>
                    <Button type='button' onClick={() => generatePaymentData()} className={'button-generate'} style={{ width: '28%' }}>GUARDAR</Button>
                    <Button type='submit' className={'button-next'} srcicon={arrow} style={{ width: '30%' }}>SIGUIENTE</Button>
                </div>
            </form>

        </>
    )
}

export default PaymentData;
