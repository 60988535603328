import {
    QUOTATION_MODULE_GET_ALL_QUOTATIONS_BEGIN,
    QUOTATION_MODULE_GET_ALL_QUOTATIONS_SUCCESS,
    QUOTATION_MODULE_GET_ALL_QUOTATIONS_FAILURE,
    QUOTATION_MODULE_UPSERT_QUOTATION_BEGIN,
    QUOTATION_MODULE_UPSERT_QUOTATION_SUCCESS,
    QUOTATION_MODULE_UPSERT_QUOTATION_FAILURE,
    QUOTATION_MODULE_DELETE_QUOTATION_BEGIN,
    QUOTATION_MODULE_DELETE_QUOTATION_SUCCESS,
    QUOTATION_MODULE_DELETE_QUOTATION_FAILURE,
    QUOTATION_GET_ALL_QUOTATIONS_DELETED_BEGIN,
    QUOTATION_GET_ALL_QUOTATIONS_DELETED_SUCCESS,
    QUOTATION_GET_ALL_QUOTATIONS_DELETED_FAILURE,
    QUOTATIONS_RECOVE_BEGIN,
    QUOTATIONS_RECOVE_SUCCESS,
    QUOTATIONS_RECOVE_FAILURE,

} from '../actionTypes';

import initialState from '../initialState';

export default function quotationReducer(state = initialState.quotation, action) {
    switch (action.type) {
        case QUOTATION_MODULE_GET_ALL_QUOTATIONS_BEGIN:
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    totalRecords: 0,
                    data: [],
                },
            };
        case QUOTATION_MODULE_GET_ALL_QUOTATIONS_SUCCESS:
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    totalRecords: action.data.totalRecords,
                    data: action.data.data,
                },
            };
        case QUOTATION_MODULE_GET_ALL_QUOTATIONS_FAILURE:
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
        case QUOTATION_MODULE_UPSERT_QUOTATION_BEGIN:
            return {
                ...state,
                upsertQuotation: {
                    ...state.upsertQuotation,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: {},
                },
            };
        case QUOTATION_MODULE_UPSERT_QUOTATION_SUCCESS:
            return {
                ...state,
                upsertQuotation: {
                    ...state.upsertQuotation,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.quotation,
                },
            };
        case QUOTATION_MODULE_UPSERT_QUOTATION_FAILURE:
            return {
                ...state,
                upsertQuotation: {
                    ...state.upsertQuotation,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: {},
                },
            };
        case QUOTATION_MODULE_DELETE_QUOTATION_BEGIN:
            return {
                ...state,
                deleteQuotation: {
                    ...state.deleteQuotation,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: null,
                },
            };
        case QUOTATION_MODULE_DELETE_QUOTATION_SUCCESS:
            return {
                ...state,
                deleteQuotation: {
                    ...state.deleteQuotation,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.quotation,
                },
            }
        case QUOTATION_MODULE_DELETE_QUOTATION_FAILURE:
            return {
                ...state,
                deleteQuotation: {
                    ...state.deleteQuotation,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: null,
                },
            }
            //get all deleted quotations
            case QUOTATION_GET_ALL_QUOTATIONS_DELETED_BEGIN:
                return {
                    ...state,
                    getDeleteQuotation: {
                        ...state.getDeleteQuotation,
                        isLoading: true,
                        isSuccess: false,
                        err: null,
                        data: null,
                    },
                };
            case QUOTATION_GET_ALL_QUOTATIONS_DELETED_SUCCESS:
                return {
                    ...state,
                    getDeleteQuotation: {
                        ...state.getDeleteQuotation,
                        isLoading: false,
                        isSuccess: true,
                        err: null,
                        data: action.data.data,
                    },
                }
            case QUOTATION_GET_ALL_QUOTATIONS_DELETED_FAILURE:
                return {
                    ...state,
                    getDeleteQuotation: {
                        ...state.getDeleteQuotation,
                        isLoading: false,
                        isSuccess: false,
                        err: action.err,
                        data: null,
                    },
                }

                //recove quotations
                case QUOTATIONS_RECOVE_BEGIN:
                return {
                    ...state,
                    recoveQuotations: {
                        ...state.recoveQuotations,
                        isLoading: true,
                        isSuccess: false,
                        err: null,
                        data: null,
                    },
                };
            case QUOTATIONS_RECOVE_SUCCESS:
                return {
                    ...state,
                    recoveQuotations: {
                        ...state.recoveQuotations,
                        isLoading: false,
                        isSuccess: true,
                        err: null,
                        data:[],
                    },
                }
            case QUOTATIONS_RECOVE_FAILURE:
                return {
                    ...state,
                    recoveQuotations: {
                        ...state.recoveQuotations,
                        isLoading: false,
                        isSuccess: false,
                        err: action.err,
                        data: {},
                    },
                }


            default:
            return state;
    }
}