import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import { useHistory } from 'react-router-dom';
import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '../../../components/atoms/Button';
import Input from '../../../components/atoms/Input';
import './ChangePassword.scss';
import { updatePasswordRequestAction } from '../../../redux/actions/userActions';


const RecoverPasswordForm = (props) => {
    let history = useHistory();
    const {register, handleSubmit, formState: { errors }} = useForm();
    const onErrors = (errors, e) => console.log(errors, e);

    useEffect( ()=>{
        if(!_.isNil(props.updatePasswordRequest.isSuccess) && props.updatePasswordRequest.isSuccess) {
            history.push('/');
        }
    }, [props.updatePasswordRequest.isSuccess]);

    const onSubmitEmail = (formData) => {
        props.updatePasswordRequestAction(formData);
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmitEmail, onErrors)}
            className='form-password'
        >
            <div className={'email'}>
                <label>CORREO</label>
                <Input 
                    {...register('email', {
                        required: 'Valor requerido',
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Email invalido"
                        }
                    })}
                    type={'email'}
                    placeholder={'Agregar correo'}
                    errors={errors}
                />
                <Button
                    type='submit'
                    className={'accept'} 
                >
                    ACEPTAR
                </Button>
            </div>
        </form>
    );
}

RecoverPasswordForm.propTypes = {
    updatePasswordRequestAction: PropTypes.func,
    updatePasswordRequest: PropTypes.object,
}

function mapStateToProps (state) {
    return{
        updatePasswordRequest: _.get(state.user, 'updatePasswordRequest', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    updatePasswordRequestAction: params => dispatch(updatePasswordRequestAction(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordForm);
