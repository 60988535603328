/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import './PortsForm.scss';
import deleteIcon from '../../../assets/images/navieras/deleteTrash2.png';
import { getAllPortsAction } from '../../../redux/actions/portAction';
import _ from 'lodash';
import { getAllCountriesAction } from '../../../redux/actions/addressAction';


export const PortsForm = ({ index, setPorts, ports, arrayComplete, setPortsBorrados, portsBorrados, disabled }) => {

    const { mode } = useParams();
    const [isDisabled, setIsDisabled] = useState(mode == 'ver' ? true : false);
    const [countrySelected, setCountrySelected] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllPortsAction());
        dispatch(getAllCountriesAction());
    }, []);

    const countries = useSelector(state => state.address.countries.data);
    const portsAvailable = useSelector(state => state.port.ports.data);

    // const history =  useHistory();

    const handleAddFile = (e) => {
        let arrayCopy = JSON.parse(JSON.stringify(arrayComplete));

        arrayCopy.map((el) => {
            if (ports.id === el.id) {
                el.id = e.target.value;
            }
        })
        setPorts(arrayCopy)
    }


    const handleDelete = () => {

        let arrayCopy = JSON.parse(JSON.stringify(arrayComplete));
        let borrado = [];
        // arrayCopy.forEach((i, ind) => (ind !== index ? arreglo.push(i) : borrado.push({
        //     id: i.id,
        //     deletedAt: new Date(),
        // })));
        let dlt = [];
        dlt = arrayCopy.splice(index, index)
        borrado.push(...dlt)
        setPorts(arrayCopy);
        if (portsBorrados.length >= 0) {
            console.warn([...borrado]);
            setPortsBorrados([...portsBorrados, ...borrado]);
        } else {
            setPortsBorrados([...borrado]);

        }
    }


    const [portSelected, setPortSelected] = useState({
        name: '',
        id: '',
    });


    return (
        <>
            <div className='port-form-container'>
                {mode !== "ver" &&  arrayComplete.length !== 0 && 

                    <div className='delete-container-button'>
                        <img src={deleteIcon} onClick={() => handleDelete()} alt="deletePort" />
                    </div>
                }

                {/* <div className='input-container'>
                <div className='input-container-port'> */}
                {/* <div className='input-container-port'>
                    <div className='label-container'>
                        <label className='container-label'>País*</label>
                    </div>
                    <div className='value-container' >
                        <select disabled={isDisabled} required className='input-select' value={countrySelected.name} onChange={(e) => {
                            setCountrySelected(e.target.value);
                        }}>
                            <option className='option-selected' value={null} selected hidden>{countrySelected.name != null ? countrySelected.name : "Seleccionar"}</option>
                            {
                                _.get(countries, 'data') &&
                                countries.data.map((ele, i) => {
                                    return <option key={i} value={ele.id}> {ele.name}</option>
                                })
                            }
                        </select >
                    </div>
                </div> */}

                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Puerto*</label>
                    </div>
                    <div className='value-container-select' >
                        <select disabled={isDisabled} className='input-select' value={arrayComplete[index].id} onChange={(e) => {
                            // setPortSelected(parseInt({...portSelected, id: e.target.value})), 
                                handleAddFile(e)
                        }}>
                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                            {
                                _.get(portsAvailable, 'data') &&
                                portsAvailable.data.map((ele, i) => {
                                    return <option key={i} value={ele.id}>{ele.port}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
        </>
    )
}

