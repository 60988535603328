export default {
  user:{
    token: '',
    isAuthenticated: false,
    role: 'company',
    request: {
      headers:{
          'Content-Type': 'application/json',
      },
      body: {},
    },
    loading: false,
    error: null,
    users:{},
    user: {},
    updatePasswordRequest: {
      loading: false,
      isSuccess: false,
      error: null,
    },
    setNewPassword: {
      loading: false,
      isSuccess: false,
      error: null,
    },
  },
  sidebar: {
    sideSelected: false,
    optionSelected: 2
  },
  userInterface: {
    isSidebarOpened: false,
  },
  notification: {
    toastTitle: null,
    toastType: null,
    position: "bottom-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  },
  company: {
    companies: {
      loadingGetAllClients: false,
      errorGetAllClients: null,
      totalRecords: 0,
      data: []
    },
    upsertCompany: {
      loading: false,
      isSuccess: false,
      err: null,
      data: {},
    },
    companyRecord: {
      loading: false,
      isSuccess: false,
      error: null,
      data: null,
    },
    companyAccounts: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    companyConnect: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    companyUploadFile: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
  },
  admin: {
    admins: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    adminRecord: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: [],
    },
    upsertAdmin: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    deleteAdmin: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
  },
  customsAgent:{
    customsAgents:{
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
  },
  client: {
    clients: {
      loadingGetAllClients: false,
      errorGetAllClients: null,
      totalRecords: 0,
      data: [],
    },
    clientsAvailableByCustomerAgent: {
      loadingGetAllClients: false,
      errorGetAllClients: null,
      totalRecords: 0,
      data: []
    },
  },
  customAgent: {
    customAgents: {
      loadingGetAllCustomAgents: false,
      errorGetAllCustomAgents: null,
      totalRecords: 0,
      data: [],
    },
    customAgentsAvailableByClient: {
        loadingGetAllCustomerAgents: false,
        errorGetAllCustomerAgents: null,
        totalRecords: 0,
        data: []
    },
  },
  costumerCustomAgent: {
    upsertCostumerCustomAgent: {
      loadingGetAllCustomAgents: false,
      errorGetAllCustomAgents: null,
      totalRecords: 0,
      data: []
    }
  },
  provider: {
    providers: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    providerRecord: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    upsertProvider: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    deleteProvider: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
  },
  quotation: {
    quotations: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    quotationRecord: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    upsertQuotation: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    deleteQuotation: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    getDeleteQuotation: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: [],
    },
    recoveQuotations:{
      isLoading: false,
      isSuccess: false,
      err: null,
      data: [],
      
    },
  },
  port: {
    portsAvailable: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    portsClient: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    ports: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    portRecord: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    upsertPort: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    deletePort: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
  },
  operative: {
    operatives: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    operativeRecord: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: [],
    },
    upsertOperative: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    deleteOperative: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
  },
  address: {
    countries: {
      loadingGetAllCountries: false,
      totalRecords: 0,
      data: []
    },
    states: {
      loadingGetAllStates: false,
      totalRecords: 0,
      data: []
    },
    statesOrigen: {
      loadingGetAllStates: false,
      totalRecords: 0,
      data: []
    },
    statesDestino: {
      loadingGetAllStates: false,
      totalRecords: 0,
      data: []
    },
    cities: {
      loadingGetAllCities: false,
      totalRecords: 0,
      data: []
    },
  },

  record: {
    upsertRecord: {
      loading: false,
      isSuccess: false,
      err: null,
      data: {},
    },
    getAllRecord: {
      loading: false,
      isSuccess: false,
      err: null,
      data: {},
    },
    getAllRecordDeleted: {
      loading: false,
      isSuccess: false,
      err: null,
      data: {},
    },  
  },

  permission: {
    permissions:  {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },

  },
  
};
