/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Box, MenuItem, createSvgIcon } from '@material-ui/core';
import plus from '../../assets/images/navieras/plus-icon.png'
import '../../styles/tableViewStyles.scss';
import { Button as ButtonD } from '@material-ui/core';
import {
    Button,
    DataTable,
    ActionMenu,
    GlobalFilter,
    Link,
} from '../../components/atoms';
import {
    getAllProvidersAction,
    deleteProviderAction,
} from '../../redux/actions/providerAction';
import Modal from '../../components/organisms/Modal/Modal';
import { CardContainer } from '../../components/molecules/CardContainer/CardContainer';
import { DeleteBody } from '../../components/molecules/CancelFormBody/DeleteBody';
import trash from '../../assets/images/navieras/trash-can.png';
import view from '../../assets/images/navieras/view.png';
import edit from '../../assets/images/navieras/edit-icon.png';

import { DataGrid, GridToolbarContainer, esES, gridSortedRowIdsSelector, useGridApiContext } from '@mui/x-data-grid';



const ProvidersDashboard = (props) => {
    const history = useHistory();
    const [searchObj, setSearchObj] = useState({});
    const [searchCondition, setSearchCondition] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [itemSelected, setItemSelected] = useState(true);
    const getProvider = useSelector(state => state.provider.providers.data)
    const [provider, setProvider] = useState([]);




    useEffect(() => {
        setProvider([])
        getProvider && getProvider.length > 0 && getProvider.map((el) => {
            let sectionCopy = JSON.parse(JSON.stringify(el));
            sectionCopy.id = sectionCopy.id;
            // sectionCopy.expedient.mov = sectionCopy.expedient.mov;
            setProvider((d) => [...d, sectionCopy]);
        })
    }, [getProvider])

    const toggleModal = ({ type, id }) => {
        if (openModal === true) {
            setItemSelected(null)
        } {
            setItemSelected({ type, id })
        }
        setOpenModal(!openModal);
    }

    useEffect(() => {
        props.getAllProvidersAction(searchCondition);
    }, [props.isSuccessDeleteProvider, searchCondition]);

    useEffect(() => {
        if (!_.isEmpty(searchObj)) {
            props.getAllProvidersAction(
                {
                    where: searchObj
                }
            );
        }
    }, [searchObj]);


    // useEffect(()=>{
    //     props.getAllAdministratorsAction(searchCondition);
    // },[itemDeleted, searchCondition]);

    const onClickActionMenu = (actionSelected, payload) => {
        switch (actionSelected) {
            case 'showRecord':
                history.push({
                    pathname: `/dashboard/proveedores/${payload.id}/ver`,
                    state: payload
                })
                break;
            case 'editRecord':
                history.push({
                    pathname: `/dashboard/proveedores/${payload.id}/editar`,
                    state: payload
                })
                break;
            case 'deleteRecord':
                props.deleteProviderAction({ id: payload.id });
                break;
            default:
                break;
        }
    }
    const deleteUser = () => {
        props.deleteProviderAction({ id: itemSelected.id });
        setOpenModal(!openModal);
    }


    const columnsGrid = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            type: 'number',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.id || 'null'}`
        },
        {
            field: 'name',
            headerName: 'Nombre del proveedor',
            headerAlign: 'center',
            width: 280,
            type: 'text',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.name || 'Sin informacion'}`
        },
        {
            field: 'commercialbusiness',
            headerName: 'Giro comercial',
            width: 180,
            headerAlign: 'center',
            aling: 'center',
            type: 'text',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.commercialbusiness || 'Sin informacion'}`


        },
        {
            field: 'contacname',
            headerName: 'Nombre del contacto',
            width: 270,
            headerAlign: 'center',
            aling: 'center',
            type: 'text',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.contacname || 'Sin informacion'}`


        },
        {
            field: 'email',
            headerName: 'Correo',
            width: 300,
            headerAlign: 'center',
            aling: 'center',
            type: 'text',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.email || 'Sin informacion'}`


        },
        {
            field: 'phone',
            headerName: 'Telefono',
            type: 'number',
            width: 120,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            aling: 'center',
            valueGetter: (params) =>
                `${params.row.phone || 'Sin informacion'}`
        },
        {
            field: 'action',
            headerName: 'Acciones',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            width: 115,
            headerAlign: 'center',
            aling: 'center',
            renderCell: (params) => {
                return (
                    <ActionMenu id={params.row.id} >
                        <MenuItem onClick={() => onClickActionMenu('showRecord', params.row)}><img src={view} /> Ver proveedor</MenuItem>
                        <MenuItem onClick={() => onClickActionMenu('editRecord', params.row)}><img src={edit} /> Editar proveedor</MenuItem>
                        <MenuItem onClick={() => toggleModal({ type: 'eliminar', id: params.row.id })}><img src={trash} /> Eliminar proveedor</MenuItem>
                    </ActionMenu>
                );
            },
        }
    ];



    const getNewData = (searchCondition) => {
        setSearchCondition(searchCondition);
    };


    const ExportIcon = createSvgIcon(
        <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
        "SaveAlt"
    );

    const getUnfilteredRows = ({ apiRef }) => gridSortedRowIdsSelector(apiRef);

    const descargarExpedient = () => {
        const apiRef = useGridApiContext();
        const handleExport = (options) => apiRef.current.exportDataAsCsv(options);
        const buttonBaseProps = {
            color: "primary",
            size: "small",
            startIcon: <ExportIcon />,
        };

        return (
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    // marginBottom: "1rem",
                    background: "#000;",

                }}
            >



                <GridToolbarContainer
                    csvOptions={{
                        utf8WithBom: true,
                        fileName: "Custom Agent",
                    }}
                >

                    <ButtonD className='button-exp2'
                        {...buttonBaseProps}
                        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}>
                        {/* <img icon={} className="img" style={{ marginRight: "1rem" }} /> */}
                        Descargar Excel
                    </ButtonD>
                </GridToolbarContainer>
            </div>
        );
    };


    const localizedTextsMap = {

        //Fileters operator text
        columnMenuUnsort: "Desclasificar",
        columnMenuSortAsc: "Ordenar por ASC",
        columnMenuSortDesc: "Ordenar por DESC",
        columnMenuFilter: "Filtrar",
        columnMenuHideColumn: "Esconder",
        columnMenuShowColumns: "Mostrar colunas",
        noRowsLabel: "No existen registros",
        noResultsOverlayLabel: 'No se han encontrado resultados',
        errorOverlayDefaultLabel: 'Ocurrió un error.',

        //Filter operator
        filterPanelColumns: 'Columnas',
        filterPanelOperators: 'Operador',
        filterPanelInputLabel: 'Valor',
        filterPanelInputPlaceholder: 'Valor de filtro',

        // Filter operators text
        filterOperatorContains: 'Contiene',
        filterOperatorEquals: 'Igual',
        filterOperatorStartsWith: 'Comienza con',
        filterOperatorEndsWith: 'Termina con',
        filterOperatorIs: 'Es',
        filterOperatorNot: 'No es',
        filterOperatorAfter: 'Es despues de',
        filterOperatorOnOrAfter: 'Está encendido o después',
        filterOperatorBefore: 'Es antes',
        filterOperatorOnOrBefore: 'Está en o antes',
        filterOperatorIsEmpty: 'Está vacío',
        filterOperatorIsNotEmpty: 'No está vacío',
        filterOperatorIsAnyOf: 'Es cualquiera de',


        // Columns panel text
        columnsPanelTextFieldLabel: 'Buscar columna',
        columnsPanelTextFieldPlaceholder: 'Título de la columna',
        columnsPanelDragIconLabel: 'Reordenar columna',
        columnsPanelShowAllButton: 'Mostrar todo',
        columnsPanelHideAllButton: 'Ocultar todo',
    };

    return (
        <>
            {
                openModal && itemSelected.type === 'eliminar' &&
                <Modal
                    isOpen={toggleModal}
                    toggle={toggleModal}
                >
                    <CardContainer btnLeftAction={toggleModal} btnRightAction={() => deleteUser()} body={<DeleteBody user={'proveedor'} />} />
                </Modal>
            }
            <section className='ClientRecordAccountSection' >
                <div className='ClientRecordAccountSection__container'>
                    <div className='ClientRecordAccountSection__headerContainer'>
                        <div className='HomeTableSection__containerFilters'>
                            <GlobalFilter
                                filter={searchObj}
                                setFilter={setSearchObj}
                                showTextSearch
                                showDateRangeSearch={false}
                                placeholderSearch={'Buscar ID...'}
                            />
                        </div>
                        <div className='HomeTableSection__containerButtons'>
                            <Link
                                className={'linkClient'}
                                to={`/dashboard/proveedores/crear`}>
                                <Button className={'containerButtons__clientButton'} srcicon={plus}>CREAR</Button>
                            </Link>
                        </div>
                    </div>



                    <div className='ClientRecordAccountSection__tableContainer'>
                        <Box sx={{
                            height: 390,
                            width: '100%',
                            '& .super-app-theme--header': {
                                backgroundColor: '#707070',
                                color: '#ffff',
                                fontFamily: 'Montserrat',
                                fontSize: '.9vw',
                            },
                        }}>

                            <div style={{ height: "100%", width: "100%", overflow: 'auto'}}>


                                <DataGrid
                                    sx={{
                                        '@media print': {
                                            '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)' },
                                        }
                                    }}
                                    localeText={localizedTextsMap}
                                    rows={provider}
                                    columns={columnsGrid}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    components={{ Toolbar: descargarExpedient }}
                                    checkboxSelection={false}
                                    disableSelectionOnClick
                                    experimentalFeatures={{ newEditingApi: true }}
                                    scrollbarSize={10}
                                    pageSizeOptions={[5, 10, 25]}
                                />

                            </div>
                        </Box>

                    </div>

                </div>
            </section >
        </>
    );
}

ProvidersDashboard.propTypes = {
    history: PropTypes.any,
    dispatch: PropTypes.any,
    getAllProvidersAction: PropTypes.any,
    providers: PropTypes.any,
    deleteProviderAction: PropTypes.any,
    isSuccessDeleteProvider: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
};

function mapStateToProps(state) {
    return {
        providers: _.get(state, 'provider.providers', {}),
        isSuccessDeleteProvider: _.get(state, 'provider.deleteProvider.isSuccess', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    getAllProvidersAction: params => dispatch(getAllProvidersAction(params)),
    deleteProviderAction: params => dispatch(deleteProviderAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProvidersDashboard);
