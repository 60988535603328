import {
    PORT_MODULE_GET_ALL_PORTS_BEGIN,
    PORT_MODULE_GET_ALL_PORTS_SUCCESS,
    PORT_MODULE_GET_ALL_PORTS_FAILURE,
    PORT_MODULE_UPSERT_PORT_BEGIN,
    PORT_MODULE_UPSERT_PORT_SUCCESS,
    PORT_MODULE_UPSERT_PORT_FAILURE,
    PORT_MODULE_DELETE_PORT_BEGIN,
    PORT_MODULE_DELETE_PORT_SUCCESS,
    PORT_MODULE_DELETE_PORT_FAILURE,
    PORT_MODULE_GET_ALL_PORTS_CLIENT_BEGIN,
    PORT_MODULE_GET_ALL_PORTS_CLIENT_SUCCESS,
    PORT_MODULE_GET_ALL_PORTS_CLIENT_FAILURE,
    GET_ALL_PORTS_AVAILABLE_BEGIN,
    GET_ALL_PORTS_AVAILABLE_SUCCESS,
    GET_ALL_PORTS_AVAILABLE_FAILURE,
} from '../actionTypes';

import initialState from '../initialState';

export default function portReducer(state = initialState.port, action) {
    switch (action.type) {
        case PORT_MODULE_GET_ALL_PORTS_BEGIN:
            return {
                ...state,
                ports: {
                    ...state.ports,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    totalRecords: 0,
                    data: [],
                },
            };
        case PORT_MODULE_GET_ALL_PORTS_SUCCESS:
            return {
                ...state,
                ports: {
                    ...state.ports,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    totalRecords: action.totalRecords,
                    data: action.data,
                },
            };
        case PORT_MODULE_GET_ALL_PORTS_FAILURE:
            return {
                ...state,
                ports: {
                    ...state.ports,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
        case PORT_MODULE_UPSERT_PORT_BEGIN:
            return {
                ...state,
                upsertPort: {
                    ...state.upsertPort,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: {},
                },
            };
        case PORT_MODULE_UPSERT_PORT_SUCCESS:
            return {
                ...state,
                upsertPort: {
                    ...state.upsertPort,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.port,
                },
            };
        case PORT_MODULE_UPSERT_PORT_FAILURE:
            return {
                ...state,
                upsertPort: {
                    ...state.upsertPort,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: {},
                },
            };
        case PORT_MODULE_DELETE_PORT_BEGIN:
            return {
                ...state,
                deletePort: {
                    ...state.deletePort,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: null,
                },
            };
        case PORT_MODULE_DELETE_PORT_SUCCESS:
            return {
                ...state,
                deletePort: {
                    ...state.deletePort,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.port,
                },
            }
        case PORT_MODULE_DELETE_PORT_FAILURE:
            return {
                ...state,
                deletePort: {
                    ...state.deletePort,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: null,
                },
            }
        case PORT_MODULE_GET_ALL_PORTS_CLIENT_BEGIN:
            return {
                ...state,
                portsClient: {
                    ...state.portsClient,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    totalRecords: 0,
                    data: [],
                },
            };
        case PORT_MODULE_GET_ALL_PORTS_CLIENT_SUCCESS:
            return {
                ...state,
                portsClient: {
                    ...state.portsClient,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    totalRecords: action.totalRecords,
                    data: action.data,
                },
            };
        case PORT_MODULE_GET_ALL_PORTS_CLIENT_FAILURE:
            return {
                ...state,
                portsClient: {
                    ...state.portsClient,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
        case GET_ALL_PORTS_AVAILABLE_BEGIN:
            return {
                ...state,
                portsAvailable: {
                    ...state.portsAvailable,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    totalRecords: 0,
                    data: [],
                },
            };

        case GET_ALL_PORTS_AVAILABLE_SUCCESS:
            return {
                ...state,
                portsAvailable: {
                    ...state.portsAvailable,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    totalRecords: action.totalRecords,
                    data: action.data,
                },
            };

        case GET_ALL_PORTS_AVAILABLE_FAILURE:
            return {
                ...state,
                portsAvailable: {
                    ...state.portsAvailable,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };

        default:
            return state;
    }
}