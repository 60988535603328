/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button } from './../../../components/atoms';
import '../../../styles/stylesSteppers.scss';
import arrow from './../../../assets/images/navieras/arrowButton.png'
import { useHistory, useLocation } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import './RecordsData.scss';

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCustomAgentsAction } from '../../../redux/actions/customsAgentAction';
import { async } from 'q';
import { upsertExpedient } from '../../../redux/actions/recordAction';
import { getAllPortsClientAction } from '../../../redux/actions/portAction';
import Modal from '../../../components/organisms/Modal/Modal';
import { BaseModalBody } from '../../../components/molecules/ModalBody/BaseModalBody';
import { cancelUploadOfDocs, noDocumentsUploded } from '../../../components/molecules/ModalBody/modalBodyVaribles';
import { RECORDS } from '../../../routes/Paths';
import { CardContainer } from '../../../components/molecules/CardContainer/CardContainer';

const DocumentsAndCustomAgent = ({ setStepper, stepper, customsPorts = [''], formData, setFormData }) => {
    const [status, setStatus] = useState(null);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const agents = useSelector(state => state.customAgent.customAgents)
    const availableAgents = []

    const [openModal, setOpenModal] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);


    useEffect(() => {
        dispatch(getAllCustomAgentsAction({ "offset": 0, "limit": 500 }))
        dispatch(getAllPortsClientAction({ clientId: location && location.payload.row.quotation.clientId }))
    }, [])


    const customAgentLocation = _.get(location, 'payload.row.customDocuments.customAgentId')
    const customAgentIdDefault = _.get(formData, 'customAgentId', '');
    const polDefault = _.get(location, 'payload.row.customDocuments.pol', '');
    const podDefault = _.get(location, 'payload.row.customDocuments.pod', '');
    const [checkedHbl, setCheckedHbl] = useState(formData.customAndFiles ? formData.customAndFiles.hbl : location.payload.row.customDocuments.hbl);
    const [checkedMbl, setCheckedMbl] = useState(formData.customAndFiles ? formData.customAndFiles.mbl : location.payload.row.customDocuments.mbl);
    const [checkedEir, setCheckedEir] = useState(formData.customAndFiles ? formData.customAndFiles.eir : location.payload.row.customDocuments.eir);
    const [checkedTelex, setCheckedTelex] = useState(formData.customAndFiles ? formData.customAndFiles.telex : location.payload.row.customDocuments.telex);
    const [checkedRelease, setCheckedRelease] = useState(formData.customAndFiles ? formData.customAndFiles.release : location.payload.row.customDocuments.release);
    const [checkedInsurancePolice, setCheckedInsurancePolice] = useState(formData.customAndFiles ? formData.customAndFiles.insurancePolice : location.payload.row.customDocuments.insurancePolice);
    const [checkedCourtDelays, setCheckedCourtDelays] = useState(formData.customAndFiles ? formData.customAndFiles.courtDelays : location.payload.row.customDocuments.courtDelays);
    const [checkedOtherFile, setCheckedOtherFile] = useState(formData.customAndFiles ? formData.customAndFiles.otherFile : location.payload.row.customDocuments.otherFile);


    const defaultValues = {
        hbl: checkedHbl,
        mbl: checkedMbl,
        eir: checkedEir,
        telex: checkedTelex,
        release: checkedRelease,
        insurancePolice: checkedInsurancePolice,
        courtDelays: checkedCourtDelays,
        otherFile: checkedOtherFile,
        customAgentId: customAgentIdDefault ? customAgentIdDefault : customAgentLocation,
        pol: polDefault,
        pod: podDefault,
    };

    const methods = useForm({ defaultValues: defaultValues });
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;


    const [data, setData] = useState({
        hbl: checkedHbl,
        mbl: checkedMbl,
        eir: checkedEir,
        telex: checkedTelex,
        release: checkedRelease,
        insurancePolice: checkedInsurancePolice,
        courtDelays: checkedCourtDelays,
        otherFile: checkedOtherFile,
        customAgentId: customAgentIdDefault ? customAgentIdDefault : customAgentLocation,
        pol: polDefault,
        pod: podDefault,
    })


    const noAvailablePorts = [{ port: { portName: 'No hay agentes disponibles' }, id: 0 }];
    let availablePorts;

    if (customsPorts && customsPorts.length > 0) availablePorts = customsPorts;
    else availablePorts = noAvailablePorts;

    if (customsPorts && customsPorts.length > 0) availablePorts = customsPorts;
    else availableAgents = noAvailablePorts;


    const changeStage = () => {
        setStepper(stepper + 1)
    }


    const expedient_ = {
        arrivalPortDate: formData.arrivalPortDate == null ? '' : formData.arrivalPortDate,
        departureDate: formData.departureDate  == null ? '' : formData.departureDate,
        eta:formData.eta  == null ? '' : formData.eta ,
        etd:formData.etd  == null ? '' : formData.etd,
        folioTl:formData.folioTl == null ? '' : formData.folioTl,
        naviera:formData.naviera == null ? '' : formData.naviera,
        noContainers:formData.noContainers == null ? '' : formData.noContainers,
        numberMbl:formData.numberMbl == null ? '' : formData.numberMbl,
        quiantityContainers:formData.quiantityContainers == null ? '' : formData.quiantityContainers,
        status:formData.status == null ? '' : formData.status,
        teus:formData.teus == null ? '' : formData.teus,
    }


    const expedient = JSON.stringify(expedient_)

    const customAgent_ = {
        hbl: checkedHbl,
        mbl: checkedMbl,
        eir: checkedEir,
        telex: checkedTelex,
        release: checkedRelease,
        insurancePolice: checkedInsurancePolice,
        courtDelays: checkedCourtDelays,
        otherFile: checkedOtherFile,
        customAgentId: data.customAgentId == null ? 0 : data.customAgentId,
        pol: location.payload.row && location.payload.row.originPort.id,
        pod: location.payload.row && location.payload.row.destinyPort.id,
    }

    const customAgent = JSON.stringify(customAgent_);

    //CheckBox
    const handleChangeHbl = (event) => {
        setCheckedHbl(event.target.checked);
    };

    const handleChangeMbl = (event) => {
        setCheckedMbl(event.target.checked);
    };

    const handleChangeEir = (event) => {
        setCheckedEir(event.target.checked);
    };

    const handleChangeTelex = (event) => {
        setCheckedTelex(event.target.checked);
    };

    const handleChangeRelease = (event) => {
        setCheckedRelease(event.target.checked);
    };

    const handleChangeInsurance = (event) => {
        setCheckedInsurancePolice(event.target.checked);
    };

    const handleChangeCourtDelays = (event) => {
        setCheckedCourtDelays(event.target.checked);
    };

    const handleChangeOther = (event) => {
        setCheckedOtherFile(event.target.checked);
    };


    const generateExpedient = async () => {

        const formData_ = new FormData()

        formData_.append("id", location.payload.row.expedientId)
        formData_.append("expedient", expedient);
        formData_.append("customAgent", customAgent);
        formData_.append("arrivalDates", '{}');
        formData_.append("paymentDetails", '{}');
        formData_.append("pickupDelivery", '{}');
        formData_.append("delay", '{}');
        formData_.append("route", '{}');
        formData_.append("deviation", '[]');
        formData_.append("tracing", '[]');
        formData_.append("mediasDeleted", '[]');
        formData_.append("File", '[]');


        const res = await dispatch(upsertExpedient(formData_))
        if (res) {
            history.push('/dashboard/expedientes');
        }
    }

    const onSubmit = async ({ pol, pod }) => {

        const dataNew = {
            expedient: {
                ...formData,
            },
            customAndFiles: {
                hbl: checkedHbl,
                mbl: checkedMbl,
                eir: checkedEir,
                telex: checkedTelex,
                release: checkedRelease,
                insurancePolice: checkedInsurancePolice,
                courtDelays: checkedCourtDelays,
                otherFile: checkedOtherFile,
                customAgentId: data.customAgentId,
                pol: location.payload.row && location.payload.row.originPort.id,
                pod: location.payload.row && location.payload.row.destinyPort.id,
            }
        }

        setFormData(dataNew)
        changeStage()
    }

    useEffect(() => {
    }, [formData])

    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    const toggleModal2 = () => {
        setOpenModal2(!openModal);
    }


    const cancel = () => {
        history.push(RECORDS);
    }

    return (
        <>

            {
                openModal &&
                <Modal
                    isOpen={openModal}
                    toggle={toggleModal}
                >
                    <CardContainer
                        btnLeftAction={toggleModal}
                        btnRightAction={cancel}
                        body={<BaseModalBody data={cancelUploadOfDocs} />}
                        btnLeftText={'REGRESAR'}
                    />
                </Modal>
            }
            {
                openModal2 &&
                <Modal
                    isOpen={openModal2}
                    toggle={toggleModal2}
                >
                    <CardContainer
                        btnLeftAction={toggleModal2}
                        btnRightAction={cancel}
                        body={<BaseModalBody data={noDocumentsUploded} />}
                        btnLeftText={'REGRESAR'}
                    />
                </Modal>
            }

            <form className='main-container-generic-type' onSubmit={handleSubmit(onSubmit)}>
                <div className='header-form-generic-type'>
                    <h2>Documentos</h2>
                    <h3>ID:{location.payload.row.id}</h3>
                </div>
                <div className='body-form-generic-type-data record-data records checkboxes' style={{ height: '45vh' }} >
                    <form className='input-container-data records checkboxes'>
                        <div className='input-row-container'>
                            <div className='checkbox-records-container'>
                                <label className='checkbox-records-label'>HBL</label>
                                <Checkbox className={'checkbox-records'}
                                    checked={checkedHbl} onChange={handleChangeHbl} value={checkedHbl} />
                            </div>
                            <div className='checkbox-records-container'>
                                <label className='checkbox-records-label'>MBL</label>
                                <Checkbox className={'checkbox-records'} checked={checkedMbl} onChange={handleChangeMbl} />
                            </div>
                            <div className='checkbox-records-container'>
                                <label className='checkbox-records-label'>EIR</label>
                                <Checkbox className={'checkbox-records'} checked={checkedEir} onChange={handleChangeEir} />
                            </div>
                            <div className='checkbox-records-container'>
                                <label className='checkbox-records-label'>TELEX</label>
                                <Checkbox type={'checkbox'} className={'checkbox-records'} checked={checkedTelex} onChange={handleChangeTelex} />
                            </div>
                            <div className='checkbox-records-container'>
                                <label className='checkbox-records-label'>RELEASE</label>
                                <Checkbox type={'checkbox'} className={'checkbox-records'} checked={checkedRelease} onChange={handleChangeRelease} />
                            </div>
                        </div>
                        <div className='input-row-container'>
                            <div className='checkbox-records-container'>
                                <label className='checkbox-records-label'>Póliza de seguros</label>
                                <Checkbox type={'checkbox'} className={'checkbox-records'} checked={checkedInsurancePolice} onChange={handleChangeInsurance} />
                            </div>
                            <div className='checkbox-records-container'>
                                <label className='checkbox-records-label'>Corte de demoras</label>
                                <Checkbox type={'checkbox'} className={'checkbox-records'} checked={checkedCourtDelays} onChange={handleChangeCourtDelays} />
                            </div>
                            <div className='checkbox-records-container'>
                                <label className='checkbox-records-label'>Otro documento</label>
                                <Checkbox type={'checkbox'} className={'checkbox-records'} checked={checkedOtherFile} onChange={handleChangeOther} />
                            </div>
                        </div>
                    </form>

                    <div className='header-form-generic-type'>
                        <h2>Agente Aduanal</h2>
                    </div>
                    <div className='body-form-generic-type records'>
                        <div className='input-container-data records'>
                            <div className='input-row-container'>
                                <div className='input-container'>
                                    <div className='label-container'>
                                        <label className='container-label'>Agente Aduanal</label>
                                    </div>
                                    <div className='value-container' >
                                        <select className='input-select' value={data.customAgentId} onChange={(e) => setData({ ...data, customAgentId: e.target.value })} >
                                            <option className='option-selected' selected hidden>Seleccionar Agente</option>
                                            {
                                                agents && agents.data.length > 0 &&
                                                agents.data.map((ele, i) => {
                                                    return <option key={i} value={ele.id}> {ele.agentname}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='input-container'>
                                    <div className='input-container'>
                                        <div className='label-container'>
                                            <label className='container-label'>POL</label>
                                        </div>
                                        <div className='value-container' >
                                            <input value={(location && location.payload && location.payload.row && location.payload.row.originPort.port) ? (location && location.payload && location.payload.row && location.payload.row.originPort.port) : "N/A"}
                                                disabled type='text' className='input-generic records' />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='input-row-container'>
                                <div className='input-column-container'>
                                    <div className='input-container'>
                                        <div className='label-container'>
                                            <label className='container-label'>POD</label>
                                        </div>
                                        <div className='value-container' >
                                            <input value={(location && location.payload && location.payload.row && location.payload.row.destinyPort.port) ? (location && location.payload && location.payload.row && location.payload.row.destinyPort.port) : "N/A"}
                                                disabled type='text' className='input-generic records' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='footer-form-generic-type records'>
                    <Button type='button' onClick={toggleModal} className={'button-back'} style={{ width: '28%' }}>CANCELAR</Button>
                    <Button type='button' onClick={() => generateExpedient()} className={'button-generate'} style={{ width: '28%' }}>GUARDAR</Button>
                    <Button type='submit' className={'button-next'} srcicon={arrow} style={{ width: '30%' }}>SIGUIENTE</Button>
                </div>
            </form>
        </>
    )
}

export default DocumentsAndCustomAgent;
