/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { CancelFormBody } from '../../components/molecules/CancelFormBody/CancelFormBody';
import { CardContainer } from '../../components/molecules/CardContainer/CardContainer';
import Modal from '../../components/organisms/Modal/Modal';
import '../../styles/stylesSteppers.scss';
import './OperativeRegister.scss';
import OperativePrincipalData from './OperativePrincipalData';
// import { OperativePrincipalDataForm } from './OperativePrincipalDataForm';
import { OPERATIVES } from '../../routes/Paths';

const OperativeRegister = (props) => {
    const history = useHistory();
    // const currentData = _.get(props, 'location.state', null);

    const [openModal, setOpenModal] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);

    const toggleModal = () => {
        setOpenModal(!openModal);
    }
    
    const cancel = () => {
        history.push(OPERATIVES)
    }

    return (
        <>
            {
                openModal &&
                <Modal
                    isOpen={toggleModal}
                    toggle={toggleModal}
                >
                    <CardContainer btnLeftAction={toggleModal} btnRightAction={cancel} body={<CancelFormBody/>}/>
                </Modal>
            }
            <div className='main-container'>
                <div className='card-container-stepper operative' style={{width: '50%', height: '75%'}}>
                    <OperativePrincipalData toggleModal={toggleModal} />
                </div>
            </div>
        </>
    )
}

export default OperativeRegister;
