/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Modal from '../../components/organisms/Modal/Modal';
import Button from '../../components/atoms/Button.js';
import refresh from '../../assets/images/navieras/refresh.png';
import './RecordRoute.scss';
import RecordDeviations from './RecordModals/RecordDeviations';
import RecordChanges from './RecordModals/RecordChanges';
import RecordDelays from './RecordModals/RecordDelays';
import RecordManualCapture from './RecordModals/RecordManualCapture';
import MapView from '../../components/molecules/Map';
import VerticalStepper from '../../components/Stepper/VerticalStepper';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

const RecordRoute = () => {

    const location = useLocation();
    const history = useHistory();

    const changesNavieraDefault = _.get(location, 'payload.row.route.changeNaviera', null)
    const commentaryDelayDefault = _.get(location, 'payload.row.route.reasonDelay', null)
    const daysDelayDefault = _.get(location, 'payload.row.route.daysLate', null)
    const commentaryDeviationDefault = _.get(location, 'payload.row.route.reasonDeviations', null)

    // const changesOrigin = useSelector(state => state.record.getAllRecord.data)
    // const changesOrigin = useSelector(state => state.data[1].route.changeNaviera)


    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [receptionDate, setReceptionDate] = useState(null);
    const [deliveryDate, setDeliveryDate] = useState('04/11/2022');
    const [originPlace, setOriginPlace] = useState('Ningbo, China');
    const [destinyPlace, setDestinyPlace] = useState('Ensenada, México');

    const [changeNavierasOrigin, setChangeNavierasOrigin] = useState(changesNavieraDefault);
    const [commentaryDelay, setCommentaryDelay] = useState(commentaryDelayDefault)
    const [daysDelay, setDaysDelay] = useState(daysDelayDefault)
    const [commentaryDeviation, setCommentaryDeviation] = useState(commentaryDeviationDefault)

    const [deliveryHistory, setDeliveryHistory] = useState([])

    const [tracing, setTracing] = useState(location.payload.row.route.tracing)
    

    useEffect(() => {
        let routes = [];
        for (let i = 0; i < tracing.length; i++) {
            let value = {
                country: tracing && tracing[i] && tracing[i].country && tracing[i].country.name,
                state: tracing && tracing[i] && tracing[i].state && tracing[i].state.name,
                date: tracing && tracing[i] && tracing[i].date,
                status: tracing && tracing[i] && tracing[i].action,
                lat: tracing && tracing[i] && tracing[i].state && tracing[i].state.latitude,
                lng: tracing && tracing[i] && tracing[i].state && tracing[i].state.longitude,
            }
            routes.push(value)
        }

        setDeliveryHistory([...routes])
    }, [])


    const [lat, setLat] = useState(!_.isEmpty(deliveryHistory) ? deliveryHistory[deliveryHistory.length - 1].lat : '0');
    const [lng, setLng] = useState(!_.isEmpty(deliveryHistory) ? deliveryHistory[deliveryHistory.length - 1].lng : '0');

    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    const selectPosition = (i) => {
        setLat(deliveryHistory[i].lat);
        setLng(deliveryHistory[i].lng);
    }

    let horizontalCheckpointClassName = 'circle-boarder';
    let connectLineClassName = 'line-between';
    if (receptionDate != null) {
        horizontalCheckpointClassName = 'circle-boarder active';
        connectLineClassName = 'line-between active';
    }


    return (
        <>
            {
                openModal &&
                <Modal
                    isOpen={toggleModal}
                    toggle={toggleModal}
                >
                    {
                        modalType == 'deviations' &&
                        <RecordDeviations
                            toggleModal={toggleModal}
                            location={location}
                            commentaryDeviation={commentaryDeviation}
                            setCommentaryDeviation={setCommentaryDeviation}
                        />
                    }
                    {
                        modalType == 'changes' &&
                        <RecordChanges
                            toggleModal={toggleModal}
                            changeNavierasOrigin={changeNavierasOrigin}
                            setChangeNavierasOrigin={setChangeNavierasOrigin}

                        />
                    }
                    {
                        modalType == 'delays' &&
                        <RecordDelays toggleModal={toggleModal}
                            commentaryDelay={commentaryDelay}
                            setCommentaryDelay={setCommentaryDelay}
                            daysDelay={daysDelay}
                            setDaysDelay={setDaysDelay} />
                    }
                    {
                        modalType == 'capture' &&
                        <RecordManualCapture
                            setDeliveryHistory={setDeliveryHistory}
                            deliveryHistory={deliveryHistory}
                            toggleModal={toggleModal}
                            setLatPosition={setLat}
                            setLngPosition={setLng}
                        />
                    }
                </Modal>
            }
            <div className='route-record-general-container'>
                <div className='route-record-map-container'>
                    <MapView position={[lat, lng]} />
                </div>
                <div className='route-record-data-container'>
                    <div className='data-record-card-container'>
                        <div className='header-form-generic-type'>
                            <h2>Ruta</h2>
                            <h4>ID Expediente: {location && location.payload && location.payload.row && location.payload.row.expedientId}</h4>
                        </div>
                        {/* <div className='body-route-declaration-container'>
                            <div className='places-container'>
                                <div className='place-date-container'>
                                    <h4 className='place-text'>{originPlace}</h4>
                                    <p className='date-text'>{deliveryDate}</p>
                                </div>
                                <div className='place-date-container'>
                                    <h4 className='place-text'>{destinyPlace}</h4>
                                    <p className='date-text'>{receptionDate}</p>
                                </div>
                            </div>
                            <div className='circle-pointers-container'>
                                <div className='circle-boarder active'><div className='circle'></div></div>
                                <div className={connectLineClassName}></div>
                                <div className={horizontalCheckpointClassName}><div className='circle'></div></div>
                            </div>
                        </div> */}
                        <div className='buttons-container'>
                            {/* <button className={'refresh-button'}><img src={refresh} /></button> */}
                            <Button onClick={() => { toggleModal(), setModalType('capture') }}>CAPTURAR MANUAL</Button>
                        </div>
                        <VerticalStepper array={deliveryHistory} onClick={selectPosition} />
                        <div className='footer-record-route' style={{ justifyContent: 'space-between' }}>
                            <label>Cambios</label>
                            <div className='button-radio' onClick={() => { toggleModal(), setModalType('changes') }}></div>
                            <label>Desvíos</label>
                            <div className='button-radio' onClick={() => { toggleModal(), setModalType('deviations') }}></div>
                            <label>Retrasos</label>
                            <div className='button-radio' onClick={() => { toggleModal(), setModalType('delays') }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecordRoute;
