/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
// import './Quotation.scss';
import '../../styles/stylesSteppers.scss';
import arrow from '../../assets/images/navieras/arrowButton.png'
import add from '../../assets/images/navieras/AGREGAR.png'
import { useForm } from 'react-hook-form';
import _ from 'lodash'
import { UploadFile } from '../../components/organisms/UploadFile/UploadFile';
import { Button } from '../../components/atoms';
import { upsertQuotationAction } from '../../redux/actions/quotationAction';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';



export const QuotationDocuments = ({
    stepper,
    setStepper,
    toggleModal,
    formData,
    setFormData,
    FormClassName,
    onlyDocs,

    documents = [],
}) => {

    const methods = useForm({ defaultValues: defaultValues });
    const defaultValues = {
        // name : '',
    };
    const { id } = useParams();
    const location = useLocation();
    const typeAction = useSelector((state) => state.router.location.hash)
    const history = useHistory();
    const dispatch = useDispatch();



    const {
        clientId,
        modalityTypeId,
        providerId,
        serviceExport,
        serviceImport,
        serviceNacional,
    } = formData ? formData : location.payload ? location.payload : {};


    const quotationD = {
        clientId,
        modalityTypeId,
        providerId,
        serviceExport,
        serviceImport,
        serviceNacional,
    }

    const quotation = JSON.stringify(quotationD);


    // const quotation_ = {}

    const {
        agency,
        bussines,
        cbms,
        coordination,
        countryDestiny,
        countryOrigin,
        delivery,
        destinyCountryId,
        destinyLandFreight,
        destinyLocalChargesBl,
        destinyLocalChargesCbm,
        destinySeaFreight,
        destinyStateId,
        factor,
        insurance,
        invoice,
        modality,
        name,
        officeMx,
        originCountryId,
        originLandFreight,
        originLocalCharges,
        originSeaFreight,
        originStateId,
        quotationId,
        stateDestiny,
        stateOrigin,
        total,
        updatedAt,
        validity,
        weight,
        dropOff,
        landFreight,
        maneuvers,
        overweight,
        platform,
        service,
        aditionalService,
        containerType,
        containersNumber,
        delayDays,
        deletedAt,
        destinyLocalChargesContainer,
        destinyPortId,
        fcl,
        intercom,
        lcl,
        officeMxTlp,
        originCharges,
        originPortId,
        other,
        pickup,
        servicest3,
        storageDays,
    } = modalityTypeId == 1 && !formData ? location.payload.modalityAir
            : modalityTypeId == 2 && !formData ? location.payload.modalityGround
                : modalityTypeId == 3 && !formData ? location.payload.modalityMarine
                    : modalityTypeId == 4 && !formData ? location.payload.modalityMarine : {}







    const quotation_ = {
        agency,
        bussines,
        cbms,
        coordination,
        countryDestiny,
        countryOrigin,
        delivery,
        destinyCountryId,
        destinyLandFreight,
        destinyLocalChargesBl,
        destinyLocalChargesCbm,
        destinySeaFreight,
        destinyStateId,
        factor,
        id,
        insurance,
        invoice,
        modality,
        name,
        officeMx,
        originCountryId,
        originLandFreight,
        originLocalCharges,
        originSeaFreight,
        originStateId,
        quotationId,
        stateDestiny,
        stateOrigin,
        total,
        updatedAt,
        validity,
        weight,
        dropOff,
        landFreight,
        maneuvers,
        overweight,
        platform,
        service,
        aditionalService,
        containerType,
        containersNumber,
        delayDays,
        deletedAt,
        destinyLocalChargesContainer,
        destinyPortId,
        fcl,
        intercom,
        lcl,
        officeMxTlp,
        originCharges,
        originPortId,
        other,
        pickup,
        servicest3,
        storageDays,
    }


    const quotationData = JSON.stringify(quotation_);



    const quotationDataCreate = formData && formData.quotationData ? JSON.stringify(formData.quotationData) : [];


    const { register, handleSubmit, watch } = useForm();
    const [mediasDeleted, setMediasDeleted] = useState([]);

    const changeStage = () => {
        setStepper(stepper + 1)
    }
    const changeStageBack = () => {
        history.push('/dashboard/cotizacion')
        //     history.push({
        //         pathname: `/dashboard/cotizacion/${location.payload.id}/ver`,
        //         state: location.payload
        //  })
    }

    // const selectFile = () => {
    //     const inputFile = document.getElementById('documentMedia');
    //         inputFile.click();
    //     }


    const [watchDocumentMedia, setWatchDocumentMedia] = useState(false);
    const [last, setLast] = useState(null);
    const [files, setFiles] = useState(
        [
            {
                name: 'RFC',
                id: 0,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'Situación fiscal',
                id: 1,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'INE, pasaporte, cédula profesional',
                id: 2,
                file: null,
                deletedAt: false,
                watch: false
            },
        ]
    )

    useEffect(() => {
        if (documents.length > 0) {
            let newFiles = documents.map((el) => {
                return {
                    id: el.id,
                    file: {
                        url: el.file.url,
                        name: el.file.url,
                        deletedAt: el.deletedAt,
                    },
                    watch: true
                }
            })
            setFiles(newFiles)
        }
    }, [])

    const [fileToSend, setFileToSend] = useState();
    useEffect(() => {
        let file = [];
        files.forEach((ele) => {
            if (!_.isNil(ele.file)) {
                file.push(ele.file)
            }
        })
        setFileToSend(file)
    }, [files])


    useEffect(() => {
        let final = [...files];
        setLast(final.pop())
    }, [files])

    const data = [
        { name: 'RFC' }, { name: 'Situacion  Fiscal' }, { name: 'INE, pasaporte, cédula profesional' }
    ]


    const handleAdd = () => {
        let arrayCopy = JSON.parse(JSON.stringify(files));
        let lastElement = files.pop();
        arrayCopy.push(
            {
                name: 'Archivo',
                id: lastElement.id + 1,
                file: null,
                watch: false,
                deletedAt: false,
                newAdd: true,
            }
        )
        setFiles(arrayCopy);
    }

    const submitData = async () => {


        if (_.isEmpty(files)) { toggleModalNoFiles() }

        const formData_ = new FormData();

        formData_.append("quotation", quotation);
        formData_.append("quotationData", formData ? quotationDataCreate : quotationData);

        if (typeAction != '#/dashboard/cotizaciones/crear') formData_.append('id', JSON.stringify(formData ? formData.id : location.payload.id));

        formData_.append("mediasDeleted", JSON.stringify(mediasDeleted));

        const arrFiles = files.map(file => file.file != null ? file : false).filter(file => file != false)
        if(arrFiles.length > 0){
            for (let i = 0; i < arrFiles.length; i++) {
                if (arrFiles[i].newAdd) {
                formData_.append("File", arrFiles[i].file);
                }
            }
        }else{
            formData_.append("File", '[]');
        }

        if (!formData_.has("File")) {
            formData_.append("File", '[]');
        }


        const res = await dispatch(upsertQuotationAction(formData_))
        if (res) {
            history.push('/dashboard/cotizacion');
        }




    }

    return (
        <form className={`main-container-generic-type ${FormClassName}`}>
            <div className='header-form-generic-type'>
                <h2>Documentos</h2>
            </div>
            <div className='body-form-generic-type-docs'>
                {
                    files.map((ele, i) => {
                        return (
                            <UploadFile
                                key={i}
                                files={ele}
                                last={last}
                                setFiles={setFiles}
                                arrayComplete={files}
                                hideTitle={true}
                                setArrayDeleted={setMediasDeleted}
                                arrayDeleted={mediasDeleted}
                            />
                        )
                    })
                }
                <div className='add-button-container' style={{ justifyContent: 'center' }}>
                    <img className='add-card' src={add} onClick={handleAdd} />
                </div>
            </div>
            <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                <Button type='button' onClick={changeStageBack} className={'button-back generate'} style={{ width: '30%' }}>{onlyDocs ? 'REGRESAR' : 'CANCELAR'}</Button>
                {/* {
                    !onlyDocs &&
                    <Button type='button' onClick={toggleModal} className={'button-generate'} style={{ width: '30%' }}>GENERAR EXPEDIENTE</Button>
                } */}
                <Button type='button' onClick={submitData} className={'button-next'} /* srcicon={arrow} */ style={{ width: '30%' }}>GUARDAR</Button>
            </div>
        </form>
    )
}
