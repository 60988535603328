import React from 'react';
import PropTypes from 'prop-types';

const SimpleFooter = (props) => {
    return (
        <>
            <p>{props.text}</p>
        </>
    );
}

SimpleFooter.propTypes = {
    text: PropTypes.string,
}

export default SimpleFooter;
