import React, { useEffect, useState } from 'react';
import './QuotationForms.scss';
import {
    modality,
    delivery,
    deliveryFCL,
    containerType,
    containerTypeGround,
    service,
    sino,
    intercom,
    aditionalServices,
} from './optionsQuotation';
import { getAllCountriesAction, getAllStatesAction } from '../../redux/actions/addressAction';
import { useDispatch, useSelector } from 'react-redux';

const MaritimeLCLQuotationForm = ({ principalDataMaritimeForm, setPrincipalDataMaritimeForm, countriesSelect, clientPorts }) => {

    const countries = useSelector(state => state.address.countries.data);
    const statesOrigen = useSelector(state => state.address.statesOrigen.data);
    const statesDestino = useSelector(state => state.address.statesDestino.data);
    const [disabled, setDisabled] = useState(true);
    const [disabledDelivery, setDisabledDelivery] = useState(false);
    const [disabledHarvest, setDisabledHarvest] = useState(false);
    const dispatch = useDispatch();
    const [factor, setFactor] = useState();
    const client = useSelector(state => state.client.getOneClients);
    const tradename = _.get(client, 'data[0].tradename', '')


    const changeData = (ele, value) => {
        const data = {
            ...principalDataMaritimeForm,
            "lcl": true,
            "fcl": false,
            [value]: ele,
        }
        setPrincipalDataMaritimeForm(data)
    }
    const [totalSuma, setTotalSuma] = useState(0)


    
    useEffect(() => {
        let suma = parseInt(principalDataMaritimeForm.originCharges) + parseInt(principalDataMaritimeForm.destinyCharges) + parseInt(principalDataMaritimeForm.destinyLocalChargesBl) + parseInt(principalDataMaritimeForm.destinyLocalChargesCbm);
        if (principalDataMaritimeForm.originCharges != '' && principalDataMaritimeForm.destinyCharges != '' && principalDataMaritimeForm.destinyLocalChargesCbm != '' && principalDataMaritimeForm.destinyLocalChargesBl != '') {
            setTotalSuma(suma)
        } else {
            setTotalSuma(0)
        }
        //  + parseInt(principalDataMaritimeForm.destinyCharges) + parseInt(principalDataMaritimeForm.destinyLocalChargesContainer) + parseInt(principalDataMaritimeForm.destinyLocalChargesBl))


    }, [principalDataMaritimeForm])



    useEffect(() => {
        const cmbs = parseFloat(principalDataMaritimeForm.cbms);
        const weight = parseFloat(principalDataMaritimeForm.weight);
        
        if (cmbs > weight) {
            setFactor(cmbs)
        }else if (weight > cmbs){
            setFactor( weight)
        }
    //  + parseInt(principalDataMaritimeForm.destinyCharges) + parseInt(principalDataMaritimeForm.destinyLocalChargesContainer) + parseInt(principalDataMaritimeForm.destinyLocalChargesBl))


}, [principalDataMaritimeForm])


    useEffect(() => {
    }, [principalDataMaritimeForm])
useEffect(() => {
    dispatch(getAllCountriesAction());
    if (_.get(principalDataMaritimeForm, "id")) {
        dispatch(getAllStatesAction(
            {
                where: {
                    countryId: principalDataMaritimeForm.originCountryId
                }, target: "Origen",
            }
        ));
        dispatch(getAllStatesAction(
            {
                where: {
                    countryId: principalDataMaritimeForm.destinyCountryId
                }, target: "Destino",
            }
        ));
    }
}, [])


useEffect(() => {
    if (principalDataMaritimeForm.modality === "SD-CY") {
        setDisabledDelivery(true)
        setDisabledHarvest(false)
    } else if (principalDataMaritimeForm.modality === "SD-SD") {
        setDisabledDelivery(false)
        setDisabledHarvest(false)
    } else if (principalDataMaritimeForm.modality === "CY-CY") {
        setDisabledDelivery(true)
        setDisabledHarvest(true)
    } else if (principalDataMaritimeForm.modality === "CY-SD") {
        setDisabledDelivery(false)
        setDisabledHarvest(true)
    }
}, [principalDataMaritimeForm.modality])


return (
    <div className='quotation-form-general-container'>
        <div className='value-container-principal-data'>
            <p className='data-section-title'>Datos principales</p>
            <div className='data-section-container'>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Folio</label>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic"
                            type="text"
                            placeholder="Agregar"
                            value={principalDataMaritimeForm.invoice}
                            onChange={(e) => changeData(e.target.value, "invoice")}
                        />
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Nombre</label>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic"
                            type="text"
                            placeholder="Agregar"
                            value={principalDataMaritimeForm.name}
                            onChange={(e) => changeData(e.target.value, "name")}
                        />
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Empresa*</label>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic"
                            type="text"
                            placeholder="Agregar"
                            value={tradename && tradename}
                            onChange={(e) => changeData(e.target.value, "bussines")}
                        />
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Incoterm*</label>
                    </div>
                    <div className='value-container-select' >
                        <select className='input-select' value={principalDataMaritimeForm.intercom} onChange={(e) => changeData(e.target.value, "intercom")}>
                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                            {
                                intercom &&
                                intercom.map((ele, i) => {
                                    return <option key={i} value={ele.value}>{ele.value}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Modalidad*</label>
                    </div>
                    <div className='value-container-select' >
                        <select className='input-select' value={principalDataMaritimeForm.modality} onChange={(e) => changeData(e.target.value, "modality")}>
                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                            {
                                modality &&
                                modality.map((ele, i) => {
                                    return <option key={i} value={ele.name}>{ele.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Entrega*</label>
                    </div>
                    <div className='value-container-select' >
                        <select className='input-select' value={principalDataMaritimeForm.delivery} onChange={(e) => changeData(e.target.value, "delivery")} disabled={disabledDelivery}>
                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                            {
                                delivery &&
                                delivery.map((ele, i) => {
                                    return <option key={i} value={ele.name}>{ele.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Recoleccion</label>
                    </div>
                    <div className='value-container-select' >
                        <select className='input-select' value={principalDataMaritimeForm.pickup} onChange={(e) => changeData(e.target.value, "pickup")} disabled={disabledHarvest}>
                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                            {
                                deliveryFCL &&
                                deliveryFCL.map((ele, i) => {
                                    return <option key={i} value={ele.name}>{ele.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>CBM*</label>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic"
                            type="number"
                            placeholder="Agregar"
                            value={principalDataMaritimeForm.cbms}
                            onChange={(e) => changeData(e.target.value, "cbms")}
                        />
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Tonelada*</label>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic"
                            type="number"
                            placeholder="Agregar"
                            value={principalDataMaritimeForm.weight}
                            onChange={(e) => changeData(e.target.value, "weight")}
                        />
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Factor a usar*</label>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic"
                            type="text"
                            placeholder="Agregar"
                            value={factor}
                            onChange={(e) => changeData(e.target.value, "factor")}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='value-container-origin-data'>
            <p className='data-section-title'>Datos de origen</p>
            <div className='data-section-container'>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>País*</label>
                    </div>
                    <div className='value-container-select' >
                        <select className='input-select' value={principalDataMaritimeForm.originCountryId} onChange={(e) => {
                            changeData(parseInt(e.target.value), "originCountryId");
                            dispatch(getAllStatesAction(
                                {
                                    where:
                                    {
                                        countryId: parseInt(e.target.value)
                                    },
                                    target: "Origen",
                                }
                            ));
                        }}>
                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                            {
                                countriesSelect &&
                                countriesSelect.map(ele => {
                                    return <option value={ele.countryId}>{ele.countryName}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Estado*</label>
                    </div>
                    <div className='value-container-select' >
                        <select className='input-select' value={principalDataMaritimeForm.originStateId} onChange={(e) => changeData(parseInt(e.target.value), "originStateId")}>
                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                            {
                                _.get(statesOrigen, 'data') &&
                                statesOrigen.data.map(ele => {
                                    return <option value={ele.id}>{ele.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>POL*</label>
                    </div>
                    <div className='value-container-select' >
                        <select className='input-select' value={principalDataMaritimeForm.originPortId} onChange={(e) => changeData(parseInt(e.target.value), "originPortId")}>
                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                            {
                                _.get(clientPorts, 'data') &&
                                clientPorts.data.map((ele, i) => {
                                    if (principalDataMaritimeForm.originCountryId === ele.countryId) {
                                        return <option key={i} value={ele.portId}>{ele.portName}</option>
                                    }
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Cargos en origen</label>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic"
                            type="number"
                            placeholder="Agregar"
                            value={principalDataMaritimeForm.originCharges}
                            onChange={(e) => changeData(e.target.value, "originCharges")}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='value-container-destiny-data'>
            <p className='data-section-title'>Datos de destino</p>
            <div className='data-section-container'>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>País*</label>
                    </div>
                    <div className='value-container-select' >
                        <select className='input-select' value={principalDataMaritimeForm.destinyCountryId} onChange={(e) => {
                            changeData(parseInt(e.target.value), "destinyCountryId");
                            dispatch(getAllStatesAction(
                                {
                                    where:
                                    {
                                        countryId: parseInt(e.target.value)
                                    },
                                    target: "Destino",
                                }
                            ));
                        }}>
                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                            {
                                countriesSelect && countriesSelect.map(ele => {
                                    return <option value={ele.countryId}>{ele.countryName}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Estado*</label>
                    </div>
                    <div className='value-container-select' >
                        <select className='input-select' value={principalDataMaritimeForm.destinyStateId} onChange={(e) => changeData(parseInt(e.target.value), "destinyStateId")}>
                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                            {
                                _.get(statesDestino, 'data') &&
                                statesDestino.data.map(ele => {
                                    return <option value={ele.id}>{ele.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>POD*</label>
                    </div>
                    <div className='value-container-select' >
                        <select className='input-select' value={principalDataMaritimeForm.destinyPortId} onChange={(e) => changeData(parseInt(e.target.value), "destinyPortId")}>
                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                            {
                                _.get(clientPorts, 'data') &&
                                clientPorts.data.map((ele, i) => {
                                    if (principalDataMaritimeForm.destinyCountryId === ele.countryId) {
                                        return <option key={i} value={ele.portId}>{ele.portName}</option>
                                    }
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Cargos en destino*</label>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic"
                            type="number"
                            placeholder="Agregar"
                            value={principalDataMaritimeForm.destinyCharges}
                            onChange={(e) => changeData(e.target.value, "destinyCharges")}
                        />
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Flete Marítimo*</label>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic"
                            type="text"
                            placeholder="Agregar"
                            value={principalDataMaritimeForm.destinySeaFreight}
                            onChange={(e) => changeData(e.target.value, "destinySeaFreight")}
                        />
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Flete Terrestre</label>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic"
                            type="text"
                            placeholder="Agregar"
                            value={principalDataMaritimeForm.destinyLandFreight}
                            onChange={(e) => changeData(e.target.value, "destinyLandFreight")}
                        />
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Cargos locales por BL*</label>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic"
                            type="number"
                            placeholder="Agregar"
                            value={principalDataMaritimeForm.destinyLocalChargesBl}
                            onChange={(e) => changeData(e.target.value, "destinyLocalChargesBl")}
                        />
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Cargos locales por CBM*</label>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic"
                            type="number"
                            placeholder="Agregar"
                            value={principalDataMaritimeForm.destinyLocalChargesCbm}
                            onChange={(e) => changeData(e.target.value, "destinyLocalChargesCbm")}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='value-container-other'>
            <p className='data-section-title'>Otros</p>
            <div className='data-section-container'>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Coordinación*</label>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic"
                            type="text"
                            placeholder="Agregar"
                            value={principalDataMaritimeForm.coordination}
                            onChange={(e) => changeData(e.target.value, "coordination")}
                        />
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Despacho en México</label>
                    </div>
                    <div className='value-container-select' >
                        <select className='input-select' value={principalDataMaritimeForm.officeMx} onChange={(e) => {
                            changeData(e.target.value, "officeMx");
                            e.target.value === 'true' ? setDisabled(false) : setDisabled(true)
                        }}>
                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                            {
                                sino &&
                                sino.map((ele, i) => {
                                    return <option key={i} value={ele.value}>{ele.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Agencia</label>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic"
                            type="text"
                            placeholder="Agregar"
                            disabled={disabled}
                            value={principalDataMaritimeForm.agency}
                            onChange={(e) => changeData(e.target.value, "agency")}
                        />
                    </div>
                </div>
                <div className='input-container-form'>
                    <div className='label-container'>
                        <label className='container-label'>Seguro</label>
                    </div>
                    <div className='value-container-select' >
                        <select className='input-select' value={principalDataMaritimeForm.insurance} onChange={(e) => changeData(e.target.value, "insurance")}>
                            <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                            {
                                sino &&
                                sino.map((ele, i) => {
                                    return <option key={i} value={ele.value}>{ele.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div className='value-container-total-amount'>
            <div className='input-container-total'>
                <div className='label-container'>
                    <p className='container-label'>Total $</p>
                </div>
                <div className='value-container'>
                    <input
                        className="input-generic"
                        type="text"
                        placeholder="$"
                        value={totalSuma.toString()}
                        onChange={(e) => setTotalSuma(parseInt(e.target.value))}
                    />
                </div>
            </div>
            <div className='input-container-total'>
                <div className='label-container'>
                    <p className='container-label'>Vigencia</p>
                </div>
                <div className='value-container'>
                    <input
                        className="input-generic date"
                        type="date"
                        min={'2023-05-12'}
                        max='2050-12-31'
                        value={principalDataMaritimeForm.validity}
                        onChange={(e) => changeData(e.target.value, "validity")}
                    />
                </div>
            </div>
        </div>
    </div>
);
}

export default MaritimeLCLQuotationForm;
