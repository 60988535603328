/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import _ from 'lodash';
import { Button } from '../../components/atoms';
// import './Quotation.scss';
import '../../styles/stylesSteppers.scss';
import '../ProvidersDashboard/ProviderPrincipalData.scss';
import './OperativePrincipalDataForm.scss'
import '../../components/templates/BaseLayout/Profile/ProfileFormStyles.scss';
import file from '../../assets/images/navieras/upload-file.png';
import profile from '../../assets/images/perfil.png';
// import arrow from '../../assets/images/navieras/arrowButton.png'
import { OPERATIVES } from '../../routes/Paths';
import { upsertOperativeAction } from '../../redux/actions/operativeAction';
import { addNotification } from '../../redux/actions/notificationAction';
import { updatePasswordRequestAction } from '../../redux/actions/userActions';


const OperativePrincipalData = (props, {toggleModal, setValue}) => {

    let { userId } = useParams();
    const dispatch = useDispatch();
    //ADD IMG
    const [imageSrc, setImageSrc] = useState(profile);
    const [fileUrl, setFileUrl] = useState([]);
    const [imgBase64, setImgBase64] = useState([]);
    
    const createOperativeSuccess = useSelector(state => state.operative.upsertOperative.isSuccess);

    //ESTE ES EL QUE SE VA A RECIBIR POR PARAMS
    const mainData = _.get(props, 'data', null);
    const emailDefault = _.get(mainData, 'email', '')
    const firstNameDefault = _.get(mainData, 'person.firstName', '')
    const firstLastNameDefault = _.get(mainData, 'person.firstLastName', '')
    const positionDefault = _.get(mainData, 'administrator.job', '')
    const startsHourAtDefault = _.get(mainData, 'administrator.workHourStartAt', '')
    const endsHourAtDefault = _.get(mainData, 'administrator.workHourEndAt', '')
    const dateOfAdmissionTLPDefault = _.get(mainData, 'administrator.dateOfAdmissionTLP', null)
    const userRolDefault =  _.get(mainData, 'administrator.isSuperAdmin') === true ? 1 : 2;
    const userRolNameDefault = userRolDefault===1 ? 'Super admin' : 'Admin';
    
    const [fechaIngresoDefault, setFechaIngresoDefault] = useState(null);
    const [ dateOfAdmissionTLP, setDateOfAdmissionTLP ] = useState();
    const fechaIngreso = fechaIngresoDefault ? moment(fechaIngresoDefault).format('yyyy-MM-DD') : moment(new Date()).format('yyyy-MM-DD') ;
   
    const defaultValues = {
        name : firstNameDefault,
        lastName: firstLastNameDefault,
        position: positionDefault,
        email: emailDefault,
        dateOfAdmissionTLP: dateOfAdmissionTLPDefault
    };
    const methods = useForm({ defaultValues: defaultValues });
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;

    const imgDefault = 0;
    const id = userId;
    const [disabled, setDisabled]= useState(id ? true : false);

    useEffect(() => {
        if (mainData) {
            let sessiones = _.get(mainData, 'administrator');
            setHoraInicio(startsHourAtDefault);
            setHoraFin(endsHourAtDefault);
            let semana=[];
            if (sessiones.workInMonday === true) {
                semana.push({
                    dia:'L',
                    id:1,
                    selected:true
                })
            }else{
                semana.push({
                    dia:'L',
                    id:1,
                    selected:false
                }
            )}
            if (sessiones.workInTuesday === true) {
                semana.push({
                    dia:'M',
                    id:2,
                    selected:true
                })
            }else{
                semana.push({
                    dia:'M',
                    id:2,
                    selected:false
                }
            )}
            if (sessiones.workInWednesday === true) {
                semana.push({
                    dia:'M',
                    id:3,
                    selected:true
                })
            }else{
                semana.push({
                    dia:'M',
                    id:3,
                    selected:false
                }
            )}
            if (sessiones.workInThursday === true) {
                semana.push({
                    dia:'J',
                    id:4,
                    selected:true
                })
            }else{
                semana.push({
                    dia:'J',
                    id:4,
                    selected:false
                }
            )}
            if (sessiones.workInFriday === true) {
                semana.push({
                    dia:'V',
                    id:5,
                    selected:true
                })
            }else{
                semana.push({
                    dia:'V',
                    id:5,
                    selected:false
                }
            )}
            if (sessiones.workInSaturday === true) {
                semana.push({
                    dia:'S',
                    id:6,
                    selected:true
                })
            }else{
                semana.push({
                    dia:'S',
                    id:6,
                    selected:false
                }
            )}
            if (sessiones.workInSunday === true) {
                semana.push({
                    dia:'D',
                    id:7,
                    selected:true
                })
            }else{
                semana.push({
                    dia:'D',
                    id:7,
                    selected:false
                }
            )}
            setSemana1(semana)
        }

    }, [])

    const history = useHistory();
    const returnTable = () => {
        history.goBack();
    }

    const [ profilePhoto, setProfilePhoto ] = useState({
        name: 'Subir archivo',
        url: ''
    })

    const [ firstName, setFirstName ] = useState();
    const [ firstLastName, setFirstLastName ] = useState();
    
    const [ position, setPosition ] = useState();
    const [ email, setEmail ] = useState();
    const [horaInicio, setHoraInicio] = useState('08:00');
    const [horaFin, setHoraFin] = useState('17:00');
    const handleAddHour = (e) => {
        setHoraInicio(e.target.value)
    }
    const handleAddHourFinal = (e) => {
        setHoraFin(e.target.value)
    } 
    const [semana1,setSemana1] = useState([
        {
            dia:'L',
            id:1,
            selected:false
        },
        {
            dia:'M',
            id:2,
            selected:false
        },
        {
            dia:'M',
            id:3,
            selected:false
        },
        {
            dia:'J',
            id:4,
            selected:false
        },
        {
            dia:'V',
            id:5,
            selected:false
        },
        {
            dia:'S',
            id:6,
            selected:false
        },
        {
            dia:'D',
            id:7,
            selected:false
        },
    ])

    const handleAdd = (item) => {
        let semanaCopy = JSON.parse(JSON.stringify(semana1));
        semanaCopy.map( (el) => {
            if(item.id === el.id){
                if(el.selected === true){
                    el.selected = false
                }
                else{
                    el.selected = true
                }
            }
        })
        setSemana1(semanaCopy);
    }
    const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                return resolve({
                    src: reader.result,
                    title: file.name
                });
            }
            reader.onerror = error => reject(error);
        });
    };
    const unpdateInfoFile = (event) => {
        const profilePhoto = URL.createObjectURL(event.target.files[0])
        setProfilePhoto({...profilePhoto, url: profilePhoto});
        setProfilePhoto({...profilePhoto, name: event.target.files[0].name})
        const imageFile = event.target.files;
        const urlImages = [];
        const arrImgBase64 = [];
        Object.entries(imageFile).map(async ele => {

            urlImages.push(URL.createObjectURL(ele[1]));

            try {
                const base64 = await toBase64(ele[1])
                    .then(img => {
                        return img;
                    })

                if (base64) {
                    arrImgBase64.push(base64);
                }
            } catch (err) {
                console.log(err)
                return err
            }

        }
        );


        setFileUrl(
            urlImages,
        )

        setImgBase64(
            arrImgBase64,
        )
        // setPicturesSelected(imageFile.name)

    };

    const onClickInput = () => {
        const inputFile = document.getElementById('input-logo');
        inputFile.click();
    }

    const handleChange = (event) => {
        const profilePhoto = URL.createObjectURL(event.target.files[0])
        setProfilePhoto({...profilePhoto, url: profilePhoto});
        setProfilePhoto({...profilePhoto, name: event.target.files[0].name})
        // const image = event.target.files[0];
    }
    
    const profileImg = fileUrl[fileUrl.length - 1] ? fileUrl[fileUrl.length - 1] : (imgDefault ? `${api}/${imgDefault}` : imageSrc);

    const setImgProfile = {
        backgroundImage:  `url(${profileImg})`,
        backgroundSize:     "cover",                      
        backgroundRepeat:   "no-repeat",
    }
    //END ADD IMG 

    const onSubmit = async () => {
        const data = {
            user: {
                email: email,
            },
            person: {
                firstName: firstName,
                firstLastName: firstLastName,
            },
            operative: {
                job: position,
                workHourStartAt: horaInicio,
                workHourEndAt: horaFin,
                dateOfAdmission: fechaIngreso,
                dateOfAdmissionTLP,
                workInMonday: semana1[0].selected,
                workInTuesday: semana1[1].selected,
                workInWednesday: semana1[2].selected,
                workInThursday:semana1[3].selected,
                workInFriday:semana1[4].selected,
                workInSaturday:semana1[5].selected,
                workInSunday:semana1[6].selected,
                media: imgBase64.length < 1 ? null : {
                    base64: {
                        src: imgBase64[0].src,
                        title: imgBase64[0].title.replace(/[{()}]/g, ''),
                    }
                },
            },
        }
        const res = await dispatch(upsertOperativeAction(data));
        if (res) {
            const res2 = await dispatch(updatePasswordRequestAction({email}));
            if(res2){
                dispatch(addNotification({
                    toastType: 'success',
                    toastTitle: 'Se han envido instrucciones para concluir la acción a su email',
                }));
                // history.push(OPERATIVES);
            }
            history.push(OPERATIVES);
        }
    }

    const idOperative = 'UO0001';
    
    return (
        <>
            <div className='main-container-generic-type-quotation operative'>
                <div className='quotation-options-container operative'>
                    <div className='header-form-generic-type'>
                        <h2>Datos principales</h2>
                    </div>
                    <div className='body-form-generic-type-data'>
                        <form className='form-admin-container operative' onSubmit={handleSubmit(onSubmit)}>
                            <div className='body-form'>
                                <div className='input-container'>
                                    <div className='label-container'>
                                        <label className='container-label'>Nombre(s)*</label>
                                    </div>
                                    <div className='value-container'>
                                        <input 
                                            required
                                            disabled={disabled} 
                                            control={control} 
                                            name={'name'} 
                                            className="input-generic" 
                                            type="text" placeholder="Nombre(s)"
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='input-container'>
                                    <div className='label-container'>
                                        <label className='container-label'>Apellido(s)*</label>
                                    </div>
                                    <div className='value-container'>
                                        <input 
                                            required
                                            disabled={disabled} 
                                            control={control} 
                                            name={'lastName'} 
                                            className="input-generic" 
                                            type="text" placeholder="Apellido(s)"
                                            onChange={(e) => setFirstLastName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='input-container'>
                                    <div className='label-container'>
                                        <label className='container-label'>Correo electrónico*</label>
                                    </div>
                                    <div className='value-container'>
                                        <input 
                                            required
                                            disabled={disabled} 
                                            control={control} 
                                            name={'email'} 
                                            className="input-generic" 
                                            type="text" 
                                            placeholder="Correo electrónico"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='input-container'>
                                    <div className='label-container'>
                                        <label className='container-label'>Puesto*</label>
                                    </div>
                                    <div className='value-container'>
                                        <input 
                                            required
                                            disabled={disabled} 
                                            control={control} 
                                            name={'position'} 
                                            className="input-generic" 
                                            type="text" 
                                            placeholder="Puesto"
                                            onChange={(e) => setPosition(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='input-container provider div-input'>
                                <div className='input-label-container'>
                                    <div className='label-container'>
                                        <label className='container-label'>Foto de perfil</label>
                                    </div>
                                    <div className='value-container div-input'>
                                        <div 
                                            className='input-generic div-input'
                                            onClick={onClickInput}
                                        >
                                            <p>{profilePhoto.name}</p>
                                            <div className='icon-container'><img src={file}/></div>
                                        </div>
                                        <input 
                                            className="input-files"
                                            id={'input-logo'}
                                            type="file"
                                            accept='image/png, image/jpg, image/jpeg'
                                            placeholder="Subir archivo"
                                            onChange={unpdateInfoFile}
                                        />
                                    </div>
                                </div>
                            </div>           
                                <div className='input-container'>
                                    <div className='label-container'>
                                        <label className='container-label'>Fecha de ingreso a TLP</label>
                                    </div>
                                    <div className='value-container' >
                                        <input 
                                            type='date' 
                                            disabled={disabled}
                                            value={dateOfAdmissionTLP} 
                                            onChange={(e) => setDateOfAdmissionTLP(e.target.value)} 
                                            className='input-time operative'
                                        />
                                    </div>
                                </div>
                                <div className='input-container'>
                                    <div className='label-container'>
                                        <label className='container-label'>Fecha de ingreso al sistema</label>
                                    </div>
                                    <div className='value-container' >
                                        <input 
                                            type='date' 
                                            disabled={true} 
                                            value={fechaIngreso} 
                                            onChange={(e) => setFechaIngresoDefault(e.target.value)} 
                                            className='input-time operative'
                                        />
                                    </div>
                                </div>
                                <div className='input-container'>
                                    <div className='label-container'>
                                        <label className='container-label'>Horario</label>
                                    </div>
                                    <div className='value-container' style={{justifyContent: 'space-between'}}>
                                        <input type='time' disabled={disabled} control={control} value={horaInicio} onChange={(e) => handleAddHour(e)} className='input-time'/>
                                        <input type='time' disabled={disabled} control={control} value={horaFin} onChange={(e) => handleAddHourFinal(e)} className='input-time'/>
                                    </div>
                                </div>
                                <div className='input-container'>
                                    <div className='label-container'>
                                    </div>
                                    <div className='value-container'>
                                        <div className='container-days'>
                                                { 
                                                    semana1 &&
                                                    semana1.map( (item, i) => (
                                                        <button key={i} type='button' onClick={() => handleAdd(item)} className={`btnDay ${item.selected === true ? 'active' : ''}`}  name={item.id}>{item.dia}</button>
                                                    ))
                                                }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='footer-form-generic-type operative' style={{justifyContent: 'space-between'}}>
                    <Button type='button' onClick={toggleModal ? toggleModal : returnTable} className={'button-back'} style={{width: '40%'}}>CANCELAR</Button>
                    <Button type='submit' onClick={onSubmit} className={'button-next'} style={{width: '40%'}}>REGISTRAR</Button>
                </div>
            </div>
        </>
    )
}

OperativePrincipalData.propTypes = {
    toggleModal: PropTypes.any,
    setValue: PropTypes.func,
    isSuccessUpsertOperative: PropTypes.any,
}

function mapStateToProps (state) {
    return {
        isSuccessUpsertOperative: _.get(state, 'operative.upsertOperative.isSuccess', {}),
    };
}

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(OperativePrincipalData);
