import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux'
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { CancelFormBody } from '../../../../../components/molecules/CancelFormBody/CancelFormBody';
import { CardContainer } from '../../../../../components/molecules/CardContainer/CardContainer';
import Modal from '../../../../../components/organisms/Modal/Modal';
import { StepperComponent } from '../../../../../components/Stepper/Stepper';
import { ClientDocuments } from '../ClientDocuments';
// import '../ClientRegister.scss'
import '../../../../../styles/stylesSteppers.scss';
import { PrincipalDataClient } from '../PrincipalDataClient';
import { CustomsAgents } from './CustomsAgents';
import { SeeClientDocuments } from './SeeClientDocuments';
import { LegalRepresentative } from '../LegalRepresentative';


export const seeClientRegister = (props) => {

    const { mode } = useParams();
    const history = useHistory();
    const [stepper, setStepper] = useState(0);

    const [openModal, setOpenModal] = useState(false)
    const toggleModal = () => {
        setOpenModal(!openModal);
    }
    const data = _.get(props, 'location.state', null)
    


    const [formData, setFormData] = useState(data);
    const steps = formData && formData.isfisica == true ? [
        {
            label: 'Datos principales',
            step: 0,
            component: <PrincipalDataClient mode={mode} stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData} toggleModal={toggleModal} type={"editFisica"} />
        },{
            label: 'Documentación',
            step: 1,
            component: <ClientDocuments mode={mode} stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData} toggleModal={toggleModal} />
        },
        {
            label: 'Agentes aduanales asignados',
            step: 2,
            component: <CustomsAgents mode={mode} stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData} toggleModal={toggleModal} />
        },
        
        // {
        //     label: 'Cotizaciones',
        //     step: 3,
        //     component: <SeeClientDocuments stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData} toggleModal={toggleModal} mode={mode} />
        // },
    ] :

        [
            {
                label: 'Datos principales',
                step: 0,
                component: <PrincipalDataClient stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData} toggleModal={toggleModal} type={"editMoral"}  />
            },
            {
                label: 'Datos representante legal',
                step: 1,
                component: <LegalRepresentative stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData} toggleModal={toggleModal} type={"editMoral"}  />
            },
            {
                label: 'Documentación',
                step: 2,
                component: <ClientDocuments mode={mode} stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData} toggleModal={toggleModal} />
            },
            {
                label: 'Agentes aduanales asignados',
                step: 3,
                component: <CustomsAgents mode={mode} stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData} toggleModal={toggleModal} />
            },
            // {
            //     label: 'Cotizaciones',
            //     step: 3,
            //     component: <SeeClientDocuments stepper={stepper} setStepper={setStepper} formData={formData} setFormData={setFormData} toggleModal={toggleModal} mode={mode}  />
            // },
        ]


    const [render, setRender] = useState(null)
    useEffect(() => {
        setRender(steps[stepper].component)
    }, [stepper])

    const renders = steps.map((ele) => {
        return (
            ele.component
        )
    })
    const cancel = () => {
        history.push('/dashboard/gestion-clientes')
    }
 

    return (
        <>
            {
                openModal &&
                <Modal
                    isOpen={toggleModal}
                    toggle={toggleModal}
                >
                    <CardContainer btnLeftAction={toggleModal} btnRightAction={cancel} body={<CancelFormBody />} />
                </Modal>
            }
            <div className='main-container'>
                <div className='stepper-container'>
                    <StepperComponent step={stepper} steps={steps} setStepper={setStepper} client={true} />
                </div>
                <div className='card-container-stepper'>
                    {
                        render
                    }
                </div>
            </div>
        </>
    )
}
