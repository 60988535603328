import React from "react";
import PropTypes from "prop-types";


const InputPassword = (props) => {
    return (
        <div className="container-input-component">
            <div className="btn-input-component-icouser" >
            <div className="subcontainer-img-left">

                <img src={props.icon} alt="" />
            </div>
                <input
                    type={props.type}
                    name={props.name}
                    {...props}
                    placeholder={props.placeholder}
                    className="input-component-login"
                />
            </div>
        </div>
    );
}

InputPassword.propTypes = {
    props: PropTypes.any,
    color: PropTypes.any,
    icon: PropTypes.any,
    title: PropTypes.any,
    name: PropTypes.string,
    placeholder: PropTypes.string,
};

export default InputPassword;