/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '../../../components/atoms/Button';
import plusIcon from '../../../assets/images/navieras/plus-icon.png';
import './AssingForms.scss';
import './AssignClient.scss';
import { CUSTOMER_CREATE } from '../../../routes/Paths';
import { GlobalFilter } from '../../atoms';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClientBelongsCustomAgent } from '../../../redux/actions/customsAgentAction';

export const AssignClient = ({ clients = [], setClientAssigned, clientAssigned, formData, coditional }) => {
    const [searchObj, setSearchObj] = useState({ textSearch: '' });
    const noAvailableClients = [{ tradename: 'No hay clientes disponibles', id: 0 }];
    const [availableClients, setAvailableClients] = useState()
    const dispatch = useDispatch()
    const offset = 0;
    const limit = 2;

    const clientAvailableCustom = useSelector(state => state.client.clientsAvailableByCustomerAgent.data.rows)


    // useEffect(() => {
    //     let clientFilter = [];
    //     let search = searchObj.textSearch;
    //     _.get(clients, 'rows', []).map(ele => {
    //         if (ele && ele.tradename && ele.tradename.toLowerCase().includes(search.toLowerCase())) {
    //             clientFilter.push(ele)
    //         }
    //     })
    //     if (clientFilter && clientFilter.length > 0) setAvailableClients(clientFilter);
    //     else setAvailableClients(noAvailableClients);
    // }, [searchObj])



    useEffect(() => {
        const customAgentId = formData && formData.id;
        return () => {
            if (coditional)
                dispatch(getAllClientBelongsCustomAgent({ customAgentId, offset, limit }))
        }

    }, [])


    const selectClient = (e) => {
        setClientAssigned(parseInt(e.target.value));
    }

    const history = useHistory();





    return (
        <div className='assign-form' style={{ width: '45vw', height: '14em', display: 'flex', justifyContent: 'center' }}>
            <div className='assign-form-button-container'>
                <Button onClick={() => history.push(CUSTOMER_CREATE)} srcicon={plusIcon}>CREAR</Button>
            </div>
            {/* <div className='filter-container'>
                <GlobalFilter 
                    filter={searchObj}
                    setFilter={setSearchObj}
                    showTextSearch
                    showDateRangeSearch={false}
                    placeholderSearch={'Buscar cliente...'}
                />
            </div> */}
            <div className='input-container-data-form'>
                <div className='label-container'>
                    <label className='container-label'>Seleccionar cliente</label>
                </div>
                <div className='value-container' >
                    <select className='input-select' value={clientAssigned} onChange={(e) => {
                        selectClient(e);
                    }}>
                        <option className='option-selected' value={''} selected hidden>Seleccionar cliente</option>
                        {
                            clientAvailableCustom &&
                                clientAvailableCustom.length > 0 ?
                                clientAvailableCustom.map((ele, i) => {
                                    return <option key={i} value={ele.id}>{ele.tradename}</option>
                                })
                                :
                                clients
                                    && clients.length > 0 ?
                                    clients.map((ele, i) => {
                                        return <option key={i} value={ele.id}>{ele.tradename}</option>
                                    })
                                    :
                                    <option> {"No hay clientes disponibles"}</option>
                        }
                    </select>
                </div>
            </div>
        </div>
    )
}

AssignClient.propTypes = {
    clients: PropTypes.array,
}
