/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Button } from './../../components/atoms';
import done from './../../assets/images/navieras/done-icon.png';
import './RecordClosure.scss';
import { RECORDS } from './../../routes/Paths';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getAllExpedientAction, upsertExpedient } from '../../redux/actions/recordAction';



const RecordClosure = ({ closureDate = '00/00/0000', closureTime = '00:00 hrs' }) => {

    const location = useLocation()
    const admin = useSelector(state => state.user.user.adminId)
    const operative = useSelector(state => state.user.user.operativeId)

    const dispatch = useDispatch()
    const history = useHistory()



    const date = new Date();
    const dataLocal = date.toLocaleDateString();
    const time = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();


    useEffect(() => {
        const formData_ = new FormData()

        formData_.append("id", location.payload.row.expedientId)
        formData_.append("closeFile", true)

        if(admin != null){
            formData_.append("adminId", admin);
        }else if(operative ){
            formData_.append("operativeId", operative);

        }
        formData_.append("closeFileDate", date)
        formData_.append("expedient", '{}');
        formData_.append("customAgent", '{}');
        formData_.append("arrivalDates", '{}');
        formData_.append("paymentDetails", '{}');
        formData_.append("pickupDelivery", '{}');
        formData_.append("delay", '{}');
        formData_.append("route", '{}');
        formData_.append("deviation", '[]');
        formData_.append("tracing", '[]');
        formData_.append("mediasDeleted", '[]');
        formData_.append("File", '[]');
        dispatch(upsertExpedient(formData_))
    }, [])


    const accept = () => {
        history.push(`${RECORDS}`);
    }


    // location && location.payload && location.payload.row.closeFileDate

    return (
        <div className='closure-record-container'>
            <div className='closure-record-image-container'>
                <img src={done} />
            </div>
            <div className='closure-record-info-container'>
                <h1 className='closure-record-title'>EXPEDIENTE CERRADO CON ÉXITO</h1>
                <div className='text-info-container'>
                    <p className='closure-record-info'>{`Fecha de cierre: ${dataLocal} - ${time} ${'hrs'}`}</p>
                    <p className='closure-record-info'>{location.payload.row.adminId && location.payload.row.operativeId == null ? 
                    `Administrador: ${location && location.payload && location.payload.row && location.payload.row.personAdministrator.firstName} ${location && location.payload && location.payload.row && location.payload.row.personAdministrator.firstLastName}`
                     : `Operativo: ${location && location.payload && location.payload.row && location.payload.row.personOperative.firstLastName}
                     ${location && location.payload && location.payload.row && location.payload.row.personOperative.firstName}`} </p>
                </div>
                <Button type='button' onClick={() => accept()} className={'button-'} style={{ width: '65%' }}>ACEPTAR</Button>
            </div>
        </div>
    )
}

export default RecordClosure;
