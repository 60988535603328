import {
    OPERATIVE_MODULE_GET_ALL_OPERATIVES_BEGIN,
    OPERATIVE_MODULE_GET_ALL_OPERATIVES_SUCCESS,
    OPERATIVE_MODULE_GET_ALL_OPERATIVES_FAILURE,
    OPERATIVE_MODULE_GET_ONE_OPERATIVE_BEGIN,
    OPERATIVE_MODULE_GET_ONE_OPERATIVE_SUCCESS,
    OPERATIVE_MODULE_GET_ONE_OPERATIVE_FAILURE,
    OPERATIVE_MODULE_UPSERT_OPERATIVE_BEGIN,
    OPERATIVE_MODULE_UPSERT_OPERATIVE_SUCCESS,
    OPERATIVE_MODULE_UPSERT_OPERATIVE_FAILURE,
    OPERATIVE_MODULE_DELETE_OPERATIVE_BEGIN,
    OPERATIVE_MODULE_DELETE_OPERATIVE_SUCCESS,
    OPERATIVE_MODULE_DELETE_OPERATIVE_FAILURE,
} from '../actionTypes';

import initialState from '../initialState';

export default function operativeReducer(state = initialState.operative, action) {
    switch (action.type) {
        case OPERATIVE_MODULE_GET_ALL_OPERATIVES_BEGIN:
            return {
                ...state,
                operatives: {
                    ...state.operatives,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    totalRecords: 0,
                    data: [],
                },
            };
        case OPERATIVE_MODULE_GET_ALL_OPERATIVES_SUCCESS:
            return {
                ...state,
                operatives: {
                    ...state.operatives,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    totalRecords: action.data.totalRecords,
                    data: action.data.data,
                },
            };
        case OPERATIVE_MODULE_GET_ALL_OPERATIVES_FAILURE:
            return {
                ...state,
                operatives: {
                    ...state.operatives,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
            case OPERATIVE_MODULE_GET_ONE_OPERATIVE_BEGIN:
                return {
                    ...state,
                    operativeRecord: {
                        ...state.operativeRecord,
                        isLoading: true,
                        isSuccess: false,
                        err: null,
                        data: [],
                    },
                };
            case OPERATIVE_MODULE_GET_ONE_OPERATIVE_SUCCESS:
                return {
                    ...state,
                    operativeRecord: {
                        ...state.operativeRecord,
                        isLoading: false,
                        isSuccess: true,
                        err: null,
                        data: action.data.data,
                    },
                };
            case OPERATIVE_MODULE_GET_ONE_OPERATIVE_FAILURE:
                return {
                    ...state,
                    operativeRecord: {
                        ...state.operativeRecord,
                        isLoading: false,
                        isSuccess: false,
                        err: action.err,
                        totalRecords: 0,
                        data: [],
                    },
                };
        case OPERATIVE_MODULE_UPSERT_OPERATIVE_BEGIN:
            return {
                ...state,
                upsertOperative: {
                    ...state.upsertOperative,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: {},
                },
            };
        case OPERATIVE_MODULE_UPSERT_OPERATIVE_SUCCESS:
            return {
                ...state,
                upsertOperative: {
                    ...state.upsertOperative,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.operative,
                },
            };
        case OPERATIVE_MODULE_UPSERT_OPERATIVE_FAILURE:
            return {
                ...state,
                upsertOperative: {
                    ...state.upsertOperative,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: {},
                },
            };
        case OPERATIVE_MODULE_DELETE_OPERATIVE_BEGIN:
            return {
                ...state,
                deleteOperative: {
                    ...state.deleteOperative,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: null,
                },
            };
        case OPERATIVE_MODULE_DELETE_OPERATIVE_SUCCESS:
            return {
                ...state,
                deleteOperative: {
                    ...state.deleteOperative,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.operative,
                },
            }
        case OPERATIVE_MODULE_DELETE_OPERATIVE_FAILURE:
            return {
                ...state,
                deleteOperative: {
                    ...state.deleteOperative,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: null,
                },
            }
            default:
            return state;
    }
}