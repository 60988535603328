import React, { useEffect, useState } from 'react'
import '../../../styles/tableViewStyles.scss';
import { ActionMenu, DataTable, GlobalFilter } from '../../../components/atoms';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDeleteQuotationsAction, recoveQuotationAction } from '../../../redux/actions/quotationAction';
import { MenuItem } from '@material-ui/core';
import { useParams } from 'react-router-dom';

const QuotationDeleted = () => {

  const dispatch = useDispatch();
  const { clientId } = useParams();
  const [searchObj, setSearchObj] = useState({});
  const [searchCondition, setSearchCondition] = useState({});

const [list,setList] = useState()

  const dataDeleted = useSelector(state => state.quotation.getDeleteQuotation.data)
  const [record, setRecord] = useState([]);






  useEffect(() => {
    // if (dataDeleted && dataDeleted.length < 0 && dataDeleted == null)
    dispatch(getAllDeleteQuotationsAction({ "offset": 0, "limit": 500 }))
    setList(dataDeleted)
  }, [])

  
  useEffect(()=>{
    // ? {"where":{"clientId": clientId}} :z
    let search ;
    if(clientId){
        search = {...searchCondition,
        "where" : {
            ...searchCondition.where,
            "clientId" : clientId
        }
        }
    }else{
        search = {...searchCondition}
    }
    dispatch(getAllDeleteQuotationsAction(search));
},[searchCondition]);

  

  const getNewData = (searchCondition) => {
    // TODO: Restrucutrar vs Filtros*
    setSearchCondition(searchCondition);
  };

  const recoveQuotation = (item) => {
    dispatch(recoveQuotationAction({"id": item.id}))
    dispatch(getAllDeleteQuotationsAction({ "offset": 0, "limit": 500 }))

  }


  const columns = React.useMemo(
    () => [
      {
        id: 'id',
        Header: 'ID',
        disableSortBy: true,
        disableFilters: true,
        filter: 'text',
        width: '8%',
        accessor: d => ({
          id: _.get(d, 'id', ''),
        }),
        Cell: function cell({
          value: {
            id,
          },
          row
        }) {
          return (<span>{`${id}`}</span>)
        }
      },
      {
        id: 'cliente',
        Header: 'Cliente',
        disableSortBy: true,
        disableFilters: true,
        filter: 'text',
        width: '18%',
        accessor: d => ({
          tradename: _.get(d, 'client.tradename', ''),
        }),
        Cell: function cell({
          value: {
            tradename,
          },
          row
        }) {
          return (<span>{tradename}</span>)
        }
      },
      {
        id: "proveedor.name",
        Header: 'Proveedor',
        disableSortBy: true,
        disableFilters: true,
        width: '18%',
        accessor: d => ({
          provider: _.get(d, 'provider.name', ''),
        }),
        Cell: function cell({ value: { provider }, row }) {
          return (<spam >{provider}</spam>);
        },
      },
      {
        id: "modalidad.type",
        Header: 'Tipo de modalidad',
        disableSortBy: true,
        disableFilters: true,
        width: '25%',
        // accessor: d => ({
        //     modality: _.get(modality(d), ''),
        // }),
        // Cell: function cell ({ value: { modality }, row }) {
        //     return (<spam >{modality}</spam>); 
        // },
        accessor: d => ({
          modalityGround: _.get(d, 'modalityGroundId', ''),
          modalityAir: _.get(d, 'modalityAirId', ''),
          modalityMarine: _.get(d, 'modalityMarineId', ''),
          lcl: _.get(d, 'quotationData.lcl', false),
          fcl: _.get(d, 'quotationData.fcl', false),
        }),
        Cell: function cell({ value: { modalityGround, modalityAir, modalityMarine, lcl, fcl }, row }) {
          return (<spam >{
            `${modalityGround != null ? 'Terrestre' : modalityAir != null ? 'Aéreo' : modalityMarine != null ? 'Marítimo' : ''} ${lcl == true ? 'LCL' : fcl == true ? 'FCL' : ''}`
          }</spam>);
        },
      },
      {
        id: "servicio.type",
        Header: 'Tipo de servicio',
        disableSortBy: true,
        disableFilters: true,
        width: '25%',
        accessor: d => ({
          serviceImport: _.get(d, 'serviceImport', false),
          serviceExport: _.get(d, 'serviceExport', false)
        }),
        Cell: function cell({ value: { serviceImport, serviceExport }, row }) {
          return (<spam >{serviceImport ? 'Importación' : serviceExport ? 'Exportación' : ''}</spam>);
        },
      },
      {
        id: 'options.name',
        Header: 'Acciones',
        filter: "text",
        disableFilters: true,
        width: '15%',
        accessor: d => ({
          id: d.id,
          item: d
        }),
        Cell: function cell({ value: { id, item }, row }) {
          return (
            <ActionMenu id={id} >
              <MenuItem onClick={() => recoveQuotation(item)}>Recuperar Cotización</MenuItem>
            </ActionMenu>
          );
        }
      },
    ],
  );


  return (
    <>
      <section className='ClientRecordAccountSection' >
        <div className='ClientRecordAccountSection__container'>
          <div className='ClientRecordAccountSection__headerContainer'>
            <div className='HomeTableSection__containerFilters'>
              <GlobalFilter
                filter={searchObj}
                setFilter={setSearchObj}
                showTextSearch
                showDateRangeSearch={false}
                // classNameDate={'containerFilters__date'}
                // classNameSearch={'containerFilters__search'}
                placeholderSearch={'Buscar ID...'}
              />
            </div>
          </div>

          <div className='ClientRecordAccountSection__tableContainer'>
            <DataTable
              columns={columns}
              data={
                {
                  data: dataDeleted != null ? dataDeleted : [],
                  // data: _.get(dataDeleted, 'data', []),
                  totalRecords: _.get(dataDeleted, 'totalRecords', 0),
                }}
              getData={getNewData}
              searchObj={searchObj}
            />
          </div>
        </div>
      </section>
    </>
  )
}


export default QuotationDeleted;
