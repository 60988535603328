/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from '../../../components/atoms';
import { getAllExpedientAction, upsertExpedient } from '../../../redux/actions/recordAction';
import './RecordChanges.scss';

const RecordChanges = ({ toggleModal,changeNavierasOrigin,setChangeNavierasOrigin}) => {


    // const dataRedux = useSelector(state => state.record.getAllRecord.data[0].route.changeNaviera)
    const dispatch = useDispatch()
    const [changes, setChanges] = useState(changeNavierasOrigin);
    const location = useLocation()

    const [data, setData] = useState({
        changeNaviera: changes,

    })

    useEffect(() => {
      setData({changeNaviera: changes,})
    }, [changes])
    
    const route = JSON.stringify(data)
    
   
    const saveChanges = async () => {
        const formData_ = new FormData()

        formData_.append("id", location.payload.row.expedientId)
        formData_.append("expedient", '{}');
        formData_.append("customAgent", '{}');
        formData_.append("arrivalDates", '{}');
        formData_.append("paymentDetails", '{}');
        formData_.append("pickupDelivery", '{}');
        formData_.append("delay", '{}');
        formData_.append("route", route);
        formData_.append("deviation", '[]');
        formData_.append("tracing", '[]');
        formData_.append("mediasDeleted", '[]');

        formData_.append("File", '[]');

        const res = await dispatch(upsertExpedient(formData_))
        if (res) {
            setChangeNavierasOrigin(changes)
              toggleModal();
        }
     
    }

    return (
        <div className='changes-modal-container'>
            <div className='changes-title-container'>
                <h3>Cambios</h3>
                <h4>Capture textualmente los cambios:</h4>
            </div>
            <div className='changes-reason'>
                <input
                    className='reasons-changes-input'
                    type='textarea'
                    placeholder='Escriba aquí sus cambios...'
                    value={changes}
                    onChange={(e) => setChanges(e.target.value)}
                />
            </div>
            <div className='footer-form-generic-type modal' style={{ justifyContent: 'space-between' }}>
                <Button type='button' onClick={toggleModal} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                <Button type='button' onClick={() => saveChanges()} className={'button-next'} style={{ width: '40%' }}>GUARDAR</Button>
            </div>
        </div>
    )
}

export default RecordChanges;
