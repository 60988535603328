import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './RecordRegisterOne.scss';

const RecordRegisterOne = () => {

    const location = useLocation();


    return (
        <div className='record-register-general-data-one'>
            <label className='container-label'>Datos del cliente</label>
            <div className='container-colums'>

                <div className='container-row'>
                    <label className='container-label-sub'>Cliente</label>
                    <input
                        type='text'
                        value={location && location.payload && location.payload.row && location.payload.row.client.tradename}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>ID del expediente</label>
                    <input
                        type='number'
                        value={location && location.payload && location.payload.row && location.payload.row.expedientId}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>TL (No.Folio)</label>
                    <input
                        type='number'
                        value={location && location.payload && location.payload.row && location.payload.row.expedient.folioTl}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>

            <div className='container-colums' >
                <div className='container-row'>
                    <label className='container-label-sub'>Proveedor</label>
                    <input
                        type='text'
                        value={location && location.payload && location.payload.row && location.payload.row.provider.name}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Modalidad</label>
                    <input
                        type='text'
                        value={location && location.payload && location.payload.row && location.payload.row.quotation.modalityAirId ?
                            "Aérea" :
                            location && location.payload && location.payload.row && location.payload.row.quotation.modalityGroundId ?
                                "Terrestre" :
                                location.payload && location.payload.row && location.payload.row.quotation.modalityMarineId && location.payload.row.modalityType.fcl == true ? 'FCL' : 'LCL'
                        }
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Servicio</label>
                    <input
                        type='text'
                        value={location && location.payload && location.payload.row && location.payload.row.expedient.mov}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>


            <div className='container-colums' >
                <div className='container-row-sub'>
                    <label className='container-label-sub'>Fecha de cierre del embarque</label>
                    <input
                        type='date'
                        value={''}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row-sub'>
                    <label className='container-label-sub'>Fecha de vigencia cotización</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.modalityType.validity}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>



            <label className='container-label-one'>Datos de agente aduanal</label>

            <div className='container-colums' >
                <div className='container-row'>
                    <label className='container-label-sub'>Agende aduanal</label>
                    <input
                        type='text'
                        value={location && location.payload && location.payload.row && location.payload.row.customAgend && location.payload.row.customAgend.agentname}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Teléfono </label>
                    <input
                        type='number'
                        value={location && location.payload && location.payload.row && location.payload.row.customAgend && location.payload.row.customAgend.phone}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Correo</label>
                    <input
                        type='email'
                        value={location && location.payload && location.payload.row && location.payload.row.customAgend && location.payload.row.customAgend.email}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>


            <div className='container-colums' >
                <div className='container-row'>
                    <label className='container-label-sub'>Pais de origen</label>
                    <input
                        type='text'
                        value={location && location.payload && location.payload.row && location.payload.row.countryOrigin && location.payload.row.countryOrigin.name}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Estado de origen</label>
                    <input
                        type='text'
                        value={location && location.payload && location.payload.row && location.payload.row.stateOrigin && location.payload.row.stateOrigin.name}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>POL</label>
                    <input
                        type='text'
                        value={(location && location.payload && location.payload.row && location.payload.row.originPort.port) ? (location && location.payload && location.payload.row && location.payload.row.originPort.port) : "N/A" }
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>

            <div className='container-colums' >
                <div className='container-row'>
                    <label className='container-label-sub'>Pais de destino</label>
                    <input
                        type='text'
                        value={location && location.payload && location.payload.row && location.payload.row.countryDestiny && location.payload.row.countryDestiny.name}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Estado de destino</label>
                    <input
                        type='text'
                        value={location && location.payload && location.payload.row && location.payload.row.stateDestiny && location.payload.row.stateDestiny.name}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>POD</label>
                    <input
                        type='text'
                        value={(location && location.payload && location.payload.row && location.payload.row.destinyPort.port) ? (location && location.payload && location.payload.row && location.payload.row.destinyPort.port) : "N/A" }
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>


            <label className='container-label-one'>Datos de partida y llegada</label>

            <div className='container-colums' >
                <div className='container-row'>
                    <label className='container-label-sub'>ETD</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.expedient.etd}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>ETA </label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.expedient.eta}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Fecha de zarpe</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.expedient.departureDate}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>

            <div className='container-colums' >
                <div className='container-row-sub'>
                    <label className='container-label-sub'>Fecha de llegada al puerto</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.expedient.arrivalPortDate}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>


            <label className='container-label-one'>Datos de contenedor</label>

            <div className='container-colums' >
                <div className='container-row'>
                    <label className='container-label-sub'>Número de MBL</label>
                    <input
                        type='number'
                        value={location && location.payload && location.payload.row && location.payload.row.expedient.numberMbl}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Número de contenedores</label>
                    <input
                        type='number'
                        value={location && location.payload && location.payload.row && location.payload.row.expedient.noContainers}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Naviera</label>
                    <input
                        type='text'
                        value={location && location.payload && location.payload.row && location.payload.row.expedient.naviera}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>

            <div className='container-colums' >
                <div className='container-row'>
                    <label className='container-label-sub'>Cantidad de contenedores</label>
                    <input
                        type='number'
                        value={location && location.payload && location.payload.row && location.payload.row.expedient.quiantityContainers}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
                <div className='container-row'>
                    <label className='container-label-sub'>Equipo</label>
                    <input
                        type='text'
                        value={location && location.payload && location.payload.row && location.payload.row.expedient.team}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
                <div className='container-row'>
                    <label className='container-label-sub'>TEUS</label>
                    <input
                        type='number'
                        value={location && location.payload && location.payload.row && location.payload.row.expedient.teus}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>


            <label className='container-label-one'>Datos de arribo</label>

            <div className='container-colums' >
                <div className='container-row'>
                    <label className='container-label-sub'>Fecha de arribo</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.arrivalDates.arrivalDate}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Fecha 14 días antes de arribo</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.arrivalDates.dateFourteenDaysAfterArrival}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Fecha 7 días antes de arribo</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.arrivalDates.dateSevenDaysAfterArrival}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>

            <div className='container-colums' >
                <div className='container-row'>
                    <label className='container-label-sub'>Fecha 3 días antes de arribo</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.arrivalDates.dateThreeDaysAfterArrival}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Días de demora</label>
                    <input
                        type='number'
                        value={location && location.payload && location.payload.row && location.payload.row.arrivalDates.daysOfDelay}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Último día libre de demora</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.arrivalDates.lastDayOffDelay}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>

            <label className='container-label-one'>Datos de pago</label>

            <div className='container-colums' >
                <div className='container-row'>
                    <label className='container-label-sub'>Total de pago</label>
                    <input
                        type='number'
                        value={location && location.payload && location.payload.row && location.payload.row.paymentDetails.payment}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Fecha de pago</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.paymentDetails.paydayLimit}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Pago de naviera</label>
                    <input
                        type='number'
                        value={location && location.payload && location.payload.row && location.payload.row.paymentDetails.paymentNaviera}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>

            <div className='container-colums' >
                <div className='container-row'>
                    <label className='container-label-sub'>Fecha de pago naviera</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.paymentDetails.paymentDateNaviera}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Pago al agente</label>
                    <input
                        type='number'
                        value={location && location.payload && location.payload.row && location.payload.row.paymentDetails.paymentAgent}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Fecha de pago al agente</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.paymentDetails.paymentDateAgent}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>

            <div className='container-colums' >
                <div className='container-row-sub'>
                    <label className='container-label-sub'>Cargos locales</label>
                    <input
                        type='number'
                        value={location && location.payload && location.payload.row && location.payload.row.paymentDetails.localCharges}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row-sub'>
                    <label className='container-label-sub'>Fecha de cargos locales</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.paymentDetails.dateLocalCharges}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>



            <label className='container-label-one'>Datos de recoleccion y entrega</label>

            <div className='container-colums' >
                <div className='container-row'>
                    <label className='container-label-sub'>MODE</label>
                    <input
                        type='text'
                        value={location && location.payload && location.payload.row && location.payload.row.pickupDelivery && location.payload.row.pickupDelivery.mode}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Tipo de entrega</label>
                    <input
                        type='text'
                        value={location && location.payload && location.payload.row && location.payload.row.pickupDelivery.deliveryType ==1 ? 'Rail + Truck'
                        : location.payload.row.pickupDelivery.deliveryType == 2 ? 'Rail + Truck Ext' 
                        :location.payload.row.pickupDelivery.deliveryType ==3 ? 'Rail Ramp'
                        : location.payload.row.pickupDelivery.deliveryType ==4 ? 'All Truck Sencillo'
                        : location.payload.row.pickupDelivery.deliveryType ==5 ? 'All Truck Full'
                        :location.payload.row.pickupDelivery.deliveryType ==6? 'All Truck Sencillo Ext'
                        :location.payload.row.pickupDelivery.deliveryType ==7 ? 'All Truck Full Ext' 
                        :location.payload.row.pickupDelivery.deliveryType ==8 ? 'No aplica' : 'Sin información'}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Fecha de llegada de ferrocarril</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.pickupDelivery.railArrivalDate}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>

            <div className='container-colums' >
                <div className='container-row'>
                    <label className='container-label-sub'>Fecha de entrega ferrocarril</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.pickupDelivery.railwayDeliveryDate}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Fecha de retorno de vacío</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.pickupDelivery.emptyReturnDate}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>PickUp</label>
                    <input
                        type='text'
                        value={location && location.payload && location.payload.row && location.payload.row.pickupDelivery.pickUp}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>

            <div className='container-colums' >
                <div className='container-row-sub'>
                    <label className='container-label-sub'>FPOD</label>
                    <input
                        type='text'
                        value={location && location.payload && location.payload.row && location.payload.row.pickupDelivery.fpod}

                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>


            <label className='container-label-one'>Demora</label>

            <div className='container-colums' >
                <div className='container-row'>
                    <label className='container-label-sub'>Regreso de vacío</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.delay.emptyReturn}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Último día de demora</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.delay.lastDayOfDelays}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Días demora regreso de vacío</label>
                    <input
                        type='number'
                        value={location && location.payload && location.payload.row && location.payload.row.delay.daysOfDelayReturnEmpty}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>
            </div>

            <div className='container-colums' >
                <div className='container-row'>
                    <label className='container-label-sub'>Motivo de la demora</label>
                    <input
                        type='text'
                        value={location && location.payload && location.payload.row && location.payload.row.delay.reasonForTheDelay}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Total de pago de demora</label>
                    <input
                        type='number'
                        value={location && location.payload && location.payload.row && location.payload.row.delay.totalPaymentForDelays}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>

                <div className='container-row'>
                    <label className='container-label-sub'>Fecha de liquidacion de pago</label>
                    <input
                        type='date'
                        value={location && location.payload && location.payload.row && location.payload.row.delay.paymentSettlementDate}
                        // onChange={(e) => setData({...data, sail: e.target.value})}
                        className='input-time records'
                        disabled />
                </div>


            </div>
        </div>
    )
}

export default RecordRegisterOne;
