/* eslint-disable no-unused-vars */
import request from "../../utils/request";
import { addNotification } from './notificationAction';

import {
    CLIENT_MODULE_GET_ALL_CLIENTS_BEGIN,
    CLIENT_MODULE_GET_ALL_CLIENTS_SUCCESS,
    CLIENT_MODULE_GET_ALL_CLIENTS_FAILURE,
    CLIENT_MODULE_UPSERT_CLIENT_BEGIN,
    CLIENT_MODULE_UPSERT_CLIENT_SUCCESS,
    CLIENT_MODULE_UPSERT_CLIENT_FAILURE,
    GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_BEGIN,
    GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_SUCCESS,
    GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_FAILURE,
    GET_ONE_CLIENT_BEGIN,
    GET_ONE_CLIENT_SUCCESS,
    GET_ONE_CLIENT_FAILURE

} from '../actionTypes';

// ACTIONS TO REDUCER

export const getAllClientsBegin = () => ({
    type: CLIENT_MODULE_GET_ALL_CLIENTS_BEGIN,
});

export const getAllClientsSuccess = (data) => ({
    type: CLIENT_MODULE_GET_ALL_CLIENTS_SUCCESS,
    data,
});

export const getAllClientsFailure = (err) => ({
    type: CLIENT_MODULE_GET_ALL_CLIENTS_FAILURE,
    err,
});

export const upsertClientBegin = () => ({
    type: CLIENT_MODULE_UPSERT_CLIENT_BEGIN,
});

export const upsertClientSuccess = (company) => ({
    type: CLIENT_MODULE_UPSERT_CLIENT_SUCCESS,
    company,
});

export const upsertClientFailure = (err) => ({
    type: CLIENT_MODULE_UPSERT_CLIENT_FAILURE,
    err,
});

export const getAllAvailableClientsBegin = () => ({
    type: GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_BEGIN,
});

export const getAllAvailableClientsSuccess = (data) => ({
    type: GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_SUCCESS,
    data,
});

export const getAllAvailableClientsFailure = (err) => ({
    type: GET_ALL_AVAILABLE_CLIENTS_BY_CUSTOM_AGENT_FAILURE,
    err,
});

export const getOneClientBegin = () => ({
    type: GET_ONE_CLIENT_BEGIN,
});

export const getOneClientSuccess = (data) => ({
    type: GET_ONE_CLIENT_SUCCESS,
    data,
});

export const getOneClientFailure = (err) => ({
    type: GET_ONE_CLIENT_FAILURE,
    err,
});


// DISPATCH TO ACTIONS

export function getAllClientsAction(data) {
    return (dispatch, getState) => {
        dispatch(getAllClientsBegin());
        return request({
            partialUrl: `/api/v1/client/get-all-client-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
            .then(request => request.json())
            .then(data => {
                dispatch(getAllClientsSuccess(data.data));
                return true;
            })
            .catch(err => {
                console.log(err);
                dispatch(addNotification({
                    toastType: 'error',
                    toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
                }));
                return dispatch(getAllClientsFailure(err));
            });
    };
}

export function upsertClientAction(data) {
    return (dispatch, getState) => {
        dispatch(upsertClientBegin());
        return request({
            partialUrl: `/api/v1/client/upsert-client-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,

            headers: {
                //'Content-Type': 'multipart/form-data; boundary=—-WebKitFormBoundaryfgtsKTYLsT7PNUVD',
                //Pragma: 'no-cache',
                // 'Cache-control': 'no-store',
                // 'Access-Control-Allow-Origin': '*',
                // 'access-control-allow-origin': '*',
            },
            isFileUpload: true,

        })
            .then(request => request.json())
            .then(data => {
                dispatch(addNotification({
                    toastType: 'success',
                    toastTitle: 'Se ha ejecutado la acción con éxito',
                }));
                dispatch(upsertClientSuccess(data.data));
                return true;
            })
            .catch(err => {
                console.log(err);
                dispatch(addNotification({
                    toastType: 'error',
                    toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
                }));
                return dispatch(upsertClientFailure(err));
            });
    };
}
export function deleteClientAction(data) {
    return (dispatch, getState) => {
        return request({
            partialUrl: `/api/v1/client/delete-client-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
            .then(request => request.json())
            .then(data => {
                dispatch(addNotification({
                    toastType: 'success',
                    toastTitle: 'Se ha eliminado con éxito',
                }));
                return true;
            })
            .catch(err => {
                console.log(err);
                dispatch(addNotification({
                    toastType: 'error',
                    toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
                }));
                // return dispatch(deleteClientFailure(err));
            });
    };
}


export function getAllAvailableClientsAction(data) {
    return (dispatch, getState) => {
        dispatch(getAllAvailableClientsBegin());
        return request({
            partialUrl: `/api/v1/custom-agend/client/get-all-availability-clients-by-custom-agent-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
            .then(request => request.json())
            .then(data => {
                dispatch(getAllAvailableClientsSuccess(data.data));
                return true;
            })
            .catch(err => {
                console.log(err);
                dispatch(addNotification({
                    toastType: 'error',
                    toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
                }));
                return dispatch(getAllAvailableClientsFailure(err));
            });
    };
}



export function getOneClientAction(data) {
    return (dispatch, getState) => {
        dispatch(getOneClientBegin());
        return request({
            partialUrl: `/api/v1/client/get-one-client-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
            .then(request => request.json())
            .then(data => {
                dispatch(getOneClientSuccess(data.data));
                return true;
            })
            .catch(err => {
                console.log(err);
                dispatch(addNotification({
                    toastType: 'error',
                    toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
                }));
                return dispatch(getOneClientFailure(err));
            });
    };
}
