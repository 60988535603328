import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../atoms';
import './CardContainer.scss';

export const CardContainer = ({
    title, 
    body,
    btnLeftAction, 
    btnRightAction,
    btnLeftText = 'CANCELAR',
    btnRightText = 'ACEPTAR',
    noCancelButton,
}) => {

    let justifyButtons = 'space-between';
    if (noCancelButton) justifyButtons = 'center';

    return (
    <div className='main-container-card-data'>
        {
        title &&
        <div className='header-form-card-data'>
            <h2>{title}</h2>
        </div>
        }
        <div className='body-form-card-data-data'>
            {body}
        </div>
        <div className='footer-form-card-data' style={{justifyContent: `${justifyButtons}`}}>
            {
                !noCancelButton &&
                <Button type='button' onClick={btnLeftAction} className={'button-back modal-card'} style={{width: '40%'}}>{btnLeftText}</Button>
            }
            <Button type='button' onClick={btnRightAction} className={'button-next modal-card'} style={{width: '40%'}}>{btnRightText}</Button>
        </div>
    </div>
    )
}

CardContainer.propTypes = {
    title: PropTypes.string,
    body: PropTypes.any,
    btnLeftAction: PropTypes.func,
    btnRightAction: PropTypes.func,
    btnLeftText: PropTypes.any,
    btnRightText: PropTypes.any,
    noCancelButton: PropTypes.bool,
}
