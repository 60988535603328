/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button } from './../../../components/atoms';
import '../../../styles/stylesSteppers.scss';
import arrow from './../../../assets/images/navieras/arrowButton.png'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import './RecordsData.scss';
import { containerType, status } from '../../../constants/dummyData';
import { useForm } from 'react-hook-form';
import _ from "lodash"
import { useEffect } from 'react';
import { upsertExpedient } from '../../../redux/actions/recordAction';
import { useDispatch } from 'react-redux';
import Modal from '../../../components/organisms/Modal/Modal';
import { CardContainer } from '../../../components/molecules/CardContainer/CardContainer';
import { RECORDS } from '../../../routes/Paths';
import { BaseModalBody } from '../../../components/molecules/ModalBody/BaseModalBody';
import { cancelUploadOfDocs, noDocumentsUploded } from '../../../components/molecules/ModalBody/modalBodyVaribles';

const RecordsData = ({ setStepper, stepper, formData, setFormData }) => {

    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();


    const folioLocation = _.get(location, 'payload.row.expedient.folioTl')
    const numberMblLocation = _.get(location, 'payload.row.expedient.numberMbl')
    const noContainersLocation = _.get(location, 'payload.row.expedient.noContainers')
    const navieraLocation = _.get(location, 'payload.row.expedient.naviera')
    const quiantityContainersLocation = _.get(location, 'payload.row.expedient.quiantityContainers')
    const teusLocation = _.get(location, 'payload.row.expedient.teus', '')
    const edtLocation = _.get(location, 'payload.row.expedient.etd', '')
    const etaLocation = _.get(location, 'payload.row.expedient.eta', '')
    const departureDateLocation = _.get(location, 'payload.row.expedient.departureDate', '')
    const arrivalPortDateLocation = _.get(location, 'payload.row.expedient.arrivalPortDate', '')
    const statusLocation = _.get(location, 'payload.row.expedient.status', '')



    const folioTlDefault = _.get(formData, 'folioTl', '')
    const numberMblDefault = _.get(formData, 'numberMbl', '')
    const noContainersDefault = _.get(formData, 'noContainers', '')
    const navieraDefault = _.get(formData, 'naviera', '')
    const quiantityContainersDefault = _.get(formData, 'quiantityContainers', '')
    // const teamDefault = _.get(formData, 'loca', '')
    const teusDefault = _.get(formData, 'teus', '')
    const edtDefault = _.get(formData, 'etd', '')
    const etaDefault = _.get(formData, 'eta', '')
    const departureDateDefault = _.get(formData, 'departureDate', '')
    const arrivalPortDateDefault = _.get(formData, 'arrivalPortDate', '')
    const statusDefault = _.get(formData, 'status', '')


    const defaultValues = {
        folioTl: folioTlDefault ? folioTlDefault : folioLocation,
        numberMbl: numberMblDefault ? numberMblDefault : numberMblLocation,
        noContainers: noContainersDefault ? noContainersDefault : noContainersLocation,
        naviera: navieraDefault ? navieraDefault : navieraLocation,
        quiantityContainers: quiantityContainersDefault ? quiantityContainersDefault : quiantityContainersLocation,
        // team: teamDefault,
        teus: teusDefault ? teusDefault : teusLocation,
        etd: edtDefault ? edtDefault : edtLocation,
        eta: etaDefault ? etaDefault : etaLocation,
        departureDate: departureDateDefault ? departureDateDefault : departureDateLocation,
        arrivalPortDate: arrivalPortDateDefault ? arrivalPortDateDefault : arrivalPortDateLocation,
        status: statusDefault ? statusDefault :  statusLocation,
    };


    const methods = useForm({ defaultValues: defaultValues });
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;

    const [openModal, setOpenModal] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);


    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    const toggleModal2 = () => {
        setOpenModal2(!openModal);
    }



    const [data, setData] = useState({
        folioTl: folioTlDefault,
        numberMbl: numberMblDefault,
        noContainers: noContainersDefault,
        naviera: navieraDefault,
        quiantityContainers: quiantityContainersDefault,
        // team: teamDefault,
        teus: teusDefault,
        etd: edtDefault,
        eta: etaDefault,
        departureDate: departureDateDefault,
        arrivalPortDate: arrivalPortDateDefault,
        status: statusLocation,
    });

    const changeStage = () => {
        setStepper(stepper + 1);
        console.warn(data);
    }


    const expedient_ = {
        quotationId: location && location.payload && location.payload.row && location.payload.row.expedient &&  location.payload.row.expedient.quotationId,
        clientId: location && location.payload && location.payload.row && location.payload.row.expedient &&  location.payload.row.expedient.clientId,
        mov: location && location.payload && location.payload.row && location.payload.row.expedient &&  location.payload.row.expedient.mov,
        folioTl: data.folioTl ? data.folioTl : defaultValues.folioTl  ? defaultValues.folioTl : '',
        numberMbl: data.numberMbl ? data.numberMbl : defaultValues.numberMbl ?  defaultValues.numberMbl :'',
        noContainers: data.noContainers ? data.noContainers : defaultValues.noContainers ? defaultValues.noContainers : '',
        naviera:  data.naviera ?  data.naviera : defaultValues.naviera ? defaultValues.naviera : '',
        quiantityContainers:  data.quiantityContainers ?  data.quiantityContainers : defaultValues.quiantityContainers ? defaultValues.quiantityContainers : '',
        team: location && location.payload && location.payload.row && location.payload.row.expedient && location.payload.row.expedient.team,
        teus: data.teus ? data.teus : defaultValues.teus ?defaultValues.teus : '',
        etd: data.etd ? data.etd : defaultValues.etd ? defaultValues.etd : '',
        eta: data.eta ? data.eta : defaultValues.eta ? defaultValues.eta : '',
        departureDate: data.departureDate ? data.departureDate : defaultValues.departureDate ? defaultValues.departureDate : '',
        arrivalPortDate:data.arrivalPortDate ? data.arrivalPortDate : defaultValues.arrivalPortDate ? defaultValues.arrivalPortDate : '',
        status: data.status ? data.status : defaultValues.status ? defaultValues.status : '',
    }

    const expedient = JSON.stringify(expedient_);


    const generateExpedient = async () => {

        const formData_ = new FormData()

        formData_.append("id", location.payload.row.expedientId)
        formData_.append("expedient", expedient);
        formData_.append("customAgent", '{}');
        formData_.append("arrivalDates", '{}');
        formData_.append("paymentDetails", '{}');
        formData_.append("pickupDelivery", '{}');
        formData_.append("delay", '{}');
        formData_.append("route", '{}');
        formData_.append("deviation", '[]');
        formData_.append("tracing", '[]');
        formData_.append("mediasDeleted", '[]');
        formData_.append("File", '[]');

    
        const res = await dispatch(upsertExpedient(formData_))
        if (res) {
            history.push('/dashboard/expedientes');
        }
    }


    const onSubmit = async ({ folioTl, numberMbl, noContainers, naviera, quiantityContainers, team, teus, etd, eta, departureDate, arrivalPortDate,status }) => {

        const dataNew = {
            quotationId: location.payload.row.expedient.quotationId,
            folioTl,
            numberMbl,
            noContainers,
            quiantityContainers,
            naviera,
            team: location.payload.row.expedient.team,
            teus,
            etd,
            eta,
            departureDate,
            arrivalPortDate,
            status:data.status,
        }

        setFormData(dataNew)
        changeStage()
    }

    const cancel = () => {
        history.push(RECORDS);
    }

    return (
        <>
            {
                openModal &&
                <Modal
                    isOpen={openModal}
                    toggle={toggleModal}
                >
                    <CardContainer
                        btnLeftAction={toggleModal}
                        btnRightAction={cancel}
                        body={<BaseModalBody data={cancelUploadOfDocs} />}
                        btnLeftText={'REGRESAR'}
                    />
                </Modal>
            }
            {
                openModal2 &&
                <Modal
                    isOpen={openModal2}
                    toggle={toggleModal2}
                >
                    <CardContainer
                        btnLeftAction={toggleModal2}
                        btnRightAction={cancel}
                        body={<BaseModalBody data={noDocumentsUploded} />}
                        btnLeftText={'REGRESAR'}
                    />
                </Modal>
            }


            <form className='main-container-generic-type' onSubmit={handleSubmit(onSubmit)}>
                <div className='header-form-generic-type'>
                    <h2>Datos del expediente</h2>
                    <h3>ID: {location && location.payload && location.payload.row && location.payload.row.id}</h3>
                </div>
                <div className='body-form-generic-type-data record-data' style={{ height: '45vh' }}>
                    <div className='input-container-data record-data'>
                        <h3>Datos del cliente</h3>
                        <div className='input-column-container records'>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>TL (Número de folio)</label>
                                </div>
                                <div className='value-container'>
                                    <input
                                        className="input-generic"
                                        type="text"
                                        disabled={false}
                                        control={control}
                                        placeholder="Agregar..."
                                        name='folioTl'
                                        {...register('folioTl')}
                                        //  value={location && location.payload && location.payload.row && location.payload.row.expedient && location.payload.row.expedient.folioTl}
                                        onChange={(e) => setData({ ...data, folioTl: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Cliente</label>
                                </div>
                                <div className='value-container'>
                                    <input
                                        className="input-generic"
                                        type="text"
                                        disabled={false}
                                        placeholder="Nombre del cliente"
                                        control={control}
                                        value={location && location.payload && location.payload.row && location.payload.row.client.tradename}
                                        onChange={(e) => setData({ ...data, client: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='input-container-data record-data'>
                        <h3>Datos de contenedores</h3>
                        <div className='input-column-container records'>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Número de MBL</label>
                                </div>
                                <div className='value-container' >
                                    <input
                                        type='text'
                                        placeholder="Agregar..."
                                        name='numberMbl'
                                        disabled={false}
                                        {...register('numberMbl')}
                                        control={control}
                                        // value={defaultValues.numberMbl}
                                        onChange={(e) => setData({ ...data, numberMbl: e.target.value })}
                                        className='input-generic'
                                    />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Número de contenedores</label>
                                </div>
                                <div className='value-container' >
                                    <input
                                        type='text'
                                        placeholder="Agregar..."
                                        disabled={false}
                                        name='noContainers'
                                        {...register('noContainers')}
                                        control={control}
                                        // value={location && location.payload && location.payload.row && location.payload.row.expedient && location.payload.row.expedient.noContainers} 
                                        onChange={(e) => setData({ ...data, noContainers: e.target.value })}
                                        className='input-generic'
                                    />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Naviera</label>
                                </div>
                                <div className='value-container' >
                                    <input
                                        type='text'
                                        placeholder="Agregar..."
                                        name='naviera'
                                        disabled={false}
                                        {...register('naviera')}
                                        control={control}
                                        // value={location && location.payload && location.payload.row && location.payload.row.expedient && location.payload.row.expedient.naviera}
                                        onChange={(e) => setData({ ...data, naviera: e.target.value })}
                                        className='input-generic'
                                    />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Cantidad de contenedores</label>
                                </div>
                                <div className='value-container' >
                                    <input
                                        placeholder="Agregar..."
                                        type='text'
                                        disabled={false}
                                        name='quiantityContainers'
                                        {...register('quiantityContainers')}
                                        control={control}
                                        //  value={location && location.payload && location.payload.row && location.payload.row.expedient && location.payload.row.expedient.quiantityContainers ? location.payload.row.expedient.quiantityContainers : ''}
                                        onChange={(e) => setData({ ...data, quiantityContainers: e.target.value })}
                                        className='input-generic'
                                    />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Equipo</label>
                                </div>
                                <div className='value-container' >
                                    <input
                                        placeholder="Agregar..."
                                        // type='number' 
                                        disabled={false}
                                        control={control}
                                        value={location && location.payload && location.payload.row && location.payload.row.expedient && location.payload.row.expedient.team}
                                        // onChange={(e) => setData({ ...data, quiantityContainers: e.target.value })}
                                        className='input-generic'
                                    />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>TEUS</label>
                                </div>
                                <div className='value-container' >
                                    <input
                                        placeholder="Agregar..."
                                        name='teus'
                                        {...register('teus')}
                                        control={control}
                                        type='text'
                                        disabled={false}
                                        // value={location && location.payload && location.payload.row && location.payload.row.expedient && location.payload.row.expedient.teus}
                                        onChange={(e) => setData({ ...data, teus: e.target.value })}
                                        className='input-generic'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='input-container-data record-data'>
                        <h3>Otros datos</h3>
                        <div className='input-column-container records'>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>ETD</label>
                                </div>
                                <div className='value-container'>
                                    <input

                                        className="input-generic date"
                                        type="date"
                                        name='etd'
                                        disabled={false}
                                        {...register('etd')}
                                        placeholder="Agregar..."
                                        control={control}
                                        // value={location && location.payload && location.payload.row && location.payload.row.expedient && location.payload.row.expedient.etd}
                                        onChange={(e) => setData({ ...data, etd: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>ETA</label>
                                </div>
                                <div className='value-container'>
                                    <input
                                        className="input-generic date"
                                        type="date"
                                        placeholder="Agregar..."
                                        name='eta'
                                        disabled={false}
                                        {...register('eta')}
                                        control={control}
                                        // value={location && location.payload && location.payload.row && location.payload.row.expedient && location.payload.row.expedient.eta}
                                        onChange={(e) => setData({ ...data, eta: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Fecha del Zarpe</label>
                                </div>
                                <div className='value-container'>
                                    <input
                                        className="input-generic date"
                                        type="date"
                                        disabled={false}
                                        placeholder="Agregar..."
                                        name='departureDate'
                                        {...register('departureDate')}
                                        control={control}
                                        // value={location && location.payload && location.payload.row && location.payload.row.expedient && location.payload.row.expedient.departureDate} 
                                        onChange={(e) => setData({ ...data, departureDate: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>Fecha del Llegada al puerto</label>
                                </div>
                                <div className='value-container'>
                                    <input
                                        className="input-generic date"
                                        type="date"
                                        disabled={false}
                                        placeholder="Agregar..."
                                        name='arrivalPortDate'
                                        {...register('arrivalPortDate')}
                                        control={control}
                                        // value={location && location.payload && location.payload.row && location.payload.row.expedient && location.payload.row.expedient.arrivalPortDate} 
                                        onChange={(e) => setData({ ...data, arrivalPortDate: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className='input-container'>
                                <div className='label-container'>
                                    <label className='container-label'>STATUS</label>
                                </div>

                                <div className='value-container' >
                                    <select className='input-select' value={data.status} onChange={(e) => {
                                         setData({ ...data, status: e.target.value });
                                    }}>
                                        <option className='option-selected' value={null} selected hidden>{location && location.payload && location.payload.row && location.payload.row.expedient ? location.payload.row.expedient.status : Seleccionar}</option>
                                        {
                                            status &&
                                            status.map(ele => {
                                                return <option key={ele} value={ele.value}>{ele.value}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-form-generic-type records'>
                    <Button type='button' onClick={toggleModal} className={'button-back'} style={{ width: '28%' }}>CANCELAR</Button>
                    <Button type='button' onClick={() => generateExpedient()} className={'button-generate'} style={{ width: '28%' }}>GUARDAR</Button>
                    <Button type='submit' className={'button-next'} srcicon={arrow} style={{ width: '30%' }}>SIGUIENTE</Button>
                </div>
            </form>
        </>
    )

}

export default RecordsData;
