import React from 'react';
import PropTypes from 'prop-types';
import '../CancelFormBody/CancelFormBody.scss';

export const BaseModalBody = ({data}) => {
  return (
    <div className='cancel-body-container'>
        {
          data.src &&
          <div className='img-modal-container'>
              <img src={data.src}/>
          </div>
        }
        <h2>{data.title}</h2>
        <p>{data.body}</p>
    </div>
  )
}

BaseModalBody.propTypes = {
    data: PropTypes.object,
}
