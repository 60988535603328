import React, { useState } from 'react'
import '../../../styles/tableViewStyles.scss';
import { ActionMenu, DataTable } from '../../../components/atoms';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllExpedientDeletedAction, recoveExpedientAction } from '../../../redux/actions/recordAction';
import { Box, MenuItem } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';

const RecordDeleted = () => {

  const [searchCondition, setSearchCondition] = useState({});
  const dispatch = useDispatch()
  const getExpedient = useSelector(state => state.record.getAllRecordDeleted.data)




  const getNewData = (searchCondition) => {
    // TODO: Restrucutrar vs Filtros*
    setSearchCondition(searchCondition);
  };


  useEffect(() => {
    dispatch(getAllExpedientDeletedAction({
      "offset": 0, "limit": 500
    }))
  }, [])

  const recoveRecord = (item) => {
    dispatch(recoveExpedientAction({ "id": item.id }))
    dispatch(getAllExpedientDeletedAction({ "offset": 0, "limit": 500 }))

  }

  const columnsGrid = [
    {
      field: 'id',
      headerName: 'ID',
      width: 50,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      valueGetter: (params) =>
        `${params.row.id || 'null'}`
    },
    {
      field: 'tl',
      headerName: 'TL',
      headerAlign: 'center',
      width: 150,
      headerClassName: 'super-app-theme--header',
      valueGetter: (params) =>
        `${params.row.expedient.folioTl || 'Sin informacion'}`,
    },
    {
      field: 'client',
      headerName: 'Cliente',
      width: 200,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      valueGetter: (params) =>
        `${params.row.client.tradename || 'Sin informacion'}`,
    },
    {
      field: 'estatus',
      headerName: 'Estatus',
      type: 'number',
      width: 110,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      valueGetter: (params) =>
        `${params.row.firstName || 'Sin informacion'} `,
    },
    {
      field: 'mov',
      headerName: 'Mov',
      sortable: false,
      width: 150,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      valueGetter: (params) =>
        `${params.row.expedient.mov || 'Sin informacion'} `,
    },
    {
      field: 'bl',
      headerName: 'BL',
      sortable: false,
      width: 150,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      valueGetter: (params) =>
        `${params.row.expedient.numberMbl || 'Sin informacion'}`,
    },
    {
      field: 'noContenedores',
      headerName: 'No.Contenedores',
      sortable: false,
      width: 180,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      valueGetter: (params) =>
        `${params.row.expedient.noContainers || 'Sin informacion'}`,
    },
    {
      field: 'naviera',
      headerName: 'Naviera',
      sortable: false,
      width: 150,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      type: 'number',
      valueGetter: (params) =>
        `${params.row.expedient.naviera || 'Sin informacion'}`,
    },
    {
      field: 'cnt',
      headerName: '#Cnt',
      sortable: false,
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      valueGetter: (params) =>
        `${params.row.expedient.quiantityContainers || 'Sin informacion'}`,
    },
    {
      field: 'equipo',
      headerName: 'Equipo',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      width: 150,
      headerAlign: 'center',
      valueGetter: (params) =>
        `${params.row.expedient.team || 'Sin informacion'}`,
    },
    {
      field: 'teus',
      headerName: 'TEUS',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      width: 150,
      headerAlign: 'center',
      valueGetter: (params) =>
        `${params.row.expedient.teus || 'Sin informacion'}`,
    },
    {
      field: 'agenteAduanal',
      headerName: 'Agente Aduanal',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      width: 250,
      headerAlign: 'center',
      valueGetter: (params) =>
        `${params.row.customAgend && params.row.customAgend.agentname || 'Sin informacion'} `,
    },
    {
      field: 'mode',
      headerName: 'MODE',
      sortable: false,
      width: 150,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      valueGetter: (params) =>
        `${params.row.pickupDelivery.mode == 1 ? 'SD-CY'
          : params.row.pickupDelivery.mode == 2 ? 'SD-SD'
            : params.row.pickupDelivery.mode == 3 ? 'CY-CY'
              : params.row.pickupDelivery.mode == 4 ? 'CY-SD'
                : 'Sin informacion'}`,
    },
    {
      field: 'modalidad',
      headerName: 'Modalidad',
      sortable: false,
      width: 150,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      valueGetter: (params) =>
        `${params.row.pickupDelivery.pickUp || 'Sin informacion'}`,
    },
    {
      field: 'pickup',
      headerName: 'Pick Up',
      sortable: false,
      width: 150,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      valueGetter: (params) =>
        `${params.row.pickupDelivery.pickUp || 'Sin informacion'}`,
    },
    {
      field: 'pol',
      headerName: 'POL',
      sortable: false,
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      valueGetter: (params) =>
        `${params.row.originPort.port || 'N/A'}`,
    },
    {
      field: 'pod',
      headerName: 'POD',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      width: 150,
      headerAlign: 'center',
      valueGetter: (params) =>
        `${params.row.destinyPort.port || 'N/A'}`,
    },
    {
      field: 'fpod',
      headerName: 'FPOD',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      width: 140,
      headerAlign: 'center',
      valueGetter: (params) =>
        `${params.row.pickupDelivery.fpod || 'Sin informacion'}`,
    },
    {
      field: 'etd',
      headerName: 'ETD',
      sortable: false,
      width: 120,
      type: 'date',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      valueGetter: (params) =>
        `${params.row.expedient.etd || 'Sin informacion'}`,
    },
    {
      field: 'eta',
      headerName: 'ETA',
      sortable: false,
      width: 120,
      type: 'date',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      valueGetter: (params) =>
        `${params.row.expedient.eta || 'Sin informacion'}`,
    },
    {
      field: 'diasDemora',
      headerName: 'Dias libre de demora',
      sortable: false,
      width: 180,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      valueGetter: (params) =>

        `${params.row.arrivalDates.daysOfDelay || 'Sin informacion'}`,
    },
    {
      field: 'ultimoDiaDemora',
      headerName: 'Ultimo dia de demora',
      sortable: false,
      width: 180,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      valueGetter: (params) =>
        `${params.row.paymentDetails.paydayLimit || 'Sin informacion'}`,
    },
    {
      field: 'action',
      headerName: 'Acciones',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      width: 90,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <ActionMenu id={[params.row.id]} >
            <MenuItem onClick={() => recoveRecord(params)}> Recuperar Expediente</MenuItem>
          </ActionMenu>
        );
      },
    }
  ];

  return (
    <>
      <section className='ClientRecordAccountSection' >
        <div className='ClientRecordAccountSection__container'>
          <div className='ClientRecordAccountSection__tableContainer'>
            <Box sx={{
              height: 300,
              width: '100%',
              '& .super-app-theme--header': {
                backgroundColor: '#707070',
                color: '#ffff',
                fontFamily: 'Montserrat',
                fontSize: '.9vw',
              },
            }}>
              <DataGrid
                sx={{
                  '@media print': {
                    '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)' },
                  }
                }}
                localeText={{
                  noRowsLabel: "No se encontraron datos"
                }}
                rows={getExpedient}
                columns={columnsGrid}
                pageSize={10}
                rowsPerPageOptions={[10]}
                // components={{ Toolbar: descargarExpedient }}
                checkboxSelection={false}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}

              />
            </Box>
          </div>
        </div>
      </section>
    </>
  )
}


export default RecordDeleted;
