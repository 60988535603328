/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Button } from '../../../../components/atoms';
// import './ClientRegister.scss';
import '../../../../styles/stylesSteppers.scss'
import arrow from '../../../../assets/images/navieras/arrowButton.png'
import docIcon from '../../../../assets/images/navieras/docIcon.png'
import add from '../../../../assets/images/navieras/AGREGAR.png'
import { useForm } from 'react-hook-form';
import _ from 'lodash'
import { UploadFile } from '../../../../components/organisms/UploadFile/UploadFile';
import { upsertClientAction } from '../../../../redux/actions/clientAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';


export const ClientDocuments = ({ stepper, setStepper, toggleModal, formData, setFormData, mode = "crear" }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const methods = useForm({ defaultValues: defaultValues });
    const typeAction = useSelector(state => state.router.location.hash)
    const [mediasDeleted, setMediasDeleted] = useState([]);
    const [isDisabled, setIsDisabled] = useState(mode === 'ver' ? true : false);
    const [watchDocumentMedia, setWatchDocumentMedia] = useState(false);
    const [deletedFiles, setDeletedFiles] = useState([]);
    const [last, setLast] = useState(null);
    const agent = JSON.stringify(formData && formData.agent ? formData.agent : []);
    const person = JSON.stringify(formData.person);


    const defaultValues = {
        // name : '',
    };

    const {
        id,
        businessname,
        country,
        creatorId,
        direction,
        isfisica,
        ismoral,
        paymentmethods,
        postcode,
        rfc,
        state,
        tradename,
        zone,
        file,
    } = formData;

    const client = {
        businessname,
        country,
        creatorId,
        direction,
        isfisica,
        ismoral,
        paymentmethods,
        postcode,
        rfc,
        state,
        tradename,
        zone,
        file
    }

    const clientD = JSON.stringify(client);


    const { register, handleSubmit, watch } = useForm();


    const changeStage = () => {
        setStepper(stepper + 1)
    }

    const changeStageBack = () => {
        setStepper(stepper - 1)
    }


    const [files, setFiles] = useState(
        [
            {
                name: 'RFC',
                id: 0,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'Situación fiscal',
                id: 1,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'INE, pasaporte, cédula profesional',
                id: 2,
                file: null,
                deletedAt: false,
                watch: false
            },
        ]
    )

    useEffect(() => {
        if (mode != 'crear') {
            if (file && file != null && file != undefined && file.length >= 1) {
                let newFiles = file.map((el, i) => {
                    return {
                        id: el.fileId,
                        file: {
                            url: el.file.url,
                            name: el.file.url,
                            deletedAt: el.deletedAt,
                        },
                        watch: true
                    }
                })
                setFiles(newFiles)
            }
        }
    }, []);

    const [fileToSend, setFileToSend] = useState([]);

    const data = [
        { name: 'RFC' }, { name: 'Situacion  Fiscal' }, { name: 'INE, pasaporte, cédula profesional' }
    ]

    const handleAdd = () => {
        let arrayCopy = [...files];
        let lastElement = _.last(files);
        arrayCopy.push(
            {
                name: 'Archivo',
                id: lastElement.id + 1,
                file: null,
                watch: false,
                deletedAt: false,
                newAdd: true,
            }
        )
        setFiles(arrayCopy);
    }



    const arrFiles2 = files.map(file => file.file != null ? file : false).filter(file => file != false)




    const submitData = async () => {

        if (mode === "crear") {
            const formData_ = new FormData();

            if (_.isEmpty(files)) { toggleModalNoFiles() }

            formData_.append("client", clientD);
            formData_.append("person", person);
            formData_.append("agent", agent);
            formData_.append("mediasDeleted", JSON.stringify(mediasDeleted));

            const arrFiles = files.map(file => file.file != null ? file : false).filter(file => file != false)

            if (arrFiles.length > 0) {
                for (let i = 0; i < arrFiles.length; i++) {
                        formData_.append("File", arrFiles[i].file);
                }
            } else {
                formData_.append("File", '[]');
            }

            const res = await dispatch(upsertClientAction(formData_))

            if (res) {
                history.push('/dashboard/gestion-clientes');
            }

        } else {

            // const arrFilesSend = files.map(file => file.file != null ? file : false).filter(file => file != false)
            // const fileSend = []
            // for (let i = 0; i < arrFilesSend.length; i++) {
            //   fileSend.push(arrFilesSend[i].file)
            // }



            let data = {
                ...formData,
                file: files,
                mediasDeleted: mediasDeleted
            }

            setFormData(data)
            changeStage()
        }

    }

    return (
        <form className='main-container-generic-type'>
            <div className='header-form-generic-type'>
                <h2>Documentos</h2>
            </div>
            <div className='body-form-generic-type-docs'>
                {
                    files.map((ele, i) => {
                        return (
                            <UploadFile
                                setArrayDeleted={setMediasDeleted}
                                arrayDeleted={mediasDeleted}
                                key={i}
                                files={ele}
                                last={last}
                                setFiles={setFiles}
                                arrayComplete={files}
                                arrToSend={fileToSend}
                                setArrToSend={setFileToSend}
                                disabled={isDisabled}
                                mode={mode}
                            />
                        )
                    })
                }
                <div className='add-button-container'>
                    {
                        mode != 'ver' &&
                        <img className='add-card' src={add} onClick={handleAdd} />
                    }
                </div>
            </div>
            <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                <Button type='button' onClick={toggleModal ? toggleModal : changeStageBack} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                <Button type='button' onClick={submitData} className={'button-next'} style={{ width: '40%' }}>{mode === 'ver' || mode === 'editar' ? 'SIGUIENTE' : 'GUARDAR'}</Button>
            </div>
        </form>
    )
}
