/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect, useSelector} from 'react-redux';
import { toggleSidebar } from '../../../../redux/actions/userInterfaceAction';
import SidebarOptionItem from './SidebarOptionItem';
// import {
//     adminSidebarOptions,
//     operativeSidebarOptions,
// } from './sidebarOptions';

// SRC & STYLES
import './Sidebar.scss';
import menu from './../../../../assets/images/menu.png';
import logo from  '../../../../assets/images/navieras/logo-nav.png';


const Sidebar = (props) => {

    const onClickToogle = () => {
        props.toggleSidebar();
    }
    
    const openMenu = () => {
        const openButton = document.getElementById('menu');
        openButton.click();
    }   

    let mainClass = ['sidebar'];
    let sidebar = '';

    if (!props.isSidebarOpened) {
        mainClass.push('navMin');
        sidebar ='panel';
    } else {sidebar = 'activePanel';}


    return (
        <aside>
            <nav className={mainClass.join(' ')} >
                <div className='header-logo'>
                    <div className="logo-container">
                        <img src={logo} alt="navieras logo" />
                    </div>
                    <div className='toogle-button-container'>
                        <button 
                            className="header-toggle-btn pull-right" 
                            onClick={onClickToogle} 
                            id='menu'
                        />
                        <img
                            className='menuButton'
                            src={menu}
                            onClick={openMenu}
                        />
                    </div>
                </div>
                <div className={sidebar}>
                    {
                        props.sidebarOptions.map( option =>
                            <SidebarOptionItem
                                img={option.img}
                                optionName={option.optionName}
                                link={option.link}
                                sidebarState={props.isSidebarOpened}
                                onclick={onClickToogle}
                            />
                        )
                    }
                </div>
            </nav>
        </aside>
    );
}

Sidebar.propTypes = {
    isSidebarOpened: PropTypes.any,
    toggleSidebar: PropTypes.func,
    user: PropTypes.any,
    sidebarOptions: PropTypes.any,
}

function mapStateToProps (state) {
    return {
        isSidebarOpened: _.get(state.userInterface, 'isSidebarOpened', false),
        user: _.get(state.user, 'user', []),
        role: _.get(state.user,'user.roles[0]', false),
    };
}

const mapDispatchToProps = dispatch => ({
    toggleSidebar: () => dispatch(toggleSidebar()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
