import request from "../../utils/request";
import { addNotification } from './notificationAction';

import {
    COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_BEGIN,
    COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_SUCCESS,
    COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_FAILURE,
} from '../actionTypes';

// ACTIONS TO REDUCER
export const upsertCostumerCustomAgentBegin = () => ({
    type: COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_BEGIN,
});

export const upsertCostumerCustomAgentSuccess = (company) => ({
    type: COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_SUCCESS,
    company,
});

export const upsertCostumerCustomAgentFailure = (err) => ({
    type: COSTUMER_CUSTOM_AGENT_MODULE_UPSERT_COSTUMER_CUSTOM_AGENT_FAILURE,
    err,
});

// DISPATCH TO ACTIONS

export function upsertCostumerCustomAgentAction(data){
    return (dispatch, getState) => {
        dispatch(upsertCostumerCustomAgentBegin());
        return request({
            partialUrl: `/api/v1/association-agend-client/upsert-association-client-agent-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(upsertCostumerCustomAgentSuccess(data.data));
            return true;
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(upsertCostumerCustomAgentFailure(err));
        });
    };
}