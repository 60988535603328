/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { userLogout } from '../../../../redux/actions/userActions';
import { toggleSidebar } from '../../../../redux/actions/userInterfaceAction';
import OptionsSelector from '../../../molecules/OptionsSelector';
import { PROFILE } from '../../../../routes/Paths';
import { getOneOperativeAction } from '../../../../redux/actions/operativeAction';
import { getOneAdministratorRecordAction } from '../../../../redux/actions/adminAction';


// src and styles
import './Header.scss';
import backButton from '../../../../assets/images/navieras/back-arrow.png';


const Header = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    let location = useLocation();
    const [text,setText] = useState('');
    
    const actualUrl = window.location.href;

    const user = useSelector(state => state.user.user);
    const toggle = useSelector(state => state.userInterface.isSidebarOpened);

    
    useEffect(()=>{
        if (location.pathname.includes('inicio')) {
            setText('Inicio')
        } else if (location.pathname.includes('admin')) {
            setText('Administrador')
        }
        else if(location.pathname.includes('gestion-clientes')){
            setText('Clientes')
        }
        else if(location.pathname.includes('agentes-aduanales')){
            setText('Agentes aduanales')
        }
        else if(location.pathname.includes('cotizacion')){
            setText('Cotización')
        }
        else if(location.pathname.includes('expedientes')){
            setText('Gestión de expedientes')
        }
        else if(location.pathname.includes('operativos')){
            setText('Gestión operativos')
        }
        else if(location.pathname.includes('proveedores')){
            setText('Gestión de proveedores')
        }
        else if(location.pathname.includes('puertos')){
            setText('Gestión de puertos')
        }
        else if(location.pathname.includes('aduanales')){
            setText('Agentes aduanales')
        } else if (location.pathname.includes('record-delete')) {
            setText('Gestión de expedientes')
        } else {
            setText('');
        }
      },[location])


    const handleClickLogOut = () => {
        history.push('/app/iniciar-sesion');
        props.userLogout();
    }

    let role;
    if (props.isOperative) role = 'Operativo';
    else role = 'Administrador';

    const seeProfile = () => {
        let result;
        const getProfileInfo = async () => {
            if (props.isOperative) result = await dispatch(getOneOperativeAction({ id: user.operativeId }));
            else result = await dispatch(getOneAdministratorRecordAction({ userId: user.id }));
        }
        getProfileInfo().then(() => {
            console.warn(result)
            history.push('/dashboard/perfil');
        });
    }

    const options = [
        {
            name: 'Mi perfil',
            path: PROFILE,
            onClick: seeProfile,
        },
        {
            name: 'Log out',
            path: '/app/iniciar-sesion',
            onClick: handleClickLogOut,
        },
    ]

    const onClickGoBack = () => {
        history.goBack();
    }

    return (
        <header>
            <nav className={toggle ? 'header container-fluid' : 'header headerClose container-fluid'}>
                <div className='headerSecondContainer'>
                    <img 
                        className='backButton' 
                        src={backButton} 
                        onClick={onClickGoBack}
                    />
                    <span className='txt-header'>{text}</span>
                </div>
                <div className='headerFifthContainer'>
                    <OptionsSelector role={role} options={options} />
                </div>
            </nav>
        </header>
    );
}

Header.propTypes = {
    title: PropTypes.string,
    userLogout: PropTypes.func,
    isSidebarOpened: PropTypes.any,
    toggleSidebar: PropTypes.func,
    userName: PropTypes.any,
}

function mapStateToProps (state) {
    return {
        isSidebarOpened: _.get(state.userInterface, 'isSidebarOpened', false),
    };
}

const mapDispatchToProps = dispatch => ({
    userLogout: () => dispatch(userLogout()),
    toggleSidebar: () => dispatch(toggleSidebar()),})

export default connect(mapStateToProps, mapDispatchToProps)(Header);
