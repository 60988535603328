import {
    PROVIDER_MODULE_GET_ALL_PROVIDERS_BEGIN,
    PROVIDER_MODULE_GET_ALL_PROVIDERS_SUCCESS,
    PROVIDER_MODULE_GET_ALL_PROVIDERS_FAILURE,
    PROVIDER_MODULE_UPSERT_PROVIDER_BEGIN,
    PROVIDER_MODULE_UPSERT_PROVIDER_SUCCESS,
    PROVIDER_MODULE_UPSERT_PROVIDER_FAILURE,
    PROVIDER_MODULE_DELETE_PROVIDER_BEGIN,
    PROVIDER_MODULE_DELETE_PROVIDER_SUCCESS,
    PROVIDER_MODULE_DELETE_PROVIDER_FAILURE,
} from '../actionTypes';

import initialState from '../initialState';

export default function providerReducer(state = initialState.provider, action) {
    switch (action.type) {
        case PROVIDER_MODULE_GET_ALL_PROVIDERS_BEGIN:
            return {
                ...state,
                providers: {
                    ...state.providers,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    totalRecords: 0,
                    data: [],
                },
            };
        case PROVIDER_MODULE_GET_ALL_PROVIDERS_SUCCESS:
            return {
                ...state,
                providers: {
                    ...state.providers,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    totalRecords: action.data.totalRecords,
                    data: action.data.data,
                },
            };
        case PROVIDER_MODULE_GET_ALL_PROVIDERS_FAILURE:
            return {
                ...state,
                providers: {
                    ...state.providers,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
        case PROVIDER_MODULE_UPSERT_PROVIDER_BEGIN:
            return {
                ...state,
                upsertProvider: {
                    ...state.upsertProvider,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: {},
                },
            };
        case PROVIDER_MODULE_UPSERT_PROVIDER_SUCCESS:
            return {
                ...state,
                upsertProvider: {
                    ...state.upsertProvider,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.provider,
                },
            };
        case PROVIDER_MODULE_UPSERT_PROVIDER_FAILURE:
            return {
                ...state,
                upsertProvider: {
                    ...state.upsertProvider,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: {},
                },
            };
        case PROVIDER_MODULE_DELETE_PROVIDER_BEGIN:
            return {
                ...state,
                deleteProvider: {
                    ...state.deleteProvider,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: null,
                },
            };
        case PROVIDER_MODULE_DELETE_PROVIDER_SUCCESS:
            return {
                ...state,
                deleteProvider: {
                    ...state.deleteProvider,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.provider,
                },
            }
        case PROVIDER_MODULE_DELETE_PROVIDER_FAILURE:
            return {
                ...state,
                deleteProvider: {
                    ...state.deleteProvider,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: null,
                },
            }
            default:
            return state;
    }
}