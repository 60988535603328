import React from 'react';
import {connect} from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';

// STYLES
import "./Modal.scss";

const CustomModal = (props) => {

    const handleClose = () => {
      if(props.toggle){
        props.toggle();
      }
    };

        return (
        <div>
            <Modal
              className={`modalContainer ${props.classNameModal}`}
              open={props.isOpen}
              onClose={handleClose}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            >
            <div className={['modal-content', props.bigModal ? 'big-modal' : ''].join(' ')}>
                {
                  !props.noCloseBotton ? '' :
                  <div 
                    className={props.iconClassName ? props.iconClassName : 'closeIcon'} 
                    style={{width:'90%', textAlign:'right', margin: '20px', marginRight: '2.5rem'}}>
                    <CloseIcon style={{marginTop: '10px'}} className={'close-icon-modal redIcon'} onClick={handleClose} />
                  </div>
                }
                <div className={props.cnContainer ? props. cnContainer : 'modalContainer'}>
                    {props.children}
                </div>
            </div>
            </Modal>
        </div>
        );
    }


CustomModal.propTypes = {
  iconClassName: PropTypes.any,
  noCloseBotton: PropTypes.any,
  classNameModal: PropTypes.string,
  toggle: PropTypes.any,
  isOpen: PropTypes.any,
  bigModal:PropTypes.any,
  title: PropTypes.any,
  subTitle: PropTypes.any,
  children: PropTypes.any,
  cnContainer: PropTypes.any,
};

function mapStateToProps (state) {
  return{};
}

const mapDispatchToProps = (dispatch) => ({
  getReportInfo: params => console.log(params),
  test: params => console.log(params),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomModal);
