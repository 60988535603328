import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { CancelFormBody } from '../../../components/molecules/CancelFormBody/CancelFormBody';
import { CardContainer } from '../../../components/molecules/CardContainer/CardContainer';
import Modal from '../../../components/organisms/Modal/Modal';
import { StepperComponent } from '../../../components/Stepper/Stepper';
// import './Quotation.scss';
import RecordsData from './RecordsData';
import ArrivalDates from './ArrivalDates';
import DocumentsAndCustomAgent from './DocumentsAndCustomAgent';
import PaymentData from './PaymentData';
import PickupAndDeliveryData from './PickupAndDeliveryData';
import '../../../styles/stylesSteppers.scss';
import { useDispatch } from 'react-redux';
import { RECORDS } from '../../../routes/Paths';

export const RecordDetailsStepper = (props) => {
    const history = useHistory()
    const [client, setClient] = useState(null);
    const [clientPorts, setClientPorts] = useState(null);
    const [stepper, setStepper] = useState(0);
    const [formData, setFormData] = useState({});
    const [ modalitySelected, setModalitySelected ] = useState(null);
    const [ countriesSelect, setCountriesSelect ] = useState(null);
    const dispatch = useDispatch();

    const [openModal, setOpenModal] = useState(false)
    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    useEffect(() => {   
       
        const getPorts = async () => {
            const res = await dispatch(getAllPortsClientAction({clientId : client}))
            if(res){ 
                setClientPorts(res)
            }
        }
        if(client){
            getPorts();
        }
        
    }, [client])
    
    useEffect(() => {
    }, [countriesSelect])
    
    useEffect(() => {
        let countries = [];
        let puertos;
        if(clientPorts!==null && clientPorts.data.length > 0){
            clientPorts && clientPorts.data.map((ele, index) => {
                puertos = 
                    { ...puertos,
                    [ele.countryId]:{
                    countryName: ele.countryName,countryId:ele.countryId}
                    }
                })
                Object.entries(puertos).map(([key, value]) => {
                countries.push(value)
                });
                setCountriesSelect(countries)
        }else{
        }
        
    }, [clientPorts])
    
    const steps = [
        {
            label: 'Datos del expediente',
            step:0,
            component: <RecordsData stepper={stepper} setStepper={setStepper} toggleModal={toggleModal} formData={formData} setFormData={setFormData} setClient={setClient}/>
        },
        {
            /// Aqui es agregar tipo de servicio y modalidad
            label: 'Documentos & agente aduanal',
            step:1,
            component: 
                <DocumentsAndCustomAgent 
                    stepper={stepper} 
                    setStepper={setStepper} 
                    toggleModal={toggleModal}
                    modality={formData.modalityTypeId}
                    formData={formData} 
                    setFormData={setFormData}
                    clientPorts={clientPorts}
                    countriesSelect={countriesSelect}
                />
        },
        {
            /// Dependiendo el tipo de modalidad mostrar formularios aqui 
            label: 'Fechas de arribo',
            step:2,
            component: 
                <ArrivalDates 
                    stepper={stepper} 
                    setStepper={setStepper} 
                    toggleModal={toggleModal}
                    setValue={setModalitySelected}
                    // value={modalitySelected}
                    formData={formData} setFormData={setFormData}
                 />
        },
        {
            label: 'Datos de pago',
            step:4,
            component: <PaymentData stepper={stepper} setStepper={setStepper} toggleModal={toggleModal} formData={formData} setFormData={setFormData}/>
        },
        {
            label: 'Datos de recolección y entrega',
            step:5,
            component: <PickupAndDeliveryData stepper={stepper} setStepper={setStepper} toggleModal={toggleModal} formData={formData} setFormData={setFormData}/>
        },
    ] 

    const [render, setRender]= useState(null)
    useEffect(() => {
        setRender(steps[stepper].component)
    }, [stepper])
    
    const renders = steps.map((ele)=>{
        return(
            ele.component
        )
    })
    
    const cancel = ()=>{
        history.push(RECORDS)
    }
    return (
        <>
        {
            openModal &&
            <Modal
                isOpen={toggleModal}
                toggle={toggleModal}
            >
                <CardContainer btnLeftAction={toggleModal} btnRightAction={cancel} body={<CancelFormBody/>}/>
            </Modal>
        }
        <div className='main-container'>
            <div className='stepper-container'>
            <StepperComponent step={stepper} steps={steps} setStepper={setStepper}/>
            </div>
            <div className='card-container-stepper' style={{width: '80%', margin: '15px'}}>
                {
                render
                }
            </div>
        </div>
        </>
    )
}
