/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Button } from '../../../components/atoms';
// import './CustomAgent.scss';
// import './ClientRegister.scss';
import '../../../styles/stylesSteppers.scss'
import arrow from '../../../assets/images/navieras/arrowButton.png'

import docIcon from '../../../assets/images/navieras/docIcon.png'
import add from '../../../assets/images/navieras/AGREGAR.png'
import { useForm } from 'react-hook-form';
import _ from 'lodash'
import { UploadFile } from '../../../components/organisms/UploadFile/UploadFile';
import { upsertCustomAgentAction } from '../../../redux/actions/customsAgentAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
export const CustomAgentDocuments = ({ stepper, setStepper, toggleModal, formData, setFormData, setSelectedModal, mode }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const methods = useForm({ defaultValues: defaultValues });
    const typeAction = useSelector(state => state.router.location.hash)
    const defaultValues = {
        // name : '',
    };
    const [fileToSend, setFileToSend] = useState();


    // const { mode } = useParams();


    // const [isDisabled, setIsDisabled] = useState(typeAction != '#/dashboard/agentes-aduanales/1/ver' ? true : false);



    const [mediasDeleted, setMediasDeleted] = useState([]);


    const { register, handleSubmit, watch } = useForm();

    const changeStage = () => {
        setStepper(stepper + 1)
    }

    const changeStageBack = () => {
        setStepper(stepper - 1)
    }


    const {
        agentname,
        email,
        id,
        patent,
        phone,
        tradename,
        media,
        file
    } = formData

    const agent = {
        agentname,
        email,
        patent,
        phone,
        tradename,
        media,
    }

    const agend = JSON.stringify(agent)


    //  const { file } = formData;


    // const selectFile = () => {
    //     const inputFile = document.getElementById('documentMedia');
    //         inputFile.click();
    //     }



    const [watchDocumentMedia, setWatchDocumentMedia] = useState(false);
    const [last, setLast] = useState(null);
    const [files, setFiles] = useState(
        [
            {
                name: 'RFC',
                id: 0,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'Situación fiscal',
                id: 1,
                file: null,
                deletedAt: false,
                watch: false
            },
            {
                name: 'INE, pasaporte, cédula profesional',
                id: 2,
                file: null,
                deletedAt: false,
                watch: false
            },
        ]
    )



    useEffect(() => {
        if (mode != 'crear') {
            if (file && file != null && file != undefined && file.length >= 1) {
                let newFiles = file.map((el, i) => {
                    return {
                        id: el && el.fileId,
                        file: {
                            url: el && el.file &&  el.file.url,
                            name: el &&el.file && el.file.url,
                            deletedAt: el && el.deletedAt,
                        },
                        watch: true
                    }
                })
                setFiles(newFiles)
            }
        }
    }, []);


    const data = [
        { name: 'RFC' }, { name: 'Situacion  Fiscal' }, { name: 'INE, pasaporte, cédula profesional' }
    ]


    // useEffect(() => {
    //     if (_.get(formData, 'Files', []).length > 0) {
    //         let newFiles = files.map((el) => {
    //             return {
    //                 // name: 'INE, pasaporte, cédula profesional',
    //                 id: el.id,
    //                 file: {
    //                     url: el.file.url,
    //                     name: el.file.url,
    //                     deletedAt: el.deletedAt,
    //                 },
    //                 watch: true
    //             }
    //         })
    //         setFiles(newFiles)
    //     }
    // }, [])


    useEffect(() => {
        let file = [];
        files.forEach((ele) => {
            if (!_.isNil(ele.file)) {
                file.push(ele.file)
            }
        })
        setFileToSend(file)
    }, [files])



    useEffect(() => {
        let final = [...files];
        setLast(final.pop())
    }, [files])






    const handleAdd = () => {
        let arrayCopy = JSON.parse(JSON.stringify(files));
        let lastElement = files.pop();
        arrayCopy.push(
            {
                name: 'Archivo',
                id: lastElement.id + 1,
                file: null,
                watch: false,
                deletedAt: false,
                newAdd: true,
            }
        )
        setFiles(arrayCopy);
    }

    const submitData = async () => {

        if ((typeAction === "#/dashboard/agentes-aduanales/crear")) {

            if (_.isEmpty(files)) { toggleModalNoFiles() }

            const formData_ = new FormData();


            // if (typeAction === "#/dashboard/agentes-aduanales/editar") {
            //     formData_.append("id", formData.agend.id)
            // }

            let ports = JSON.stringify(formData.ports);


            // let delPort = JSON.stringify(formData.deletePorts);
            formData_.append("agend", agend);
            formData_.append("ports", ports);
            formData_.append("deletedAssociationPorts", '[]');
            formData_.append("mediasDeleted", '[]');


            const arrFiles = files.map(file => file.file != null ? file : false).filter(file => file != false)

            if (arrFiles.length > 0) {
                for (let i = 0; i < arrFiles.length; i++) {
                    formData_.append("File", arrFiles[i].file);
                }
            } else {
                formData_.append("File", '[]');
            }

            const res = await dispatch(upsertCustomAgentAction(formData_))
            if (res) {
                history.push('/dashboard/agentes-aduanales');
            }

        } else {
            let data = {
                ...formData,
                file: files,
                mediasDeleted: mediasDeleted,
            }
            setFormData(data)
            changeStage();
        }
    }


    return (
        <form className='main-container-generic-type' onSubmit={handleSubmit(submitData)}>
            <div className='header-form-generic-type'>
                <h2>Documentos</h2>
            </div>
            <div className='body-form-generic-type-docs'>
                {
                    files.map((ele, i) => {
                        return (
                            <UploadFile
                                key={i}
                                files={ele}
                                last={last}
                                setFiles={setFiles}
                                arrayComplete={files}
                                hideTitle={true}
                                setArrayDeleted={setMediasDeleted}
                                arrayDeleted={mediasDeleted}
                                setArrToSend={setFileToSend}
                                mode={mode}
                            />
                            // disabled={isDisabled} />
                        )
                    })
                }
                <div className='add-button-container' style={{ justifyContent: 'center' }}>
                    {mode != "ver" &&
                        <img className='add-card' src={add} onClick={handleAdd} />
                    }
                </div>
            </div>
            <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                <Button type='button' onClick={() => mode == 'editar' || mode == 'ver' ? changeStageBack() : (toggleModal(), setSelectedModal('cancel'))} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                <Button type='submit' className={'button-next'} srcicon={arrow} style={{ width: '40%' }}>{mode === "editar" ? "SIGUIENTE" : "GUARDAR"}</Button>
            </div>
        </form>
    )
}
