/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from '../../../components/atoms';
import { getAllExpedientAction, upsertExpedient } from '../../../redux/actions/recordAction';
import './RecordDelays.scss';

const RecordDelays = ({ toggleModal,commentaryDelay,setCommentaryDelay,daysDelay,setDaysDelay }) => {

    const location = useLocation();
    const dispatch = useDispatch()


    const [delay, setDelay] = useState({
        daysLate: daysDelay,
        reasonDelay: commentaryDelay,
    });




    const saveChanges = async () => {
        const formData_ = new FormData()

        const route = JSON.stringify(delay)

        formData_.append("id", location.payload.row.expedientId)
        formData_.append("expedient", '{}');
        formData_.append("customAgent", '{}');
        formData_.append("arrivalDates", '{}');
        formData_.append("paymentDetails", '{}');
        formData_.append("pickupDelivery", '{}');
        formData_.append("delay", '{}');
        formData_.append("route", route);
        formData_.append("deviation", '[]');
        formData_.append("tracing", '[]');
        formData_.append("mediasDeleted", '[]');
        formData_.append("File", '[]');

        const res = await dispatch(upsertExpedient(formData_))
        if (res) {
           setCommentaryDelay(delay.reasonDelay)
           setDaysDelay(delay.daysLate)
            toggleModal();
        }

    }


    return (
        <div className='delay-modal-container'>
            <div className='delay-title-container'>
                <h3>Retrasos</h3>
                <h4>Capturar los días de retraso:</h4>
                <div className='input-number-days-container'>
                    <input
                        type={'number'}
                        placeholder={0}
                        className={'days-delay-input'}
                        value={delay.daysLate}
                        onChange={(e) => setDelay({ ...delay, daysLate: e.target.value })}
                    />
                    <p className='days-text'>Días</p>
                </div>
            </div>
            <div className='delay-reason'>
                <p>Motivo del retraso:</p>
                <input
                    className='reasons-delay-input'
                    type='textarea'
                    placeholder='Escriba aquí los motivos del retraso...'
                    value={delay.reasonDelay}
                    onChange={(e) => setDelay({ ...delay, reasonDelay: e.target.value })}
                />
            </div>
            <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                <Button type='button' onClick={toggleModal} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                <Button type='submit' onClick={saveChanges} className={'button-next'} style={{ width: '40%' }}>GUARDAR</Button>
            </div>
        </div>
    )
}

export default RecordDelays;
