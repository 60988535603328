import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import "./MultipleSelect.scss";
import _, { forEach } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { element } from 'prop-types';
import { getAllPermissionsAction } from '../../../redux/actions/permissionAction';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

export default function MultipleSelect(props) {
  const theme = useTheme();

  const dispatch = useDispatch()

  const mainData = _.get(props, "data", null);

  const rows = _.get(props,"data","nada")
  
  console.log(rows)



  const [personName, setPersonName] = React.useState([]);
  const [permissionArray,setPermissionArray] = React.useState([]);
  const [arrayDelete, setArrayDelete] = React.useState([]);
  const [arrayAdd, setArrayAdd] = React.useState([]);
  const [disab,Setdisab] = React.useState(
    props.disable === 'profile' ? true : false
  )

  let array = []
  // let arrayDelete = [];
  // let arrayAdd = [];

  React.useEffect(() => {
    
    _.get(mainData, "data", null)
    // mainData.data.rows.map((item) => {
    //     console.log(item.id)
    // })
    setPermissionArray([])

    for (let index = 0; index < rows.length; index++) {
        console.log(rows[index])
       
        array.push(rows[index].permissionId)
    }
    if(_.isEmpty(permissionArray)){
        for (let index = 0; index < array.length; index++) {
            
            switch (array[index]) {
                case 1:
                    setPermissionArray(current =>[...current, 'Administrador'])
                break;
                case 2:
                    setPermissionArray(current =>[...current, 'Agentes Aduanales'])
                break;
                case 3:
                    setPermissionArray(current =>[...current, 'Clientes'])
                break;
                case 4:
                    setPermissionArray(current =>[...current, 'Cotizaciones'])
                break;
                case 5:
                    setPermissionArray(current =>[...current, 'Expedientes'])
                break;
                case 6:
                    setPermissionArray(current =>[...current, 'Operativos'])
                break;
                case 7:
                    setPermissionArray(current =>[...current, 'Proveedores'])
                break;
                case 8:
                    setPermissionArray(current =>[...current, 'Puertos'])
                break;
                
                default:
                    break;
            }
            
        }

    }
  }, [mainData])

  let changePermisos = [];


  React.useEffect(() => {
    dispatch(getAllPermissionsAction())
    
  },[])

  const permisos = useSelector((state) => state.permission.permissions.data)

    
  const handleChange = (event) => {
    console.log(event)
    const {
      target: { value },
    } = event;
    console.log(value)
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    event.target.value.forEach(item => {

       changePermisos.push(item)
    });

    setPermissionArray(changePermisos)
    // setPermissionArray(permissionArray =>[...permissionArray, changePermisos])
    
  };

  console.log(arrayDelete)
  console.log(arrayAdd)

  function deleteItem (element)  {
    console.log(element)
    let quepasa = permissionArray.indexOf(element,0);
    if(quepasa != -1){
      console.log("lo elimini"); 

      switch (element) {
        case 'Administrador':
          setArrayDelete(arrayDelete =>[...arrayDelete, 1])
        break;
        case 'Agentes Aduanales':
          setArrayDelete(arrayDelete =>[...arrayDelete, 2])

        break;
        case 'Clientes':
          setArrayDelete(arrayDelete =>[...arrayDelete, 3])
        break;
        case 'Cotizaciones':
          setArrayDelete(arrayDelete =>[...arrayDelete, 4])
        break;
        case 'Expedientes':
          setArrayDelete(arrayDelete =>[...arrayDelete, 5])
        break;
        case 'Operativos':
            setArrayDelete(arrayDelete =>[...arrayDelete, 6])
        break;
        case 'Proveedores':
            setArrayDelete(arrayDelete =>[...arrayDelete, 7])
        break;
        case 'Puertos':
            setArrayDelete(arrayDelete =>[...arrayDelete, 8])
          break;
        default:
            break;
    }
    }else{
      switch (element) {
        case 'Administrador':
          arrayAdd.push(1);
        break;
        case 'Agentes Aduanales':
          arrayAdd.push(2);
        break;
        case 'Clientes':
          arrayAdd.push(3);
        break;
        case 'Cotizaciones':
          arrayAdd.push(4);
        break;
        case 'Expedientes':
          arrayAdd.push(5);
        break;
        case 'Operativos':
          arrayAdd.push(6);
        break;
        case 'Proveedores':
          arrayAdd.push(7);
        break;
        case 'Puertos':
          arrayAdd.push(8);
        break;
        default:
            break;
    }
      console.log("lo agrego")
    }

  console.log(arrayDelete)
  props.setArrayDelete(arrayDelete)
  console.log(arrayAdd)
  props.setArrayAdd(arrayAdd)
  }
 

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }} 
      disabled={disab}
      >
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={permissionArray}
          onChange={handleChange}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {permissionArray.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {
            _.get(permisos,"data") &&
            permisos.data.map((item,i) => (
              <MenuItem
                id={'punani'}
                key={i}
                value={item.displayText}
                style={getStyles(name, personName, theme)}
              >
                <Checkbox checked={permissionArray.indexOf(item.displayText) > -1} />
                <ListItemText id={'punani'} primary={item.displayText} onClick={()=> deleteItem(item.displayText)}/>
               
            </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </div>
  );
}
