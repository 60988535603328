import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import './AdminForm.scss';
import {
    getAllAdministratorsAction,
    deleteAdministratorAction,
} from '../../../redux/actions/adminAction';

import profile from '../../../assets/images/perfil.png';
import edit from '../../../assets/images/navieras/editIcon.png';
import { Button } from '../../../components/atoms';
import { ProfileForm } from '../../../components/templates/BaseLayout/Profile/ProfileForm';




const AdminForm = (props) => {

  return (
    <div className='main-container admin'>
        <ProfileForm type={'create'}></ProfileForm>
    </div>
  )
}


AdminForm.propTypes = {
};

function mapStateToProps (state) {
    return {
    };
}

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(AdminForm);
