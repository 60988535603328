import React from 'react';
import './CancelFormBody.scss'
export const DeleteBody = ({user}) => {
  return (
    <div className='cancel-body-container'>
        <h2>Eliminar {user}</h2>
        <p>Esta acción es definitiva. Todos los datos del {user} serán borrados y no podrán ser restaurados. </p>
    </div>
  )
}
