/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
    Route,
    Switch,
    Redirect,
    withRouter,
} from 'react-router-dom';
import PropTypes from "prop-types";
import { connect, useSelector } from 'react-redux';
import _ from 'lodash';
import { PrivateRoute, PublicRoute  } from '../../../utils/routeAccessHelper';
import './BaseLayout.scss';

// BASE LAYOUT

import Footer from './Footer/Footer';
import Sidebar from './Sidebar/Sidebar';
import Header from './Header/Header';
import MainContainer from "./MainContainer/MainContainer";

// HOME

import Admin from "../../../pages/AdminDashboard/HomeModule/Admin";
import AdminCreate from "../../../pages/AdminDashboard/HomeModule/AdminForm";


// NOT FOUND

import NotFoundPage from "../NotFound/NotFoundPage";                  
import CustomAgent from "../../../pages/CustomsAgentDashboard/HomeModule/CustomAgent";
import { Profile } from "../../../pages/ProfileModule/Profile";
import Client from "../../../pages/AdminDashboard/HomeModule/CustomerManagement/Client";
import { ClientRegister } from "../../../pages/AdminDashboard/HomeModule/CustomerManagement/ClientRegister";
import { ClientType } from "../../../pages/AdminDashboard/HomeModule/CustomerManagement/ClientType";
import { seeClientRegister } from "../../../pages/AdminDashboard/HomeModule/CustomerManagement/ClientRegister/seeClientRegister";
import { CustomAgenteRegister } from "../../../pages/CustomsAgentDashboard/HomeModule/CustomAgenteRegister";
import { seeCustomAgentRegister } from "../../../pages/CustomsAgentDashboard/HomeModule/CustomAgentRegister/seeCustomAgentRegister";
import AdminSee from "../../../pages/AdminDashboard/HomeModule/AdminSee";
import Quotation from "../../../pages/QuotationDashboard/Quotation";
import { SeeQuotationRegister } from "../../../pages/QuotationDashboard/QuotationRegister/seeQuotationRegister";
import { QuotationRegister } from "../../../pages/QuotationDashboard/QuotationRegister";
import AerialQuotationViewDetails from "../../../pages/QuotationDashboard/AerialQuotationViewDetails";
import Providers from "../../../pages/ProvidersDashboard/Providers";
import ProviderRegister from "../../../pages/ProvidersDashboard/ProviderRegister";
import ProviderDetailsView from "../../../pages/ProvidersDashboard/ProviderDetailsView";
import PortsDashboard from '../../../pages/PortsDashboard/Ports';
import Operatives from "../../../pages/OpertivesDashboard/Operatives";
import OperativeRegister from "../../../pages/OpertivesDashboard/OperativeRegister";
import { OperativeDetailsView } from "../../../pages/OpertivesDashboard/OperativeDetailsView";
import Records from "../../../pages/RecordsDashboard/Records";
import RecordDocumentation from "../../../pages/RecordsDashboard/RecordsDocumentation";
import Home from "../../../pages/Home/Home";
import {
    adminSidebarOptions,
    operativeSidebarOptions
} from '../BaseLayout/Sidebar/sidebarOptions';
import PortsRegister from "../../../pages/PortsDashboard/PortRegister";
import { RecordDetailsStepper } from "../../../pages/RecordsDashboard/RecordDetails/RecordDetailsStepper";
import RecordRoute from "../../../pages/RecordsDashboard/RecordRoute";
import RecordClosure from "../../../pages/RecordsDashboard/RecordClosure";
import RecordRegister from "../../../pages/RecordsDashboard/RecordRegister";
import RecordsDelay from "../../../pages/RecordsDashboard/RecordsDelay";
import { 
    CLIENT_CREATE,
    CUSTOMSAGENT,
    CLIENT_PROFILE,
    CUSTOMS_AGENT,
    CUSTOMS_AGENT_PROFILE,
    EXPEDIENT_DELETE,
    HOME,
    OPERATIVES,
    OPERATIVES_CREATE,
    OPERATIVES_PROFILE,
    PORTS,
    PORTS_CREATE,
    PORTS_EDIT,
    PROVIDERS,
    PROVIDER_CREATE,
    PROVIDER_PROFILE,
    QUOTATION,
    QUOTATION_DELETE,
    QUOTATION_DOCUMENTS,
    RECORDS,
    RECORDS_CLOSURE,
    RECORDS_DELAY,
    RECORDS_DOCUMENTATION,
    RECORDS_REGISTER,
    RECORDS_ROUTE,
    ADMIN,
    RECORDS_VIEW,
    CUSTOMERMANAGEMENT
} from "../../../routes/Paths";
import { QuotationDocumentsView } from "../../../pages/QuotationDashboard/QuotationDocumentsView";
import RecordDeleted from "../../../pages/RecordsDashboard/RecordDeletes/RecordDeleted";
import QuotationDeleted from "../../../pages/QuotationDashboard/QuotationDeleted/QuotationDeleted";

import home from '../../../assets/images/navieras/home-a.png';
import operativos from '../../../assets/images/navieras/Icon-operativos.png';
import admins from '../../../assets/images/navieras/Icon-administradores.png';
import proveedores from '../../../assets/images/navieras/Icon-proveedores.png';
import cotizacion from '../../../assets/images/navieras/Icon-cotizaciones.png';
import expediente from '../../../assets/images/navieras/Icon-expedientes.png';
import aduanales from '../../../assets/images/navieras/Icon-aduanales.png';
import clientes from '../../../assets/images/navieras/Icon-clientes.png';
import puertos from '../../../assets/images/navieras/puertos.png';


const BaseLayout = (props) => {

    const user = useSelector(state => state.user.user);
    const [permissionSelect, setPermissionSelect] = useState([])
    let sidebarOptions = [
        {
            img: home,
            // imgSecond: homeWhite,
            optionName: 'Inicio',
            link: HOME,
        }
    ]

    useEffect(() => {
        if(!_.isNil(user.userPermission)){
            for (let index = 0; index < user.userPermission.length; index++) {
                if(_.isNil(user.userPermission[index].deletedAt)){
                    switch (user.userPermission[index].permission.displayText) {
                        case "Agentes Aduanales":
                            sidebarOptions.push(
                                {
                                    img: aduanales,
                                    // imgSecond: operativosWhite,
                                    optionName: 'Agentes Aduanales',
                                    link: CUSTOMSAGENT,
                                }
                            )
                            break;
                        case "Administrador":
                            sidebarOptions.push(
                                {
                                    img: admins,
                                    // imgSecond: adminsWhite,
                                    optionName: 'Administrador',
                                    link: ADMIN,
                                }
                            )
                            break;
                        case "Clientes":
                            sidebarOptions.push(
                                {
                                    img: clientes,
                                    // imgSecond: proveedoresWhite,
                                    optionName: 'Clientes',
                                    link: CUSTOMERMANAGEMENT,
                                }
                            )
                            break;
                        case "Cotizaciones":
                            sidebarOptions.push(
                                {
                                    img: cotizacion,
                                    // imgSecond: reportWhite,
                                    optionName: 'Cotizaciones',
                                    link: QUOTATION,
                                }
                            )
                            break;
                            case "Expedientes":
                                sidebarOptions.push(
                                    {
                                        img: expediente,
                                        // imgSecond: reportWhite,
                                        optionName: 'Expedientes',
                                        link: RECORDS,
                                    }
                                )
                                break;
                        case "Operativos":
                            sidebarOptions.push(
                                {
                                    img: operativos,
                                    // imgSecond: forexDemWhite,
                                    optionName: 'Operativos',
                                    link: OPERATIVES,
                                }
                            )
                            break;
                        case "Proveedores":
                                sidebarOptions.push(
                                    {
                                        img: proveedores,
                                        // imgSecond: forexDemWhite,
                                        optionName: 'Proveedores',
                                        link: PROVIDERS,
                                    }
                                )
                            break;
                        case "Puertos":
                                sidebarOptions.push(
                                    {
                                        img: puertos,
                                        // imgSecond: forexDemWhite,
                                        optionName: 'Puertos',
                                        link: PORTS,
                                    }
                                )
                                break;
        
    
                        default:
                            break;
                    }
                }     
            }
        }
        setPermissionSelect(sidebarOptions)
    }, [user])
    const isAdmin = user.administratorId !== null;
    const isOperative = user.operativeId !== null;

    // let sidebarOptions = isAdmin ? adminSidebarOptions : operativeSidebarOptions;

    return (
        <>
            <Sidebar sidebarOptions={permissionSelect} />
            <Header isOperative={isOperative} user={user} />
            <MainContainer>
                <Switch>
                    <Route 
                        exact
                        path="/dashboard" 
                        render={() => <Redirect to={HOME} />} 
                    />
                    <PrivateRoute
                        exact
                        path={HOME}
                        component={Home}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/perfil'}
                        component={Profile}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/admin'}
                        component={Admin}
                        isAllowed={(props.isAuthenticated ) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/admin/crear'}
                        component={AdminCreate}
                        isAllowed={(props.isAuthenticated ) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/admin/:userId'}
                        component={AdminSee}
                        isAllowed={(props.isAuthenticated ) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/gestion-clientes'}
                        component={Client}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/gestion-clientes/tipo-usuario'}
                        component={ClientType}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={CLIENT_CREATE}
                        component={ClientRegister}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={CLIENT_PROFILE}
                        component={seeClientRegister}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PublicRoute
                        exact
                        path={CUSTOMS_AGENT}
                        component={CustomAgent}
                        isAllowed={(props.isAuthenticated ) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/agentes-aduanales/crear'}
                        component={CustomAgenteRegister}
                        isAllowed={(props.isAuthenticated ) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={CUSTOMS_AGENT_PROFILE}
                        component={seeCustomAgentRegister}
                        isAllowed={(props.isAuthenticated ) ? true : false}
                    />
                    <PublicRoute
                        exact
                        path={QUOTATION}
                        component={Quotation}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PublicRoute
                        exact
                        path={'/dashboard/cotizacion/:clientId'}
                        component={Quotation}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/cotizacion/:id/ver'}
                        component={SeeQuotationRegister}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={'/dashboard/cotizaciones/crear'}
                        component={QuotationRegister}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={QUOTATION_DOCUMENTS}
                        component={QuotationDocumentsView}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={PROVIDERS}
                        component={Providers}
                        isAllowed={(props.isAuthenticated ) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={PROVIDER_CREATE}
                        component={ProviderRegister}
                        isAllowed={(props.isAuthenticated ) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={PROVIDER_PROFILE}
                        component={ProviderDetailsView}
                        isAllowed={(props.isAuthenticated ) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={PORTS}
                        component={PortsDashboard}
                        isAllowed={(props.isAuthenticated ) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={PORTS_EDIT}
                        component={PortsRegister}
                        isAllowed={(props.isAuthenticated ) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={PORTS_CREATE}
                        component={PortsRegister}
                        isAllowed={(props.isAuthenticated ) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={OPERATIVES}
                        component={Operatives}
                        isAllowed={(props.isAuthenticated ) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={OPERATIVES_CREATE}
                        component={OperativeRegister}
                        isAllowed={(props.isAuthenticated ) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={OPERATIVES_PROFILE}
                        component={OperativeDetailsView}
                        isAllowed={(props.isAuthenticated ) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={RECORDS}
                        component={Records}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={RECORDS_DOCUMENTATION}
                        component={RecordDocumentation}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={RECORDS_VIEW}
                        component={RecordDetailsStepper}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={RECORDS_ROUTE}
                        component={RecordRoute}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={RECORDS_CLOSURE}
                        component={RecordClosure}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={RECORDS_REGISTER}
                        component={RecordRegister}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />
                    <PrivateRoute
                        exact
                        path={RECORDS_DELAY}
                        component={RecordsDelay}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />

                    <PrivateRoute
                        exact
                        path={EXPEDIENT_DELETE}
                        component={RecordDeleted}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />

                    <PrivateRoute
                        exact
                        path={QUOTATION_DELETE}
                        component={QuotationDeleted}
                        isAllowed={(props.isAuthenticated) ? true : false}
                    />

                    <PublicRoute component={NotFoundPage} />
                </Switch>
            </MainContainer>
            <Footer />
        </>
    );
};

BaseLayout.propTypes = {
    classNameMain: PropTypes.string,
    isSidebarOpened: PropTypes.any,
    isAuthenticated: PropTypes.any,
    role: PropTypes.any,
    isAdmin: PropTypes.any,
};

const mapStateToProps = (state) => { 
    return { 
        isSidebarOpened: _.get(state.userInterface, 'isSidebarOpened', false),
        isAuthenticated: state.user.isAuthenticated || false,
        role: _.get(state.user,'user.roles[0]', false) === 'administrator' ? true : false,
    }; 
};

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = dispatch => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BaseLayout));
