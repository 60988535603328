import React, { useEffect, useState } from 'react'
import { Button } from '../../components/atoms';
// import './Quotation.scss';
import '../../styles/stylesSteppers.scss';
import arrow from '../../assets/images/navieras/arrowButton.png'
import _ from 'lodash'
import { AssignClient } from '../../components/molecules/AssingForms/AssignClient';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClientsAction } from '../../redux/actions/clientAction';
import { addNotification } from '../../redux/actions/notificationAction';

export const QuotationAssingClient = ({stepper, setStepper, toggleModal, formData, setFormData, setClient}) => {
    const dispatch = useDispatch();
    const clients = useSelector(state => state.client.clients);
    const [clientAssigned, setClientAssigned] = useState(null);
    const changeStage = ()=>{
        
        if(clientAssigned!==null){
            const data = {
                ...formData,
                clientId: clientAssigned 
            }
            setFormData(data);
            setStepper(stepper+1)
        }else{
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Complete los campos antes de avanzar',
            }));
        }
    }
    const changeStageBack = ()=>{
        setStepper(stepper-1)
    }
    useEffect(() => {
            dispatch(getAllClientsAction());
    }, [])
    
    return (
        <form className='main-container-generic-type'>
            <div className='header-form-generic-type'>
                <h2>Asignar cliente</h2>
            </div>
            <div className='body-form-generic-type-data' style={{height: '30vh'}}>
            <AssignClient setClient={setClient} clientAssigned={clientAssigned} setClientAssigned={setClientAssigned} clients={_.get(clients, "data", {})}/>
            </div>
            <div className='footer-form-generic-type' style={{justifyContent: 'space-between'}}>
                <Button type='button' onClick={toggleModal ? toggleModal : changeStageBack} className={'button-back'} style={{width: '40%'}}>CANCELAR</Button>
                <Button type='button' onClick={changeStage} className={'button-next'} srcicon={arrow} style={{width: '40%'}}>SIGUIENTE</Button>
            </div>
        </form>
  )
}
