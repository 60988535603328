/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Button } from '../../../components/atoms';
import { getAllCountriesAction, getAllStatesAction } from '../../../redux/actions/addressAction';
import './RecordManualCapture.scss';
import { useLocation } from 'react-router-dom';
import { upsertExpedient } from '../../../redux/actions/recordAction';

const RecordManualCapture = ({ setDeliveryHistory, deliveryHistory, toggleModal, setLatPosition, setLngPosition }) => {

    const dispatch = useDispatch();

    const countries = useSelector(state => state.address.countries.data);
    const states = useSelector(state => state.address.states.data);
    const location = useLocation()
    const admin = useSelector(state => state.user.user.id)


    const [date, setDate] = useState('');
    const [action, setAction] = useState('');
    const [countrySelected, setCountrySelected] = useState('');
    const [countrySelectedId, setCountrySelectedId] = useState('');
    const [countrySelectedName, setCountrySelectedName] = useState('');

    
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');

    const [stateSelectedId, setStateSelectedId] = useState('');
    const [stateSelectedName, setStateSelectedName] = useState('');



    useEffect(() => {
        dispatch(getAllCountriesAction({}))
    }, []);


    const nameCountry = (e) => {
        const currentCountry = countries.data.find(country => country.id == e.target.value);
        setCountrySelectedId(currentCountry.id);
        setCountrySelectedName(currentCountry.name)
    }

    const latLngState = (e) => {
        // const currentState = states.data.find(state => state.name == e.target.value);
        setLat(e.latitude && e.latitude);
        setLng(e.longitude && e.longitude);
    }

    // const saveDeliveryStatus = (data) => {
    //     setDeliveryHistory([...deliveryHistory, data]);
    //     console.warn(deliveryHistory);
    //     toggleModal();
    // }


    const data = {
        tracingCountryId: countrySelectedId,
        tracingStateId: parseInt(stateSelectedId),
        date: date,
        action: action,

    }


    const dataModal = {
        country: countrySelectedName,
        state: stateSelectedName,
        date: date,
        status: action,
        lat: lat,
        lng: lng,
    }


    const tracing = JSON.stringify(data)


    const generateRoute = async () => {
        const formData_ = new FormData()

        formData_.append("id", location.payload.row.expedientId)
        formData_.append("closeFile", false);
        formData_.append("adminId", admin);
        formData_.append("expedient", '{}');
        formData_.append("customAgent", '{}');
        formData_.append("arrivalDates", '{}');
        formData_.append("paymentDetails", '{}');
        formData_.append("pickupDelivery", '{}');
        formData_.append("delay", '{}');
        formData_.append("route", '{}');
        formData_.append("deviation", '[]');
        formData_.append("tracing", tracing);
        formData_.append("mediasDeleted", '[]');
        formData_.append("File", '[]');


        const res = await dispatch(upsertExpedient(formData_))
        if (res) {
            // setDeliveryHistory([newRoute])
            toggleModal()
        }

        setDeliveryHistory([dataModal,...deliveryHistory])
    }


    return (
        <div className='capture-modal-container'>
            <div className='capture-title-container'>
                <h4>Ingresar posición:</h4>
                <div className='inputs-container'>
                    <div className='input-container-record'>
                        <div className='label-container'>
                            <label className='container-label'>País</label>
                        </div>
                        <div className='value-container' >
                            <select required className='input-select' value={countrySelected} onChange={(e) => {
                                setCountrySelected(e.target.value);
                                dispatch(getAllStatesAction(
                                    {
                                        where: {
                                            countryId: parseInt(e.target.value)
                                        }
                                    }
                                ));
                                nameCountry(e)
                            }}>
                                <option className='option-selected' value={null} hidden>{countrySelected.name != null ? countrySelected.name : "Seleccionar"}</option>
                                {
                                    _.get(countries, 'data') &&
                                    countries.data.map(country => {
                                        return <option value={country.id}>{country.name}</option>
                                    })
                                }
                            </select >
                        </div>
                    </div>
                    <div className='input-container-record'>
                        <div className='label-container'>
                            <label className='container-label'>Estado</label>
                        </div>
                        <div className='value-container' >
                            <select required className='input-select' value={stateSelectedName} onChange={(e) => {
                                const valor = JSON.parse(e.target.value)
                                setStateSelectedId(valor.id);
                                setStateSelectedName(valor.name)
                                latLngState(valor);
                            }}>
                                <option className='option-selected' value={null} selected hidden>{stateSelectedName != null ? stateSelectedName : "Seleccionar"}</option>
                                {
                                    _.get(states, 'data') &&
                                    states.data.map((ele, i) => {
                                        return <option key={i} value={JSON.stringify(ele)}> {ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className='capture-date-action'>
                    <div className='input-container-record'>
                        <div className='label-container'>
                            <label className='container-label'>Ingresar fecha:</label>
                        </div>
                        <div className='value-container'>
                            <input type='date' value={date ? date : ''} onChange={(e) => setDate(e.target.value)} className='input-time operative' />
                        </div>
                    </div>
                    <div className='input-container-record'>
                        <div className='label-container'>
                            <label className='container-label'>Ingresar acción:</label>
                        </div>
                        <div className='value-container'>
                            <input value={action ? action : null} onChange={(e) => setAction(e.target.value)} placerholder={'Agregar...'} className="input-generic" type="text" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                <Button type='button' onClick={toggleModal} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                <Button type='submit' className={'button-next'} style={{ width: '40%' }} onClick={() => generateRoute()} > GUARDAR</Button>
            </div>
        </div >
    )
}

export default RecordManualCapture;
