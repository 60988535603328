import {
    UPSERT_EXPEDIENT_BEGIN,
    UPSERT_EXPEDIENT_SUCCESS,
    UPSERT_EXPEDIENT_FAILURE,
    GET_ALL_EXPEDIENT_BEGIN,
    GET_ALL_EXPEDIENT_SUCCESS,
    GET_ALL_EXPEDIENT_FAILURE,
    GET_ALL_EXPEDIENT_DELETED_BEGIN,
    GET_ALL_EXPEDIENT_DELETED_SUCCESS,
    GET_ALL_EXPEDIENT_DELETED_FAILURE,
} from '../actionTypes';

import initialState from '../initialState';

export default function recordReducer(state = initialState.record, action) {
    switch (action.type) {
        case UPSERT_EXPEDIENT_BEGIN:
            return {
                ...state,
                upsertRecord: {
                    ...state.upsertRecord,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    totalRecords: 0,
                    data: [],
                },
            };
        case UPSERT_EXPEDIENT_SUCCESS:
            return {
                ...state,
                upsertRecord: {
                    ...state.upsertRecord,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    totalRecords: action.data.totalRecords,
                    data: action.data.data,
                },
            };
        case UPSERT_EXPEDIENT_FAILURE:
            return {
                ...state,
                upsertRecord: {
                    ...state.upsertRecord,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
        //GET ALL

        case GET_ALL_EXPEDIENT_BEGIN:
            return {
                ...state,
                getAllRecord: {
                    ...state.getAllRecord,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    totalRecords: 0,
                    data: [],
                },
            };
        case GET_ALL_EXPEDIENT_SUCCESS:
            return {
                ...state,
                getAllRecord: {
                    ...state.getAllRecord,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    totalRecords: action.data.totalRecords,
                    data: action.data.data,
                },
            };
        case GET_ALL_EXPEDIENT_FAILURE:
            return {
                ...state,
                getAllRecord: {
                    ...state.getAllRecord,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };

            //GET ALL DELETED

            case GET_ALL_EXPEDIENT_DELETED_BEGIN:
                return {
                    ...state,
                    getAllRecordDeleted: {
                        ...state.getAllRecordDeleted,
                        isLoading: true,
                        isSuccess: false,
                        err: null,
                        totalRecords: 0,
                        data: [],
                    },
                };
            case GET_ALL_EXPEDIENT_DELETED_SUCCESS:
                return {
                    ...state,
                    getAllRecordDeleted: {
                        ...state.getAllRecordDeleted,
                        isLoading: false,
                        isSuccess: true,
                        err: null,
                        totalRecords: action.data.totalRecords,
                        data: action.data.data,
                    },
                };
            case GET_ALL_EXPEDIENT_DELETED_FAILURE:
                return {
                    ...state,
                    getAllRecordDeleted: {
                        ...state.getAllRecordDeleted,
                        isLoading: false,
                        isSuccess: false,
                        err: action.err,
                        totalRecords: 0,
                        data: [],
                    },
                };
        default:
            return state;
    }
}