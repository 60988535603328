import React from 'react';
import './CancelFormBody.scss'
export const CancelFormBody = ({role = 'cliente'}) => {

  return (
    <div className='cancel-body-container'>
        <h2>CANCELAR REGISTRO</h2>
        <p>Esta acción es definitiva. Se borrarán todos los datos hasta ahora registrados en su proceso del registro al {role}.</p>
    </div>
  )
}
