import {
    ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_BEGIN,
    ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_SUCCESS,
    ADMINISTATOR_MODULE_GET_ONE_ADMINISTRATOR_FAILURE,
    ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_BEGIN,
    ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_SUCCESS,
    ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_FAILURE,
    ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_BEGIN,
    ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_SUCCESS,
    ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_FAILURE,
    ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_BEGIN,
    ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_SUCCESS,
    ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_FAILURE,
} from './../actionTypes';

import initialState from '../initialState';

export default function adminReducer(state=initialState.admin, action) {
    switch(action.type) {
        case ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_BEGIN:
            return {
                ...state,
                adminRecord: {
                    ...state.adminRecord,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: null,
                },
            };
        case ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_SUCCESS:
            return {
                ...state,
                adminRecord: {
                    ...state.adminRecord,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.adminRecord,
                },
            };
        case ADMINISTATOR_MODULE_GET_ONE_ADMINISTRATOR_FAILURE:
            return {
                ...state,
                adminRecord: {
                    ...state.adminRecord,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: null,
                }
            };
        case ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_BEGIN:
            return {
                ...state,
                admins: {
                    ...state.admins,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    totalRecords: 0,
                    data: [],
                }
            };
        case ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_SUCCESS:
            return {
                ...state,
                admins: {
                    ...state.admins,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    totalRecords: action.totalRecords,
                    data: action.data,
                },
            };
        case ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_FAILURE:
            return {
                ...state,
                admins: {
                    ...state.admins,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
        case ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_BEGIN:
            return {
                ...state,
                upsertAdmin: {
                    ...state.upsertAdmin,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: {},
                },
            };
        case ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_SUCCESS:
            return {
                ...state,
                upsertAdmin: {
                    ...state.upsertAdmin,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.admin,
                },
            };
        case ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_FAILURE:
            return {
                ...state,
                upsertAdmin: {
                    ...state.upsertAdmin,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: {},
                },
            };
        case ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_BEGIN:
            return {
                ...state,
                deleteAdmin: {
                    ...state.deleteAdmin,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: null,
                },
            };
        case ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_SUCCESS:
            return {
                ...state,
                deleteAdmin: {
                    ...state.deleteAdmin,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.admin,
                },
            };
        case ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_FAILURE:
            return {
                ...state,
                deleteAdmin: {
                    ...state.deleteAdmin,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: null,
                },
            };
        default:
            return state;
    }
}
