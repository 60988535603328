import request from "../../utils/request";
import { addNotification } from './notificationAction';
import {
    ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_BEGIN,
    ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_SUCCESS,
    ADMINISTATOR_MODULE_GET_ONE_ADMINISTRATOR_FAILURE,
    ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_BEGIN,
    ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_SUCCESS,
    ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_FAILURE,
    ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_BEGIN,
    ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_SUCCESS,
    ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_FAILURE,
    ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_BEGIN,
    ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_SUCCESS,
    ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_FAILURE,
} from './../actionTypes';

// ACTIONS TO REDUCER

export const getOneAdministratorRecordBegin = () => ({
    type: ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_BEGIN,
});

export const getOneAdministratorRecordSuccess = (adminRecord) => ({
    type: ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_SUCCESS,
    adminRecord,
});

export const getOneAdministratorRecordFailure = () => ({
    type: ADMINISTATOR_MODULE_GET_ONE_ADMINISTRATOR_FAILURE,
});

export const getAllAdministratorsBegin = () => ({
    type: ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_BEGIN,
});

export const getAllAdministratorsSuccess = ({totalRecords, data}) => ({
    type: ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_SUCCESS,
    totalRecords,
    data,
});

export const getAllAdministratorsFailure = () => ({
    type: ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_FAILURE,
});

export const upserAdministratorBegin = () => ({
    type: ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_BEGIN,
});

export const upserAdministratorSuccess = () => ({
    type: ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_SUCCESS,
});

export const upserAdministratorFailure = () => ({
    type: ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_FAILURE,
});

export const deleteAdministratorBegin = () => ({
    type: ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_BEGIN,
});

export const deleteAdministratorSuccess = () => ({
    type: ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_SUCCESS,
});

export const deleteAdministratorFailure = (err) => ({
    type: ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_FAILURE,
    err,
});

// DISPATCH TO ACTIONSv

export function getOneAdministratorRecordAction (data){
    return (dispatch, getState) => {
        dispatch(getOneAdministratorRecordBegin());
        return request({
            partialUrl: `/api/v1/administrator/get-one-administrator-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(getOneAdministratorRecordSuccess(data.data));
            return data.data
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(getOneAdministratorRecordFailure(err));
        });
    };
}

export function getAllAdministratorsAction(data){
    return (dispatch, getState) => {
        dispatch(getAllAdministratorsBegin());
        return request({
            partialUrl: `/api/v1/administrator/get-all-administrators-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(getAllAdministratorsSuccess(data.data));
            return data.data;
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllAdministratorsFailure(err));
        });
    };
}

export function upsertAdministratorAction(data){
    return (dispatch, getState) => {
        dispatch(upserAdministratorBegin());
        return request({
            // partialUrl: `/api/v1/administrator/upsert-administrator-action`,
            partialUrl: `/api/v1/user/upsert-user-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(upserAdministratorSuccess(data.data));
            return true;
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(upserAdministratorFailure(err));
        });
    };
}

export function deleteAdministratorAction(data){
    return (dispatch, getState) => {
        dispatch(deleteAdministratorBegin());
        return request({
            partialUrl: `/api/v1/administrator/delete-administrator-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(deleteAdministratorSuccess(data.data));
            dispatch(deleteAdministratorBegin());
            return true;
        })
        .catch(err => {
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(deleteAdministratorFailure(err));
        });
    };
}

