import React from 'react';
import PropTypes from 'prop-types';
import { Link as LinkRouter } from 'react-router-dom';


const Link = ({
    className,
    to,
    id,
    children,
}) => {
    
    return (
        
        <LinkRouter
            // {...props}
            className={className}
            to={to}
            id={id}
        >
            {children}
        </LinkRouter>

    );
}

Link.propTypes = {
    className: PropTypes.string,
    to: PropTypes.any,
    children: PropTypes.any,
    target: PropTypes.string,
    id: PropTypes.any,
};

export default Link;