import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ProfileForm } from '../../components/templates/BaseLayout/Profile/ProfileForm';
import { ProfileFormOperative } from '../../components/templates/BaseLayout/Profile/ProfileFormOperative';
import './Profile.scss';

export const Profile = () => {

  const user = useSelector(state => state.user.user);
  const admin = useSelector(state => state.admin.adminRecord.data);
  const operative = useSelector(state => state.operative.operativeRecord);
  
  const isAdmin = user.administratorId != null;

  // if (isAdmin) {
  //   const user = useSelector(state => state.state.admin.adminRecord.data)
  //   setData(user)
  //   console.warn('aiuda')
  // }
  // else {
  //   const user = useSelector(state => state.operative.operativeRecord.data)
  //   setData(user)
  //   console.warn('aiuda')
  // }

  // const catchUser = async () => {
  //   debugger
  //     let res;
  //     if (user.administratorId != null) {
  //         res = await dispatch(getOneAdministratorRecordAction({ userId: user.id }));
  //     } else {
  //         res = await dispatch(getAllOperativesAction({
  //           where: { id: user.id }
  //         }));
  //     }
  //   debugger

  //   return res;
  // }

  // useEffect(() => {
  //     catchUser().then((res) => {
  //     console.warn(res);
  //         setData(res.data);
  //     })
  // }, []);

  return (
    <div className='main-container profile-container'>
        {
          isAdmin ?
          <ProfileForm data={admin} type={'profile'} /> :
          <ProfileFormOperative data={operative} type={'profile'} />
        }
    </div>
  )
}
