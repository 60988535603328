import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, useFilters, useGetLatest } from 'react-table';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TextField from '@material-ui/core/TextField';
import search from '../../assets/images/searchIcon.png';
import './DataTable.scss';
import _ from 'lodash';


function DataTable({ 
    columns, 
    data:{
      totalRecords=0, 
      data=[]
    }, 
    getData,
    searchObj={},
}) {
  // Use the state and functions returned from useTable to build your UI
  const [dataTableObject, setDataTableObject] = useState({
    offset: 0,
    limit: 5,
    sort: { id:'id', desc: true},
    where:[searchObj,]
  }); 

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(dataTableObject.limit)

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
      //const count = preFilteredRows.length;  
    return (
      <TextField
        id="outlined-size-small"
        defaultValue="Small"
        size="small"
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Holis Jezz`}
      />
    )
  }

  // No se utiliza
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      //fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  //

  const { 
    getTableProps, 
    getTableBodyProps,
    headerGroups, 
    rows, 
    prepareRow,
    state: {
      sortBy,
      filters,
    },
  } = useTable(
    {
      columns,
      data,
      manualSorting: true,
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      autoResetPage: false,
      autoResetSortBy: false,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      state: {
        sortBy, 
        filters,
        //columnFilters,
      },
    },
    useFilters,
    useSortBy,
  );

  const updateDataTableObject = (newValue) => {
    setDataTableObject({
      ...dataTableObject,
      ...newValue,
    });
  };
  
  useEffect(() => {
    updateDataTableObject({sort: sortBy});
  }, [sortBy]);

  useEffect(() => {
    updateDataTableObject({where: Object.assign({}, filters, searchObj)});
  }, [filters, searchObj]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const valor = newPage * rowsPerPage;
    updateDataTableObject({offset: valor});
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    updateDataTableObject({limit: parseInt(event.target.value, 10)});
  };

  useEffect(() => {
    getData(dataTableObject);
  },[dataTableObject]);

  // https://react-table.tanstack.com/docs/examples/basic
  // Render the UI for your table
  return (
    <>
      <div style={{width: '100%', overflow: 'auto'}}>
        <MaUTable {...getTableProps()} style={{maxHeight: '200px'}}>
          <TableHead
            className='tableHead'
            style={{ position: 'sticky', overflow: 'hidden', background: 'transparent', top: '0', color :'#fff', fontWeight: '12', fontFamily: '"Poppins", sans-serif', }}>
            {!_.isEmpty(headerGroups) && headerGroups.map(headerGroup => (
              // eslint-disable-next-line react/jsx-key
              <TableRow style={{height: '55px'}}  {...headerGroup.getHeaderGroupProps()} >
                {headerGroup.headers.map(column => (
                  // eslint-disable-next-line react/jsx-key
                  <TableCell style={{ minWidth: column.minWidth, width: column.width, verticalAlign: 'center', textAlign:'center', color:'#fff', fontSize:"1.1vw",   }}>
                    <div{...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                      </span>
                      <img style={{margin:'0 4px 2px 0'}} src={search} width='10px' height='10px'/> */}
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', bottom: 0, position: 'absolute' }}>{column.canFilter ? <img style={{margin:'0 4px 2px 0'}} src={search} width='10px' height='10px'/> : null} {column.canFilter ? column.render('Filter') : null}</div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>

            {!_.isEmpty(rows) && rows.map((row, i) => {
              prepareRow(row)
              return (
                // eslint-disable-next-line react/jsx-key
                <TableRow {...row.getRowProps()} style={{ background: 'transparent'}}>
                  {row.cells.map(cell => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <TableCell {...cell.getCellProps()} style={{ verticalAlign: 'center', textAlign:'center', color:'#707070', fontSize:"0.9vw",   }}>
                        {cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </MaUTable>
      </div>
      <TablePagination
        style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}
        component="div"
        count={totalRecords}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Filas por pagina"
      />
    </>
  )
}

DataTable.propTypes = {
    props: PropTypes.any,
    columns: PropTypes.any,
    column: PropTypes.any,
    data: PropTypes.any,
    getData: PropTypes.any,
    searchObj: PropTypes.any,
};

export default DataTable;
