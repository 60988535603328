/* eslint-disable no-unused-vars */
import request from "../../utils/request";
import { addNotification } from './notificationAction';

import {
    CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_BEGIN,
    CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_SUCCESS,
    CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_FAILURE,
    CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_BEGIN,
    CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_SUCCESS,
    CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_FAILURE,
    GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_BEGIN,
    GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_SUCCESS,
    GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_FAILURE,
    GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_BEGIN,
    GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_SUCCESS,
    GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_FAILURE,
    DELETE_ASSOCIATION_BEGIN,
    DELETE_ASSOCIATION_SUCCESS,
    DELETE_ASSOCIATION_FAILURE,
    GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_BEGIN,
    GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_SUCCESS,
    GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_FAILURE,
} from '../actionTypes';

// ACTIONS TO REDUCER

export const getAllCustomAgentsBegin = () => ({
    type: CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_BEGIN,
});

export const getAllCustomAgentsSuccess = (data) => ({
    type: CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_SUCCESS,
    data,
});

export const getAllCustomAgentsFailure = (err) => ({
    type: CUSTOM_AGENT_MODULE_GET_ALL_CUSTOM_AGENTS_FAILURE,
    err,
});

export const upsertCustomAgentBegin = () => ({
    type: CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_BEGIN,
});

export const upsertCustomAgentSuccess = (company) => ({
    type: CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_SUCCESS,
    company,
});

export const upsertCustomAgentFailure = (err) => ({
    type: CUSTOM_AGENT_MODULE_UPSERT_CUSTOM_AGENT_FAILURE,
    err,
});

export const getAllAvailableCustomAgentsBegin = () => ({
    type: GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_BEGIN,
});

export const getAllAvailableCustomAgentsSuccess = (data) => ({
    type: GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_SUCCESS,
    data,
});

export const getAllAvailableCustomAgentsFailure = (err) => ({
    type: GET_ALL_AVAILABLE_CUSTOM_AGENT_BY_CLIENTS_FAILURE,
    err,
});

export const getAllClientBelongCustomAgentBegin = () => ({
    type: GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_BEGIN,
});

export const getAllClientBelongCustomAgentSuccess = (data) => ({
    type: GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_SUCCESS,
    data,
});

export const getAllClientBelongCustomAgentFailure = (err) => ({
    type: GET_ALL_CLIENTS_BELONGS_CUSTOM_AGENT_FAILURE,
    err,
});

export const deleteAssociationBegin = () => ({
    type: DELETE_ASSOCIATION_BEGIN,
});

export const deleteAssociationSucces = (data) => ({
    type: DELETE_ASSOCIATION_SUCCESS,
    data,
});

export const deleteAssociationFailure = (err) => ({
    type: DELETE_ASSOCIATION_FAILURE,
    err,
});

export const getAllCustomAgentBelongClientBegin = () => ({
    type: GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_BEGIN,
});

export const getAllCustomAgentBelongClientSucces = (data) => ({
    type: GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_SUCCESS,
    data,
});

export const getAllCustomAgentBelongClientFailure = (err) => ({
    type: GET_ALL_CUSTOM_AGENT_BELONGS_CLIENT_FAILURE,
    err,
});



// DISPATCH TO ACTIONS

export function getAllCustomAgentsAction(data) {
    return (dispatch, getState) => {
        dispatch(getAllCustomAgentsBegin());
        return request({
            partialUrl: `/api/v1/custom-agend/get-all-custom-agend-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
            .then(request => request.json())
            .then(data => {
                dispatch(getAllCustomAgentsSuccess(data.data));
                return true;
            })
            .catch(err => {
                console.log(err);
                dispatch(addNotification({
                    toastType: 'error',
                    toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
                }));
                return dispatch(getAllCustomAgentsFailure(err));
            });
    };
}

export function upsertCustomAgentAction(data) {
    return (dispatch, getState) => {
        dispatch(upsertCustomAgentBegin());
        return request({
            partialUrl: `/api/v1/custom-agend/upsert-custom-agend-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,

            headers: {
                //'Content-Type': 'multipart/form-data; boundary=—-WebKitFormBoundaryfgtsKTYLsT7PNUVD',
                //Pragma: 'no-cache',
                // 'Cache-control': 'no-store',
                // 'Access-Control-Allow-Origin': '*',
                // 'access-control-allow-origin': '*',
            },
            isFileUpload: true,
        })
            .then(request => request.json())
            .then(data => {
                dispatch(addNotification({
                    toastType: 'success',
                    toastTitle: 'Se ha ejecutado la acción con éxito',
                }));
                dispatch(upsertCustomAgentSuccess(data.data));
                return true;
            })
            .catch(err => {
                console.log(err);
                dispatch(addNotification({
                    toastType: 'error',
                    toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
                }));
                return dispatch(upsertCustomAgentFailure(err));
            });
    };
}

export function getAllAvailableCustomAgentsAction(data) {
    return (dispatch, getState) => {
        dispatch(getAllAvailableCustomAgentsBegin());
        return request({
            partialUrl: `/api/v1/custom-agend/agent/get-all-availability-custom-agend-by-clients-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
            .then(request => request.json())
            .then(data => {
                dispatch(getAllAvailableCustomAgentsSuccess(data.data));
                return true;
            })
            .catch(err => {
                console.log(err);
                dispatch(addNotification({
                    toastType: 'error',
                    toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
                }));
                return dispatch(getAllAvailableCustomAgentsFailure(err));
            });
    };
}


export function deleteCustomAgentAction(data) {
    return (dispatch, getState) => {
        return request({
            partialUrl: `/api/v1/custom-agend/delete-custom-agend-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
            .then(request => request.json())
            .then(data => {
                dispatch(addNotification({
                    toastType: 'success',
                    toastTitle: 'Se ha eliminado con éxito',
                }));
                return true;
            })
            .catch(err => {
                console.log(err);
                dispatch(addNotification({
                    toastType: 'error',
                    toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
                }));
                // return dispatch(deleteCustomAgentFailure(err));
            });
    };
}

export function getAllClientBelongsCustomAgent(data) {
    return (dispatch, getState) => {
        dispatch(getAllClientBelongCustomAgentBegin());
        return request({
            partialUrl: `/api/v1/custom-agend/agent/get-all-client-agent-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
            .then(request => request.json())
            .then(data => {
                dispatch(getAllClientBelongCustomAgentSuccess(data.data));
                return true;
            })
            .catch(err => {
                console.log(err);
                dispatch(addNotification({
                    toastType: 'error',
                    toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
                }));
                return dispatch(getAllClientBelongCustomAgentFailure(err));
            });
    };
}

export function deleteAssociation(data) {
    return (dispatch, getState) => {
        dispatch(deleteAssociationBegin());
        return request({
            partialUrl: `/api/v1/association-agend-client/delete-association-client-agent-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
            .then(request => request.json())
            .then(data => {
                dispatch(deleteAssociationSucces(data.data));
                return true;
            })
            .catch(err => {
                console.log(err);
                dispatch(addNotification({
                    toastType: 'error',
                    toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
                }));
                return dispatch(deleteAssociationFailure(err));
            });
    };
}

export function getAllCustomAgentBelongsClient(data) {
    return (dispatch, getState) => {
        dispatch(getAllCustomAgentBelongClientBegin());
        return request({
            partialUrl: `/api/v1/custom-agend/client/get-all-custom-agent-client-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
            .then(request => request.json())
            .then(data => {
                dispatch(getAllCustomAgentBelongClientSucces(data.data));
                return true;
            })
            .catch(err => {
                console.log(err);
                dispatch(addNotification({
                    toastType: 'error',
                    toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
                }));
                return dispatch(getAllCustomAgentBelongClientFailure(err));
            });
    };
}

