import React from 'react';
import FormLogIn from '../LogIn/FormLogIn';
import { FormRestorePass } from '../RecoverPassword/FormRestorePass';
import logo from './../../../assets/images/logo.png';
import './../../../components/templates/FullScreenLayout/FullScreenLayout.scss';


const SetNewPassword = () => {

    return (
        <>
        <div className='content-Login'>
                <div className='subcontainer-login'>
                    <img  className='img-logo' src={logo}></img>
                        <FormRestorePass   to={'/app/recuperar-password'} 
                            type={'password'}
                        />
                </div>

            </div>
        </>
    )
}

export default SetNewPassword;