/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import '../../styles/stylesSteppers.scss';
import { useForm } from 'react-hook-form';
import _ from 'lodash'
import { UploadFile } from '../../components/organisms/UploadFile/UploadFile';
import { Button } from '../../components/atoms';
import { CardContainer } from '../../components/molecules/CardContainer/CardContainer';
import Modal from '../../components/organisms/Modal/Modal';
import add from '../../assets/images/navieras/AGREGAR.png'
import { BaseModalBody } from '../../components/molecules/ModalBody/BaseModalBody';
import { cancelUploadOfDocs, noDocumentsUploded } from '../../components/molecules/ModalBody/modalBodyVaribles';
import './Records.scss';
import { RECORDS } from '../../routes/Paths';
import { upsertExpedient } from '../../redux/actions/recordAction';
import { useDispatch } from 'react-redux';

const RecordDocumentation = () => {


    const methods = useForm({ defaultValues: defaultValues });
    const defaultValues = {
        // name : '',
    };
    const { register, handleSubmit, watch } = useForm();
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [mediasDeleted, setMediasDeleted] = useState([]);
    const [fileToSend, setFileToSend] = useState();


    const [disableHbl, setDisableHbl] = useState(location && location.payload && location.payload.row && location.payload.row.customDocuments && location.payload.row && location.payload.row.customDocuments.hbl)
    const [disableMbl, setDisableMbl] = useState(location && location.payload && location.payload.row && location.payload.row.customDocuments && location.payload.row && location.payload.row.customDocuments.mbl)
    const [disableEir, setDisableEta] = useState(location && location.payload && location.payload.row && location.payload.row.customDocuments && location.payload.row && location.payload.row.customDocuments.eir)
    const [disableTelex, setDisableTelex] = useState(location && location.payload && location.payload.row && location.payload.row.customDocuments && location.payload.row.customDocuments.telex)
    const [disableRelease, setDisableRelease] = useState(location && location.payload && location.payload.row && location.payload.row.customDocuments && location.payload.row.customDocuments.release)
    const [disableInsurancePolice, setDisableInsurancePolice] = useState(location && location.payload && location.payload.row && location.payload.row.customDocuments && location.payload.row.customDocuments.insurancePolice)
    const [disableCourtDelays, setDisableCourtDelays] = useState(location && location.payload && location.payload.row && location.payload.row.customDocuments && location.payload.row.customDocuments.courtDelays)
    const [disableOtherFile, setDisableOtherFile] = useState(location && location.payload && location.payload.row && location.payload.row.customDocuments && location.payload.row.customDocuments.otherFile)


    const {
        file
    } = location.payload.row;



    // const selectFile = () => {
    //     const inputFile = document.getElementById('documentMedia');
    //         inputFile.click();
    //     }
    const [watchDocumentMedia, setWatchDocumentMedia] = useState(false);
    const [last, setLast] = useState(null);
    const [files, setFiles] = useState(
        [
            {
                name: 'HBL',
                id: 0,
                file: null,
                deletedAt: false,
                watch: false,
                diseable: !disableHbl,
            },
            {
                name: 'MBL',
                id: 1,
                file: null,
                deletedAt: false,
                watch: false,
                diseable: !disableMbl,
            },
            {
                name: 'EIR',
                id: 2,
                file: null,
                deletedAt: false,
                watch: false,
                diseable: !disableEir,
            },
            {
                name: 'TELEX',
                id: 3,
                file: null,
                deletedAt: false,
                watch: false,
                diseable: !disableTelex,
            },
            {
                name: 'RELEASE',
                id: 4,
                file: null,
                deletedAt: false,
                watch: false,
                diseable: !disableRelease,
            },
            {
                name: 'PÓLIZA DE SEGURO',
                id: 5,
                file: null,
                deletedAt: false,
                watch: false,
                diseable: !disableInsurancePolice,
            },
            {
                name: 'CORTE DE DEMORAS',
                id: 6,
                file: null,
                deletedAt: false,
                watch: false,
                diseable: !disableCourtDelays,
            },
            {
                name: 'OTRO DOCUMENTO',
                id: 7,
                file: null,
                deletedAt: false,
                watch: false,
                diseable: !disableOtherFile,
            },
        ]
    )
    const [openModal, setOpenModal] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);

    const toggleModal = () => {
        setOpenModal(!openModal);
    }



    useEffect(() => {
        if (file && file != null && file != undefined && file.length >= 1) {
            let newFiles = file.map((el, i) => {
                return {
                    id: el.fileId,
                    file: {
                        url: el.file.url,
                        name: el.file.url,
                        deletedAt: el.deletedAt,
                    },
                    watch: true
                }
            })
            setFiles(newFiles)
        }
    }, []);


    const data = [
        { name: 'RFC' }, { name: 'Situacion  Fiscal' }, { name: 'INE, pasaporte, cédula profesional' }
    ]


    useEffect(() => {
        let file = [];
        files.forEach((ele) => {
            if (!_.isNil(ele.file)) {
                file.push(ele.file)
            }
        })
        setFileToSend(file)
    }, [files])

    useEffect(() => {
        let final = [...files];
        setLast(final.pop())
    }, [files])



    const cancel = () => {
        history.push(RECORDS);
    }



    const submitData = async () => {
        if (_.isEmpty(files)) { toggleModalNoFiles() }

        const formData_ = new FormData();

        formData_.append("id", location.payload.row.expedientId)
        formData_.append("expedient", '{}');
        formData_.append("customAgent", '{}');
        formData_.append("arrivalDates", '{}');
        formData_.append("paymentDetails", '{}');
        formData_.append("pickupDelivery", '{}');
        formData_.append("delay", '{}');
        formData_.append("route", '{}');
        formData_.append("deviation", '[]');
        formData_.append("tracing", '[]');
        formData_.append("mediasDeleted", JSON.stringify(mediasDeleted));


        const arrFiles = files.map(file => file.file != null ? file : false).filter(file => file != false)

        if (arrFiles.length > 0) {
            for (let i = 0; i < arrFiles.length; i++) {
                if (arrFiles[i].newAdd) {
                formData_.append("File", arrFiles[i].file);
                }
            }
        } else {
            formData_.append("File", '[]');

        }

        if (!formData_.has("File")) {
            formData_.append("File", '[]');
        }

        const res = await dispatch(upsertExpedient(formData_))
        if (res) {
            history.push('/dashboard/expedientes');

        }
    }

    const toggleModal2 = () => {
        setOpenModal2(!openModal);
    }


    const handleAdd = () => {
        let arrayCopy = JSON.parse(JSON.stringify(files));
        let lastElement = files.pop();
        arrayCopy.push(
            {
                name: 'Archivo',
                id: lastElement.id + 1,
                file: null,
                watch: false,
                deletedAt: false,
                newAdd: true,
            }
        )
        setFiles(arrayCopy);
    }


    return (
        <div className='records-documentation-container'>
            {
                openModal &&
                <Modal
                    isOpen={openModal}
                    toggle={toggleModal}
                >
                    <CardContainer
                        btnLeftAction={toggleModal}
                        btnRightAction={cancel}
                        body={<BaseModalBody data={cancelUploadOfDocs} />}
                        btnLeftText={'REGRESAR'}
                    />
                </Modal>
            }
            {
                openModal2 &&
                <Modal
                    isOpen={openModal2}
                    toggle={toggleModal2}
                >
                    <CardContainer
                        btnLeftAction={toggleModal2}
                        btnRightAction={cancel}
                        body={<BaseModalBody data={noDocumentsUploded} />}
                        btnLeftText={'REGRESAR'}
                    />
                </Modal>
            }
            <form className='main-container-generic-type records' onSubmit={handleSubmit(submitData)} >
                <div className='header-form-generic-type'>
                    <h2>Documentación</h2>
                    <h4>ID: {location.payload.row.id}</h4>
                </div>
                <div className='body-form-generic-type-docs record-documentation'>
                    {
                        files.map((ele, i) => {
                            return (
                                <UploadFile
                                    hideTitle={false}
                                    key={i}
                                    files={ele}
                                    last={last}
                                    setFiles={setFiles}
                                    arrayComplete={files}
                                    setArrayDeleted={setMediasDeleted}
                                    arrayDeleted={mediasDeleted}
                                    setArrToSend={setFileToSend}
                                    disabled={ele.diseable}

                                />
                            )
                        })
                    }
                    <div className='add-button-container' style={{ justifyContent: 'center' }}>
                    <img className='add-card' src={add} onClick={handleAdd} />
                </div>
                </div>
                <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                    <Button type='button' onClick={toggleModal} className={'button-back generate'} style={{ width: '30%' }}>CANCELAR</Button>
                    <Button type='submit' className={'button-next'} style={{ width: '30%' }}>GUARDAR</Button>
                </div>
            </form>
        </div>
    )
}

RecordDocumentation.propTypes = {
    toggleModal: PropTypes.func,
}

export default RecordDocumentation;
