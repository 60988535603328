/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import { Button, createSvgIcon, MenuItem } from '@material-ui/core';
import '../../styles/tableViewStyles.scss';
import './Records.scss';
import '../../../src/components/atoms/Button.scss';
import {
    ActionMenu,
} from '../../components/atoms';
import Modal from '../../components/organisms/Modal/Modal';
import { CardContainer } from '../../components/molecules/CardContainer/CardContainer';
import { DeleteBody } from '../../components/molecules/CancelFormBody/DeleteBody';
import trash from '../../assets/images/navieras/trash-can.png';
import document from '../../assets/images/navieras/doc.png';
import view from '../../assets/images/navieras/view.png';
import route from '../../assets/images/navieras/icon-open.png';
import follow from '../../assets/images/navieras/icon-arrow-right.png';
import close from '../../assets/images/navieras/close-circle.png';
import delate from '../../assets/images/navieras/delate.png';
import { EXPEDIENT_DELETE, RECORDS_CLOSURE, RECORDS_DELAY, RECORDS_DOCUMENTATION, RECORDS_REGISTER, RECORDS_ROUTE, RECORDS_VIEW } from '../../routes/Paths';
import { BaseModalBody } from '../../components/molecules/ModalBody/BaseModalBody';
import { closeRecordError } from '../../components/molecules/ModalBody/modalBodyVaribles';


import Box from '@mui/material/Box';
import { deleteRecordAction, getAllExpedientAction, upsertExpedient } from '../../redux/actions/recordAction';
import moment from 'moment';
import { addNotification } from '../../redux/actions/notificationAction';
import { DataGrid, gridSortedRowIdsSelector, GridToolbarContainer, useGridApiContext, useGridApiRef } from '@mui/x-data-grid';



const Records = (props) => {

    const admin = useSelector(state => state.user.user.id)
    const history = useHistory();
    const [searchObj, setSearchObj] = useState({});
    const [searchCondition, setSearchCondition] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [itemSelected, setItemSelected] = useState(true);
    const [record, setRecord] = useState([]);
    const getExpedient = useSelector(state => state.record.getAllRecord.data)
    const clients = useSelector(state => state.client.clients);
    const adminId = useSelector(state => state.user.user.administratorId)
    const apiRef = useGridApiRef();
    

    // const rows = []
    const dispatch = useDispatch();

    useEffect(() => {

        const dayActually = new Date();
        const day = dayActually ? moment(dayActually).format('yyyy-MM-DD') : moment(new Date()).format('yyyy-MM-DD');

    }, [])


    useEffect(() => {
        dispatch(getAllExpedientAction({ "offset": 0, "limit": 10000 }))
    }, [])



    useEffect(() => {
        setRecord([])
        getExpedient && getExpedient.length > 0 && getExpedient.map((el) => {
            let sectionCopy = JSON.parse(JSON.stringify(el));
            sectionCopy.id = sectionCopy.id;
            sectionCopy.expedient.mov = sectionCopy.expedient.mov;
            setRecord((d) => [...d, sectionCopy]);
        })
    }, [getExpedient])



    const ExportIcon = createSvgIcon(
        <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
        "SaveAlt"
    );
    const ExportIconDelete = createSvgIcon(
        <svg width="29px" height="29px" viewBox="0 0 35 35" version="1.1">
            <g id="surface1">
                <path d="M 14.875 6.902344 C 13.644531 7.195312 11.894531 8.070312 10.695312 8.992188 C 9.105469 10.207031 8.054688 11.699219 7.226562 13.855469 C 6.773438 15.070312 6.742188 15.28125 6.722656 17.417969 C 6.722656 19.203125 6.789062 19.882812 7.015625 20.578125 C 8.085938 23.769531 9.851562 25.84375 12.71875 27.238281 C 14.46875 28.082031 15.636719 28.355469 17.5 28.355469 C 19.347656 28.355469 20.53125 28.082031 22.28125 27.238281 C 24.582031 26.121094 26.121094 24.613281 27.191406 22.441406 C 28.128906 20.546875 28.277344 19.882812 28.277344 17.417969 C 28.257812 15.28125 28.226562 15.070312 27.773438 13.855469 C 26.65625 10.953125 25.003906 9.105469 22.246094 7.714844 C 21.597656 7.390625 20.609375 7.015625 20.058594 6.886719 C 18.796875 6.59375 16.105469 6.59375 14.875 6.902344 Z M 19.167969 12.316406 C 19.347656 12.574219 19.605469 12.640625 20.742188 12.671875 C 22.230469 12.71875 22.492188 12.898438 22.296875 13.675781 L 22.199219 14.097656 L 17.453125 14.066406 L 12.71875 14.015625 L 12.671875 13.5625 C 12.574219 12.800781 12.847656 12.640625 14.324219 12.640625 C 15.457031 12.640625 15.667969 12.589844 15.847656 12.316406 C 16.027344 12.023438 16.21875 11.992188 17.5 11.992188 C 18.746094 11.992188 18.972656 12.039062 19.167969 12.316406 Z M 21.550781 18.585938 C 21.550781 21.921875 21.519531 22.457031 21.277344 22.71875 C 21.03125 22.976562 20.691406 23.007812 17.484375 23.007812 L 13.953125 23.007812 L 13.628906 22.589844 C 13.304688 22.183594 13.285156 21.957031 13.285156 18.457031 L 13.285156 14.746094 L 21.550781 14.746094 Z M 21.550781 18.585938 " />
                <path d="M 15.132812 16.414062 C 15.101562 16.527344 15.085938 17.710938 15.101562 19.039062 C 15.152344 21.195312 15.183594 21.46875 15.441406 21.519531 C 15.703125 21.566406 15.71875 21.355469 15.71875 18.894531 C 15.71875 16.59375 15.683594 16.203125 15.472656 16.203125 C 15.328125 16.203125 15.183594 16.300781 15.132812 16.414062 Z M 15.132812 16.414062 " />
                <path d="M 17.175781 18.878906 C 17.175781 21.355469 17.191406 21.566406 17.46875 21.519531 C 17.710938 21.46875 17.742188 21.210938 17.742188 18.878906 C 17.742188 16.542969 17.710938 16.285156 17.46875 16.234375 C 17.191406 16.1875 17.175781 16.398438 17.175781 18.878906 Z M 17.175781 18.878906 " />
                <path d="M 19.28125 18.878906 C 19.28125 21.046875 19.332031 21.550781 19.507812 21.550781 C 19.867188 21.550781 19.929688 21.160156 19.929688 18.878906 C 19.929688 16.59375 19.867188 16.203125 19.507812 16.203125 C 19.332031 16.203125 19.28125 16.707031 19.28125 18.878906 Z M 19.28125 18.878906 " />
            </g>
        </svg>,
        "SaveAlt"
    );

    const buttonBasePropsDelet = {
        color: "secondary",
        size: "small",
        startIcon: <ExportIconDelete />,
    };



    const getUnfilteredRows = ({ apiRef }) => gridSortedRowIdsSelector(apiRef);

    const descargarExpedient = () => {
        const apiRef = useGridApiContext();
        const handleExport = (options) => apiRef.current.exportDataAsCsv(options);
        const buttonBaseProps = {
            color: "primary",
            size: "small",
            startIcon: <ExportIcon />,
        };

        return (
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    // marginBottom: "1rem",
                    background: "#777274;",

                }}
            >



                <GridToolbarContainer
                    csvOptions={{
                        utf8WithBom: true,
                        delimiter: ';',
                        fileName: "Expedientes",
                    }}
                >

                    <Button className='button-exp2'
                        {...buttonBaseProps}
                        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}>
                        {/* <img icon={} className="img" style={{ marginRight: "1rem" }} /> */}
                        Descargar Excel
                    </Button>
                </GridToolbarContainer>
            </div>
        );
    };

    const columnsGrid = [
        {
            field: 'id',
            headerName: 'ID',
            width: 100,
            type: 'number',
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.id || 'null'}`
        },
        {
            field: 'tl',
            headerName: 'TL',
            headerAlign: 'center',
            width: 150,
            type: 'text',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.expedient.folioTl || 'Sin informacion'}`,
        },
        {
            field: 'client',
            headerName: 'Cliente',
            width: 300,
            type: 'text',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.client.tradename || 'Sin informacion'}`,
        },
        {
            field: 'estatus',
            headerName: 'Estatus',
            type: 'text',
            width: 250,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            valueGetter: (params) =>
                `${params.row.expedient.status || 'Sin informacion'} `,
        },
        {
            field: 'mov',
            headerName: 'Mov',
            sortable: false,
            width: 150,
            type: 'text',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.expedient.mov || 'Sin informacion'} `,
        },
        {
            field: 'bl',
            headerName: 'BL',
            sortable: false,
            width: 150,
            type: 'number',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.expedient.numberMbl || 'Sin informacion'}`,
        },
        {
            field: 'noContenedores',
            headerName: 'No.Contenedores',
            sortable: false,
            width: 150,
            type: 'number',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            valueGetter: (params) =>
                `${params.row.expedient.noContainers || 'Sin informacion'}`,
        },
        {
            field: 'naviera',
            headerName: 'Naviera',
            sortable: false,
            width: 150,
            type: 'text',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.expedient.naviera || 'Sin informacion'}`,
        },
        {
            field: 'cnt',
            headerName: '#Cnt',
            sortable: false,
            width: 150,
            type: 'number',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            valueGetter: (params) =>
                `${params.row.expedient.quiantityContainers || 'Sin informacion'}`,
        },
        {
            field: 'equipo',
            headerName: 'Equipo',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            width: 150,
            type: 'text',
            headerAlign: 'center',
            valueGetter: (params) =>
                `${params.row.expedient.team || 'Sin informacion'}`,
        },
        {
            field: 'teus',
            headerName: 'TEUS',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            width: 150,
            type: 'number',
            headerAlign: 'center',
            valueGetter: (params) =>
                `${params.row.expedient.teus || 'Sin informacion'}`,
        },
        {
            field: 'agenteAduanal',
            headerName: 'Agente Aduanal',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            width: 400,
            type: 'text',
            headerAlign: 'center',
            valueGetter: (params) =>
                `${params.row.customAgend && params.row.customAgend.agentname || 'Sin informacion'} `,
        },
        {
            field: 'mode',
            headerName: 'MODE',
            sortable: false,
            width: 150,
            type: 'text',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.pickupDelivery.mode || 'Sin informacion'}`,
        },
        {
            field: 'modalidad',
            headerName: 'Modalidad',
            sortable: false,
            width: 150,
            type: 'text',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.modalityType.fcl == true ? 'Maritimo FCL' : params.row.modalityType.lcl === true ? 'Maritimo LCL' : params.row.expedient.team === 'Aereo' ? 'Aerea' : params.row.expedient.team === 'FTL' && 'Terrestre' || 'Sin informacion'}`,
        },
        {
            field: 'pickup',
            headerName: 'Pick Up',
            sortable: false,
            width: 150,
            type: 'text',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.pickupDelivery.pickUp || 'Sin informacion'}`,
        },
        {
            field: 'pol',
            headerName: 'POL',
            sortable: false,
            width: 250,
            type: 'text',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            valueGetter: (params) =>
                `${params.row.originPort.port || 'N/A'}`,
        },
        {
            field: 'pod',
            headerName: 'POD',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            width: 250,
            type: 'text',
            headerAlign: 'center',
            valueGetter: (params) =>
                `${params.row.destinyPort.port || 'N/A'}`,
        },
        {
            field: 'fpod',
            headerName: 'FPOD',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            width: 150,
            type: 'text',
            headerAlign: 'center',
            valueGetter: (params) =>
                `${params.row.pickupDelivery.fpod || 'Sin informacion'}`,
        },
        {
            field: 'etd',
            headerName: 'ETD',
            sortable: false,
            width: 150,
            type: 'date',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.expedient.etd || 'Sin informacion'}`,
        },
        {
            field: 'eta',
            headerName: 'ETA',
            sortable: false,
            width: 150,
            type: 'date',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) =>
                `${params.row.expedient.eta || 'Sin informacion'}`,
        },
        {
            field: 'diasDemora',
            headerName: 'Dias libre de demora',
            sortable: false,
            width: 150,
            type: 'number',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            valueGetter: (params) =>

                `${params.row.arrivalDates.daysOfDelay || 'Sin informacion'}`,
        },
        {
            field: 'ultimoDiaDemora',
            headerName: 'Ultimo dia de demora',
            sortable: false,
            width: 150,
            type: 'number',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            valueGetter: (params) =>
                `${params.row.paymentDetails.paydayLimit || 'Sin informacion'}`,
        },
        {
            field: 'action',
            headerName: 'Acciones',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            width: 150,
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <ActionMenu id={[params.row.id]} >
                        <MenuItem onClick={() => onClickActionMenu('view', params.row.id, params)}><img src={view} /> Ver registro</MenuItem>
                        <MenuItem onClick={() => onClickActionMenu('documentation', params.row.id, params)}><img src={document} /> Documentación</MenuItem>
                        <MenuItem onClick={() => onClickActionMenu('route', params.row.id, params)}><img src={route} /> Ruta</MenuItem>
                        <MenuItem onClick={() => onClickActionMenu('tracing', params.row.id, params)}><img src={follow} /> Seguimientos</MenuItem>
                        <MenuItem onClick={() => onClickActionMenu('delay', params.row.id, params)}><img src={delate} /> Demora</MenuItem>
                        <MenuItem onClick={() => onClickActionMenu('close', params.row.id, params)/* toggleModal({type:'closeRecordError',id}) */}><img src={close} /> Cerrar expediente</MenuItem>
                        <MenuItem onClick={() => toggleModal({ type: 'eliminar', id: params.row.id })}><img src={trash} /> Eliminar registro</MenuItem>

                    </ActionMenu>
                );
            },
        }
    ];


    const toggleModal = ({ type, id }) => {
        if (openModal === true) {
            setItemSelected(null)
        } else {
            setItemSelected({ type, id })
            setOpenModal(!openModal);
        }
        setOpenModal(!openModal);
    }

    const onClickActionMenu = (actionSelected, id, data) => {
        switch (actionSelected) {
            case 'view':
                history.push({
                    pathname: RECORDS_REGISTER.replace(':id', id),
                    payload: data,
                });
                break;
            case 'documentation':

                history.push({
                    pathname: RECORDS_DOCUMENTATION.replace(':id', id),
                    payload: data,
                });
                break;
            case 'route':
                history.push({
                    pathname: RECORDS_ROUTE.replace(':id', id),
                    payload: data,
                });
                break;
            case 'tracing':
                history.push(
                    {
                        pathname: `${RECORDS_VIEW.replace(':id', id)}`,
                        payload: data,
                    });
                break;
            case 'delay':
                history.push(
                    {
                        pathname: `${RECORDS_DELAY.replace(':id', id)}`,
                        payload: data,
                    });
                break;
            case 'close':

                if ((
                    //expedient validation
                    (data.row.expedient.arrivalPortDate) &&
                    (data.row.expedient.clientId) &&
                    (data.row.expedient.departureDate) &&
                    (data.row.expedient.eta) &&
                    (data.row.expedient.etd) &&
                    (data.row.expedient.folioTl) &&
                    (data.row.expedient.mov) &&
                    (data.row.expedient.naviera) &&
                    (data.row.expedient.noContainers) &&
                    (data.row.expedient.numberMbl) &&
                    (data.row.expedient.quiantityContainers) &&
                    (data.row.expedient.team) &&
                    (data.row.expedient.teus) &&

                    //arrivalDate validation
                    (data.row.arrivalDates.arrivalDate) &&
                    (data.row.arrivalDates.dateFourteenDaysAfterArrival) &&
                    (data.row.arrivalDates.dateSevenDaysAfterArrival) &&
                    (data.row.arrivalDates.dateThreeDaysAfterArrival) && (data.row.arrivalDates.daysOfDelay) &&
                    (data.row.arrivalDates.lastDayOffDelay) &&

                    //CustomDocuments validation
                    (data.row.customDocuments.customAgentId) &&

                    //Delay validation
                    // (data.row.delay.daysOfDelayReturnEmpty) &&
                    // (data.row.delay.emptyReturn) &&
                    // (data.row.delay.isPaymentForDelays) &&
                    // (data.row.delay.lastDayOfDelays) &&
                    // (data.row.delay.paymentSettlementDate) &&
                    // (data.row.delay.reasonForTheDelay) &&
                    // (data.row.delay.totalPaymentForDelays) &&

                    //paymentDetails validation
                    (data.row.paymentDetails.creditDays) &&
                    (data.row.paymentDetails.dateLocalCharges) &&
                    (data.row.paymentDetails.localCharges) &&
                    (data.row.paymentDetails.paydayLimit) &&
                    (data.row.paymentDetails.payment) &&
                    (data.row.paymentDetails.paymentAgent) &&
                    (data.row.paymentDetails.paymentDate) &&
                    (data.row.paymentDetails.paymentDateAgent) &&
                    (data.row.paymentDetails.paymentDateNaviera) &&
                    (data.row.paymentDetails.paymentNaviera) &&

                    //pickupDelivery deviation
                    (data.row.pickupDelivery.deliveryType) &&
                    (data.row.pickupDelivery.emptyReturnDate) &&
                    (data.row.pickupDelivery.railArrivalDate) &&
                    (data.row.pickupDelivery.railwayDeliveryDate) &&

                    //Route
                    // (data.row.route.changeNaviera) &&
                    // (data.row.route.daysLate) &&
                    // (data.row.route.reasonDelay) &&
                    // (data.row.route.reasonDeviations) &&
                    // (data.row.route.deviation) &&
                    (data.row.route.tracing))
                    &&
                    (data.row.closeFile == false)

                ) {
                    history.push(
                        {
                            pathname: `${RECORDS_CLOSURE.replace(':id', id)}`,
                            payload: data,
                        });
                } else if (data.row.closeFile == true) {

                    dispatch(addNotification({
                        toastType: 'error',
                        toastTitle: 'Este expediente ya ha sido cerrado',
                    }));

                } else {
                    dispatch(addNotification({
                        toastType: 'error',
                        toastTitle: 'No se puede cerrar el expediente si aun faltan campos por completar',
                    }));
                }
                break;
            case 'deleteRecord':
                props.deleteRecordAction({ id: id });
                break;
            default:
                break;
        }
    }

    const deleteUser = async (id) => {
        const res = await props.deleteRecordAction({ "id": id, "deletedBy": adminId })
        if (res) {
            setOpenModal(false)
            setItemSelected(null)
            dispatch(getAllExpedientAction({ "offset": 0, "limit": 200 }))

        }
    }

    const localizedTextsMap = {

        //Fileters operator text
        columnMenuUnsort: "Desclasificar",
        columnMenuSortAsc: "Ordenar por ASC",
        columnMenuSortDesc: "Ordenar por DESC",
        columnMenuFilter: "Filtrar",
        columnMenuHideColumn: "Esconder",
        columnMenuShowColumns: "Mostrar colunas",
        noRowsLabel: "No existen registros",
        noResultsOverlayLabel: 'No se han encontrado resultados',
        errorOverlayDefaultLabel: 'Ocurrió un error.',

        //Filter operator
        filterPanelColumns: 'Columnas',
        filterPanelOperators: 'Operador',
        filterPanelInputLabel: 'Valor',
        filterPanelInputPlaceholder: 'Valor de filtro',

        // Filter operators text
        filterOperatorContains: 'Contiene',
        filterOperatorEquals: 'Igual',
        filterOperatorStartsWith: 'Comienza con',
        filterOperatorEndsWith: 'Termina con',
        filterOperatorIs: 'Es',
        filterOperatorNot: 'No es',
        filterOperatorAfter: 'Es despues de',
        filterOperatorOnOrAfter: 'Está encendido o después',
        filterOperatorBefore: 'Es antes',
        filterOperatorOnOrBefore: 'Está en o antes',
        filterOperatorIsEmpty: 'Está vacío',
        filterOperatorIsNotEmpty: 'No está vacío',
        filterOperatorIsAnyOf: 'Es cualquiera de',


        // Columns panel text
        columnsPanelTextFieldLabel: 'Buscar columna',
        columnsPanelTextFieldPlaceholder: 'Título de la columna',
        columnsPanelDragIconLabel: 'Reordenar columna',
        columnsPanelShowAllButton: 'Mostrar todo',
        columnsPanelHideAllButton: 'Ocultar todo',
    };

    return (
        <>
            {
                openModal && itemSelected.type === 'eliminar' &&
                <Modal
                    isOpen={toggleModal}
                    toggle={toggleModal}
                >
                    <CardContainer
                        btnLeftAction={toggleModal}
                        btnRightAction={() => deleteUser(itemSelected.id)}
                        body={<DeleteBody user={'expediente'} />}
                    />
                </Modal>
            }
            {
                openModal && itemSelected.type === 'closeRecordError' &&
                <Modal
                    isOpen={toggleModal}
                    toggle={toggleModal}
                >
                    <CardContainer
                        noCancelButton
                        btnRightAction={toggleModal}
                        btnRightText={'ACEPTAR'}
                        body={<BaseModalBody data={closeRecordError} />}
                    />
                </Modal>
            }


            <section className='ClientRecordAccountSection' >
                <div className='ClientRecordAccountSection__container'>
                    <div className='ClientRecordAccountSection__headerContainer'>
                        <div className='HomeTableSection__containerButtons'>
                            <Link
                                className={'linkClient'}
                                to={'/dashboard/record-delete'}>
                                <button
                                    className={'containerButtons__clientButton'}
                                    srcicon={delate}
                                    onClick={() => history.push('/dashboard/record-delete')}
                                >
                                    PAPELERA
                                </button>
                            </Link>
                        </div>
                    </div>


                    <div className='ClientRecordAccountSection__tableContainer'>

                        <div
                            style={{ width: '100%', overflow: 'auto' }}

                        >

                            <Box sx={{
                                height: 400,
                                width: '100%',
                                '& .super-app-theme--header': {
                                    backgroundColor: '#707070',
                                    color: '#ffff',
                                    fontFamily: 'Montserrat',
                                    fontSize: '.9vw',
                                },
                            }}>

                               {/* / <div style={{height:500, width:'100%'}}> */}

                                    <DataGrid
                                        sx={{
                                            '@media print': {
                                                '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)' },
                                            },
                                            '.MuiDataGrid-virtualScroller': {
                                                    // background:'#f2f',
                                                overflow: 'auto',
                                                width: '100%',
                                                height: 500,
                                                // overflowX: 'scroll'
                                            }
                                        }}
                                        localeText={localizedTextsMap}
                                        rows={record}
                                        columns={columnsGrid}
                                        pageSize={10}
                                        rowsPerPageOptions={[10]}
                                        components={{ Toolbar: descargarExpedient }}
                                        disableSelectionOnClick
                                        experimentalFeatures={{ newEditingApi: true }}
                                        scrollbarSize={10}              
                                        apiRef={apiRef}
                                    // autoHeight={true}

                                    />

                                {/* </div> */}

                            </Box>
                        </div>
                    </div>
                </div >
            </section >
        </>
    );
}

Records.propTypes = {
    history: PropTypes.any,
    dispatch: PropTypes.any,
    getAllExpedientAction: PropTypes.any,
    admins: PropTypes.any,
    deleteRecordAction: PropTypes.any,
    upsertExpedient: PropTypes.any,
    isSuccessDeleteAdmin: PropTypes.any,
    value: PropTypes.any,
};

function mapStateToProps(state) {
    return {
        admins: _.get(state, 'admin.admins', {}),
        isSuccessDeleteAdmin: _.get(state, 'admin.deleteAdmin.isSuccess', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    getAllExpedientAction: params => dispatch(getAllExpedientAction(params)),
    deleteRecordAction: params => dispatch(deleteRecordAction(params)),
    upsertExpedient: params => dispatch(upsertExpedient(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Records);
