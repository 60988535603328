import request from "../../utils/request";
import { addNotification } from './notificationAction';
import {
    PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_BEGIN,
    PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_SUCCESS,
    PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_FAILURE
} from './../actionTypes';

// ACTIONS TO REDUCER

export const getAllPermissionRecordBegin = () => ({
    type: PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_BEGIN,
});

export const getAllPermissionRecordSuccess = (data) => ({
    type: PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_SUCCESS,
    data
});

export const getAllPermissionRecordFailure = (err) => ({
    type: PERMISSIONS_MODULE_GET_ALL_PERMISSIONS_FAILURE,
    err
});



// DISPATCH TO ACTIONSv

export function getAllPermissionsAction(data){
    return (dispatch, getState) => {
        dispatch(getAllPermissionRecordBegin());
        return request({
            partialUrl: `/api/v1/permission/get-all-permissions-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(getAllPermissionRecordSuccess(data.data));
            return data.data;
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllPermissionRecordFailure(err));
        });
    };
}


