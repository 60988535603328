import React, { useEffect } from 'react';

import FormLogIn from './FormLogIn';
import logo from './../../../assets/images/logo.png';
import './../../../components/templates/FullScreenLayout/FullScreenLayout.scss';
import { useParams } from 'react-router-dom';
import { FormRecoverPass } from '../RecoverPassword/FormRecoverPass';


const Login = () => {

    const { action } = useParams();

    useEffect(() => {
    }, [action])

    return (
        <>
            <div className='content-Login'>
                <div className='subcontainer-login'>

                    <img className='img-logo' src={logo}></img>
                  
                    {action === 'iniciar-sesion' ?

                        <FormLogIn to={'/app/recuperar-password'} />

                        : action === 'recuperar-password' &&

                        <FormRecoverPass />


                    }

                </div>

            </div>

        </>
    )
}

export default Login;
