/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { Button } from '../../../../components/atoms';
// import '../CustomAgent.scss';
// import './ClientRegister.scss';
import '../../../../styles/stylesSteppers.scss'
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import seeButton from '../../../../assets/images/navieras/seeButton.png'
import add from '../../../../assets/images/navieras/plus-icon.png'
import { useDispatch, useSelector, connect } from 'react-redux';
import { deleteAssociation, getAllClientBelongsCustomAgent, upsertCustomAgentAction } from '../../../../redux/actions/customsAgentAction';
import { useHistory, useParams } from 'react-router-dom';
import { CUSTOMS_AGENT } from '../../../../routes/Paths';
import { getAllAvailableClientsAction } from '../../../../redux/actions/clientAction';
import { addNotification } from '../../../../redux/actions/notificationAction';

export const ClientsCustomAgents = ({ stepper, setStepper, toggleModal, setSelectedModal, formData, setFormData, disabled, setClientsAvailables, clientss, setClientAssigned, clientAssigned }) => {
    const methods = useForm({ defaultValues: defaultValues });
    const dispatch = useDispatch();
    const offset = 0;
    const limit = 2;
    const history = useHistory();
    const { mode } = useParams();
    const [isDisabled, setIsDisabled] = useState(mode === 'ver' ? true : false);

    const clients = useSelector(state => state.customAgent.getClientBelongsCustomAgent);
    const associationDelete = useSelector(state => state.customAgent.deleteAssociation);
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;

    const [rightbuttonName, setRightbuttonName] = useState(mode === 'ver' ? 'ACEPTAR' : 'GUARDAR');

    // const agend = JSON.stringify(formData && formData.agend ? formData.agend : []);


    const {
        agentname,
        email,
        id,
        patent,
        phone,
        tradename,
        media,
        file,
        mediasDeleted,
        deletedAssociationPorts,


    } = formData

    const agent = {
        agentname,
        email,
        patent,
        phone,
        tradename,
        media: media && media.media ? null : media && media ,
    }

    const agend = JSON.stringify(agent)



    const defaultValues = {
        // name : '',
    };

    const changeStage = () => {
        setStepper(stepper + 1)
    }
    const changeStageBack = () => {
        setStepper(stepper - 1)
    }


    useEffect(() => {
        const customAgentId = formData.id;
        if (formData) {
            dispatch(getAllClientBelongsCustomAgent({ customAgentId, offset, limit }))
        } if (associationDelete && associationDelete.isSuccess) {
            dispatch(getAllClientBelongsCustomAgent({ customAgentId, offset, limit }))
        }


    }, [associationDelete])




    const deleteAsign = (id) => {
        dispatch(deleteAssociation({ id }))
    }


    const seeCustomer = (id, payload,file,person) => {

        history.push(
            {
                pathname: `/dashboard/gestion-clientes/${id}/ver`,
                state:{...payload, "file": file, "person":person}
            }
        );
    }


    const preAssingClient = async (id) => {
        const res = await dispatch(getAllAvailableClientsAction({ "customAgendId": id }));

        if (res) {
            toggleModal(1, id);
        } else {
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de clientes, favor de intentarlo de nuevo',
            }));
        }
        toggleModal();
        setSelectedModal('assign client');
        setClientsAvailables(clients)

    }


    const onSubmit = async () => {

        if (mode !== "ver") {
            const formData_ = new FormData();

            if (_.isEmpty(file)) { toggleModalNoFiles() }



            formData_.append("id", formData.id)




            const portsSend = []

            for (let i = 0; i < formData.ports.length; i++) {
                if (formData.ports[i].port === undefined) {
                    portsSend.push(formData.ports[i].id);
                }
            }

            let delPort = JSON.stringify(formData.deletedAssociationPorts
                );


            formData_.append("agend", agend);
            formData_.append("ports", JSON.stringify(portsSend));
            formData_.append("deletedAssociationPorts", JSON.stringify(deletedAssociationPorts));
            formData_.append("mediasDeleted", JSON.stringify(mediasDeleted));


            const arrFiles = file.map(file => file.file != null ? file : false).filter(file => file != false)

            if(arrFiles.length > 0){
                for (let i = 0; i < arrFiles.length; i++) {
                    if (arrFiles[i].newAdd) {
                    formData_.append("File", arrFiles[i].file);
                    }
                }
            }else{
                formData_.append("File", '[]');
            }

            if (!formData_.has("File")) {
                formData_.append("File", '[]');
            }

            const res = await dispatch(upsertCustomAgentAction(formData_))
            if (res) {
                history.push('/dashboard/agentes-aduanales');
            }
        }
        history.push('/dashboard/agentes-aduanales');

    }

    return (
        <>
            <form className='main-container-generic-type'>
                <div className='header-form-generic-type'>
                    <h2>Clientes asignados</h2>
                </div>
                <div className='body-form-generic-type-data'>

                    {
                        mode !== "ver" &&
                        <div className='button-head-container'>

                            <Button type="button" onClick={() => preAssingClient(formData.id)} style={{ margin: 0 }} srcicon={add}>ASIGNAR</Button>
                        </div>
                    }
                    <div>
                        {
                            clients && clients.data && clients.data.associationAgendClient
                            && clients.data.associationAgendClient.map((ele, i) => {
                                return (
                                    <div key={i} className='customer-agent-container'>
                                        <div className='customer-agent-row'>
                                            <h2>{ele.client && ele.client.tradename}</h2>
                                            <img onClick={() => seeCustomer(ele.idClient, ele.client,ele.file, ele.person)} src={seeButton} alt="" />
                                            <button type="button" disabled={isDisabled} onClick={() => deleteAsign(ele.id)} style={{ margin: '0' }}>QUITAR ASIGNACION</button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='footer-form-generic-type' style={{ justifyContent: 'space-between' }}>
                    <Button type='button' onClick={() => mode == 'ver' || mode == 'editar' ? changeStageBack() : (toggleModal(), setSelectedModal('cancel'))} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                    <Button type='button' onClick={onSubmit} className={'button-next'} style={{ width: '40%' }}>{rightbuttonName}</Button>
                </div>
            </form>
        </>
    )
}

function mapStateToProps(state) {
    return {
        clients: _.get(state, 'client.clientsAvailableByCustomerAgent', []),
        customsAgents: _.get(state, 'customAgent.customAgents', {}),
    };
}

const mapDispatchToProps = dispatch => ({
});

connect(mapStateToProps, mapDispatchToProps)(ClientsCustomAgents);
