/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import _ from 'lodash';
import { Button } from '../../components/atoms';
import '../../styles/stylesSteppers.scss';
import './PortDataForm.scss';
import '../ProvidersDashboard/ProviderPrincipalData.scss';
import '../../components/templates/BaseLayout/Profile/ProfileFormStyles.scss';
import { upsertPortAction } from '../../redux/actions/portAction';
import { getAllCountriesAction, getAllStatesAction } from '../../redux/actions/addressAction';
import { addNotification } from '../../redux/actions/notificationAction';
import { updatePasswordRequestAction } from '../../redux/actions/userActions';
import { PORTS } from '../../routes/Paths';

// const countries = [
//     {
//         id: 1,
//         name: 'Argentina',
//     },
//     {
//         id: 2,
//         name: 'Bélgica',
//     },
//     {
//         id: 3,
//         name: 'Colombia',
//     },
//     {
//         id: 4,
//         name: 'Dinamarca',
//     },
// ];

// const states = [
//     {
//         id: 1,
//         name: 'Aguascalientes',
//     },
//     {
//         id: 2,
//         name: 'Colima',
//     },
//     {
//         id: 3,
//         name: 'Durango',
//     },
//     {
//         id: 4,
//         name: 'Jalisco',
//     },
//     {
//         id: 5,
//         name: 'Nuevo León',
//     },
// ];

const PortData = ({ currentData, toggleModal, setValue, mode }) => {

    let { id } = useParams();
    const dispatch = useDispatch();

    const disabled = mode;
    useEffect(() => {
        dispatch(getAllCountriesAction({}))
        if (currentData && !mode) {
            dispatch(getAllStatesAction(
                {
                    where: {
                        countryId: currentData.countryId
                    }
                }
            ));
        }
    }, []);

    // useEffect(() => {
    //     if(_.get(countries, 'data')){
    //     }else{
    //     }

    // }, [countries])

    const countries = useSelector(state => state.address.countries.data);
    const states = useSelector(state => state.address.states.data);
    const [countryDefault, setCountryDefault] = useState(currentData && currentData.country ? currentData.country : '');
    const [countrySelected, setCountrySelected] = useState(currentData && currentData.country ? currentData.countryId : '');
    const [stateSelected, setStateSelected] = useState(currentData && currentData.state ? currentData.state.Id : 0);
    const [stateDefault, setStateDefault] = useState(currentData && currentData.state ? currentData.state : '');
    const [portName, setPortName] = useState(currentData && currentData.port ? currentData.port : '');
    const [executiveName, setExecutiveName] = useState(currentData && currentData.executiveName ? currentData.executiveName : '');
    const [position, setPosition] = useState(currentData && currentData.job ? currentData.job : '');
    const [email, setEmail] = useState(currentData && currentData.email ? currentData.email : '');
    const [phone, setPhone] = useState(currentData && currentData.phone ? currentData.phone : '');

    const methods = useForm({ defaultValues: [] });
    const { register, handleSubmit, control, reset, formState: { errors }, } = methods;

    const onSubmit = async () => {
        let res;
        let data = {
            id: currentData && currentData.id ? currentData.id : 0,
            port: portName,
            executiveName: executiveName,
            job: position,
            phone: phone,
            email: email,
            countryId: countrySelected,
            stateId: stateSelected,
        }

        if (currentData == null) {
            delete data.id;
            console.warn(stateSelected);
            console.warn(countrySelected);
            res = await dispatch(upsertPortAction(data));
            console.warn(res);
        }
        else if (currentData != null) {
            res = await dispatch(upsertPortAction(data));
            console.warn(res);
        }
        if (res) {
            history.push(PORTS);
        }
    }

    const history = useHistory();
    const returnTable = () => {
        history.goBack();
    }

    const idPort = currentData !== null ? currentData.id : '';



    return (
        <>
            <div className='main-container-generic-type-quotation port'>
                <div className='header-form-generic-type'>
                    <h2>Datos principales</h2>
                    {
                        currentData !== null &&
                        <h3 className='id'>ID: {idPort}</h3>
                    }
                </div>
                <div className='port-options-container'>
                    <div className='body-form-generic-type-data port'>
                        <form className='form-admin-container port' onSubmit={handleSubmit(onSubmit)}>
                            <div className='body-form'>
                                <div className='horizontal-inputs-container'>
                                    <div className='input-container'>
                                        <div className='label-container'>
                                            <label className='container-label'>Nombre puerto*</label>
                                        </div>
                                        <div className='value-container'>
                                            <input
                                                required
                                                value={portName}
                                                control={control}
                                                name={'name'}
                                                disabled={disabled}
                                                className="input-generic"
                                                type="text" placeholder="Nombre del puerto"
                                                onChange={(e) => setPortName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='input-container'>
                                    <div className='label-container'>
                                            <label className='container-label'>País*</label>
                                        </div>
                                        <div className='value-container' >
                                            <select required className='input-select' control={control} value={countrySelected} disabled={disabled} onChange={(e) => {
                                                setCountrySelected(e.target.value);
                                                dispatch(getAllStatesAction(
                                                    {
                                                        where: {
                                                            countryId: parseInt(e.target.value)
                                                        }
                                                    }
                                                ));
                                            }}>
                                                <option className='option-selected' value={null} selected hidden>{countryDefault.name != null ? countryDefault.name : "Seleccionar"}</option>
                                                {
                                                    _.get(countries, 'data') &&
                                                    countries.data.map(ele => {
                                                        return <option value={ele.id}> {ele.name}</option>
                                                    })
                                                }
                                            </select >
                                        </div>
                                        {/* <div className='label-container'>
                                            <label className='container-label'>Nombre ejecutivo*</label>
                                        </div>
                                        <div className='value-container'>
                                            <input
                                                required
                                                value={executiveName}
                                                control={control}
                                                name={'lastName'}
                                                disabled={disabled}
                                                className="input-generic"
                                                type="text" placeholder="Nombre del ejecutivo"
                                                onChange={(e) => setExecutiveName(e.target.value)}
                                            />
                                        </div> */}
                                    </div>
                                </div>
                                {/* <div className='input-container'>
                                    <div className='label-container'>
                                        <label className='container-label'>Puesto*</label>
                                    </div>
                                    <div className='value-container'>
                                        <input 
                                            required
                                            value={position}
                                            control={control} 
                                            name={'email'} 
                                            className="input-generic" 
                                            type="text" 
                                            placeholder="Puesto"
                                            onChange={(e) => setPosition(e.target.value)}
                                        />
                                    </div>
                                </div> */}
                                <div className='horizontal-inputs-container'>
                                    {/* <div className='input-container'>
                                        <div className='label-container'>
                                            <label className='container-label'>Teléfono*</label>
                                        </div>
                                        <div className='value-container'>
                                            <input
                                                required
                                                value={phone}
                                                control={control}
                                                name={'position'}
                                                className="input-generic"
                                                type="text"
                                                disabled={disabled}
                                                placeholder="Teléfono"
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </div>
                                    </div> */}
                                    {/* <div className='input-container'>
                                        <div className='label-container'>
                                            <label className='container-label'>Correo*</label>
                                        </div>
                                        <div className='value-container'>
                                            <input
                                                required
                                                value={email}
                                                control={control}
                                                name={'position'}
                                                className="input-generic"
                                                type="text"
                                                disabled={disabled}
                                                placeholder="Correo"
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div> */}

                                </div>
                                <div className='horizontal-inputs-container'>
                                    <div className='input-container'>
                                    <div className='label-container'>
                                            <label className='container-label'>Estado*</label>
                                        </div>
                                        <div className='value-container' >
                                            <select required className='input-select' control={control} value={stateSelected} disabled={disabled} onChange={(e) => {
                                                setStateSelected(e.target.value);
                                            }}>
                                                <option className='option-selected' value={null} selected hidden>{stateDefault.name != null ? stateDefault.name : "Seleccionar"}</option>
                                                {
                                                    _.get(states, 'data') &&
                                                    states.data.map(ele => {
                                                        return <option value={ele.id}> {ele.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {/* <div className='label-container'>
                                            <label className='container-label'>País*</label>
                                        </div>
                                        <div className='value-container' >
                                            <select required className='input-select' control={control} value={countrySelected} disabled={disabled} onChange={(e) => {
                                                setCountrySelected(e.target.value);
                                                dispatch(getAllStatesAction(
                                                    {
                                                        where: {
                                                            countryId: parseInt(e.target.value)
                                                        }
                                                    }
                                                ));
                                            }}>
                                                <option className='option-selected' value={null} selected hidden>{countryDefault.name != null ? countryDefault.name : "Seleccionar"}</option>
                                                {
                                                    _.get(countries, 'data') &&
                                                    countries.data.map(ele => {
                                                        return <option value={ele.id}> {ele.name}</option>
                                                    })
                                                }
                                            </select >
                                        </div> */}
                                    </div>
                                    <div className='input-container'>
                                        {/* <div className='label-container'>
                                            <label className='container-label'>Estado*</label>
                                        </div>
                                        <div className='value-container' >
                                            <select required className='input-select' control={control} value={stateSelected} disabled={disabled} onChange={(e) => {
                                                setStateSelected(e.target.value);
                                            }}>
                                                <option className='option-selected' value={null} selected hidden>{stateDefault.name != null ? stateDefault.name : "Seleccionar"}</option>
                                                {
                                                    _.get(states, 'data') &&
                                                    states.data.map(ele => {
                                                        return <option value={ele.id}> {ele.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='footer-form-generic-type port' style={{ justifyContent: 'space-between' }}>
                    <Button type='button' onClick={toggleModal && currentData == null ? toggleModal : returnTable} className={'button-back'} style={{ width: '40%' }}>CANCELAR</Button>
                    <Button type='submit' onClick={mode ? () => history.goBack() : () => onSubmit()} className={'button-next'} style={{ width: '40%' }}>{currentData == null ? 'REGISTRAR' : 'GUARDAR'}</Button>
                </div>
            </div>
        </>
    )
}



export default PortData;
