/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { CancelFormBody } from '../../components/molecules/CancelFormBody/CancelFormBody';
import { CardContainer } from '../../components/molecules/CardContainer/CardContainer';
import Modal from '../../components/organisms/Modal/Modal';
import '../../styles/stylesSteppers.scss';
import '../OpertivesDashboard/OperativeRegister.scss';
import PortDataForm from './PortDataForm';
// import { OperativePrincipalDataForm } from './OperativePrincipalDataForm';
import { PORTS } from '../../routes/Paths';

const PortsRegister = (props) => {
    const history = useHistory();
    const currentData = _.get(props, 'location.state.payload', null); 
    const mode = _.get(props, 'location.state.mode', null); 
    
    const [openModal, setOpenModal] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);

    const toggleModal = () => {
        setOpenModal(!openModal);
    }
    
    const cancel = () => {
        history.push(PORTS)
    }

    return (
        <>
            {
                openModal && currentData == null &&
                <Modal
                    isOpen={toggleModal}
                    toggle={toggleModal}
                >
                    <CardContainer btnLeftAction={toggleModal} btnRightAction={cancel} body={<CancelFormBody/>}/>
                </Modal>
            }
            <div className='main-container'>
                <div className='card-container-stepper' style={{width: '65% !important', height: '60% !important'}}>
                    <PortDataForm toggleModal={toggleModal} currentData={currentData} mode={mode}/>
                </div>
            </div>
        </>
    )
}

export default PortsRegister;
