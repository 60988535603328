import React, { useState } from 'react';
import './QuotationForms.scss';
import {
    intercom,
    modality,
    deliveryFCL,
    containerType,
    sino,
    aditionalServices
} from './optionsQuotation';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCountriesAction, getAllStatesAction } from '../../redux/actions/addressAction';
import _ from 'lodash';
import { useLocation, useParams } from 'react-router-dom';

const MaritimeFCLQuotationForm = ({ principalDataMaritimeForm, setPrincipalDataMaritimeForm, countriesSelect, clientPorts, arrayContainers, setArrayContainers, selectValues, setSelectValues }) => {

    const countries = useSelector(state => state.address.countries.data);
    const statesOrigen = useSelector(state => state.address.statesOrigen.data);
    const statesDestino = useSelector(state => state.address.statesDestino.data);
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(true);
    const [disabledAs, setDisabledAs] = useState(true);
    const [disabledDelivery, setDisabledDelivery] = useState(false);
    const [disabledHarvest, setDisabledHarvest] = useState(false);
    const client = useSelector(state => state.client.getOneClients);
    const tradename = _.get(client, 'data[0].tradename', '')
    const container = _.get(principalDataMaritimeForm, 'containerTypes', [])
    
    // const container = useSelector(state => state.quotation.quotations.data)

    const [number, setNumber] = useState(0);

    const [updatedSelectValues, setUpdateSelectValues] = useState()
    // const [selectValues, setSelectValues] = useState();


    const { id } = useParams()
    const location = useLocation()


    useEffect(() => {

        // const newArray = container.map((ele) => ele.nameTypeContainer)

        if (id && selectValues.length == 0) {
            
            const newArrayData = container && container.map((ele) => ({id:ele.id , nameTypeContainer: ele.nameTypeContainer}))
            setSelectValues(newArrayData)
        }

    }, [principalDataMaritimeForm])





    const [totalSuma, setTotalSuma] = useState(0)
    // const [numContainers, setNumContainers] = useState();



    useEffect(() => {
        let suma = parseInt(principalDataMaritimeForm.originCharges) + parseInt(principalDataMaritimeForm.destinyCharges) + parseInt(principalDataMaritimeForm.destinyLocalChargesContainer) + parseInt(principalDataMaritimeForm.destinyLocalChargesBl);
        if (principalDataMaritimeForm.originCharges != '' && principalDataMaritimeForm.destinyCharges != '' && principalDataMaritimeForm.destinyLocalChargesContainer != '' && principalDataMaritimeForm.destinyLocalChargesBl != '') {
            setTotalSuma(suma)
        } else {
            setTotalSuma(0)
        }
        //  + parseInt(principalDataMaritimeForm.destinyCharges) + parseInt(principalDataMaritimeForm.destinyLocalChargesContainer) + parseInt(principalDataMaritimeForm.destinyLocalChargesBl))
    }, [principalDataMaritimeForm])

    let iterations = 0;

    // const [arrayContainers, setArrayContainers] = useState([])

    useEffect(() => {
        iterations = parseInt(principalDataMaritimeForm.containersNumber)
        setNumber(iterations)
        setArrayContainers(Array.from({ length: iterations }, (_, index) => index + 1))
        // changeData(arrayContainers, "containersNumber")
    }, [principalDataMaritimeForm])



    const changeData = (ele, value) => {
        const data = {
            ...principalDataMaritimeForm,
            "lcl": false,
            "fcl": true,
            [value]: ele,
        }
        setPrincipalDataMaritimeForm(data)
    }



    useEffect(() => {
        dispatch(getAllCountriesAction());
        if (_.get(principalDataMaritimeForm, "id")) {
            dispatch(getAllStatesAction(
                {
                    where: {
                        countryId: principalDataMaritimeForm.originCountryId
                    }, target: "Origen",
                }
            ));
            dispatch(getAllStatesAction(
                {
                    where: {
                        countryId: principalDataMaritimeForm.destinyCountryId
                    }, target: "Destino",
                }
            ));
        }
    }, [])


    useEffect(() => {
        if (principalDataMaritimeForm.modality === "SD-CY") {
            setDisabledDelivery(true)
            setDisabledHarvest(false)
        } else if (principalDataMaritimeForm.modality === "SD-SD") {
            setDisabledDelivery(false)
            setDisabledHarvest(false)
        } else if (principalDataMaritimeForm.modality === "CY-CY") {
            setDisabledDelivery(true)
            setDisabledHarvest(true)
        } else if (principalDataMaritimeForm.modality === "CY-SD") {
            setDisabledDelivery(false)
            setDisabledHarvest(true)
        }

    }, [principalDataMaritimeForm.modality])


    useEffect(() => {
       
        setSelectValues([])
        changeData([], "containerType")

    }, [principalDataMaritimeForm.containersNumber])




    const handleSelectChange = (event, index, x) => {
        const updatedSelectValues = [...selectValues];
        if ( x ){
            updatedSelectValues[index].nameTypeContainer = event.target.value;
            
        }else{
            updatedSelectValues.push({nameTypeContainer: event.target.value})

        }
        setSelectValues(updatedSelectValues);
        changeData(updatedSelectValues, "containerType")

    };



    return (
        <div className='quotation-form-general-container'>
            <div className='value-container-principal-data'>
                <p className='data-section-title'>Datos principales</p>
                <div className='data-section-container'>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Folio</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="text"
                                placeholder="Agregar"
                                value={principalDataMaritimeForm.invoice}
                                onChange={(e) => changeData(e.target.value, "invoice")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Nombre</label>
                        </div>
                        <div className='value-container'>
                            <input  /* control={control} name={'bussinesName'} {...register('bussinesName')} */
                                className="input-generic"
                                type="text"
                                placeholder="Agregar"
                                value={principalDataMaritimeForm.name}
                                onChange={(e) => changeData(e.target.value, "name")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Empresa*</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="text"
                                placeholder="Agregar"
                                value={tradename && tradename}
                                onChange={(e) => changeData(e.target.value, "bussines")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Incoterm*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataMaritimeForm.intercom} onChange={(e) => changeData(e.target.value, "intercom")}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    intercom &&
                                    intercom.map((ele, i) => {
                                        return <option key={i} value={ele.value}>{ele.value}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Modalidad*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataMaritimeForm.modality} onChange={(e) => changeData(e.target.value, "modality")}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    modality &&
                                    modality.map((ele, i) => {
                                        return <option key={i} value={ele.name}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Entrega*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataMaritimeForm.delivery} onChange={(e) => changeData(e.target.value, "delivery")} disabled={disabledDelivery} >
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    deliveryFCL &&
                                    deliveryFCL.map((ele, i) => {
                                        return <option key={i} value={ele.name}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Recoleccion</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataMaritimeForm.pickup} onChange={(e) => changeData(e.target.value, "pickup")} disabled={disabledHarvest}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    deliveryFCL &&
                                    deliveryFCL.map((ele, i) => {
                                        return <option key={i} value={ele.name}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Número de contenedores*</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="number"
                                placeholder="Agregar"
                                value={principalDataMaritimeForm.containersNumber}
                                onChange={(e) => changeData(e.target.value, "containersNumber")}
                            />
                        </div>
                    </div>
                    <>
                        {
                            arrayContainers && arrayContainers.length > 0 &&
                            arrayContainers.map((value, index) => (

                                <div className='input-container-form'>
                                    <div className='label-container'>
                                        <label className='container-label'>Tipo de contenedor {index + 1}*</label>
                                    </div>
                                    <div className='value-container-select' >
                                        <select key={index} className='input-select' value={selectValues && selectValues[index] && selectValues[index].nameTypeContainer} onChange={(e) => handleSelectChange(e, index, selectValues[index] )}>
                                            <option className='option-selected' value={value[index]} selected hidden>Seleccionar</option>
                                            {
                                                containerType &&
                                                containerType.map((ele, i) => {
                                                    return <option key={ele.value} value={ele.value}>{ele.value}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                            ))
                        }
                    </>
                </div>
            </div>
            <div className='value-container-origin-data'>
                <p className='data-section-title'>Datos de origen</p>
                <div className='data-section-container'>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>País*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataMaritimeForm.originCountryId} onChange={(e) => {
                                changeData(parseInt(e.target.value), "originCountryId");
                                dispatch(getAllStatesAction(
                                    {
                                        where:
                                        {
                                            countryId: parseInt(e.target.value)
                                        },
                                        target: "Origen",
                                    }
                                ));
                            }}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    countriesSelect !== null &&
                                    countriesSelect.map(ele => {
                                        return <option value={ele.countryId}>{ele.countryName}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Estado*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataMaritimeForm.originStateId} onChange={(e) => changeData(parseInt(e.target.value), "originStateId")}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    _.get(statesOrigen, 'data') &&
                                    statesOrigen.data.map(ele => {
                                        return <option value={ele.id}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>POL*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataMaritimeForm.originPortId} onChange={(e) => changeData(parseInt(e.target.value), "originPortId")}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    _.get(clientPorts, 'data') &&
                                    clientPorts.data.map((ele, i) => {
                                        if (principalDataMaritimeForm.originCountryId === ele.countryId) {
                                            return <option key={i} value={ele.portId}>{ele.portName}</option>
                                        }
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Cargos en origen</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="number"
                                placeholder="Agregar"
                                value={principalDataMaritimeForm.originCharges}
                                onChange={(e) => changeData(e.target.value, "originCharges")}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='value-container-destiny-data'>
                <p className='data-section-title'>Datos de destino</p>
                <div className='data-section-container'>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>País*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataMaritimeForm.destinyCountryId} onChange={(e) => {
                                changeData(parseInt(e.target.value), "destinyCountryId");
                                dispatch(getAllStatesAction(
                                    {
                                        where:
                                        {
                                            countryId: parseInt(e.target.value)
                                        },
                                        target: "Destino",
                                    }
                                ));
                            }}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    countriesSelect &&
                                    countriesSelect.map(ele => {
                                        return <option value={ele.countryId}>{ele.countryName}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Estado*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataMaritimeForm.destinyStateId} onChange={(e) => changeData(parseInt(e.target.value), "destinyStateId")}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    _.get(statesDestino, 'data') &&
                                    statesDestino.data.map(ele => {
                                        return <option value={ele.id}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>POD*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataMaritimeForm.destinyPortId} onChange={(e) => changeData(parseInt(e.target.value), "destinyPortId")}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    _.get(clientPorts, 'data') &&
                                    clientPorts.data.map((ele, i) => {
                                        if (principalDataMaritimeForm.destinyCountryId === ele.countryId) {
                                            return <option key={i} value={ele.portId}>{ele.portName}</option>
                                        }
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Días de demora*</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="number"
                                placeholder="Agregar"
                                value={principalDataMaritimeForm.delayDays}
                                onChange={(e) => changeData(e.target.value, "delayDays")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Días de almacenaje*</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="number"
                                placeholder="Agregar"
                                value={principalDataMaritimeForm.storageDays}
                                onChange={(e) => changeData(e.target.value, "storageDays")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Cargos en destino</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="number"
                                placeholder="Agregar"
                                value={principalDataMaritimeForm.destinyCharges}
                                onChange={(e) => changeData(e.target.value, "destinyCharges")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Flete Marítimo</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="text"
                                placeholder="Agregar"
                                value={principalDataMaritimeForm.destinySeaFreight}
                                onChange={(e) => changeData(e.target.value, "destinySeaFreight")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Flete Terrestre</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="text"
                                placeholder="Agregar"
                                value={principalDataMaritimeForm.destinyLandFreight}
                                onChange={(e) => changeData(e.target.value, "destinyLandFreight")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Cargos locales por contenedor</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="number"
                                placeholder="Agregar"
                                value={principalDataMaritimeForm.destinyLocalChargesContainer}
                                onChange={(e) => changeData(e.target.value, "destinyLocalChargesContainer")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Cargos locales por BL</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="number"
                                placeholder="Agregar"
                                value={principalDataMaritimeForm.destinyLocalChargesBl}
                                onChange={(e) => changeData(e.target.value, "destinyLocalChargesBl")}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='value-container-other'>
                <p className='data-section-title'>Otros</p>
                <div className='data-section-container'>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Servicio de T3</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataMaritimeForm.servicest3} onChange={(e) => changeData(e.target.value, "servicest3")}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    sino &&
                                    sino.map((ele, i) => {
                                        return <option key={i} value={ele.value}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Sevicio Adicional</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataMaritimeForm.aditionalService} onChange={(e) => { changeData(e.target.value, "aditionalService"); e.target.value === 'Otros' ? setDisabledAs(false) : setDisabled(true) }}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    aditionalServices &&
                                    aditionalServices.map((ele, i) => {
                                        return <option key={i} value={ele.value}>{ele.value}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Otros:</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="text"
                                placeholder="Agregar"
                                disabled={disabledAs}
                                value={principalDataMaritimeForm.other}
                                onChange={(e) => changeData(e.target.value, "other")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Despacho en México por TLP*</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataMaritimeForm.officeMxTlp} onChange={(e) => { changeData(e.target.value, "officeMxTlp"); e.target.value === 'true' ? setDisabled(false) : setDisabled(true) }}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    sino &&
                                    sino.map((ele, i) => {
                                        return <option key={i} value={ele.value}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Agencia</label>
                        </div>
                        <div className='value-container'>
                            <input
                                className="input-generic"
                                type="text"
                                placeholder="Agregar"
                                disabled={disabled}
                                value={principalDataMaritimeForm.agency}
                                onChange={(e) => changeData(e.target.value, "agency")}
                            />
                        </div>
                    </div>
                    <div className='input-container-form'>
                        <div className='label-container'>
                            <label className='container-label'>Seguro</label>
                        </div>
                        <div className='value-container-select' >
                            <select className='input-select' value={principalDataMaritimeForm.insurance} onChange={(e) => changeData(e.target.value, "insurance")}>
                                <option className='option-selected' value={null} selected hidden>Seleccionar</option>
                                {
                                    sino &&
                                    sino.map((ele, i) => {
                                        return <option key={i} value={ele.value}>{ele.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='value-container-total-amount'>
                <div className='input-container-total'>
                    <div className='label-container'>
                        <p className='container-label'>Total $</p>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic"
                            type="text"
                            placeholder="$"
                            value={totalSuma.toString()}
                            onChange={(e) => setTotalSuma(e.target.value)}
                        />
                    </div>
                </div>
                <div className='input-container-total'>
                    <div className='label-container'>
                        <p className='container-label'>Vigencia</p>
                    </div>
                    <div className='value-container'>
                        <input
                            className="input-generic date"
                            type="date"
                            min={'2023-05-12'}
                            max='2050-12-31'
                            value={principalDataMaritimeForm.validity}
                            onChange={(e) => changeData(e.target.value, "validity")}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MaritimeFCLQuotationForm;
