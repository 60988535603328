export default {
    global: {
        accept: 'Aceptar',
        cancel: 'Cancelar',
    },
    components: {
        atoms: {

        },
        molecules: {

        },
        organisms: {

        },
        templates: {

        },
    },
    authModule: {
        login: {
            title: 'Iniciar sesión en la plataforma',
            loginButtonText: "Iniciar sesión",
        },
    },
};
